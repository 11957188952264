import cscartApi from './cscart-api'

/**
 * Request api for login
 *
 * @param {string} email
 * @param {string} password
 */
export const login = (email: string, password: string) =>
  cscartApi
    .auth
    .login(
      email,
      password
    )

/**
 * Request api for oauth login
 *
 * @param {string} provider
 * @param {string} tokenId
 * @param {string} clientId
 */
export const socialLogin = (provider: string, tokenId: string, clientId: string) =>
  cscartApi
  .auth
  .socialLogin(provider, tokenId, clientId)

/**
 * Set user token for auth requests
 *
 * @param {string} token
 */
export const setToken = (token: string) =>
  cscartApi.setUserToken(token)
