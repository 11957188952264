import { map, switchMap, filter, catchError } from 'rxjs/operators'
import { from, of, EMPTY, } from 'rxjs'
import { ActionsObservable, ofType } from 'redux-observable'

import db from '../../db/models/'
import cscartApi from '../../api/'
import {
  RequestVendor,
  RequestVendorFailure,
  RequestVendors,
  RequestVendorsFailure,
  DbRequestVendor,
  DbRequestVendors,

  requestVendorsSuccess,
  requestVendorsFailure,

  requestVendorSuccess,
  requestVendorFailure,

  dbRequestVendor,
  dbRequestVendorSuccess,
  dbRequestVendorFailure,
  dbRequestVendors,
  dbRequestVendorsSuccess,
  setCurrentVendor,
  setVendorIsRequesting,
} from '../actions/vendor/VendorAction'
import VendorActionTypes from '../actions/vendor/VendorActionTypes'
import { switchFailedRequestActionToAction } from './utils/mapFailActionToAction'
import { RequestBlocksSuccess, RequestBlocksFailure, RequestBlocks } from '../actions'
import LayoutsActionsTypes from '../types/actions/Layouts'
import Vendor from '../../entities/vendor/Vendor'
import LayoutTypes from '../../constants/LayoutTypes'
import ApiError from '../../entities/error/ApiError'

/**
 * Request vendors from API
 */
export const requestVendorsEpic = (
  action$: ActionsObservable<RequestVendors>,
  state$: null,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(VendorActionTypes.REQUEST_VENDORS),
  switchMap((action: RequestVendors) =>
    from(
      api.vendors.getVendors(action.payload.selection)
    ).pipe(
      map((result: any) => {

        if (!result.data) {
          return requestVendorsFailure(
            new ApiError({
              status: result.status,
              message: result.message
            })
          )
        }

        return requestVendorsSuccess(
          result.data.sravendors,
          result.data.params,
          action.payload.shouldAppend
        ) 
      }),
      catchError((error) => {
        console.error(error)

        return of(requestVendorsFailure(
          new ApiError({
            status: 200,
            message: 'Server responded with an unexpected data'
          })
        ))
      })
    ),
  ),
)

/**
 * Requests a vendor from API
 */
export const requestVendorEpic = (
  action$: ActionsObservable<RequestVendor>,
  state$: null,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(VendorActionTypes.REQUEST_VENDOR),
  switchMap((action: RequestVendor) =>
    from(
      api.vendors.getVendor(action.payload.id)
    ).pipe(
      map((result: any) => {

        if (!result.data) {
          return requestVendorFailure(
            action.payload.id,
            new ApiError({
              status: result.status,
              message: result.message
            })
          )
        }
        
        return requestVendorSuccess(result.data)
      }),
      catchError((error) => {
        console.error(error)

        return of(requestVendorFailure(
          action.payload.id,
          new ApiError({
            status: 200,
            message: 'Server responded with an unexpected data'
          })
        ))
      })
    )
  ),
)

/**
 * Should request DB for a vendor on network error
 */
export const mapApiRequestFailureToDbRequestEpic = (action$: ActionsObservable<RequestVendorFailure>) =>
  switchFailedRequestActionToAction<RequestVendorFailure, DbRequestVendor>(
    action$,
    VendorActionTypes.REQUEST_VENDOR_FAILURE,
    (id) => dbRequestVendor(id)
  );

/**
 * Get vendor from DB
 *
 * @param action$
 * @param state$
 * @param param2
 */
export const dbRequestVendorEpic = (
  action$: ActionsObservable<DbRequestVendor>,
  state$: null,
  { indexedDb }: { indexedDb: typeof db }
) => action$.pipe(
  ofType(VendorActionTypes.DB_REQUEST_VENDOR),
  // filter
  switchMap((action: DbRequestVendor) =>
    from(
      indexedDb.vendors.findVendor(action.payload.id)
    ).pipe(
      map(vendor =>
        vendor
        ?
          dbRequestVendorSuccess(vendor)
        :
          dbRequestVendorFailure(action.payload.id)
      ),
    )
  ),
)


/**
 * Should request DB for a vendors on network error
 */
export const mapVendorsApiRequestFailureToDbRequestEpic = (
  action$: ActionsObservable<RequestVendorsFailure>
) => action$.pipe(
  ofType(VendorActionTypes.REQUEST_VENDORS_FAILURE),
  // get only network error
  filter((action: RequestVendorsFailure) => action.payload.error.status === 0),
  switchMap((action: RequestVendorsFailure) => of(dbRequestVendors())),
)

/**
 * Get vendors from DB
 *
 * @param action$
 * @param state$
 * @param param2
 */
export const dbRequestVendorsEpic = (
  action$: ActionsObservable<DbRequestVendors>,
  state$: null,
  { indexedDb }: { indexedDb: typeof db }
) => action$.pipe(
  ofType(VendorActionTypes.DB_REQUEST_VENDORS),
  // filter
  switchMap(() =>
    from(
      indexedDb.vendors.findAllVendors()
    ).pipe(
      map(vendors =>
        dbRequestVendorsSuccess(vendors)
      ),
    )
  ),
)

/**
 * Set current vendor
 */
export const setCurrentVendorEpic = (
  action$: ActionsObservable<RequestBlocksSuccess>
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS_SUCCESS),
  switchMap((action) => {

    if (action.payload.entity && action.payload.entity instanceof Vendor) {
      return of(setCurrentVendor(action.payload.entity))
    }

    return EMPTY
  }),
)

/**
 * Map layout request with slug failure to db request for entity
 */
export const mapFailuredLayoutRequestToVendorRequestEpic = (
  action$: ActionsObservable<RequestBlocksFailure>,
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS_FAILURE),
  filter((action) => action.payload.dispatch === LayoutTypes.VENDOR && !!action.payload.slug),
  switchMap((action) =>
    of(dbRequestVendor(action.payload.slug!))
  ),
)

/**
 * set current vendor is loading true when map layout request for a vendor begins
 */
export const mapSetVendorIsRequestingOnLayoutEpic = (
  action$: ActionsObservable<RequestBlocks>,
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS),
  filter((action) => action.payload.dispatch === LayoutTypes.VENDOR && !!action.payload.slug),
  switchMap(() =>
    of(setVendorIsRequesting(true))
  ),
)

export default [
  requestVendorsEpic,
  requestVendorEpic,
  mapApiRequestFailureToDbRequestEpic,
  mapVendorsApiRequestFailureToDbRequestEpic,
  dbRequestVendorEpic,
  dbRequestVendorsEpic,

  setCurrentVendorEpic,
  mapFailuredLayoutRequestToVendorRequestEpic,
  mapSetVendorIsRequestingOnLayoutEpic,
]
