import forOwn from 'lodash/forOwn'

import IFormField from './IFormField'
import FieldTypes from '../../constants/FieldTypes'

class FormField implements IFormField {
  id:           string;
  type:         FieldTypes;
  name:         string;
  description:  string;
  isDefault:    boolean;
  isRequired:   boolean;
  values?:      Array<any>;

  constructor(rawField: any) {
    const field = {...rawField};

    this.id  = field.id || field.field_id;
    this.type  = castFieldType(field.type || field.field_type);

    /**
     * Fix for duplicate emails with different types
     */
    this.type  = (this.id === 'email') ? FieldTypes.EMAIL : this.type;

    this.name  = field.name || field.field_name;
    this.description  = field.description || '';
    this.isDefault  = field.isDefault || field.is_default || false;
    this.isRequired  = field.isRequired || field.required || false;

    const valuesArray: Array<any> = [];

    if (Array.isArray(this.values)) {
      this.values = this.values;
    } else {
      if (this.type === FieldTypes.SELECTBOX) {
        forOwn(field.values, (label, key) => {

          valuesArray.push({
            value: key,
            label: label
          })
        })

        this.values = valuesArray;
      }
      if (this.type === FieldTypes.SLAVE_SELECTBOX) {
        forOwn(field.values, (values, parentValue) => {
          valuesArray.push({
            parentValue: parentValue,
            values: []
          })

          forOwn(values, (label, key) => {
            (valuesArray.find((item) => item.parentValue === parentValue)).values.push({
              value: key,
              label: label
            })
          })
        })

        this.values = valuesArray;
      }
    }

    if (typeof this.id === 'undefined') {
      throw new Error('field ID is undefined')
    }
  }
}

const castFieldType = (type: string): FieldTypes => {
  switch (type) {
    case 'E':
    case FieldTypes.EMAIL:
      return FieldTypes.EMAIL

    case 'W':
    case FieldTypes.PASSWORD:
      return FieldTypes.PASSWORD

    case 'O':
    case FieldTypes.SELECTBOX:
      return FieldTypes.SELECTBOX

    /**
     * Slave selectbox goes right after master input field (select & etc)
     */
    case 'A':
    case FieldTypes.SLAVE_SELECTBOX:
      return FieldTypes.SLAVE_SELECTBOX

    case 'C':
    case FieldTypes.CHECKBOX:
      return FieldTypes.CHECKBOX

    case FieldTypes.MULTILINE_TEXT:
      return FieldTypes.MULTILINE_TEXT

    default:
      return FieldTypes.TEXT
  }
}

export default FormField
