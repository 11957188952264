import ICategorySeoSnippet from './ISeoSnippet'
import ISeoSnippet from '../seo/ISeoSnippet'

/**
 * Data for schema.org and html seo (title, meta description and etc.)
 */
class SeoSnippet implements ICategorySeoSnippet, ISeoSnippet {
  title:            string;
  metaDescription:  string;
  description:      string;
  name:             string;

  constructor(rawSeoSnippet: any) {
    const seoSnippet = {...rawSeoSnippet};

    this.name             = seoSnippet.name;
    this.description      = seoSnippet.description;
    this.metaDescription  = seoSnippet.metaDescription;
    this.title = seoSnippet.title;
  }
}

export default SeoSnippet
