import ReviewableEntities from '../../constants/ReviewableEntities'
import { createUuid } from './ReviewsThread'

import ITestimonialDraft from './ITestimonialDraft'

class TestimonialDraft implements ITestimonialDraft {
  parentId:           number;
  parentType:         ReviewableEntities;
  rating?:            number;
  text?:              string;
  isFinal:            boolean;

  constructor(rawDraft: ITestimonialDraft) {
    const draft = {...rawDraft};

    if (!draft.rating && !draft.text) {
      throw new Error('rating or text must be filled')
    }

    this.parentId   = draft.parentId;
    this.parentType = draft.parentType;
    this.rating     = draft.rating;
    this.text       = draft.text;
    this.isFinal    = !!draft.isFinal;
  }

  get uuid(): string {
    return createUuid(this.parentId, this.parentType)
  }
}

export default TestimonialDraft
