interface IProductOptionSelected {
  name:     string;
  value:    string|boolean;
  type:     ProductOptionSelectedTypes;
}

export enum ProductOptionSelectedTypes {
  /**
   * For values like: Capacity: 16GB
   */
  KEY_VALUE = 'KEY_VALUE',
  /**
   * For values like: Special delivery
   */
  VALUE     = 'VALUE'
}

export default IProductOptionSelected
