import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import Auth from './AuthRoute'
import * as actions from '../../redux/actions'
import { StoreState } from '../../redux/types'

function mapStateToProps(state: StoreState, ownProps: any) {
  return {
    isLogged: state.Auth.isLogged,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.LayoutsAction>) {
  return {
    // requestBlocksDrawer: () => dispatch(actions.requestBlocksDrawer()),
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth));
