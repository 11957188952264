

import { StoreState } from '../types'

import rehydrateAuth from './Auth'
import rehydrateWishlist from './Wishlist'
import rehydrateLayouts from './Layouts'
import rehydrateCart from './Cart'
import rehydrateUi from './Ui'

/**
 * Restore types after JSON unserialize
 * @param state - redux unserialized state object
 */
const rehydrate = (state: StoreState): StoreState => {
  if (!state) {
    return state
  }

  return {
    ...state,
    Auth:  rehydrateAuth({...state.Auth}),
    Wishlist: rehydrateWishlist({...state.Wishlist}),
    Layouts:  rehydrateLayouts({...state.Layouts}),
    Cart:  rehydrateCart({...state.Cart}),
    Ui:  rehydrateUi({...state.Ui}),
  }
}

export default rehydrate
