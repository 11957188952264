import AuthState from '../types/AuthState'

import Profile from '../../entities/auth/Profile'
import FormSection from '../../entities/form/FormSection'

const rehydrateAuth = (state: AuthState): AuthState => {
  return {
    ...state,
    profile: new Profile(state.profile),
    signupForm: {
      error: state.signupForm.error,
      form: state.signupForm.form.map((section) => new FormSection(section))
    }
  }
}

export default rehydrateAuth
