import { CartAction } from '../actions/Cart'
import CartState from '../types/CartState'
import CartActionsTypes from '../types/actions/Cart'
import AddToCart from '../../entities/cart/AddToCart'
import CartError from '../../entities/cart/CartError'
import CartEntity from '../../entities/cart/Cart'
import UserData from '../../entities/cart/UserData'
import ApiError from '../../entities/error/ApiError'
import CartUpdate from '../../entities/cart/CartUpdate'
import { OrderAction } from '../actions'
import OrderActionsTypes from '../types/actions/Order'

export const initialState: CartState = {
  isCartRequesting: false,
  cart: new CartEntity({}),
  additionQueue: [],
  additionQueueProducts: [],
  updateQueue: [],
  deletionQueue: [],
  additionQueueErrors: [],
  additionQueueErrorsProducts: [],
  checkoutForm: {
    form: []
  },
}

const appendToQueueAdd = (
  addToCartQueue: CartState['additionQueue'],
  addToCart: AddToCart
): CartState['additionQueue'] => {
  // find queue item for the same product
  const existingItem = addToCartQueue
    .find((item: AddToCart) => item.isEqual(addToCart))

  // increase amount for it and return queue back
  if (existingItem) {
    existingItem.addAmount(addToCart.amount)
    return addToCartQueue
  }

  // else add to queue new product
  return [
    ...addToCartQueue,
    addToCart
  ]
}

const appendToUpdateQueue = (
  updateQueue: CartState['updateQueue'],
  cartUpdate: CartUpdate
): CartState['updateQueue'] => {
  const existingItem = updateQueue
    .find(item => item.cartId === cartUpdate.cartId)

  if (existingItem) {
    existingItem.updateAddToCart(cartUpdate.addToCart)
    return [
      ...updateQueue,
    ]
  }

  return [
    ...updateQueue,
    cartUpdate
  ]
}

const removeFromUpdateQueue = (
  updateQueue: CartState['updateQueue'],
  cartId: string,
): CartState['updateQueue'] =>
  updateQueue
    .filter(item => item.cartId !== cartId)

const removeFromQueueAdd = (
  additionQueue: CartState['additionQueue'],
  addToCart: AddToCart,
): CartState['additionQueue'] =>
  additionQueue
    .filter(item => !item.isSame(addToCart))

let deletionQueue: Set<string>;

const appendToCartErrors = (
  additionQueueErrorsQueue: CartState['additionQueueErrors'],
  addToCart: AddToCart,
  apiError: ApiError,
): CartState['additionQueueErrors'] => {

  const cartError = new CartError({
    addToCart,
    error: apiError
  })

  const double = additionQueueErrorsQueue.find(error => cartError.addToCart.isEqual(error.addToCart));
  if (double) {
    double.error.message = apiError.message
    return additionQueueErrorsQueue
  }

  return [
    ...additionQueueErrorsQueue,
    cartError
  ]
}

const removeFromCartErrors = (
  additionQueueErrors: CartState['additionQueueErrors'],
  addToCart: AddToCart,
): CartState['additionQueueErrors'] =>
  additionQueueErrors
    .filter((item: CartError) => !item.addToCart.isEqual(addToCart))

const Cart = function cart(state: CartState = initialState, action: CartAction | OrderAction): CartState {
  switch (action.type) {
    case CartActionsTypes.ADD_TO_ADD_QUEUE:
      return {
        ...state,
        additionQueue: appendToQueueAdd(state.additionQueue, action.payload.addToCart),
      }

    case CartActionsTypes.REMOVE_FROM_ADD_QUEUE:
      return {
        ...state,
        additionQueue: removeFromQueueAdd(state.additionQueue, action.payload.addToCart),
        additionQueueProducts: state.additionQueueProducts.filter(product =>
          !product.getAddToCart().isSame(action.payload.addToCart)
        ),
      }

    case CartActionsTypes.FILL_QUEUE:
      return {
        ...state,
        additionQueueProducts: action.payload.products
      }

    case CartActionsTypes.CLEAR_QUEUE:
      return {
        ...state,
        additionQueueProducts: [],
      }

    case CartActionsTypes.REQUEST_CART:
      return {
        ...state,
        isCartRequesting: true,
      }

    case CartActionsTypes.REQUEST_CLEAR_CART:
      return {
        ...state,
        isCartRequesting: true,
      }

    case CartActionsTypes.REQUEST_CLEAR_CART_SUCCESS:
      return {
        ...state,
        isCartRequesting: false,
      }

    case CartActionsTypes.REQUEST_CLEAR_CART_FAILURE:
      return {
        ...state,
        isCartRequesting: false,
      }

    case CartActionsTypes.REQUEST_CART_SUCCESS:
      return {
        ...state,
        isCartRequesting: false,
        cart: {
          ...action.payload.cart,
          chosenPayment: state.cart.chosenPayment ? state.cart.chosenPayment : action.payload.cart.chosenPayment,
        }
      }

    case CartActionsTypes.REQUEST_CART_FAILURE:
      return {
        ...state,
        isCartRequesting: false,
      }

    case CartActionsTypes.CLEAR_CART:
      return {
        ...state,
        isCartRequesting: false,
        cart: new CartEntity({})
      }

    case CartActionsTypes.ADD_TO_UPDATE_QUEUE:
      return {
        ...state,
        updateQueue: appendToUpdateQueue(state.updateQueue, action.payload.cartUpdate),
      }

    case CartActionsTypes.REMOVE_FROM_UPDATE_QUEUE:
      return {
        ...state,
        updateQueue: removeFromUpdateQueue(state.updateQueue, action.payload.cartId),
      }

    case CartActionsTypes.ADD_TO_DELETE_QUEUE:
      deletionQueue = new Set(state.deletionQueue);
      deletionQueue.add(action.payload.cartId)

      return {
        ...state,
        deletionQueue: Array.from(deletionQueue)
      }

    case CartActionsTypes.REMOVE_FROM_DELETE_QUEUE:
      deletionQueue = new Set(state.deletionQueue);
      deletionQueue.delete(action.payload.cartId)

      return {
        ...state,
        deletionQueue: Array.from(deletionQueue)
      }

    case CartActionsTypes.ADD_TO_CART_ERRORS:
      return {
        ...state,
        additionQueueErrors: appendToCartErrors(state.additionQueueErrors, action.payload.addToCart, action.payload.error),
        additionQueue: removeFromQueueAdd(state.additionQueue, action.payload.addToCart),
        additionQueueProducts: state.additionQueueProducts.filter(product =>
          !product.getAddToCart().isSame(action.payload.addToCart)
        ),
      }

    case CartActionsTypes.REMOVE_FROM_CART_ERRORS:
      return {
        ...state,
        additionQueueErrors: removeFromCartErrors(state.additionQueueErrors, action.payload.addToCart)
      }

    case CartActionsTypes.FILL_ERROR_PRODUCTS:
      return {
        ...state,
        additionQueueErrorsProducts: action.payload.products
      }

    case CartActionsTypes.SET_SHIPPING_METHOD:
      return {
        ...state,
        cart: {
          ...state.cart,
          chosenShippings: state.cart.chosenShippings.map(
            (shippingId: number, groupIndex: number) => {
              return (
                groupIndex === action.payload.groupIndex
                ?
                  action.payload.shippingId
                :
                  shippingId
              )
            }
          )
        }
      }

    case CartActionsTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        cart: {
          ...state.cart,
          chosenPayment: action.payload.paymentId
        }
      }

    case CartActionsTypes.SET_PAYMENT_DATA:
      return {
        ...state,
        cart: {
          ...state.cart,
          paymentData: action.payload.data
        }
      }

    case CartActionsTypes.SET_USER_DATA:
      return {
        ...state,
        cart: {
          ...state.cart,
          userData: action.payload.userData
        }
      }

    case CartActionsTypes.REQUEST_UPDATE_USER_DATA_FAILURE:
      if (!state.cart.userData) {
        return state
      }

      return {
        ...state,
        cart: {
          ...state.cart,
          userData: new UserData({
            ...state.cart.userData,
            error: action.payload.error
          })
        }
      }

    case CartActionsTypes.RESET_CART_STATE:
      return initialState

    case CartActionsTypes.REQUEST_CHECKOUT_FORM_SUCCESS:
      return {
        ...state,
        checkoutForm: {
          form: action.payload.sections
        }
      }

    case OrderActionsTypes.REQUEST_ONLINE_PAYMENT_SUCCESS:
      return {
        ...state,
        redirectionForm: action.payload.redirectionForm,
      }
    default:
      return state
  }
}

export default Cart
