import Dexie from 'dexie'

import Database from '../Database'
import Category from '../../entities/category/Category'

/**
 * Save category to database
 *
 * @param {Category} category
 *
 * @returns void
 */
export const persistCategory = (category: Category) => {
  const categoryToSave = new Category({...category});

  categoryToSave.subcategories.forEach((category) => persistCategory(category))
  categoryToSave.setSubcategories([])

  Database.categories.put(categoryToSave)
}

/**
 * Find category by id or slug
 *
 * @example findCategory(15)
 * @example findCategory('electronics')
 *
 * @param {string|number} key
 *
 * @returns Dexie.Promise<Category|undefined>
 */
export const findCategory = (key: number|string): Dexie.Promise<Category|undefined> => {

  let query: any;
  if (typeof key === 'string') {
    query = {  slug: key };
  } else {
    query = key;
  }

  return new Dexie.Promise((resolve) => {
    Database.categories.get(query).then(
      (category) => {
        if (category) {

          Database.categories.filter(
            (subcategory: any) => !!subcategory.parents.find((parent: any) => category.id === parent.id)
          ).toArray().then((categories) => {
            categories.forEach((subcategory) => category.subcategories.push(new Category(subcategory)))
            resolve(category)
          })
        } else {
          resolve(undefined)
        }
      }
    );
  })
}
