import { connect,  } from 'react-redux'
import { Dispatch } from 'redux'

import { StoreState } from '../../redux/types'
import * as actions from '../../redux/actions'

import NavigationBar from './NavigationBar'
import { IStateProps, IOwnProps, IDispatchProps } from './INavigationBar'

export function mapStateToProps(state: StoreState): IStateProps {
  return {
    title:            state.NavigationBar.title,
    shouldShow:       state.NavigationBar.shouldShow,
    offline:          state.NavigationBar.offline,
    isSearchVisible:  state.NavigationBar.isSearchVisible,
    deviceType:       state.Ui.deviceType,
    shouldSetSearchFocusOnInit: state.NavigationBar.shouldSetSearchFocusOnInit,
  };
}

export function mapDispatchToProps(dispatch: Dispatch<actions.NavigationBarAction>): IDispatchProps {
  return {
    toggleSearch: () => dispatch(actions.toggleSearch()),
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, StoreState>(
    mapStateToProps,
    mapDispatchToProps
  )(NavigationBar)
