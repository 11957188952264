import ILanguage from './ILanguage'

class Language implements ILanguage {
  countryCode:  string;
  direction:    'ltr' | 'rtl';
  code:         string;
  name:         string;

  constructor(rawLanguage: any) {
    const language = {...rawLanguage};

    if ('countryCode' in language) {
      this.countryCode  = language.countryCode;
      this.direction    = language.direction;
      this.code         = language.code;
      this.name         = language.name;
    } else {
      this.countryCode  = language.country_code;
      this.direction    = language.direction;
      this.code         = language.lang_code;
      this.name         = language.name;
    }

    if (!this.code) {
      throw new Error('Language code is missing. Check incoming object')
    }
  }
}

export default Language
