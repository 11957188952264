import IConfig from './IConfig'

const envConfig = {
  layoutId:         process.env.REACT_APP_LAYOUT_ID,
  backendSiteUrl:   process.env.REACT_APP_SITE_URL,
  backendApiUrl:    process.env.REACT_APP_API_URL,
  backendApiKey:    process.env.REACT_APP_API_KEY,
  appName:          process.env.REACT_APP_WEBSITE_NAME,
  defaultLanguage:  process.env.REACT_APP_DEFAULT_LANGUAGE || 'en',
  defaultCurrency:  process.env.REACT_APP_DEFAULT_CURRENCY || 'USD',

  categorySmallIconWidth:   parseInt(process.env.REACT_APP_CATEGORY_SMALL_ICON_WIDTH || '500'),
  categorySmallIconHeight:  parseInt(process.env.REACT_APP_CATEGORY_SMALL_ICON_HEIGHT || '500'),

  categoryBigIconWidth:   parseInt(process.env.REACT_APP_CATEGORY_BIG_ICON_WIDTH || '1000'),
  categoryBigIconHeight:  parseInt(process.env.REACT_APP_CATEGORY_BIG_ICON_HEIGHT || '1000'),

  productSmallIconWidth:   parseInt(process.env.REACT_APP_PRODUCT_SMALL_ICON_WIDTH || '500'),
  productSmallIconHeight:  parseInt(process.env.REACT_APP_PRODUCT_SMALL_ICON_HEIGHT || '500'),

  productBigIconWidth:   parseInt(process.env.REACT_APP_PRODUCT_BIG_ICON_WIDTH || '1000'),
  productBigIconHeight:  parseInt(process.env.REACT_APP_PRODUCT_BIG_ICON_HEIGHT || '1000'),

  optionVariantSmallIconWidth:   parseInt(process.env.REACT_APP_OPTION_VARIANT_SMALL_ICON_WIDTH || '500'),
  optionVariantSmallIconHeight:  parseInt(process.env.REACT_APP_OPTION_VARIANT_SMALL_ICON_HEIGHT || '500'),

  optionVariantBigIconWidth:   parseInt(process.env.REACT_APP_OPTION_VARIANT_BIG_ICON_WIDTH || '1000'),
  optionVariantBigIconHeight:  parseInt(process.env.REACT_APP_OPTION_VARIANT_BIG_ICON_HEIGHT || '1000'),

  articleSmallIconWidth:   parseInt(process.env.REACT_APP_ARTICLE_SMALL_ICON_WIDTH || '500'),
  articleSmallIconHeight:  parseInt(process.env.REACT_APP_ARTICLE_SMALL_ICON_HEIGHT || '500'),

  articleBigIconWidth:   parseInt(process.env.REACT_APP_ARTICLE_BIG_ICON_WIDTH || '1000'),
  articleBigIconHeight:  parseInt(process.env.REACT_APP_ARTICLE_BIG_ICON_HEIGHT || '1000'),

  storefrontSettingsLifetime:
    process.env.REACT_APP_STOREFRONT_SETTINGS_LIFETIME
    ?
      parseInt(process.env.REACT_APP_STOREFRONT_SETTINGS_LIFETIME)
    :
      1000 * 60 * 30,

  layoutsLifetimeSeconds:
    process.env.REACT_APP_LAYOUTS_LIFETIME_SECONDS
    ?
      parseInt(process.env.REACT_APP_LAYOUTS_LIFETIME_SECONDS)
    :
      60 * 30,

  schedulerInterval:        15000, // ms
  windowResizeDebounceTime: 600, // ms
}

class Config {
  layoutId:                       string;
  backendSiteUrl:                 string;
  backendApiUrl:                  string;
  backendApiKey:                  string;
  appName:                        string;
  defaultLanguage:                string;
  defaultCurrency:                string;
  categorySmallIconWidth:         number;
  categorySmallIconHeight:        number;
  categoryBigIconWidth:           number;
  categoryBigIconHeight:          number;
  productSmallIconWidth:          number;
  productSmallIconHeight:         number;
  productBigIconWidth:            number;
  productBigIconHeight:           number;
  optionVariantSmallIconWidth:    number;
  optionVariantSmallIconHeight:   number;
  optionVariantBigIconWidth:      number;
  optionVariantBigIconHeight:     number;
  articleSmallIconWidth:          number;
  articleSmallIconHeight:         number;
  articleBigIconWidth:            number;
  articleBigIconHeight:           number;
  storefrontSettingsLifetime:     number;
  layoutsLifetimeSeconds:         number;
  schedulerInterval:              number;
  windowResizeDebounceTime:       number;

  constructor(data: IConfig) {
    this.layoutId                      = data.layoutId;
    this.backendSiteUrl                = data.backendSiteUrl;
    this.backendApiUrl                 = data.backendApiUrl;
    this.backendApiKey                 = data.backendApiKey;
    this.appName                       = data.appName;
    this.defaultLanguage               = data.defaultLanguage;
    this.defaultCurrency               = data.defaultCurrency;
    this.categorySmallIconWidth        = data.categorySmallIconWidth;
    this.categorySmallIconHeight       = data.categorySmallIconHeight;
    this.categoryBigIconWidth          = data.categoryBigIconWidth;
    this.categoryBigIconHeight         = data.categoryBigIconHeight;
    this.productSmallIconWidth         = data.productSmallIconWidth;
    this.productSmallIconHeight        = data.productSmallIconHeight;
    this.productBigIconWidth           = data.productBigIconWidth;
    this.productBigIconHeight          = data.productBigIconHeight;
    this.optionVariantSmallIconWidth   = data.optionVariantSmallIconWidth;
    this.optionVariantSmallIconHeight  = data.optionVariantSmallIconHeight;
    this.optionVariantBigIconWidth     = data.optionVariantBigIconWidth;
    this.optionVariantBigIconHeight    = data.optionVariantBigIconHeight;
    this.articleSmallIconWidth         = data.articleSmallIconWidth;
    this.articleSmallIconHeight        = data.articleSmallIconHeight;
    this.articleBigIconWidth           = data.articleBigIconWidth;
    this.articleBigIconHeight          = data.articleBigIconHeight;
    this.storefrontSettingsLifetime    = data.storefrontSettingsLifetime;
    this.layoutsLifetimeSeconds        = data.layoutsLifetimeSeconds;
    this.schedulerInterval             = data.schedulerInterval;
    this.windowResizeDebounceTime      = data.windowResizeDebounceTime;

    this.getConfigFromRootElement()
  }

  getConfigFromRootElement() {
    const rootElement = document.getElementById('root');

    if (!rootElement) {
      return
    }

    this.set('layoutId', rootElement.getAttribute('data-layout-id'))
    this.set('backendSiteUrl', rootElement.getAttribute('data-backend-site-url'))
    this.set('backendApiUrl', rootElement.getAttribute('data-backend-api-url'))
    this.set('backendApiKey', rootElement.getAttribute('data-backend-api-key'))
    this.set('appName', rootElement.getAttribute('data-app-name'))
    this.set('defaultLanguage', rootElement.getAttribute('data-default-language'))
    this.set('defaultCurrency', rootElement.getAttribute('data-default-currency'))
  }

  set<T extends keyof IConfig>(key: T, value: this[T]|null): void {
    if (value){
      (this[key] as any) = value;
    }
  }

  validate() {
    if (!this.layoutId
      || !this.backendSiteUrl
      || !this.backendApiUrl
      || !this.backendApiKey
      || !this.appName
      ) {
      throw new Error('Some required config variables are missing. Check .env file or index.html root data attributes')
    }
  }
}

const config = new Config(envConfig as any);

export default config
