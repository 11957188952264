import { toast } from 'react-toastify'

const defaults = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 7000,
  className: 'rotateY animated',
  rtl: false,
  closeButton: true,
}

export const showWarningMessage = (message: string) => {
  toast(message, {
    ...defaults,
    type: toast.TYPE.WARNING,
  })
}

export const showSuccessMessage = (message: string) => {
  toast(message, {
    ...defaults,
    type: toast.TYPE.SUCCESS,
  })
}

export const showInformationMessage = (message: string) => {
  toast(message, {
    ...defaults,
    type: toast.TYPE.INFO,
  })
}
