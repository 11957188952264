import stripTags from './stripTags'

/**
 * Counts words at text or html
 *
 * @returns number
 */
const countWords = (text: string): number => {

  if (!text) {
    return 0
  }

  const plainText = stripTags(text);

  const matches = plainText.match(/\S+/g)

  return matches ? matches.length : 0
}

export default countWords
