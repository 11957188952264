import values from 'lodash/values'

import UiActions from '../types/actions/Ui'
import cscartApi from '../../api/cscart-api'
import ApiError from '../../entities/error/ApiError'
import Language from '../../entities/language/Language'
import Currency from '../../entities/currency/Currency'
import DeviceTypes from '../../constants/DeviceTypes'
import getMatchedDeviceType from '../../utils/responsive/getMatchedDeviceType'

export interface AcceptGdpr {
  type: UiActions.ACCEPT_GDPR;
}

export function acceptGdpr(): AcceptGdpr {
  return {
    type: UiActions.ACCEPT_GDPR,
  }
}

export interface SetNotificationPermission {
  type: UiActions.SET_NOTIFICATION_PERMISSION;
  payload: {
    permission: NotificationPermission
  }
}

export function setNotificationPermission(permission: NotificationPermission): SetNotificationPermission {
  return {
    type: UiActions.SET_NOTIFICATION_PERMISSION,
    payload: {
      permission
    }
  }
}

export interface ReadyToUpdate {
  type: UiActions.READY_TO_UPDATE;
}

export function readyToUpdate(): ReadyToUpdate {
  return {
    type: UiActions.READY_TO_UPDATE,
  }
}

export interface Updated {
  type: UiActions.UPDATED;
}

export function updated(): Updated {
  return {
    type: UiActions.UPDATED,
  }
}

export interface SchedulerPerform {
  type: UiActions.SCHEDULER_PERFORM;
}

export function schedulerPerform(): SchedulerPerform {
  return {
    type: UiActions.SCHEDULER_PERFORM,
  }
}

export interface StartLoading {
  type: UiActions.START_LOADING;
}

export function startLoading(): StartLoading {
  return {
    type: UiActions.START_LOADING,
  }
}

export interface EndLoading {
  type: UiActions.END_LOADING;
}

export function endLoading(): EndLoading {
  return {
    type: UiActions.END_LOADING,
  }
}

// language selection
export interface SetLanguage {
  type: UiActions.SET_LANGUAGE
  payload: {
    language: string
  }
}

export function setLanguage(language: string): SetLanguage {
  cscartApi.setLanguage(language)

  return {
    type: UiActions.SET_LANGUAGE,
    payload: {
      language
    }
  }
}

// language on init
export interface SetInitialLanguage {
  type: UiActions.SET_INITIAL_LANGUAGE
  payload: {
    language: string
  }
}

export function setInitialLanguage(language: string): SetInitialLanguage {
  cscartApi.setLanguage(language)

  return {
    type: UiActions.SET_INITIAL_LANGUAGE,
    payload: {
      language
    }
  }
}

// currency selection
export interface SetCurrency {
  type: UiActions.SET_CURRENCY
  payload: {
    currency: string;
  }
}

export function setCurrency(currency: string): SetCurrency {
  // cscartApi.setCurrency(currency)

  return {
    type: UiActions.SET_CURRENCY,
    payload: {
      currency
    }
  }
}

// --- RequestSettings
export interface RequestStoreSettings {
  type: UiActions.REQUEST_STORE_SETTINGS;
  payload: {
    // productId: number;
    // options?: Array<any>;
  }
}

export function requestStoreSettings(): RequestStoreSettings {
  return {
    type: UiActions.REQUEST_STORE_SETTINGS,
    payload: {
      // productId: id,
      // options: options,
    }
  }
}

export interface RequestStoreSettingsSuccess {
  type: UiActions.REQUEST_STORE_SETTINGS_SUCCESS;
  payload: {
    languages:  Array<Language>;
    currencies: Array<Currency>;
    properties: any;
  }
}

export function requestStoreSettingsSuccess(rawSettings: any): RequestStoreSettingsSuccess {

  const languages = values(rawSettings.languages).map((language: any) => new Language(language));
  const currencies = values(rawSettings.currencies).map((currency: any) => new Currency(currency));

  return {
    type: UiActions.REQUEST_STORE_SETTINGS_SUCCESS,
    payload: {
      languages,
      currencies,
      properties: rawSettings.properties,
    }
  }
}

export interface RequestStoreSettingsFailure {
  type: UiActions.REQUEST_STORE_SETTINGS_FAILURE;
  payload: {
    error: ApiError;
  }
}

export function requestStoreSettingsFailure(error: any): RequestStoreSettingsFailure {
  const apiError = new ApiError({
    status: error.status,
    shouldRetry: error.shouldRetry || false,
    message: error.message
  })

  return {
    type: UiActions.REQUEST_STORE_SETTINGS_FAILURE,
    payload: {
      error: apiError
    }
  }
}

// --- Resize
export interface Resize {
  type: UiActions.RESIZE;
  payload: {
    matchDeviceMedia: DeviceTypes;
  }
}

export function resize(width: number): Resize {

  return {
    type: UiActions.RESIZE,
    payload: {
      matchDeviceMedia: getMatchedDeviceType(width)
    }
  }
}

// --- Open/Close drawer
export interface OpenDrawer {
  type: UiActions.OPEN_DRAWER;
  payload: {
    id: string;
  }
}

export function openDrawer(id: string): OpenDrawer {

  return {
    type: UiActions.OPEN_DRAWER,
    payload: {
      id,
    }
  }
}

export interface CloseDrawer {
  type: UiActions.CLOSE_DRAWER;
  payload: {
    id: string;
  }
}

export function closeDrawer(id: string): CloseDrawer {

  return {
    type: UiActions.CLOSE_DRAWER,
    payload: {
      id,
    }
  }
}


export interface CloseAllDrawers {
  type: UiActions.CLOSE_ALL_DRAWERS;
}

export function closeAllDrawers(): CloseAllDrawers {

  return {
    type: UiActions.CLOSE_ALL_DRAWERS,
  }
}

export type UiAction = ReadyToUpdate | Updated
| SetNotificationPermission
| StartLoading | EndLoading
| SetLanguage | SetInitialLanguage
| SetCurrency
| SchedulerPerform
| RequestStoreSettings | RequestStoreSettingsSuccess | RequestStoreSettingsFailure
| Resize
| OpenDrawer | CloseDrawer | CloseAllDrawers
| AcceptGdpr;
