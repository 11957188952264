import { ActionsObservable, ofType } from 'redux-observable'
import { switchMap, map } from 'rxjs/operators'
import { from } from 'rxjs'
import { SelectionAction, SuccessedRequestSelectionAction } from '../../actions'
import AbstractItemsSelection from '../../../entities/selection/AbstractItemsSelection'

/**
 * Perform DB request for a item by selection
 */
export function requestDbBySelection<
  SelectionType extends AbstractItemsSelection,
  Action extends SelectionAction<SelectionType>,
  Success extends SuccessedRequestSelectionAction<SelectionType>
> (
  action$: ActionsObservable<Action>,
  type: string,
  find: (selection: SelectionType|undefined) => Promise<SelectionType>,
  onSuccess: (selection: SelectionType) => Success
) {

  return action$.pipe(
    ofType(type),
    switchMap((action: Action) =>
      from(
        find(action.payload.selection)
      ).pipe(
        map((selection: SelectionType) =>
          onSuccess(selection)
        ),
      )
    ),
  )
}
