import Article from './Article'
import AbstractItemsSelection from '../selection/AbstractItemsSelection'
import IArticleSelection from './IArticleSelection'

class ArticleSelection extends AbstractItemsSelection implements IArticleSelection {
  articles:   Array<Article>;
  filters:   Array<any>;

  constructor(rawArticleSelection: any) {
    const articleSelection = {...rawArticleSelection};
    super(articleSelection)

    this.articles = articleSelection.articles.map((article: any) => new Article(article));
    this.filters = [];
  }

  prependItems(articles: Array<Article>): ArticleSelection {
    return new ArticleSelection({
      ...this,
      articles: [
        ...articles,
        ...this.articles,
      ]
    })
  }
}

export default ArticleSelection
