import React from 'react'

import AuthProviders from '../../../constants/AuthProviders'
import GoogleAuthLoader from '../../auth/social-login/google/GoogleAuthLoader'

import { IProps } from './IAuthScriptsHandler'

/**
 * Usually you should load script for auth after auth too
 * to have possibility log out with oauth
 *
 * @param props
 */
const AuthScriptsHandler:React.FC<IProps> = (props) => {
  const {
    provider,
    googleClientId,
  } = props;

  switch (provider) {
    case AuthProviders.GOOGLE:
      if (!googleClientId) {
        return null
      }

      const GoogleAuthScript = GoogleAuthLoader(() => null)

      return <GoogleAuthScript />

    default:
      return null
  }
}

export default AuthScriptsHandler
