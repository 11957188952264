import { OrderAction } from '../actions/Order'
import OrderState from '../types/OrderState'
import OrdersActionsTypes from '../types/actions/Order'


export const initialState: OrderState = {
  id:           0,
  isRequesting: false,
  order:        null,
  error:        null,
}

const Orders = function order(state: OrderState = initialState, action: OrderAction): OrderState {
  switch (action.type) {
    case OrdersActionsTypes.REQUEST_ORDER:
      return {
        ...state,
        isRequesting: true,
        order:        null,
        error:        null,
      }

    case OrdersActionsTypes.REQUEST_ORDER_SUCCESS:
    case OrdersActionsTypes.DB_REQUEST_ORDER_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        order:        action.payload.order,
      }

    case OrdersActionsTypes.REQUEST_ORDER_FAILURE:
      return {
        ...state,
        isRequesting: false,
        error:        action.payload.error,
      }

    default:
      return state;
  }
}

export default Orders
