import Dexie from 'dexie'

import Category from '../entities/category/Category'
import Product from '../entities/product/Product'
import Article from '../entities/article/Article'
import Layout from '../entities/layout/Layout'
import Vendor from '../entities/vendor/Vendor'
import Order from '../entities/order/Order'

class Database extends Dexie {
  public categories:  Dexie.Table<Category, number>; // id is number in this case
  public products:    Dexie.Table<Product, number>;
  public articles:    Dexie.Table<Article, number>;
  public layouts:     Dexie.Table<Layout, string>;
  public vendors:     Dexie.Table<Vendor, number>;
  public orders:      Dexie.Table<Order, number>;

  constructor() {
    super('SpaDatabase')

    this.version(1).stores({
      categories: '++id,relevanceTimestamp,category',
      products:   '++id,relevanceTimestamp,product'
    })

    this.version(2).stores({
      categories: null,
      products:   null
    })

    this.version(3).stores({
      categories: 'id',
      products:   'id'
    })

    this.version(4).stores({
      categories: 'id',
      products:   'id',
      articles:   'id',
    })

    // product entity changed
    this.version(7).stores({
      categories: 'id',
      products:   'id',
      articles:   'id',
    }).upgrade (async () => {
      await this.delete();
    });

    this.version(8).stores({
      categories: 'id',
      products:   'id',
      articles:   'id',
      layouts:    'type',
    })

    this.version(9).stores({
      categories: 'id',
      products:   'id',
      articles:   'id',
      layouts:    'type',
      vendors:    'id',
    })

    this.version(10).stores({
      categories: 'id',
      products:   'id',
      articles:   'id',
      layouts:    'type',
      vendors:    'id',
      orders:     'id',
    })

    this.version(11).stores({
      categories: 'id,slug',
      products:   'id,slug',
      articles:   'id,slug',
    }).upgrade (async () => {
      await this.delete();
    });

    this.version(12).stores({
      vendors:   'id,slug',
    }).upgrade (async () => {
      await this.delete();
    });

    /**
     * Parents added
     */
    this.version(13).stores({
      products:   'id,slug',
    }).upgrade (async () => {
      await this.delete();
    });

    /**
     * Base price added
     */
    this.version(14).stores({
      products:   'id,slug',
    }).upgrade (async () => {
      await this.delete();
    });

    /**
     * Parents added
     */
    this.version(14).stores({
      categories: 'id,slug',
    }).upgrade (async () => {
      await this.delete();
    });

    this.categories = this.table('categories');
    this.products   = this.table('products');
    this.articles   = this.table('articles');
    this.layouts    = this.table('layouts');
    this.vendors    = this.table('vendors');
    this.orders     = this.table('orders');

    this.categories.mapToClass(Category)
    this.products.mapToClass(Product)
    this.articles.mapToClass(Article)
    this.layouts.mapToClass(Layout)
    this.vendors.mapToClass(Vendor)
    this.orders.mapToClass(Order)

    /**
     * Because of mapToClass loses composition
     */
    this.products.hook('reading', product => product ? new Product({...product}) : product);
    this.categories.hook('reading', category => category ? new Category({...category}) : category);
    this.articles.hook('reading', article => article ? new Article({...article}) : article);
    this.layouts.hook('reading', layout => layout ? new Layout({...layout}) : layout);
    this.vendors.hook('reading', vendor => vendor ? new Vendor({...vendor}) : vendor);
    this.orders.hook('reading', order => order ? new Order({...order}) : order);
  }
}

const db = new Database();

export default db
