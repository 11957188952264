import WishlistState from '../types/WishlistState'

import Product from '../../entities/product/Product'
import IProduct from '../../entities/product/IProduct'

const rehydrateWishlist = (state: WishlistState): WishlistState => {
  return {
    ...state,
    wishlist: {
      ...state.wishlist,
      products: (state.wishlist.products || []).map((product: IProduct) => new Product(product))
    }
  }
}

export default rehydrateWishlist
