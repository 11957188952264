import Database from '../Database'

import Layout from '../../entities/layout/Layout'
import LayoutTypes from '../../constants/LayoutTypes'
import config from '../../config/Config';
import getPastDate from '../../utils/date/getPastDate';

/**
 * Saves a layout to indexedDB
 *
 * @param {Layout} layout
 *
 * @returns {void}
 */
export const persistLayout = (layout: Layout): void => {
  const layoutToSave = new Layout({...layout});

  Database.layouts.put(layoutToSave)
}

/**
 * Get a layout from indexedDB by type
 *
 * @param {LayoutTypes} type
 *
 * @returns {Promise<Layout>}
 */
export const findLayout = (type: LayoutTypes): Promise<Layout> =>
  Database.layouts.get(type)

/**
 * Get default layout from indexedDB
 *
 * @returns {Promise<Layout>}
 */
export const findDefaultLayout = (): Promise<Layout> =>
  Database.layouts.get(LayoutTypes.DEFAULT)

/**
 * Works like findLayout, but returns undefined
 * if layout is stale
 *
 * @param {LayoutTypes} type
 *
 * @returns {Promise<Layout>}
 */
export const findActualLayout = (type: LayoutTypes): Promise<Layout> =>
  new Promise((resolve, reject) => {
    Database.layouts.get(type)
      .then(layout =>
        resolve(
          (layout && layout.recievedAt >= getPastDate(config.layoutsLifetimeSeconds)) ? layout : undefined
        )
      )
      .catch(reason => reject(reason))
  })
