import IOrderProduct from './IOrderProduct'
import Product from '../product/Product'
import ProductExtras from '../product/ProductExtras'
import IProductOptionSelected, { ProductOptionSelectedTypes } from '../product/IProductOptionSelected'

class OrderProduct implements IOrderProduct {

  uuid:             string;
  subtotal:         number;
  amount:           number;
  product:          Product;
  extras:           ProductExtras;

  constructor(rawOrderItem: IOrderProduct | any) {
    const orderItem  = {...rawOrderItem};

    if ('uuid' in orderItem) {
      this.uuid         = orderItem.uuid;
      this.subtotal     = orderItem.subtotal;
      this.product      = new Product(orderItem.product);
      this.extras       = new ProductExtras(orderItem.extras);
      this.amount       = orderItem.amount;
    } else {
      this.uuid         = orderItem.cart_id;
      this.subtotal     = orderItem.display_subtotal;
      this.product      = new Product(orderItem);
      this.amount       = parseInt(orderItem.amount);
      this.extras       = new ProductExtras({
        productOptionsSelected: (orderItem.extra.product_options_value || []).map((option: any) => (typeCastProductOption(option)))
      });

      /**
       * Fix cscart product interface issues
       */
      const orderProduct = {
        ...orderItem,
        amount:           orderItem.in_stock,
      }
      this.product  =   new Product(orderProduct);
    }
  }
}

const typeCastProductOption = (option: any): IProductOptionSelected => {
  return {
    name: option.option_name,
    value: option.option_type !== 'C' ? option.variant_name : (option.position === '1' ? true : false),
    type: option.option_type === 'C' ? ProductOptionSelectedTypes.VALUE : ProductOptionSelectedTypes.KEY_VALUE,
  }
}

export default OrderProduct
