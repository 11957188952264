import values from 'lodash/values'

import IProductOption from './IProductOption'
import ProductOptionVariant from './ProductOptionVariant'
import ProductOptionTypes from '../../constants/ProductOptionTypes'

class ProductOption implements IProductOption {
  id:       number;
  name:     string;
  value:    string | number;
  type:     ProductOptionTypes;
  variants: Array<ProductOptionVariant>;
  position: number;

  constructor(rawOption: any) {
    const option = {...rawOption};

    if (option.id) {
      this.id       = option.id;
      this.name     = option.name;
      this.type     = option.type;
      this.position = option.position;
      this.variants = option.variants.map((variant: any) => new ProductOptionVariant(variant));
      this.value    = option.value;
    } else {
      this.id       = parseInt(option.option_id);
      this.name     = option.option_name;
      this.type     = convertOptionType(option.option_type);
      this.variants = (values(option.variants) || []).map((variant: any) => new ProductOptionVariant(variant, this.id));
      this.position = parseInt(option.position);
      /**
       * fix cscart types issue: when value is id it may be a string or a number.
       */
      this.value    = this.variants.length ? parseInt(option.value) : option.value;
    }

    if (!this.id) {
      throw new Error('Id param is missing. Check incoming object.')
    }
  }

  public select(value: any): boolean {
    this.value = value;

    return true
  }

  public isWithImages(): boolean {
    return this.variants.length ? this.variants.reduce((withImage: boolean, variant) => withImage && !variant.mainPair.isEmpty(), true) : false
  }
}

const convertOptionType = (rawType: string) => {
  switch (rawType) {
    case 'S':
    case 'R':
    case 'C':
      return ProductOptionTypes.SELECT

    default:
      return ProductOptionTypes.TEXT
  }
}

export default ProductOption
