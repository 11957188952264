import { map, switchMap, filter, auditTime, first, } from 'rxjs/operators'
import { from, EMPTY, of, fromEvent } from 'rxjs'
import { ofType } from 'redux-observable'
import { ActionsObservable, StateObservable } from 'redux-observable'

import { intl as i } from '../../intl/IntlGlobalProvider'
import cscartApi from '../../api'
import { AuthAction, AppAction, AppInit } from '../actions'
import {
  requestProfile,
  setToken,
  requestProfileSuccess,
  requestSignupFormSuccess,
  requestSignupFormFailure,

  requestSignupSuccess,
  requestSignupFailure,

  logout,
  requestLoginSuccess,
  requestLoginFailure,
  RequestLoginSuccess,
  SetToken,
  RequestLogin,
  RequestSignupSuccess,
  RequestProfile,
  requestProfileFailure,
  RequestSignup,
  Logout,
  RequestSocialLogin,
  logoutSuccess,
} from '../actions/Auth'
import { StoreState } from '../types'
import {
  APP_INIT,
} from '../types/actions'
import AuthActionsTypes from '../types/actions/Auth'
import { showInformationMessage } from '../../utils/notifications/messages'
import AuthProviders from '../../constants/AuthProviders'

/**
 * Request api for login
 */
export const requestLoginEpic = (
  action$: ActionsObservable<RequestLogin>,
  state$: null,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType(AuthActionsTypes.REQUEST_LOGIN),
  switchMap(action =>
    from(
      api.auth.login(action.payload.email, action.payload.password)
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestLoginSuccess(
            result.data.token,
            action.payload.provider
          )
        :
          requestLoginFailure({
            status: result.status
          })
      ),
    )
  ),
)

/**
 * Side effects for success login
 */
export const successLoginEpic = (
  action$: ActionsObservable<RequestSignupSuccess | RequestLoginSuccess | SetToken>,
  state$: null,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType(
    AuthActionsTypes.REQUEST_LOGIN_SUCCESS,
    AuthActionsTypes.REQUEST_SIGNUP_SUCCESS,
    AuthActionsTypes.SET_TOKEN,
  ),
  switchMap(action => {
    api.auth.setToken(action.payload.token)

    return EMPTY
  }),
)

/**
 * Set token on app init
 */
export const setTokenOnInitEpic = (
  action$: ActionsObservable<AppAction>,
  state$: StateObservable<StoreState>
) =>
  action$.pipe(
    ofType(APP_INIT),
    filter(() => !!state$.value.Auth.token),
    switchMap(
      () => of(setToken(state$.value.Auth.token))
    ),
  )

/**
 * Request profile on login or signup
 */
export const mapActionsToRequestProfileEpic = (
    action$: ActionsObservable<AppInit | RequestLoginSuccess | RequestSignupSuccess>,
    state$: StateObservable<StoreState>
  ) =>
    action$.pipe(
      ofType<AppInit | RequestLoginSuccess | RequestSignupSuccess>(
        APP_INIT,
        AuthActionsTypes.REQUEST_LOGIN_SUCCESS,
        AuthActionsTypes.REQUEST_SIGNUP_SUCCESS,
      ),
      filter(() => state$.value.Auth.isLogged),
      switchMap(() =>
        of(requestProfile())
      ),
    )

/**
 * Get user profile
 */
export const getUserProfileEpic = (
    action$: ActionsObservable<RequestProfile>,
    state$: StateObservable<StoreState>,
    {
      api
    }: {
      api: typeof cscartApi
    }
  ) =>
    action$.pipe(
      ofType(
        AuthActionsTypes.REQUEST_PROFILE,
      ),
      filter(() => state$.value.Auth.isLogged),
      switchMap(() =>
        from(
          api.profile.getProfile()
        ).pipe(
          map((result: any) =>
            result.data
            ?
              requestProfileSuccess(result.data)
            :
              requestProfileFailure({
                status: result.status,
              })
          ),
        )
      ),
    )

/**
 * Request signup form from api
 */
export const getSignupFormEpic = (
    action$: ActionsObservable<AuthAction>,
    state$: StateObservable<StoreState>,
    {
      api
    }: {
      api: typeof cscartApi
    }
  ) =>
    action$.pipe(
      ofType(
        AuthActionsTypes.REQUEST_SIGNUP_FORM,
      ),
      filter(() => !state$.value.Auth.isLogged),
      switchMap(() =>
        from(
          api.profile.getSignupForm()
        ).pipe(
          map((result: any) =>
            result.data
            ?
              requestSignupFormSuccess(result.data)
            :
              requestSignupFormFailure({
                status: result.status
              })
          ),
        )
      ),
    )

/**
 * Request api for create profile
 */
export const requestSignupEpic = (
  action$: ActionsObservable<RequestSignup>,
  state$: StateObservable<StoreState>,
  {
    api
  }: {
    api: typeof cscartApi
  }
) =>
  action$.pipe(
    ofType(
      AuthActionsTypes.REQUEST_SIGNUP,
    ),
    filter(() => !state$.value.Auth.isLogged),
    switchMap(action =>
      from(
        api.profile.createProfile(action.payload.profile)
      ).pipe(
        map((result: any) =>
          result.data
          ?
            requestSignupSuccess(result.data.auth.token)
          :
            requestSignupFailure({
              status: result.status,
              message: result.message
            })
        ),
      )
    ),
  )

/**
 * Side effects on backend logout
 */
export const apiLogoutEpic = (
  action$: null,
  state$: null,
  { intl }: { intl: () => typeof i }
) =>
  fromEvent(window, 'api.need-auth')
  .pipe(
    auditTime(2000),
    map(() => {
      showInformationMessage(intl().formatMessage({ id: 'app.api.session-expired'}))

      return logout()
    }),
  )

/**
 * Reset token on logout action
 */
export const logoutEpic = (
  action$: ActionsObservable<Logout>,
  state$: StateObservable<StoreState>,
  {
    asyncUtils: {
      handleThirdpartyLogout
    }
  }: {
    asyncUtils: {
      handleThirdpartyLogout: (provider: AuthProviders|null) => any
    }
  }
) =>
  action$.pipe(
    ofType<AuthAction>(
      AuthActionsTypes.LOGOUT,
    ),
    switchMap(() => {
      return handleThirdpartyLogout(state$.value.Auth.provider)
    }),
    switchMap(() => {
      return [
        setToken(''),
        logoutSuccess()
      ]
    })
  )

/**
 * Request api for verify oauth login
 */
export const requestSocialLoginEpic = (
  action$: ActionsObservable<RequestSocialLogin>,
  state$: null,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType(AuthActionsTypes.REQUEST_SOCIAL_LOGIN),
  switchMap(action =>
    from(
      api.auth.socialLogin(action.payload.provider, action.payload.idToken, action.payload.clientId)
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestLoginSuccess(
            result.data.token,
            action.payload.provider
          )
        :
          requestLoginFailure({
            status: result.status
          })
      ),
    )
  ),
)

/**
 * Side effects to logout user from thirdparty services
 *
 * @param {AuthProviders|null} provider
 */
export const handleThirdpartyLogout = (provider: AuthProviders|null) => {
  return new Promise((resolveDefaultLogout) => {
    switch (provider) {
      case AuthProviders.GOOGLE:
        let googleAuth: any = false;

        try {
          googleAuth = (window as any).gapi && (window as any).gapi.auth2.getAuthInstance();
        } catch (error) {
        }

        if ((window as any).isGapiInitialised) {
          if (googleAuth.isSignedIn.get()) {
            googleLogout(resolveDefaultLogout)
          } else {
            resolveDefaultLogout()
          }
        } else {
          fromEvent<CustomEvent>(
            window, 'social-login.google.init'
          ).pipe(first()).subscribe(() => googleLogout(resolveDefaultLogout))
        }
        break

      default:
        resolveDefaultLogout()
    }
  })
}

/**
 * Sign out user from google
 *
 * @param resolve
 */
const googleLogout = (resolve: (value?: unknown) => void) => {

  (window as any).gapi.auth2.getAuthInstance().signOut().then(() => resolve())
}

export default [
  requestLoginEpic,
  successLoginEpic,
  setTokenOnInitEpic,
  mapActionsToRequestProfileEpic,
  getUserProfileEpic,
  getSignupFormEpic,
  requestSignupEpic,
  apiLogoutEpic,
  logoutEpic,

  requestSocialLoginEpic,
]
