enum ApplyForVendorActionTypes {
  REQUEST_APPLY_FOR_VENDOR_FORM          = 'REQUEST_APPLY_FOR_VENDOR_FORM',
  REQUEST_APPLY_FOR_VENDOR_FORM_SUCCESS  = 'REQUEST_APPLY_FOR_VENDOR_FORM_SUCCESS',
  REQUEST_APPLY_FOR_VENDOR_FORM_FAILURE  = 'REQUEST_APPLY_FOR_VENDOR_FORM_FAILURE',

  REQUEST_APPLY_FOR_VENDOR_SIGNUP          = 'REQUEST_APPLY_FOR_VENDOR_SIGNUP',
  REQUEST_APPLY_FOR_VENDOR_SIGNUP_SUCCESS  = 'REQUEST_APPLY_FOR_VENDOR_SIGNUP_SUCCESS',
  REQUEST_APPLY_FOR_VENDOR_SIGNUP_FAILURE  = 'REQUEST_APPLY_FOR_VENDOR_SIGNUP_FAILURE',
}

export default ApplyForVendorActionTypes
