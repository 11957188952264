enum UiActions {
  START_LOADING = 'START_LOADING',
  END_LOADING   = 'END_LOADING',

  SCHEDULER_PERFORM   = 'SCHEDULER_PERFORM',

  SET_INITIAL_LANGUAGE  = 'SET_INITIAL_LANGUAGE',
  SET_LANGUAGE  = 'SET_LANGUAGE',
  SET_CURRENCY  = 'SET_CURRENCY',

  REQUEST_STORE_SETTINGS          = 'REQUEST_STORE_SETTINGS',
  REQUEST_STORE_SETTINGS_SUCCESS  = 'REQUEST_STORE_SETTINGS_SUCCESS',
  REQUEST_STORE_SETTINGS_FAILURE  = 'REQUEST_STORE_SETTINGS_FAILURE',

  RESIZE  = 'RESIZE',

  READY_TO_UPDATE = 'READY_TO_UPDATE',
  UPDATED = 'UPDATED',

  OPEN_DRAWER       = 'OPEN_DRAWER',
  CLOSE_DRAWER      = 'CLOSE_DRAWER',
  CLOSE_ALL_DRAWERS = 'CLOSE_ALL_DRAWERS',

  SET_NOTIFICATION_PERMISSION = 'SET_NOTIFICATION_PERMISSION',

  ACCEPT_GDPR = 'ACCEPT_GDPR',
}

export default UiActions
