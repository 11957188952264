import uniq from 'lodash/uniq'

import { SearchAction } from '../actions/Search'
// import SearchActionTypes from '../types/actions/Search'
import ProductActionTypes from '../actions/product/ProductActionTypes'
import SearchActionsTypes from '../types/actions/Search'
import SearchState from '../types/SearchState'
import { ProductAction } from '../actions'

export const initialState: SearchState = {
  isProductsRequesting: false,
  error:                null,
  isAutocompleteOpened: false,
  queryHistories:       [],
}

const maximumHistoryQueries = 10;

const Search = function search(state: SearchState = initialState, action: SearchAction | ProductAction): SearchState {
  switch (action.type) {
    case SearchActionsTypes.PERFORM_SEARCH:

      const newQuery = decodeURI(action.payload.query.substr(2)).trim(); // remove q= from query string

      if (newQuery) {
        const newHistoryQueries = uniq([
          newQuery,
          ...state.queryHistories,
        ]);

        return {
          ...state,
          queryHistories: newHistoryQueries.slice(0, maximumHistoryQueries),
        }
      } else {
        return {
          ...state
        }
      }

    case ProductActionTypes.REQUEST_SEARCH_PRODUCTS:

      return {
        ...state,
        isProductsRequesting: true,
        productSelection: action.payload.productSelection ? action.payload.productSelection : state.productSelection,
        error: null,
      }

    case ProductActionTypes.REQUEST_SEARCH_PRODUCTS_SUCCESS:

      return {
        ...state,
        productSelection: (
          (action.payload.shouldAppend && state.productSelection)
            ?
            action.payload.productSelection.prependItems(state.productSelection.products)
            :
              action.payload.productSelection),
        isProductsRequesting: false,
      }

    case ProductActionTypes.REQUEST_SEARCH_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isProductsRequesting: false,
      }

    case SearchActionsTypes.OPEN_SEARCH_AUTOCOMPLETE:
      return {
        ...state,
        isAutocompleteOpened: true,
      }

    case SearchActionsTypes.CLOSE_SEARCH_AUTOCOMPLETE:
      return {
        ...state,
        isAutocompleteOpened: false,
      }

    default:
      return state;
  }
}

export default Search
