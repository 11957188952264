import { connect } from 'react-redux'

import PageLoadIndicator from './PageLoadIndicator'
import { StoreState } from '../../../redux/types'

import { IStateProps, IDispatchProps, IOwnProps } from './IPageLoadIndicator'

export function mapStateToProps(state: StoreState, ownProps: IOwnProps): IStateProps {
  return {
    isLoading: state.Category.isRequesting
      || state.Category.isProductsRequesting
      || state.Product.isOptionsRequesting
      || state.Product.isRequesting
      || state.ApplyForVendor.form.isRequesting
      || state.Article.isArticlesRequesting
      || state.Article.isRequesting
      || state.Auth.isLoginRequesting
      || state.Cart.isCartRequesting
      || state.Order.isRequesting
      || state.Orders.isRequesting
      || state.Search.isProductsRequesting
      || state.Vendor.isProductsRequesting
      || state.Vendor.isRequesting
      || state.Vendors.isRequesting,
  };
}

export function mapDispatchToProps(): IDispatchProps {
  return {
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(PageLoadIndicator);
