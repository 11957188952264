import ProductActionTypes from './ProductActionTypes'
import Product from '../../../entities/product/Product'
import { persistProduct } from '../../../db/models/Product'
import ApiError from '../../../entities/error/ApiError'
import ProductSelection from '../../../entities/product/ProductSelection'

import {
  RequestProduct,
  RequestProductSuccess,
  RequestProductFailure,
} from './RequestProduct'

import {
  DbRequestProduct,
  DbRequestProductFailure,
  DbRequestProductSuccess,
} from './DbRequestProduct'

import {
  RequestCategoryProducts,
  RequestCategoryProductsFailure,
  RequestCategoryProductsSuccess,
} from './RequestCategoryProducts'

import {
  DbRequestCategoryProducts,
  DbRequestCategoryProductsFailure,
  DbRequestCategoryProductsSuccess,
} from './DbRequestCategoryProducts'

import {
  RequestVendorProducts,
  RequestVendorProductsFailure,
  RequestVendorProductsSuccess,
} from './RequestVendorProducts'

import {
  DbRequestVendorProducts,
  DbRequestVendorProductsFailure,
  DbRequestVendorProductsSuccess,
} from './DbRequestVendorProducts'

export * from './RequestProduct'
export * from './DbRequestProduct'
export * from './RequestCategoryProducts'
export * from './DbRequestCategoryProducts'
export * from './RequestVendorProducts'
export * from './DbRequestVendorProducts'

// Set current product
export interface SetCurrentProduct {
  type: ProductActionTypes.SET_CURRENT_PRODUCT;
  payload: {
    product: Product;
  }
}

export function setCurrentProduct(rawProduct: any): SetCurrentProduct {

  const product = new Product(rawProduct);
  persistProduct(product)

  return {
    type: ProductActionTypes.SET_CURRENT_PRODUCT,
    payload: {
      product
    }
  }
}

// --- RequestSearchProducts
export interface RequestSearchProducts {
  type: ProductActionTypes.REQUEST_SEARCH_PRODUCTS;
  payload: {
    productSelection?: ProductSelection;
    shouldAppend?: boolean;
  }
}

export function requestSearchProducts(
  productSelection?: ProductSelection,
  shouldAppend?: boolean
): RequestSearchProducts {
  return {
    type: ProductActionTypes.REQUEST_SEARCH_PRODUCTS,
    payload: {
      productSelection,
      shouldAppend
    }
  }
}

export interface RequestSearchProductsSuccess {
  type: ProductActionTypes.REQUEST_SEARCH_PRODUCTS_SUCCESS;
  payload: {
    productSelection: ProductSelection;
    shouldAppend: boolean;
  }
}

export function requestSearchProductsSuccess(
  rawProducts: Array<any>,
  rawParams: any,
  rawFilters: Array<any>,
  shouldAppend: boolean = false
): RequestSearchProductsSuccess {

  const productSelection = new ProductSelection({
    products: rawProducts,
    pagination: rawParams,
    filterHashes: rawParams.features_hash,
    filters: rawFilters,
    sorting: rawParams,
    search: rawParams.q,
  });

  productSelection.products.forEach(product => persistProduct(product))

  return {
    type: ProductActionTypes.REQUEST_SEARCH_PRODUCTS_SUCCESS,
    payload: {
      productSelection,
      shouldAppend,
    }
  }
}

export interface RequestSearchProductsFailure {
  type: ProductActionTypes.REQUEST_SEARCH_PRODUCTS_FAILURE;
  payload: {
    error:  ApiError;
  }
}

export function requestSearchProductsFailure(error: any): RequestSearchProductsFailure {
  const apiError = new ApiError({
    status: error.status,
    shouldRetry: error.shouldRetry || false,
    message: error.message || 'Unhandled error'
  })

  return {
    type: ProductActionTypes.REQUEST_SEARCH_PRODUCTS_FAILURE,
    payload: {
      error: apiError
    }
  }
}

export interface SetProductIsRequesting {
  type: ProductActionTypes.SET_PRODUCT_IS_REQUESTING;
  payload: {
    isRequesting: boolean;
  }
}

export function setProductIsRequesting(isRequesting: boolean): SetProductIsRequesting {

  return {
    type: ProductActionTypes.SET_PRODUCT_IS_REQUESTING,
    payload: {
      isRequesting,
    }
  }
}

export type ProductAction =
| SetCurrentProduct | SetProductIsRequesting
| RequestProduct | RequestProductSuccess | RequestProductFailure
| DbRequestProduct | DbRequestProductSuccess | DbRequestProductFailure
| RequestCategoryProducts | RequestCategoryProductsSuccess | RequestCategoryProductsFailure
| DbRequestCategoryProducts | DbRequestCategoryProductsSuccess | DbRequestCategoryProductsFailure
| RequestVendorProducts | RequestVendorProductsSuccess | RequestVendorProductsFailure
| RequestSearchProducts | RequestSearchProductsSuccess | RequestSearchProductsFailure
| DbRequestVendorProducts | DbRequestVendorProductsSuccess | DbRequestVendorProductsFailure;
