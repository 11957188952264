import cscartApi from './cscart-api'
import config from '../config/Config'
import LayoutTypes from '../constants/LayoutTypes'

/**
 * Get layout from api by dispatch string
 *
 * @param {string|LayoutTypes} type
 * @param {string|undefined} slug
 */
export const getLayout = (type: LayoutTypes, slug?: string) => {

  let baseRequest = cscartApi
    .layouts
    .setIconSize([
      config.productSmallIconWidth,
      config.productSmallIconHeight
    ])
    .setIconSize([
      config.productBigIconWidth,
      config.productBigIconHeight
    ])
    .one(config.layoutId)
    .forLocation(type)
    .withBlocks()

    if (slug) {
      baseRequest = baseRequest.withSlug(slug)
    }

    return baseRequest.get()
}
