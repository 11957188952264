import includes from 'lodash/includes'

import Database from '../Database'
import Vendor from '../../entities/vendor/Vendor'

/**
 * Saves a vendor to indexedDB
 *
 * @param {Vendor} vendor
 *
 * @returns {void}
 */
export const persistVendor = (vendor: Vendor): void => {
  const vendorToSave = new Vendor({...vendor});

  Database.vendors.put(vendorToSave)
}

/**
 * Get a vendor from indexedDB by id
 *
 * @param {number|string} vendorId
 *
 * @returns {Promise<Vendor|undefined>}
 */
export const findVendor = (vendorId: number|string): Promise<Vendor|undefined> => {
  let query: any;

  if (typeof vendorId === 'string') {
    query = { slug: vendorId };
  } else {
    query = vendorId;
  }

  return Database.vendors.get(query)
}

/**
 * Get vendors from indexedDB by it's ids
 *
 * @param {number[]} vendorsIds
 *
 * @returns {Promise<Array<Vendor>>}
 */
export const findVendors = (vendorsIds: Array<number>): Promise<Array<Vendor>> =>
  Database.vendors.filter(
    vendor => includes(vendorsIds, vendor.id)
  ).toArray()

/**
 * Get all vendors from indexedDB
 *
 * @returns {Promise<Array<Vendor>>}
 */
export const findAllVendors = (): Promise<Array<Vendor>> =>
  Database.vendors.toArray()
