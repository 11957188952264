import values from 'lodash/values'

import IFormSection from './IFormSection'
import FormField from './FormField'

class FormSection implements IFormSection {
  description: string;
  id: string;

  fields: Array<FormField>;

  constructor(rawSection: any) {
    const section = {...rawSection};

    this.id           = section.id;
    this.description  = section.description || '';
    this.fields       = (values(section.fields) || []).map((field: any) => new FormField(field));

    if (!this.id) {
      throw new Error('Invalid id')
    }
  }
}

export default FormSection
