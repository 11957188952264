import IFilterVariant from './IFilterVariant'

/**
 * Variant for Product Filter Entity
 */
class FilterVariant implements IFilterVariant {
  id:       number;
  position: number;
  name:     string;
  disabled: boolean;

  constructor(rawVariant: any) {
    const variant = {...rawVariant};

    if ('id' in variant) {
      this.id       = variant.id;
      this.position = variant.position;
      this.name     = variant.name;
    } else {
      this.id       = parseInt(variant.variant_id);
      this.position = parseInt(variant.position);
      this.name     = variant.variant;
    }

    this.disabled = !!variant.disabled;

    if (!this.id) {
      throw new Error('Id parameter is missing. Check incoming object')
    }
  }
}

export default FilterVariant
