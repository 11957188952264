import keys from 'lodash/keys'

import CartActionsTypes from '../types/actions/Cart'
import IAddToCart from '../../entities/cart/IAddToCart'
import AddToCart from '../../entities/cart/AddToCart'
import Product from '../../entities/product/Product'
import FormSection from '../../entities/form/FormSection'
import UserData from '../../entities/cart/UserData'
import Cart from '../../entities/cart/Cart'
import ApiError from '../../entities/error/ApiError'
import CartUpdate from '../../entities/cart/CartUpdate'

export interface RequestCart {
  type: CartActionsTypes.REQUEST_CART;
}

export function requestCart(): RequestCart {
  return {
    type: CartActionsTypes.REQUEST_CART,
  }
}

export interface RequestCartSuccess {
  type: CartActionsTypes.REQUEST_CART_SUCCESS;
  payload: {
    cart: Cart;
  }
}

export function requestCartSuccess(rawCart: any): RequestCartSuccess {
  const cart = new Cart(rawCart);

  return {
    type: CartActionsTypes.REQUEST_CART_SUCCESS,
    payload: {
      cart,
    }
  }
}

export interface RequestCartFailure {
  type: CartActionsTypes.REQUEST_CART_FAILURE;
  payload: {
    error: ApiError
  }
}

export function requestCartFailure(error: any): RequestCartFailure {

  const apiError = new ApiError({
    status: error.status || 0,
    shouldRetry: error.shouldRetry || false,
    message: error.message || 'Unhandled error'
  })

  return {
    type: CartActionsTypes.REQUEST_CART_FAILURE,
    payload: {
      error: apiError,
    }
  }
}

// ---

export interface AddToDeleteQueue {
  type: CartActionsTypes.ADD_TO_DELETE_QUEUE;
  payload: {
    cartId: string;
  }
}

export function addToDeleteQueue(cartId: string): AddToDeleteQueue {
  return {
    type: CartActionsTypes.ADD_TO_DELETE_QUEUE,
    payload: {
      cartId,
    }
  }
}

export interface RemoveFromDeleteQueue {
  type: CartActionsTypes.REMOVE_FROM_DELETE_QUEUE;
  payload: {
    cartId: string;
  }
}

export function removeFromDeleteQueue(cartId: string): RemoveFromDeleteQueue {
  return {
    type: CartActionsTypes.REMOVE_FROM_DELETE_QUEUE,
    payload: {
      cartId,
    }
  }
}

// ---
export interface AddToUpdateQueue {
  type: CartActionsTypes.ADD_TO_UPDATE_QUEUE;
  payload: {
    cartUpdate: CartUpdate;
  }
}

export function addToUpdateQueue(cartId: string, addToCart: AddToCart): AddToUpdateQueue {
  const cartUpdate = new CartUpdate({
    cartId,
    addToCart,
  });

  return {
    type: CartActionsTypes.ADD_TO_UPDATE_QUEUE,
    payload: {
      cartUpdate,
    }
  }
}

export interface RemoveFromUpdateQueue {
  type: CartActionsTypes.REMOVE_FROM_UPDATE_QUEUE;
  payload: {
    cartId: string;
  }
}

export function removeFromUpdateQueue(cartId: string): RemoveFromUpdateQueue {
  return {
    type: CartActionsTypes.REMOVE_FROM_UPDATE_QUEUE,
    payload: {
      cartId,
    }
  }
}

// ---

export interface AddToAddQueue {
  type: CartActionsTypes.ADD_TO_ADD_QUEUE;
  payload: {
    addToCart: AddToCart;
  }
}

export function addToAddQueue(addToCart: IAddToCart): AddToAddQueue {

  return {
    type: CartActionsTypes.ADD_TO_ADD_QUEUE,
    payload: {
      addToCart: new AddToCart(addToCart),
    }
  }
}

export interface RemoveFromAddQueue {
  type: CartActionsTypes.REMOVE_FROM_ADD_QUEUE;
  payload: {
    addToCart: AddToCart;
  }
}

export function removeFromAddQueue(addToCart: AddToCart): RemoveFromAddQueue {

  return {
    type: CartActionsTypes.REMOVE_FROM_ADD_QUEUE,
    payload: {
      addToCart,
    }
  }
}

export interface FillQueue {
  type: CartActionsTypes.FILL_QUEUE;
  payload: {
    products: Array<Product>;
  }
}

export function fillQueue(products: Array<Product>): FillQueue {
  return {
    type: CartActionsTypes.FILL_QUEUE,
    payload: {
      products,
    }
  }
}

export interface ClearQueue {
  type: CartActionsTypes.CLEAR_QUEUE;
}

export function clearQueue(products: Array<Product>): ClearQueue {
  return {
    type: CartActionsTypes.CLEAR_QUEUE,
  }
}

// ---

export interface RequestAddToCart {
  type: CartActionsTypes.REQUEST_ADD_TO_CART;
  payload: {
    product: any;
  }
}

export function requestAddToCart(product: any): RequestAddToCart {
  return {
    type: CartActionsTypes.REQUEST_ADD_TO_CART,
    payload: {
      product,
    }
  }
}

export interface RequestAddToCartSuccess {
  type: CartActionsTypes.REQUEST_ADD_TO_CART_SUCCESS;
  payload: {
    cartIds: Array<{
      cartId: string,
      productId: number,
    }>;
  }
}

export function requestAddToCartSuccess(cartIds: any): RequestAddToCartSuccess {

  return {
    type: CartActionsTypes.REQUEST_ADD_TO_CART_SUCCESS,
    payload: {
      cartIds: keys(cartIds).map((cartId: string) => ({
        cartId: cartId,
        productId: cartIds[cartId]
      })),
    }
  }
}

export interface RequestAddToCartFailure {
  type: CartActionsTypes.REQUEST_ADD_TO_CART_FAILURE;
  payload: {
    error: ApiError
  }
}

export function requestAddToCartFailure(error: any): RequestAddToCartFailure {

  const apiError = new ApiError({
    status: error.status || 0,
    shouldRetry: error.shouldRetry || false,
    message: error.message || 'Unhandled error'
  })

  return {
    type: CartActionsTypes.REQUEST_ADD_TO_CART_FAILURE,
    payload: {
      error: apiError,
    }
  }
}

// ---
export interface ClearCart {
  type: CartActionsTypes.CLEAR_CART;
}

export function clearCart(): ClearCart {
  return {
    type: CartActionsTypes.CLEAR_CART,
  }
}

// ---

export interface RequestClearCart {
  type: CartActionsTypes.REQUEST_CLEAR_CART;
}

export function requestClearCart(): RequestClearCart {
  return {
    type: CartActionsTypes.REQUEST_CLEAR_CART,
  }
}

export interface RequestClearCartSuccess {
  type: CartActionsTypes.REQUEST_CLEAR_CART_SUCCESS;
}

export function requestClearCartSuccess(): RequestClearCartSuccess {
  return {
    type: CartActionsTypes.REQUEST_CLEAR_CART_SUCCESS,
  }
}

export interface RequestClearCartFailure {
  type: CartActionsTypes.REQUEST_CLEAR_CART_FAILURE;
}

export function requestClearCartFailure(error: any): RequestClearCartFailure {
  return {
    type: CartActionsTypes.REQUEST_CLEAR_CART_FAILURE,
  }
}

// ---
// Cart errors

export interface AddToCartErrors {
  type: CartActionsTypes.ADD_TO_CART_ERRORS;
  payload: {
    addToCart: AddToCart,
    error: ApiError,
  }
}

export function addToCartErrors(addToCart: AddToCart, error: any): AddToCartErrors {

  const apiError = new ApiError({
    status: error.status,
    shouldRetry: error.shouldRetry,
    message: error.message
  })

  return {
    type: CartActionsTypes.ADD_TO_CART_ERRORS,
    payload: {
      addToCart,
      error: apiError
    }
  }
}

export interface RemoveFromCartErrors {
  type: CartActionsTypes.REMOVE_FROM_CART_ERRORS;
  payload: {
    addToCart: AddToCart
  }
}

export function removeFromCartErrors(addToCart: AddToCart): RemoveFromCartErrors {
  return {
    type: CartActionsTypes.REMOVE_FROM_CART_ERRORS,
    payload: {
      addToCart
    }
  }
}

export interface FillErrorProducts {
  type: CartActionsTypes.FILL_ERROR_PRODUCTS;
  payload: {
    products: Array<Product>;
  }
}

export function fillProductErrors(products: Array<Product>): FillErrorProducts {
  return {
    type: CartActionsTypes.FILL_ERROR_PRODUCTS,
    payload: {
      products,
    }
  }
}

// Reset
export interface ResetCartState {
  type: CartActionsTypes.RESET_CART_STATE;
}

export function resetCartState(): ResetCartState {
  return {
    type: CartActionsTypes.RESET_CART_STATE,
  }
}

// Set shipping method
export interface SetShippingMethod {
  type: CartActionsTypes.SET_SHIPPING_METHOD;
  payload: {
    groupIndex: number;
    shippingId: number;
  }
}

export function setShippingMethod(groupIndex: number, shippingId: number): SetShippingMethod {
  return {
    type: CartActionsTypes.SET_SHIPPING_METHOD,
    payload: {
      groupIndex,
      shippingId,
    }
  }
}

// Set payment method
export interface SetPaymentMethod {
  type: CartActionsTypes.SET_PAYMENT_METHOD;
  payload: {
    paymentId: number;
  }
}

export function setPaymentMethod(paymentId: number): SetPaymentMethod {
  return {
    type: CartActionsTypes.SET_PAYMENT_METHOD,
    payload: {
      paymentId,
    }
  }
}

// Set payment data
export interface SetPaymentData {
  type: CartActionsTypes.SET_PAYMENT_DATA;
  payload: {
    data: any;
  }
}

export function setPaymentData(data: any): SetPaymentData {
  return {
    type: CartActionsTypes.SET_PAYMENT_DATA,
    payload: {
      data,
    }
  }
}

// Set user data
export interface SetUserData {
  type: CartActionsTypes.SET_USER_DATA;
  payload: {
    userData: UserData;
  }
}

export function setUserData(userData: UserData): SetUserData {
  return {
    type: CartActionsTypes.SET_USER_DATA,
    payload: {
      userData,
    }
  }
}

// Checkout form fields
export interface RequestCheckoutForm {
  type: CartActionsTypes.REQUEST_CHECKOUT_FORM;
}

export function requestCheckoutForm(): RequestCheckoutForm {
  return {
    type: CartActionsTypes.REQUEST_CHECKOUT_FORM,
  }
}

export interface RequestCheckoutFormSuccess {
  type: CartActionsTypes.REQUEST_CHECKOUT_FORM_SUCCESS;
  payload: {
    sections: Array<FormSection>
  }
}

export function requestCheckoutFormSuccess(sections: Array<FormSection>): RequestCheckoutFormSuccess {
  return {
    type: CartActionsTypes.REQUEST_CHECKOUT_FORM_SUCCESS,
    payload: {
      sections
    }
  }
}

export interface RequestCheckoutFormFailure {
  type: CartActionsTypes.REQUEST_CHECKOUT_FORM_FAILURE;
}

export function requestCheckoutFormFailure(): RequestCheckoutFormFailure {
  return {
    type: CartActionsTypes.REQUEST_CHECKOUT_FORM_FAILURE,
  }
}


// save user data to api
export interface RequestUpdateUserData {
  type: CartActionsTypes.REQUEST_UPDATE_USER_DATA;
  payload: {
    userData?: UserData;
  }
}

export function requestUpdateUserData(userData?: UserData): RequestUpdateUserData {
  return {
    type: CartActionsTypes.REQUEST_UPDATE_USER_DATA,
    payload: {
      userData,
    }
  }
}

export interface RequestUpdateUserDataSuccess {
  type: CartActionsTypes.REQUEST_UPDATE_USER_DATA_SUCCESS;
}

export function requestUpdateUserDataSuccess(): RequestUpdateUserDataSuccess {
  return {
    type: CartActionsTypes.REQUEST_UPDATE_USER_DATA_SUCCESS,
  }
}

export interface RequestUpdateUserDataFailure {
  type: CartActionsTypes.REQUEST_UPDATE_USER_DATA_FAILURE;
  payload: {
    error: ApiError
  }
}

export function requestUpdateUserDataFailure(error: any): RequestUpdateUserDataFailure {

  const apiError = new ApiError({
    status: error.status || 0,
    shouldRetry: error.shouldRetry || false,
    message: error.message || 'Unhandled error'
  })

  return {
    type: CartActionsTypes.REQUEST_UPDATE_USER_DATA_FAILURE,
    payload: {
      error: apiError,
    }
  }
}

export type CartAction = RequestCart | RequestCartSuccess | RequestCartFailure
| AddToAddQueue | RemoveFromAddQueue | FillQueue | ClearQueue
| AddToUpdateQueue | RemoveFromUpdateQueue
| AddToDeleteQueue | RemoveFromDeleteQueue
| RequestAddToCart | RequestAddToCartSuccess | RequestAddToCartFailure
| ClearCart
| RequestClearCart | RequestClearCartSuccess | RequestClearCartFailure
| AddToCartErrors | FillErrorProducts | RemoveFromCartErrors //| | ClearCartErrors;
| ResetCartState
| SetShippingMethod | SetPaymentMethod | SetPaymentData
| RequestCheckoutForm | RequestCheckoutFormSuccess | RequestCheckoutFormFailure
| RequestUpdateUserData | RequestUpdateUserDataSuccess | RequestUpdateUserDataFailure
| SetUserData;
