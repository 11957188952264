import IIcon from './IIcon'

class Icon implements IIcon {
  height: number;
  width:  number;
  url:    string;
  alt:    string;

  constructor(rawIcon: any) {
    const icon = {...rawIcon};

    if ('url' in icon) {
      this.height = icon.height;
      this.width  = icon.width;
      this.url    = icon.url;
      this.alt    = icon.alt;
    } else {
      this.height = icon.height ? parseInt(icon.height) : 0;
      this.width  = icon.width ? parseInt(icon.width) : 0;
      this.alt    = icon.alt || '';
      this.url    = icon.image_path || '';
    }
  }
}

export default Icon
