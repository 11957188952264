import ReviewActions from '../types/actions/Review'
import ReviewableEntities from '../../constants/ReviewableEntities'
import ReviewsThread from '../../entities/testimonial/ReviewsThread'
import ApiError from '../../entities/error/ApiError'
import TestimonialDraft from '../../entities/testimonial/TestimonialDraft'
import ReviewSelection from '../../entities/testimonial/ReviewSelection'

export interface RequestReviewThread {
  type: ReviewActions.REQUEST_REVIEW_THREAD;
  payload: {
    id: number;
    type: ReviewableEntities;
    selection?: ReviewSelection;
    shouldAppend?: boolean;
  }
}

export function requestReviewThread(
  id: number,
  type: ReviewableEntities,
  selection?: ReviewSelection,
  shouldAppend?: boolean
): RequestReviewThread {

  return {
    type: ReviewActions.REQUEST_REVIEW_THREAD,
    payload: {
      id,
      type,
      selection,
      shouldAppend,
    }
  }
}

export interface RequestReviewThreadSuccess {
  type: ReviewActions.REQUEST_REVIEW_THREAD_SUCCESS;
  payload: {
    thread: ReviewsThread;
    shouldAppend?: boolean;
  }
}

export function requestReviewThreadSuccess(rawThread: any, shouldAppend?: boolean): RequestReviewThreadSuccess {
  const thread = new ReviewsThread(rawThread);

  return {
    type: ReviewActions.REQUEST_REVIEW_THREAD_SUCCESS,
    payload: {
      thread,
      shouldAppend,
    }
  }
}

export interface RequestReviewThreadFailure {
  type: ReviewActions.REQUEST_REVIEW_THREAD_FAILURE;
  payload: {
    uuid:   string;
    error:  ApiError,
  }
}

export function requestReviewThreadFailure(uuid: string, error: any): RequestReviewThreadFailure {
  const apiError = new ApiError(error);

  return {
    type: ReviewActions.REQUEST_REVIEW_THREAD_FAILURE,
    payload: {
      uuid,
      error: apiError,
    }
  }
}

export interface CreateDraft {
  type: ReviewActions.CREATE_TESTIMONIAL_DRAFT;
  payload: {
    draft: TestimonialDraft,
  }
}

export function createDraft(draft: TestimonialDraft): CreateDraft {

  return {
    type: ReviewActions.CREATE_TESTIMONIAL_DRAFT,
    payload: {
      draft,
    }
  }
}

export interface RemoveDraft {
  type: ReviewActions.REMOVE_TESTIMONIAL_DRAFT;
  payload: {
    uuid: string,
  }
}

export function removeDraft(uuid: string): RemoveDraft {

  return {
    type: ReviewActions.REMOVE_TESTIMONIAL_DRAFT,
    payload: {
      uuid,
    }
  }
}

export interface RequestReviewAddFailure {
  type: ReviewActions.REQUEST_REVIEW_ADD_FAILURE;
  payload: {
    uuid:   string;
    error:  ApiError,
  }
}

export function requestReviewAddFailure(uuid: string, error: any): RequestReviewAddFailure {
  const apiError = new ApiError(error);

  return {
    type: ReviewActions.REQUEST_REVIEW_ADD_FAILURE,
    payload: {
      uuid,
      error: apiError,
    }
  }
}

export interface RequestReviewAddSuccess {
  type: ReviewActions.REQUEST_REVIEW_ADD_SUCCESS;
  payload: {
    uuid:   string;
  }
}

export function requestReviewAddSuccess(uuid: string): RequestReviewAddSuccess {

  return {
    type: ReviewActions.REQUEST_REVIEW_ADD_SUCCESS,
    payload: {
      uuid,
    }
  }
}

export type ReviewAction = RequestReviewThread | RequestReviewThreadSuccess | RequestReviewThreadFailure
| CreateDraft | RemoveDraft
| RequestReviewAddFailure | RequestReviewAddSuccess;
