import React from 'react'

import { IProps } from './IPageLoadIndicator'
import './PageLoadIndicator.css'

const PageLoadIndicator:React.FC<IProps> = (props) => {
  const {
    isLoading,
  } = props;

  if (!isLoading) {
    return null
  }

  return (
    <div className='b-page-load-indicator'></div>
  )
}

export default PageLoadIndicator
