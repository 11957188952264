import VendorActionTypes from './VendorActionTypes'
import Vendor from '../../../entities/vendor/Vendor'
import ApiError from '../../../entities/error/ApiError'
import { persistVendor } from '../../../db/models/Vendor'

export interface RequestVendor {
  type: VendorActionTypes.REQUEST_VENDOR;
  payload: {
    id: number;
  }
}

export function requestVendor(
  id: number
): RequestVendor {
  return {
    type: VendorActionTypes.REQUEST_VENDOR,
    payload: {
      id,
    }
  }
}

export interface RequestVendorSuccess {
  type: VendorActionTypes.REQUEST_VENDOR_SUCCESS;
  payload: {
    vendor: Vendor;
  }
}

export function requestVendorSuccess(
  rawVendor: any
): RequestVendorSuccess {

  const vendor = new Vendor(rawVendor);
  persistVendor(vendor)

  return {
    type: VendorActionTypes.REQUEST_VENDOR_SUCCESS,
    payload: {
      vendor,
    }
  }
}

export interface RequestVendorFailure {
  type: VendorActionTypes.REQUEST_VENDOR_FAILURE;
  payload: {
    id:     number;
    error:  ApiError;
  }
}

export function requestVendorFailure(id: number, error: any): RequestVendorFailure {
  const apiError = new ApiError(error)

  return {
    type: VendorActionTypes.REQUEST_VENDOR_FAILURE,
    payload: {
      id,
      error: apiError,
    }
  }
}
