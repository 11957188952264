import { connect } from 'react-redux'

import AuthScriptsHandler from './AuthScriptsHandler'
import { StoreState } from '../../../redux/types'
import { getGoogleAuthClientId } from '../../../redux/selectors/Ui'

import { IStateProps, IDispatchProps, IOwnProps } from './IAuthScriptsHandler'

export function mapStateToProps(state: StoreState, ownProps: IOwnProps): IStateProps {
  return {
    provider: state.Auth.provider,
    googleClientId: getGoogleAuthClientId(state),
  };
}

export function mapDispatchToProps(): IDispatchProps {
  return {
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(AuthScriptsHandler);
