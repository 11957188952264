import * as auth from './Auth'
import * as cart from './Cart'
import * as products from './Product'
import * as categories from './Category'
import * as articles from './Article'
import * as orders from './Order'
import * as settlements from './Settlement'
import * as settings from './Settings'
import * as testimonials from './Testimonials'
import * as vendors from './Vendors'
import * as layouts from './Layouts'

import * as profile from './Profile'

const api = {
  auth,

  settings,
  cart,

  products,
  categories,
  articles,
  testimonials,

  orders,
  settlements,
  vendors,

  profile,
  layouts,
}

export default api
