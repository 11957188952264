import React from 'react'
import { ClickAwayListener } from '@material-ui/core'

import modifyClassName from '../../../../utils/string/modifyClassName'

import { IProps } from './IAutocomplete'
import './Autocomplete.css'
import useDevice from '../../../../hooks/use-device/useDevice'
import DeviceTypes from '../../../../constants/DeviceTypes'

/**
 * Autocomplete component
 *
 * @param {IProps} props
 */
const Autocomplete:React.FC<IProps> = (props) => {
  const {
    autocomplete,
    autocompleteContent,
    children,
  } = props;

  const isAutocompleteVisible =
    !!(autocomplete && autocompleteContent && autocompleteContent.length);

  React.useEffect(() => {
    if (isAutocompleteVisible) {
      onAutocompleteShow()
    } else {
      onAutocompleteHide()
    }

    return () => document.body.classList.remove('with-search-autocomplete')
  }, [isAutocompleteVisible])

  return (
    <AutocompleteWrapper {...props} className='b-autocomplete'>
      {children}
      {
        isAutocompleteVisible
          &&
            <div className='b-autocomplete__content'>
              <ul className='b-autocomplete__list'>
                {autocompleteContent!.map((item, index) =>
                  <li
                    className='b-autocomplete__item'
                    key={index}
                  >
                    {item}
                  </li>
                )}
              </ul>
            </div>
      }
    </AutocompleteWrapper>
  )
}

interface IAutocompleteWrapperProps extends IProps {
  children: React.ReactNode;
  className: string;
}

/**
 * Wrapper for choose between full screen modal and click away listener
 *
 * @param props
 */
export const AutocompleteWrapper: React.FC<IAutocompleteWrapperProps> = (props) => {
  const {
    children,
    autocomplete,
    onClickAway,
    className,
  } = props;

  const deviceType = useDevice();

  const handleClickAway = (event: React.MouseEvent<Document, MouseEvent>) => {
    // don`t handle event if autocomplete is in full screen mode
    if (deviceType === DeviceTypes.MOBILE) {
      return
    }

    if (autocomplete && onClickAway) {
      onClickAway()
    }
  }

  return (
    /**
     * @todo - Lock focus on modal content
     */
    <ClickAwayListener onClickAway={event => handleClickAway(event)} >
      <div className={modifyClassName(className, 'dropdown')}>
        {children}
      </div>
    </ClickAwayListener>
  )
}

export const onAutocompleteShow = (): void => {
  document.body.classList.add('with-search-autocomplete')
}

export const onAutocompleteHide = (): void => {
  document.body.classList.remove('with-search-autocomplete')
}

export default Autocomplete
