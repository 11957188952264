//@ts-ignore
import ScriptLoader from 'react-script-loader-hoc'
import { getGoogleAuthClientId } from '../../../../redux/selectors/Ui'
import { store } from '../../../../redux/store'

/**
 * Properly load and init
 * https://developers.google.com/identity/sign-in/web/reference
 */
export default ScriptLoader('https://apis.google.com/js/platform.js?onload=onGoogleLoadCallback');

/**
 * Manual init auth2 with global custom event
 */
(window as any).onGoogleLoadCallback = function() {
  (window as any).gapi.load('auth2', function() {
    (window as any).gapi.auth2.init({ client_id: getGoogleAuthClientId(store.getState())}).then((googleAuth: any) => {
      (window as any).isGapiInitialised = true;
      window.dispatchEvent(
        new CustomEvent('social-login.google.init', { detail: { googleAuth } })
      )
    })
  });
}
