import LayoutsState from '../types/LayoutsState'

import Layout from '../../entities/layout/Layout';

const rehydrateLayouts = (state: LayoutsState): LayoutsState => ({
  ...state,
  layouts: state.layouts.map(layout => new Layout(layout))
})

export default rehydrateLayouts
