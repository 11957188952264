import WishlistActions from '../types/actions/Wishlist'
import Product from '../../entities/product/Product'

export interface RequestWishlist {
  type: WishlistActions.REQUEST_WISHLIST;
}

export function requestWishlist(): RequestWishlist {
  return {
    type: WishlistActions.REQUEST_WISHLIST,
  }
}

export interface RequestWishlistSuccess {
  type: WishlistActions.REQUEST_WISHLIST_SUCCESS;
  payload: {
    wishlist: any;
  }
}

export function requestWishlistSuccess(wishlist: any): RequestWishlistSuccess {
  return {
    type: WishlistActions.REQUEST_WISHLIST_SUCCESS,
    payload: {
      wishlist,
    }
  }
}

export interface RequestWishlistFailure {
  type: WishlistActions.REQUEST_WISHLIST_FAILURE;
  payload: {
    error: any
  }
}

export function requestWishlistFailure(error: any): RequestWishlistFailure {
  return {
    type: WishlistActions.REQUEST_WISHLIST_FAILURE,
    payload: {
      error,
    }
  }
}

// ---

export interface DbRequestWishlistSuccess {
  type: WishlistActions.DB_REQUEST_WISHLIST_SUCCESS;
  payload: {
    wishlist: any;
  }
}

export function dbRequestWishlistSuccess(wishlist: any): DbRequestWishlistSuccess {
  return {
    type: WishlistActions.DB_REQUEST_WISHLIST_SUCCESS,
    payload: {
      wishlist,
    }
  }
}

// ---
export interface AddToWishlistQueue {
  type: WishlistActions.ADD_TO_WISHLIST_QUEUE;
  payload: {
    product: Product;
  }
}

export function addToWishlistQueue(product: Product): AddToWishlistQueue {
  return {
    type: WishlistActions.ADD_TO_WISHLIST_QUEUE,
    payload: {
      product,
    }
  }
}

// ---
export interface AddToWishlistRemoveQueue {
  type: WishlistActions.ADD_TO_WISHLIST_REMOVE_QUEUE;
  payload: {
    product: Product;
  }
}

export function addToWishlistRemoveQueue(product: Product): AddToWishlistRemoveQueue {
  return {
    type: WishlistActions.ADD_TO_WISHLIST_REMOVE_QUEUE,
    payload: {
      product,
    }
  }
}

// ---
export interface RemoveFromWishlistQueue {
  type: WishlistActions.REMOVE_FROM_WISHLIST_QUEUE;
  payload: {
    productsIds: Array<number>;
  }
}

export function removeFromWishlistQueue(productsIds: Array<number>): RemoveFromWishlistQueue {
  return {
    type: WishlistActions.REMOVE_FROM_WISHLIST_QUEUE,
    payload: {
      productsIds,
    }
  }
}

// ---

export interface RequestAddToWishlist {
  type: WishlistActions.REQUEST_ADD_TO_WISHLIST;
}

export function requestAddToWishlist(): RequestAddToWishlist {
  return {
    type: WishlistActions.REQUEST_ADD_TO_WISHLIST,
  }
}

export interface RequestAddToWishlistSuccess {
  type: WishlistActions.REQUEST_ADD_TO_WISHLIST_SUCCESS;
  payload: {
    productsIds: Array<number>,
  }
}

export function requestAddToWishlistSuccess(productsIds: Array<number>): RequestAddToWishlistSuccess {
  return {
    type: WishlistActions.REQUEST_ADD_TO_WISHLIST_SUCCESS,
    payload: {
      productsIds,
    }
  }
}

export interface RequestAddToWishlistFailure {
  type: WishlistActions.REQUEST_ADD_TO_WISHLIST_FAILURE;
  payload: {
    error: any,
    productsIds: Array<number>,
  }
}

export function requestAddToWishlistFailure(error: any, productsIds: Array<number>): RequestAddToWishlistFailure {
  return {
    type: WishlistActions.REQUEST_ADD_TO_WISHLIST_FAILURE,
    payload: {
      error,
      productsIds,
    }
  }
}

// ---

export interface RequestRemoveFromWishlist {
  type: WishlistActions.REQUEST_REMOVE_FROM_WISHLIST;
}

export function requestRemoveFromWishlist(): RequestRemoveFromWishlist {
  return {
    type: WishlistActions.REQUEST_REMOVE_FROM_WISHLIST,
  }
}

export interface RequestRemoveFromWishlistSuccess {
  type: WishlistActions.REQUEST_REMOVE_FROM_WISHLIST_SUCCESS;
  payload: {
    productsIds: Array<number>,
  }
}

export function requestRemoveFromWishlistSuccess(productsIds: Array<number>): RequestRemoveFromWishlistSuccess {
  return {
    type: WishlistActions.REQUEST_REMOVE_FROM_WISHLIST_SUCCESS,
    payload: {
      productsIds,
    }
  }
}

export interface RequestRemoveFromWishlistFailure {
  type: WishlistActions.REQUEST_REMOVE_FROM_WISHLIST_FAILURE;
  payload: {
    error: any,
    productsIds: Array<number>,
  }
}

export function requestRemoveFromWishlistFailure(error: any, productsIds: Array<number>): RequestRemoveFromWishlistFailure {
  return {
    type: WishlistActions.REQUEST_REMOVE_FROM_WISHLIST_FAILURE,
    payload: {
      error,
      productsIds,
    }
  }
}

// ---
export interface ClearWishlist {
  type: WishlistActions.CLEAR_WISHLIST;
}

export function clearWishlist(): ClearWishlist {
  return {
    type: WishlistActions.CLEAR_WISHLIST,
  }
}

// ---

export interface RequestClearWishlist {
  type: WishlistActions.REQUEST_CLEAR_WISHLIST;
}

export function requestClearWishlist(): RequestClearWishlist {
  return {
    type: WishlistActions.REQUEST_CLEAR_WISHLIST,
  }
}

export interface RequestClearWishlistSuccess {
  type: WishlistActions.REQUEST_CLEAR_WISHLIST_SUCCESS;
}

export function requestClearWishlistSuccess(): RequestClearWishlistSuccess {
  return {
    type: WishlistActions.REQUEST_CLEAR_WISHLIST_SUCCESS,
  }
}

export interface RequestClearWishlistFailure {
  type: WishlistActions.REQUEST_CLEAR_WISHLIST_FAILURE;
}

export function requestClearWishlistFailure(error: any): RequestClearWishlistFailure {
  return {
    type: WishlistActions.REQUEST_CLEAR_WISHLIST_FAILURE,
  }
}

// ---

export interface ResetWishlistState {
  type: WishlistActions.RESET_WISHLIST_STATE;
}

export function resetWishlistState(): ResetWishlistState {
  return {
    type: WishlistActions.RESET_WISHLIST_STATE,
  }
}


export type WishlistAction = RequestWishlist | RequestWishlistSuccess | RequestWishlistFailure
| AddToWishlistQueue | RemoveFromWishlistQueue | AddToWishlistRemoveQueue
| RequestAddToWishlist | RequestAddToWishlistSuccess | RequestAddToWishlistFailure
| RequestRemoveFromWishlist | RequestRemoveFromWishlistSuccess | RequestRemoveFromWishlistFailure
| DbRequestWishlistSuccess
| ClearWishlist
| RequestClearWishlist | RequestClearWishlistSuccess | RequestClearWishlistFailure
| ResetWishlistState;
