
import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { FormattedMessage } from 'react-intl'
import Container from 'react-bootstrap/Container'

import stripTags from '../../utils/string/stripTags'
import HideOnScroll from '../subcomponents/hide-on-scroll/HideOnScroll'
import SelectionGlobalSearch from '../subcomponents/selection/global-search/SelectionGlobalSearchContainer'
import ToggleSearchButton from '../subcomponents/toggle-search-button/ToggleSearchButton'
import DeviceTypes from '../../constants/DeviceTypes'
import BackButton from '../subcomponents/back-button/BackButtonContainer'

import { IProps } from './INavigationBar'
import messages from './NavigationBar.messages'
import './NavigationBar.css'

/**
 * Navigation bar for mobile
 *
 * @param {IProps} props
 */
const NavigationBar: React.FC<IProps> = (props) => {
  const {
    title,
    shouldShow,
    offline,
    toggleSearch,
    isSearchVisible,
    deviceType,
    shouldSetSearchFocusOnInit
  } = props;

  const _isSearchVisible = React.useRef(isSearchVisible);
  const isVisible = shouldShow && deviceType === DeviceTypes.MOBILE;

  React.useEffect(() => {

    return onNavbarHide
  }, [])

  React.useEffect(() => {
    _isSearchVisible.current = isSearchVisible;
  }, [isSearchVisible])

  onNavbarShow(isVisible)

  if (!isVisible) {
    return null
  }

  return (
    <HideOnScroll>
        <AppBar
          classes={{
            root: 'b-navigation-bar'
          }}
        >
          <Toolbar
            classes={{
              root: 'b-navigation-bar__toolbar'
            }}
          >
            <Container>
              <div className='b-navigation-bar__left-side'>
                <BackButton />
                {
                  isSearchVisible
                  ?
                    <div className='b-navigation-bar__search-box animated fadeIn fast'>
                      <SelectionGlobalSearch
                        key='global-search'
                        focus={
                          shouldSetSearchFocusOnInit
                          ?
                            shouldSetFocus(_isSearchVisible.current, isSearchVisible)
                          :
                            false
                        }
                      />
                    </div>
                  :
                    <>
                      <h4 className='b-navigation-bar__title'>
                        {stripTags(title)}
                      </h4>
                      {
                        !offline
                        &&
                          <ToggleSearchButton
                            className='b-navigation-bar__toggle-search-button'
                            onClick={() => toggleSearch()}
                          />
                      }
                    </>
                }
                {
                  offline
                  &&
                    <OverlayTrigger
                      trigger='click'
                      rootClose
                      placement='bottom'
                      overlay={
                        <Popover
                          className='b-navigation-bar__popover'
                          id={'popover-navigation-bar-offline'}
                        >
                          <PopoverTitle as='h3'>
                            <FormattedMessage {...messages.offlineTitle} />
                          </PopoverTitle>
                          <PopoverContent>
                            <FormattedMessage {...messages.offlineDescription} />
                          </PopoverContent>
                        </Popover>
                      }
                    >
                      <div className='b-navigation-bar__offline animated slower infinite flash'>
                        <span className='sr-only'>
                          <FormattedMessage {...messages.offline} />
                        </span>
                        <OfflineStatusIcon size={20}/>
                      </div>
                    </OverlayTrigger>
                }
              </div>
            </Container>
          </Toolbar>
        </AppBar>
    </HideOnScroll>
  );
}

const OfflineStatusIcon = require('react-feather/dist/icons/wifi-off').default;

export default React.memo(NavigationBar)

const PopoverTitle = require('react-bootstrap/PopoverTitle');
const PopoverContent = require('react-bootstrap/PopoverContent');

export const onNavbarShow = (isVisible: boolean) => {
  isVisible ? document.body.classList.add('with-navbar') : onNavbarHide()
}

export const onNavbarHide = () => {
  document.body.classList.remove('with-navbar')
}

export const shouldSetFocus = (
  searchWasVisible: boolean,
  searchIsVisible: boolean
): boolean => {
  return searchWasVisible === false && searchIsVisible === true
}
