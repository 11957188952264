import IProductFeatureVariant from './IProductFeatureVariant'

class ProductFeatureVariant implements IProductFeatureVariant {
  id:           number;
  name:         string;
  description:  string;
  featureId:    number;
  featureType:  string;
  // imagePair:    ImagePair;
  position:     number;
  selected:     number;

  constructor(rawVariant: any) {
    const variant = {...rawVariant};

    if ('id' in variant) {
      this.id           = variant.id;
      this.name         = variant.name;
      this.description  = variant.description;
      this.featureId    = variant.featureId;
      this.featureType  = variant.featureType;
      // this.imagePair    = new ImagePair(variant.imagePair);
      this.position     = variant.position;
      this.selected     = variant.selected;
    } else {
      this.id           = parseInt(variant.variant_id);
      this.name         = variant.variant || '';
      this.description  = variant.description || '';
      this.position     = parseInt(variant.position || 0);
      this.selected     = parseInt(variant.selected || 0);
      // this.imagePair    = new ImagePair(variant.image_pair);

      this.featureId   = parseInt(variant.feature_id || 0);
      this.featureType = variant.feature_type || '';
    }

    if (!this.id) {
      throw new Error('Id parameter is missing. Check incoming object')
    }
  }
}

export default ProductFeatureVariant
