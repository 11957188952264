enum VendorActionTypes {
  REQUEST_VENDOR         = 'REQUEST_VENDOR',
  REQUEST_VENDOR_SUCCESS = 'REQUEST_VENDOR_SUCCESS',
  REQUEST_VENDOR_FAILURE = 'REQUEST_VENDOR_FAILURE',

  DB_REQUEST_VENDOR         = 'DB_REQUEST_VENDOR',
  DB_REQUEST_VENDOR_SUCCESS = 'DB_REQUEST_VENDOR_SUCCESS',
  DB_REQUEST_VENDOR_FAILURE = 'DB_REQUEST_VENDOR_FAILURE',

  REQUEST_VENDOR_SIGNUP_FORM         = 'REQUEST_VENDOR_SIGNUP_FORM',
  REQUEST_VENDOR_SIGNUP_FORM_SUCCESS = 'REQUEST_VENDOR_SIGNUP_FORM_SUCCESS',
  REQUEST_VENDOR_SIGNUP_FORM_FAILURE = 'REQUEST_VENDOR_SIGNUP_FORM_FAILURE',

  REQUEST_VENDOR_SIGNUP         = 'REQUEST_VENDOR_SIGNUP',
  REQUEST_VENDOR_SIGNUP_SUCCESS = 'REQUEST_VENDOR_SIGNUP_SUCCESS',
  REQUEST_VENDOR_SIGNUP_FAILURE = 'REQUEST_VENDOR_SIGNUP_FAILURE',

  REQUEST_VENDORS         = 'REQUEST_VENDORS',
  REQUEST_VENDORS_SUCCESS = 'REQUEST_VENDORS_SUCCESS',
  REQUEST_VENDORS_FAILURE = 'REQUEST_VENDORS_FAILURE',

  DB_REQUEST_VENDORS         = 'DB_REQUEST_VENDORS',
  DB_REQUEST_VENDORS_SUCCESS = 'DB_REQUEST_VENDORS_SUCCESS',
  DB_REQUEST_VENDORS_FAILURE = 'DB_REQUEST_VENDORS_FAILURE',

  REQUEST_VENDOR_PRODUCTS         = 'REQUEST_VENDOR_PRODUCTS',
  REQUEST_VENDOR_PRODUCTS_SUCCESS = 'REQUEST_VENDOR_PRODUCTS_SUCCESS',
  REQUEST_VENDOR_PRODUCTS_FAILURE = 'REQUEST_VENDOR_PRODUCTS_FAILURE',

  DB_REQUEST_VENDOR_PRODUCTS         = 'DB_REQUEST_VENDOR_PRODUCTS',
  DB_REQUEST_VENDOR_PRODUCTS_SUCCESS = 'DB_REQUEST_VENDOR_PRODUCTS_SUCCESS',
  DB_REQUEST_VENDOR_PRODUCTS_FAILURE = 'DB_REQUEST_VENDOR_PRODUCTS_FAILURE',

  SET_CURRENT_VENDOR = 'SET_CURRENT_VENDOR',
  SET_VENDOR_IS_REQUESTING = 'SET_VENDOR_IS_REQUESTING',
}

export default VendorActionTypes
