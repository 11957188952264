import { createSelector } from 'reselect'
import { StoreState } from '../types'
import AuthProviders from '../../constants/AuthProviders'

const getUiState = (state: StoreState) => state.Ui

export const getCurrencies = createSelector(
  [ getUiState ],
  (uiState) => uiState.currencies
)

export const getCurrentCurrencyCode = createSelector(
  [ getUiState ],
  (uiState) => uiState.currency
)

export const getCurrentCurrency = createSelector(
  [ getCurrencies, getCurrentCurrencyCode ],
  (currencies, currencyCode) =>
    currencies.find(currency => currency.code === currencyCode)
)

export const getDefaultCurrency = createSelector(
  [ getCurrencies ],
  (currencies) => currencies.find(currency => currency.isBase)
)

export const getLanguages = createSelector(
  [ getUiState ],
  (uiState) => uiState.languages
)

export const getCurrentLanguageCode = createSelector(
  [ getUiState ],
  (uiState) => uiState.language
)

export const getCurrentLanguage = createSelector(
  [ getLanguages, getCurrentLanguageCode ],
  (languages, languageCode) =>
    languages.find(language => language.code === languageCode)
)

export const isDrawerOpened = (state: StoreState, ownProps: any) => {
  const openedDrawers = new Set(state.Ui.openedDrawers);

  if (!ownProps.id) {
    throw new Error('No drawer id specified')
  }

  return openedDrawers.has(ownProps.id)
}
// export const getDefaultLanguage = createSelector(
//   [ getLanguages ],
//   (languages) => languages.find(currency => currency.isBase)
// )

export const getProperties = createSelector(
  [ getUiState ],
  (uiState) => uiState.properties || {}
)

export const getGoogleAuthClientId = createSelector(
  [ getProperties ],
  (properties) => {
    if (!properties.hybrid_auth_providers) {
      return undefined
    }

    const googleProvider = properties.hybrid_auth_providers.find((provider: any) => provider.provider === AuthProviders.GOOGLE);
    if (googleProvider) {
      return googleProvider.app_id
    }

    return undefined
  }
)
