import values from 'lodash/values'

import IImagePair from './IImagePair'
import Icon from './Icon'

class ImagePair implements IImagePair {
  position: number;
  icons:    Array<Icon>;

  constructor(rawImagePair: any) {
    const imagePair = {...rawImagePair};

    this.position = parseInt(imagePair.position || '0');
    this.icons    = values(imagePair.icons || []).map((icon: any) => new Icon(icon));
  }

  public getIconBySize(width: number, height: number) {
    if (width <= 0 || height <= 0) {
      throw new RangeError(`Wrong dimension parameters are passed: ${width}x${height}`)
    }

    return this.icons.find((icon) => icon.width === width && icon.height === height)
  }

  isEmpty(): boolean {
    return !this.icons.length
  }
}

export default ImagePair;
