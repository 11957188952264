import { map, mergeMap, switchMap, filter, catchError, } from 'rxjs/operators'
import { ActionsObservable } from 'redux-observable'
import { ofType } from 'redux-observable'
import {  from, of, } from 'rxjs'

import db from '../../db/models/'
import cscartApi from '../../api/'
import {
  // types
  RequestBlocks,
  DbRequestBlocks,
  GetLayout,
  RequestBlocksFailure,
  DbRequestBlocksFailure,

  // functions
  requestBlocks,
  requestBlocksSuccess,
  requestBlocksFailure,
  dbRequestBlocks,
  dbRequestBlocksSuccess,
  dbRequestBlocksFailure,
  getLayout,
} from '../actions/Layouts'

import LayoutsActionsTypes from '../types/actions/Layouts'
import { AppInit } from '../actions'
import { APP_INIT } from '../types/actions'
import LayoutTypes from '../../constants/LayoutTypes'
import ApiError from '../../entities/error/ApiError'

let apiRequestingLayouts: Set<string> = new Set();

/**
 * Request API for layout
 */
export const requestLayoutEpic = (
  action$: ActionsObservable<RequestBlocks>,
  state$: null,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS),
  filter((action: RequestBlocks) => !apiRequestingLayouts.has(action.payload.dispatch)),
  mergeMap((action: RequestBlocks) => {
    apiRequestingLayouts.add(action.payload.dispatch)

    return from(
        api.layouts.getLayout(action.payload.dispatch, action.payload.slug)
      ).pipe(
        map((result: any) => {
          apiRequestingLayouts.delete(action.payload.dispatch)
          return result.data
          ?
            requestBlocksSuccess(
              action.payload.dispatch,
              result.data,
              action.payload.slug,
            )
          :
            requestBlocksFailure(action.payload.dispatch, {
              status: result.status
            }, action.payload.slug)
        }),
        catchError((error) => {
          console.error(error)
  
          return of(requestBlocksFailure(
            action.payload.dispatch,
            new ApiError({
              status: 200,
              message: 'Server responded with an unexpected data'
            })
          ))
        })
      )
    }
  ),
)

/**
 * Get layout from anywhere
 * Change primary endpoint (api or db) here
 */
export const getLayoutEpic = (
  action$: ActionsObservable<GetLayout>,
) => action$.pipe(
  ofType(LayoutsActionsTypes.GET_LAYOUT),
  map((action: GetLayout) =>
    requestBlocks(action.payload.dispatch, action.payload.slug)
  ),
)

/**
 * Get layout from DB
 *
 * @param action$
 * @param state$
 * @param param2
 */
export const dbRequestLayoutEpic = (
  action$: ActionsObservable<DbRequestBlocks>,
  state$: null,
  { indexedDb }: { indexedDb: typeof db }
) => action$.pipe(
  ofType(LayoutsActionsTypes.DB_REQUEST_BLOCKS),
  // filter
  mergeMap((action: DbRequestBlocks) =>
    from(
      indexedDb.layouts.findLayout(action.payload.dispatch)
    ).pipe(
      mergeMap(layout => {

          if (layout) {
            return [
              dbRequestBlocksSuccess(
                layout
              )
            ]
          } else {
            return [dbRequestBlocksFailure(action.payload.dispatch, action.payload.slug)]
          }
      }),
    )
  ),
)

/**
 * Should request DB for a layout on network error
 */
export const mapApiRequestFailureToDbRequestEpic = (
  action$: ActionsObservable<RequestBlocksFailure>
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS_FAILURE),
  // get only network error
  filter((action: RequestBlocksFailure) => action.payload.error.status === 0),
  switchMap((action: RequestBlocksFailure) => of(dbRequestBlocks(action.payload.dispatch, action.payload.slug))),
)

/**
 * Should request API for a layout on DB error
 */
export const mapDbRequestFailureToApiRequestEpic = (
  action$: ActionsObservable<DbRequestBlocksFailure>
) => action$.pipe(
  ofType(LayoutsActionsTypes.DB_REQUEST_BLOCKS_FAILURE),
  switchMap((action: DbRequestBlocksFailure) => of(requestBlocks(action.payload.dispatch, action.payload.slug))),
)

/**
 * Should get default layout
 */
export const getDefaultLayoutOnInitEpic = (
  action$: ActionsObservable<AppInit>
) => action$.pipe(
  ofType(APP_INIT),
  switchMap((action: AppInit) => of(getLayout(LayoutTypes.DEFAULT))),
)

export default [
  requestLayoutEpic,
  dbRequestLayoutEpic,
  getLayoutEpic,
  mapApiRequestFailureToDbRequestEpic,
  getDefaultLayoutOnInitEpic,
]
