import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { createLoginLinkUrl } from '../../url'

import { IProps } from './IAuthRoute'

const AuthRoute: React.FC<IProps> = (props) => {

  const { isLogged, render, component: Component, ...rest } = props;

  if (!Component && !render) return null;

  return (
    <Route
      {...rest}
      render={props =>
        (
          isLogged
            ?
              Component ? <Component {...props} /> : (render && render(props))
            :
              <Redirect
                to={{
                  pathname: createLoginLinkUrl(),
                  state: {
                    from: props.location,
                  }
                }}
              />
        )
      }
    />
  )
}

export default AuthRoute
