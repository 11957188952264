import ICartError from './ICartError'
import ApiError from '../error/ApiError'
import AddToCart from './AddToCart'

class CartError implements ICartError {
  error: ApiError;
  addToCart: AddToCart;

  constructor(rawError: any) {
    const cartError = {...rawError};

    this.error      = cartError.error;
    this.addToCart  = cartError.addToCart;
  }
}

export default CartError
