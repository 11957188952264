import { of } from 'rxjs'

export const requestCatch = (payload: any, error: any, errorType: string) => {
  if (!error.response) {
    error = {
      ...error,
      name: error.name,
      message: error.message,
      code: 'NETWORK_ERROR'
    }
  }

  return of({
    payload: {
      ...payload,
      error,
    },
    type: errorType
  })
}

export const extractRequestStatus = (response: any): number => {
  // if response is valid
  if (response && response.status) {
    return response.status
  }

  // There is no response
  return 0
}
