import values from 'lodash/values'
import sortBy from 'lodash/sortBy'

import IFilter from './IFilter'
import FilterTypes from '../../constants/FilterTypes'
import FilterVariant from './FilterVariant'

/**
 * Product filter
 */
class Filter implements IFilter {

  id:       number;
  name:     string;
  type:     FilterTypes;
  content:  any;

  constructor(rawFilter: any) {
    const filter = {...rawFilter};

    if ('id' in filter) {
      this.id     = filter.id;
      this.type   = filter.type;
      this.name   = filter.name;

      switch (this.type) {
        case FilterTypes.SLIDER:
        case FilterTypes.PRICE:
          this.content = filter.content;
          break

        case FilterTypes.CHECKBOX:
        case FilterTypes.COLOR:
          this.content = {
            variants: filter.content.variants.map((variant: any) => new FilterVariant(variant)),
          }
          break
      }

    } else {
      this.id   = parseInt(filter.filter_id);
      this.type = getFilterType(filter.filter_style, filter.field_type);
      this.name = filter.filter;

      switch (this.type) {
        case FilterTypes.SLIDER:
        case FilterTypes.PRICE:
          this.content = {
            min: filter.min,
            max: filter.max,
          }
          break

        case FilterTypes.CHECKBOX:
        case FilterTypes.COLOR:
          this.content = {
            variants: sortBy([
              ...values(filter.selected_variants || []).map((variant: any) => new FilterVariant(variant)),
              ...(values(filter.variants) || []).map((variant: any) => new FilterVariant(variant))
            ], 'position', 'name'),
          }
          break
      }
    }

    if (!this.id) {
      throw Error('Id parameter is missing. Check incoming oject')
    }
  }
}

const getFilterType = (rawType: string|null, fieldType?: string): FilterTypes => {
  switch (rawType) {
    case 'checkbox':
    case '':
      return FilterTypes.CHECKBOX

    case 'slider':
      if (fieldType === 'P') { // Price range slider
        return FilterTypes.PRICE
      }
      return FilterTypes.SLIDER

    case 'color':
      return FilterTypes.COLOR

    // case 'date':
    //   return FilterTypes.DATE

    default:
      throw new Error(`Unsupported filter type: ${rawType} ${fieldType}`)
  }
}

export default Filter
