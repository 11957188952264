import IUserData from './IUserData'
import FormField from '../form/FormField'
import Address from '../profile/Address'
import ApiError from '../error/ApiError'

class UserData implements IUserData {
  id: number;
  profileId: number;
  // type: 'C' | 'A' | 'V';
  login: string;
  firstname: string;
  lastname: string;

  email: string;
  phone: string;

  birthday: string;

  companyId: number;
  companyName: string;

  billing: Address;
  shipping: Address;

  fields: Array<FormField>;
  error: ApiError | null;

  constructor(rawUserData: IUserData) {
    const userData = {...rawUserData};

    if (userData.user_id) {
      this.id         = parseInt(userData.user_id || '0');
      this.profileId  = parseInt(userData.profile_id || '0');
      this.login      = userData.user_login || '';
      this.firstname  = userData.firstname || '';
      this.lastname   = userData.lastname || '';
      this.email      = userData.email || '';
      this.phone      = userData.phone || '';
      this.birthday   = userData.birthday || '';

      this.companyId    = parseInt(userData.company_id || '0');
      this.companyName  = userData.company || '';

      this.shipping = new Address({
        firstname:  userData.s_firstname,
        lastname:   userData.s_lastname,
        address:    userData.s_address,
        address_2:  userData.s_address_2,
        city:       userData.s_city,
        county:     userData.s_county,
        country:    userData.s_country,
        countryName: userData.s_country_descr,
        zipcode:    userData.s_zipcode,
        state:      userData.s_state,
        stateName:  userData.s_state_descr,
      });

      if (typeof userData.b_firstname !== 'undefined') {
        this.billing = new Address({
          firstname:  userData.b_firstname,
          lastname:   userData.b_lastname,
          address:    userData.b_address,
          address_2:  userData.b_address_2,
          city:       userData.b_city,
          county:     userData.b_county,
          country:    userData.b_country,
          countryName: userData.b_country_descr,
          zipcode:    userData.b_zipcode,
          state:      userData.b_state,
          stateName:  userData.b_state_descr,
        });
      } else {
        this.billing = {...this.shipping}
      }

      this.error = null;

      /**
       * @todo check for type
       */
      this.fields = [];
    } else {
      this.id         = userData.id;
      this.profileId  = userData.profileId;
      this.login      = userData.login;
      this.firstname  = userData.firstname;
      this.lastname   = userData.lastname;
      this.email      = userData.email;
      this.phone      = userData.phone;
      this.birthday   = userData.birthday;

      this.companyId    = userData.companyId;
      this.companyName  = userData.companyName;

      this.billing  = new Address(userData.billing || {});
      this.shipping = new Address(userData.shipping || {});

      this.fields = userData.fields;
      this.error = userData.error ? new ApiError({...userData.error}) : null;
    }

    if (!this.id) {
      throw new Error('User id is missing. Check incoming Object')
    }
  }

  toApiJson(): any {
    return {
      user_id:      this.id,
      profile_id:   this.profileId,
      firstname:    this.firstname,
      lastname:     this.lastname,
      user_login:   this.login,
      email:        this.email,
      phone:        this.phone,
      birthday:     this.birthday,

      company_id:   this.companyId,
      company:      this.companyName,

      b_address:    this.billing.address,
      b_address_2:  this.billing.address2,
      b_city:       this.billing.city,
      b_country:    this.billing.country,
      b_county:     this.billing.county,
      b_firstname:  this.billing.firstname,
      b_lastname:   this.billing.lastname,
      b_phone:      this.billing.phone,
      b_state:      this.billing.state,
      b_zipcode:    this.billing.zipcode,

      s_address:    this.shipping.address,
      s_address_2:  this.shipping.address2,
      s_city:       this.shipping.city,
      s_country:    this.shipping.country,
      s_county:     this.shipping.county,
      s_firstname:  this.shipping.firstname,
      s_lastname:   this.shipping.lastname,
      s_phone:      this.shipping.phone,
      s_state:      this.shipping.state,
      s_zipcode:    this.shipping.zipcode,
    }
  }
}

export default UserData
