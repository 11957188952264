import React from 'react'
import Slide from '@material-ui/core/Slide'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'

import { IOwnProps } from './IHideOnScroll'
//import './HideOnScroll.css'

const HideOnScroll:React.FC<IOwnProps> = (props) => {
  const {
    children
  } = props;

  const trigger = useScrollTrigger();

  return (
    <Slide
      appear={false}
      direction='down'
      in={!trigger}
    >
      {children}
    </Slide>
  )
}

export default HideOnScroll
