import React from 'react'
import { Link } from 'react-router-dom'

import { createHomepageLinkUrl } from '../../../url'

import { IOwnProps } from './IHomeButton'
//import './HomeButton.css'

const HomeButton:React.FC<IOwnProps> = (props) => {
  const {
    className,
    size,
    label,
  } = props;

  return (
    <Link
      className={className}
      to={createHomepageLinkUrl()}
    >
      <HomeIcon size={size} />
      {
        label
        &&
        <p className='b-main-menu__button-label'>
          {label}
        </p>
      }
    </Link>
  )
}

const HomeIcon = require('react-feather/dist/icons/home').default;

export default HomeButton
