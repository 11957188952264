import { AuthAction } from '../actions/Auth'
import AuthState from '../types/AuthState'
import AuthActionsTypes from '../types/actions/Auth'
import Profile from '../../entities/auth/Profile'

export const initialState: AuthState = {
  provider:           null,
  isLogged:           false,
  isLoginFailed:      false,
  isLoginRequesting:  false,
  profile:            new Profile({}),
  signupForm: {
    form: []
  },
}

const Auth = function auth(state: AuthState = {...initialState}, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionsTypes.REQUEST_LOGIN:
      return {
        ...state,
        isLoginRequesting:  true,
        isLogged:           false,
        isLoginFailed:      false,
        provider:           null,
      }
    case AuthActionsTypes.REQUEST_LOGIN_FAILURE:
      return {
        ...state,
        isLoginRequesting:  false,
        isLogged:           false,
        isLoginFailed:      true,
      }
    case AuthActionsTypes.REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        token:              action.payload.token,
        isLoginRequesting:  false,
        isLoginFailed:      false,
        isLogged:           true,
        provider:           action.payload.provider,
      }

    case AuthActionsTypes.REQUEST_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.profile
      }

    case AuthActionsTypes.REQUEST_SIGNUP_FORM_SUCCESS:
      return {
        ...state,
        signupForm: {
          form: action.payload.sections
        }
      }

    case AuthActionsTypes.REQUEST_SIGNUP_SUCCESS:
      return {
        ...state,
        isLoginRequesting:  false,
        isLoginFailed:      false,
        isLogged:           true,
        token:              action.payload.token,
        signupForm: {
          ...state.signupForm,
          error: undefined
        }
      }

    case AuthActionsTypes.REQUEST_SIGNUP_FAILURE:
      return {
        ...state,
        signupForm: {
          ...state.signupForm,
          error: action.payload.error,
        }
      }

    case AuthActionsTypes.LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default Auth
