import { ApplyForVendorAction } from '../actions/ApplyForVendor'
import ApplyForVendorState from '../types/ApplyForVendorState'
import ApplyForVendorActionTypes from '../types/actions/ApplyForVendor'

export const initialState: ApplyForVendorState = {
  form: {
    isRequesting: false,
    error:        null,
  },
  signup: {
    isRequesting: false,
    error:        null,
  },
}

const ApplyForVendor = function applyForVendor(state: ApplyForVendorState = {...initialState}, action: ApplyForVendorAction): ApplyForVendorState {
  switch (action.type) {
    case ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_FORM:
      return {
        ...state,
        form: {
          isRequesting: true,
          error:        null,
        }
      }

    case ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_FORM_SUCCESS:
      return {
        ...state,
        form: {
          form:   action.payload.sections,
          error:  null,
          isRequesting: false,
        }
      }

    case ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_FORM_FAILURE:
      return {
        ...state,
        form: {
          error:  action.payload.error,
          isRequesting: false,
        }
      }

    case ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_SIGNUP:
      return {
        ...state,
        signup: {
          isRequesting: true,
          error:        null,
        }
      }

    case ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_SIGNUP_SUCCESS:
      return {
        ...state,
        signup: {
          error:  null,
          isRequesting: false,
        }
      }

    case ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_SIGNUP_FAILURE:
      return {
        ...state,
        signup: {
          error:  action.payload.error,
          isRequesting: false,
        }
      }

    default:
      return state
  }
}

export default ApplyForVendor
