/**
 * Get past date
 *
 * @param {numerb} timeGap - seconds to go back from current time
 *
 * @returns {Date}
 */
const getPastDate = (timeGap: number): Date => {
  const currentDate = new Date();
  currentDate.setSeconds(-timeGap)

  return currentDate
}

export default getPastDate
