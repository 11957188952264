import cscartApi from './cscart-api'
import config from '../config/Config'
import ProductSelection from '../entities/product/ProductSelection'
import SortOrder from '../constants/SortOrder'

export const getProduct = (productId: number, options?: Array<any>) => {
  const baseRequest = (
    cscartApi
      .products
      .setIconSize([
        config.productSmallIconWidth,
        config.productSmallIconHeight
      ])
      .setIconSize([
        config.productBigIconWidth,
        config.productBigIconWidth
      ])
      .one(productId)
  )

  const selectedOptions = options ? options : [];

  return selectedOptions.length ? baseRequest.forOptions(selectedOptions).get() : baseRequest.get()
}

export const getProductsRequest = (
  productSelection?: ProductSelection
) => {

  let baseRequest = (
    cscartApi
      .products
      .setIconSize([
        config.productSmallIconWidth,
        config.productSmallIconHeight
      ])
      .setIconSize([
        config.productBigIconWidth,
        config.productBigIconWidth
      ])
      .withFilters()
  )

  if (productSelection) {
    if (productSelection.sorting) {
      baseRequest = baseRequest.orderBy(productSelection.sorting.sortBy)

      if (productSelection.sorting.order === SortOrder.DESC) {
        baseRequest = baseRequest.desc()
      } else {
        baseRequest = baseRequest.asc()
      }
    }
    if (productSelection.pagination) {
      baseRequest = baseRequest
        .limit(productSelection.pagination.limit)
        .page(productSelection.pagination.page)
    }
    if (productSelection.search) {
      baseRequest = baseRequest
        .search(productSelection.search)
    }
    if (productSelection.filterHashes) {
      baseRequest = baseRequest
        .withFilters(productSelection.filterHashes)
    }
  }

  return baseRequest
}

export const getProductsByCategory = (
  categoryId: number,
  productSelection?: ProductSelection
): Promise<any> => {
  return getProductsRequest(productSelection).forCategory(categoryId).get()
}

export const getProductsByVendor = (
  vendorId: number,
  productSelection?: ProductSelection
): Promise<any> => {

  return getProductsRequest(productSelection).byCompany(vendorId).get()
}

export const getProductsSearch = (
  productSelection?: ProductSelection
): Promise<any> => {

  return getProductsRequest(productSelection).get()
}
