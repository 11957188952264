import ProductActionTypes from './ProductActionTypes'
import Product from '../../../entities/product/Product'
import { persistProduct } from '../../../db/models/Product'
import ApiError from '../../../entities/error/ApiError'

export interface RequestProduct {
  type: ProductActionTypes.REQUEST_PRODUCT;
  payload: {
    productId: number;
    options?: Array<any>;
  }
}

export function requestProduct(id: number, options?: Array<any>): RequestProduct {
  return {
    type: ProductActionTypes.REQUEST_PRODUCT,
    payload: {
      productId: id,
      options: options,
    }
  }
}

export interface RequestProductSuccess {
  type: ProductActionTypes.REQUEST_PRODUCT_SUCCESS;
  payload: {
    product: Product;
  }
}

export function requestProductSuccess(rawProduct: any): RequestProductSuccess {

  const product = new Product(rawProduct);
  persistProduct(product)

  return {
    type: ProductActionTypes.REQUEST_PRODUCT_SUCCESS,
    payload: {
      product
    }
  }
}

export interface RequestProductFailure {
  type: ProductActionTypes.REQUEST_PRODUCT_FAILURE;
  payload: {
    id: number|string;
    error: ApiError;
  }
}

export function requestProductFailure(id: number|string, error: any): RequestProductFailure {
  const apiError = new ApiError(error);

  return {
    type: ProductActionTypes.REQUEST_PRODUCT_FAILURE,
    payload: {
      id,
      error: apiError
    }
  }
}
