import values from 'lodash/values'
import LayoutsActionsTypes from '../types/actions/Layouts'
import LayoutTypes from '../../constants/LayoutTypes'
import { persistCategory } from '../../db/models/Category'
import { persistProduct } from '../../db/models/Product'
import { persistArticle } from '../../db/models/Article'
import BlockTypes from '../../constants/BlockTypes'

import Layout from '../../entities/layout/Layout'
import { persistLayout } from '../../db/models/Layout'
import { persistVendor } from '../../db/models/Vendor'
import ApiError from '../../entities/error/ApiError'
import Product from '../../entities/product/Product'
import Category from '../../entities/category/Category'
import Article from '../../entities/article/Article'
import Vendor from '../../entities/vendor/Vendor'

export interface GetLayout {
  type: LayoutsActionsTypes.GET_LAYOUT;
  payload: {
    dispatch: LayoutTypes;
    slug?:    string;
  };
}

export function getLayout(dispatch: LayoutTypes, slug?: string): GetLayout {
  return {
    type: LayoutsActionsTypes.GET_LAYOUT,
    payload: {
      dispatch,
      slug,
    }
  }
}

// RequestBlocks
export interface RequestBlocks {
  type: LayoutsActionsTypes.REQUEST_BLOCKS;
  payload: {
    dispatch: LayoutTypes;
    slug?:    string;
  };
}

export function requestBlocks(dispatch: LayoutTypes, slug?: string): RequestBlocks {

  return {
    type: LayoutsActionsTypes.REQUEST_BLOCKS,
    payload: {
      dispatch,
      slug,
    }
  }
}

export interface RequestBlocksSuccess {
  type: LayoutsActionsTypes.REQUEST_BLOCKS_SUCCESS;
  payload: {
    dispatch: LayoutTypes;
    layout: Layout;
    slug?: string;
    entity?: Product|Category|Article|Vendor;
  }
}

export function requestBlocksSuccess(dispatch: LayoutTypes, rawLayout: any, slug?: string): RequestBlocksSuccess {
  const layout = new Layout({
    type: dispatch,
    blocks: values(rawLayout)
  })

  const entity = getEntityFromLayout(rawLayout);
  persistLayout(layout)

  layout.blocks.forEach(block => {
    switch (block.type) {
      case BlockTypes.PRODUCTS:
        block.content.products.forEach((product: any) => persistProduct(product))
        break

      case BlockTypes.CATEGORIES:
        block.content.categories.forEach((category: any) => persistCategory(category))
        break

      case BlockTypes.ARTICLES:
        block.content.articles.forEach((article: any) => persistArticle(article))
        break

      case BlockTypes.VENDORS:
        block.content.vendors.forEach((vendor: any) => persistVendor(vendor))
        break
    }
  })

  return {
    type: LayoutsActionsTypes.REQUEST_BLOCKS_SUCCESS,
    payload: {
      dispatch,
      layout,
      slug,
      entity,
    }
  }
}

export interface RequestBlocksFailure {
  type: LayoutsActionsTypes.REQUEST_BLOCKS_FAILURE;
  payload: {
    dispatch: LayoutTypes;
    error: ApiError;
    slug?:  string;
  }
}

export function requestBlocksFailure(dispatch: LayoutTypes, error: any, slug?: string): RequestBlocksFailure {
  const apiError = new ApiError({
    status: error.status,
    shouldRetry: error.shouldRetry || false,
    message: error.message || 'Unhandled error'
  })

  return {
    type: LayoutsActionsTypes.REQUEST_BLOCKS_FAILURE,
    payload: {
      dispatch,
      error: apiError,
      slug,
    }
  }
}

// DbRequestBlocks
export interface DbRequestBlocks {
  type: LayoutsActionsTypes.DB_REQUEST_BLOCKS;
  payload: {
    dispatch: LayoutTypes;
    slug?:    string;
  };
}

export function dbRequestBlocks(dispatch: LayoutTypes, slug?: string): DbRequestBlocks {
  return {
    type: LayoutsActionsTypes.DB_REQUEST_BLOCKS,
    payload: {
      dispatch,
      slug,
    }
  }
}

export interface DbRequestBlocksSuccess {
  type: LayoutsActionsTypes.DB_REQUEST_BLOCKS_SUCCESS;
  payload: {
    layout: Layout;
  }
}

export function dbRequestBlocksSuccess(layout: Layout): DbRequestBlocksSuccess {

  return {
    type: LayoutsActionsTypes.DB_REQUEST_BLOCKS_SUCCESS,
    payload: {
      layout,
    }
  }
}

export interface DbRequestBlocksFailure {
  type: LayoutsActionsTypes.DB_REQUEST_BLOCKS_FAILURE;
  payload: {
    dispatch: LayoutTypes;
    slug?:  string;
  }
}

export function dbRequestBlocksFailure(dispatch: LayoutTypes, slug?: string): DbRequestBlocksFailure {
  return {
    type: LayoutsActionsTypes.DB_REQUEST_BLOCKS_FAILURE,
    payload: {
      dispatch,
      slug,
    }
  }
}

/**
 * Find at raw api layout object any entity,
 * convert it to SPA entity instance and persist to DB
 *
 * @param layout
 *
 * @returns Product|Category|Article|Vendor|undefined
 */
const getEntityFromLayout = (layout: any): Product|Category|Article|Vendor|undefined => {
  const blocks = values(layout);
  const mainBlock = blocks.find(block => block.type === 'spa_main');

  if (!mainBlock || !mainBlock.content) {
    return undefined
  }

  switch (true) {
    case !!mainBlock.content.product:
      const product = new Product(mainBlock.content.product);
      persistProduct(product)

      return product

    case !!mainBlock.content.category:
      const category = new Category(mainBlock.content.category);
      persistCategory(category)

      return category

    case !!mainBlock.content.page:
      const article = new Article(mainBlock.content.page);
      persistArticle(article)

      return article

    case !!mainBlock.content.company:
      const vendor = new Vendor(mainBlock.content.company);
      persistVendor(vendor)

      return vendor
  }
}

export type LayoutsAction = GetLayout
| RequestBlocks | RequestBlocksSuccess | RequestBlocksFailure
| DbRequestBlocks | DbRequestBlocksSuccess | DbRequestBlocksFailure;
