import IProfile from './IProfile'

import acronym from '../../utils/string/acronym'
import hashCode from '../../utils/string/hashCode'
import intToRgb from '../../utils/color/intToRgb'

/**
 * User profile information
 */
class Profile implements IProfile {
  firstname:    string;
  lastname:     string;
  acronym:      string;
  color:        string;
  userId:       number;
  email:        string;
  login:        string;
  companyName:  string;
  companyId:    number;

  constructor(rawProfile: any) {
    const profile = {...rawProfile};

    if ('userId' in profile) {
      this.userId       = profile.userId;
      this.login        = profile.login;
      this.email        = profile.email;
      this.firstname    = profile.firstname;
      this.lastname     = profile.lastname;
      this.acronym      = profile.acronym;
      this.companyName  = profile.companyName;
      this.companyId    = profile.companyId;
      this.color        = profile.color;
    } else {
      this.userId       = profile.user_id ? parseInt(profile.user_id) : 0;
      this.email        = profile.email;
      this.login        = profile.user_login || (!this.userId ? 'Guest' : this.email);
      this.firstname    = profile.firstname || '';
      this.lastname     = profile.lastname || '';
      this.acronym      = acronym(`${this.firstname} ${this.lastname}`);
      this.companyName  = profile.comany_name;
      this.companyId    = profile.company_id ? parseInt(profile.company_id) : 0;
      this.color        = '#' + intToRgb(hashCode(`${this.firstname} ${this.lastname}`));
    }
  }

  get isLogged(): boolean {
    return this.userId > 0
  }
}

export default Profile
