import DeviceTypes from '../../constants/DeviceTypes'

/**
 * Determine device type by viewport width
 *
 * @param {number} width - viewport width
 *
 * @returns {DeviceTypes}
 */
const getMatchedDeviceType = (width: number): DeviceTypes => {
  switch (true) {
    case width < 768:
      return  DeviceTypes.MOBILE

    case width >= 768 && width < 1200:
      return DeviceTypes.TABLET

    // case width >= 1200:
    default:
      return DeviceTypes.DESKTOP
  }
}

export default getMatchedDeviceType
