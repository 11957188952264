import { withRouter } from 'react-router-dom'

import ScreenHandler from './ScreenHandler'
import * as actions from '../../redux/actions'
import { StoreState } from '../../redux/types'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

function mapStateToProps(state: StoreState, ownProps: any) {
  return {
    language: state.Ui.language,
    currency: state.Ui.currency,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.SearchAction | actions.NavigationBarAction | actions.UiAction>) {
  return {
    resetNavigationBar: () => dispatch(actions.resetNavigationBar()),
    setLanguage:        (language: string) => dispatch(actions.setLanguage(language)),
    closeAllDrawers:    () => dispatch(actions.closeAllDrawers()),
    setCanGoBack:       () => dispatch(actions.setCanGoBack(true)),
    closeSearchAutocomplete: () => dispatch(actions.closeSearchAutocomplete()),
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenHandler));
