enum ReviewableEntities {
  PRODUCT     = 'product',
  CATEGORY    = 'category',
  ARTICLE     = 'article',
  ORDER       = 'order',
  STOREFRONT  = 'storefront',
  VENDOR      = 'vendor',
}

export default ReviewableEntities
