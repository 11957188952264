import ProductActionTypes from './ProductActionTypes'
import Product from '../../../entities/product/Product'
import ProductSelection from '../../../entities/product/ProductSelection'

export interface DbRequestVendorProducts {
  type: ProductActionTypes.DB_REQUEST_VENDOR_PRODUCTS;
  payload: {
    vendorId: number;
  }
}

export function dbRequestVendorProducts(id: number): DbRequestVendorProducts {
  return {
    type: ProductActionTypes.DB_REQUEST_VENDOR_PRODUCTS,
    payload: {
      vendorId: id,
    }
  }
}

export interface DbRequestVendorProductsSuccess {
  type: ProductActionTypes.DB_REQUEST_VENDOR_PRODUCTS_SUCCESS;
  payload: {
    productSelection: ProductSelection;
  }
}

export function dbRequestVendorProductsSuccess(products: Array<Product>): DbRequestVendorProductsSuccess {
  const productSelection = new ProductSelection({
    pagination: {
      page: 1,
      total: products.length,
      limit: products.length,
    },
    filters: [],
    products: products,
  })

  return {
    type: ProductActionTypes.DB_REQUEST_VENDOR_PRODUCTS_SUCCESS,
    payload: {
      productSelection,
    }
  }
}

export interface DbRequestVendorProductsFailure {
  type: ProductActionTypes.DB_REQUEST_VENDOR_PRODUCTS_FAILURE;
  payload: {
    vendorId:  number;
  }
}

export function dbRequestVendorProductsFailure(id: number): DbRequestVendorProductsFailure {

  return {
    type: ProductActionTypes.DB_REQUEST_VENDOR_PRODUCTS_FAILURE,
    payload: {
      vendorId: id,
    }
  }
}
