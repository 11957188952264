class RedirectionForm {
  html:     string;
  orderId:  number;

  constructor(rawData: any) {
    const data = {...rawData};

    this.html     = data.html;
    this.orderId  = data.orderId;
  }
}

export default RedirectionForm
