import { map, switchMap, filter, catchError } from 'rxjs/operators'
import {  from, of, EMPTY } from 'rxjs'

import cscartApi from '../../api/'
import { ActionsObservable, ofType } from 'redux-observable'
import db from '../../db/models/'
import {
  // types
  RequestCategoryProducts,
  RequestSearchProducts,
  RequestVendorProducts,
  RequestCategoryProductsFailure,
  RequestVendorProductsFailure,
  RequestProduct,
  RequestProductFailure,
  DbRequestProduct,
  DbRequestCategoryProducts,
  DbRequestVendorProducts,

  // actions
  requestProductSuccess,
  requestProductFailure,

  dbRequestProduct,
  dbRequestProductSuccess,
  dbRequestProductFailure,

  dbRequestCategoryProducts,
  dbRequestCategoryProductsSuccess,
  dbRequestVendorProducts,
  dbRequestVendorProductsSuccess,

  requestCategoryProductsSuccess,
  requestCategoryProductsFailure,
  requestVendorProductsSuccess,
  requestVendorProductsFailure,
  requestSearchProductsSuccess,
  requestSearchProductsFailure,
  setCurrentProduct,
  setProductIsRequesting,
} from '../actions/product/ProductAction'
import ProductActionTypes from '../actions/product/ProductActionTypes'
import Product from '../../entities/product/Product'
import { RequestBlocksSuccess, RequestBlocksFailure, RequestBlocks } from '../actions'
import LayoutsActionsTypes from '../types/actions/Layouts'
import LayoutTypes from '../../constants/LayoutTypes'

/**
 * Requests a product from API
 */
export const requestProductEpic = (
  action$: ActionsObservable<RequestProduct>,
  state$: null,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType(ProductActionTypes.REQUEST_PRODUCT),
  switchMap((action: RequestProduct) =>
    from(
      api.products.getProduct(action.payload.productId, action.payload.options)
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestProductSuccess(result.data)
        :
          requestProductFailure(action.payload.productId, {
            status: result.status,
            message: result.message
          })
      ),
      catchError((error) => {
        console.error(error)

        return of(requestProductFailure(
          action.payload.productId,
          {
            status: 200,
            message: 'Server responded with an unexpected data'
          }
        ))
      })
    )
  ),
)

/**
 * Request products for a category
 */
export const requestCategoryProductsEpic = (
  action$: ActionsObservable<RequestCategoryProducts>,
  state$: null,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(ProductActionTypes.REQUEST_CATEGORY_PRODUCTS),
  switchMap((action: RequestCategoryProducts) =>
    from(
      api.products.getProductsByCategory(
        action.payload.categoryId,
        action.payload.productSelection
      )
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestCategoryProductsSuccess(
            result.data.products,
            result.data.params,
            result.data.filters,
            action.payload.shouldAppend
          )
        :
          requestCategoryProductsFailure(action.payload.categoryId, {
            status: result.status,
            message: result.message || ''
          })
      ),
    )
  ),
)

/**
 * Request products for a search
 */
export const requestSearchProductsEpic = (
  action$: ActionsObservable<RequestSearchProducts>,
  state$: null,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(ProductActionTypes.REQUEST_SEARCH_PRODUCTS),
  switchMap((action: RequestSearchProducts) =>
    from(
      api.products.getProductsSearch(
        action.payload.productSelection
      )
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestSearchProductsSuccess(
            result.data.products,
            result.data.params,
            result.data.filters,
            action.payload.shouldAppend
          )
        :
          requestSearchProductsFailure({
            status: result.status,
            message: result.message || ''
          })
      ),
    )
  ),
)

/**
 * Request products for a vendor
 */
export const requestVendorProductsEpic = (
  action$: ActionsObservable<RequestVendorProducts>,
  state$: null,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(ProductActionTypes.REQUEST_VENDOR_PRODUCTS),
  switchMap((action: RequestVendorProducts) =>
    from(
      api.products.getProductsByVendor(
        action.payload.id,
        action.payload.productSelection
      )
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestVendorProductsSuccess(
            result.data.products,
            result.data.params,
            result.data.filters,
            action.payload.shouldAppend
          )
        :
          requestVendorProductsFailure(action.payload.id, {
            status: result.status,
            message: result.message || ''
          })
      ),
    )
  ),
)

/**
 * Should request DB for a product on network error
 */
export const mapApiRequestFailureToDbRequestEpic = (
  action$: ActionsObservable<RequestProductFailure>
) => action$.pipe(
  ofType(ProductActionTypes.REQUEST_PRODUCT_FAILURE),
  // get only network error
  filter((action: RequestProductFailure) => action.payload.error.status === 0),
  switchMap((action: RequestProductFailure) => of(dbRequestProduct(action.payload.id))),
)

/**
 * Should request DB for a products on network error
 */
export const mapCategoryProductsApiRequestFailureToDbRequestEpic = (
  action$: ActionsObservable<RequestCategoryProductsFailure>
) => action$.pipe(
  ofType(ProductActionTypes.REQUEST_CATEGORY_PRODUCTS_FAILURE),
  // get only network error
  filter((action: RequestCategoryProductsFailure) => action.payload.error.status === 0),
  switchMap((action: RequestCategoryProductsFailure) => of(dbRequestCategoryProducts(action.payload.categoryId))),
)

/**
 * Should request DB for a products by a vendor on network error
 */
export const mapVendorProductsApiRequestFailureToDbRequestEpic = (
  action$: ActionsObservable<RequestVendorProductsFailure>
) => action$.pipe(
  ofType(ProductActionTypes.REQUEST_VENDOR_PRODUCTS_FAILURE),
  // get only network error
  filter((action: RequestVendorProductsFailure) => action.payload.error.status === 0),
  switchMap((action: RequestVendorProductsFailure) => of(dbRequestVendorProducts(action.payload.id))),
)

/**
 * Perform DB request for a product by id
 */
export const dbRequestProductEpic = (
  action$: ActionsObservable<DbRequestProduct>,
  state$: null,
  { indexedDb }: { indexedDb: typeof db }
) => action$.pipe(
  ofType(ProductActionTypes.DB_REQUEST_PRODUCT),
  switchMap((action: DbRequestProduct) =>
    from(
      indexedDb.products.findProduct(action.payload.id)
    ).pipe(
      map(product =>
        product
          ?
            dbRequestProductSuccess(product)
          :
            dbRequestProductFailure(action.payload.id)
      ),
    )
  ),
)

/**
 * Perform DB request for a products by category
 */
export const dbRequestCategoryProductsEpic = (
  action$: ActionsObservable<DbRequestCategoryProducts>,
  state$: null,
  { indexedDb }: { indexedDb: typeof db }
) => action$.pipe(
  ofType(ProductActionTypes.DB_REQUEST_CATEGORY_PRODUCTS),
  switchMap((action: DbRequestCategoryProducts) =>
    from(
      indexedDb.products.findProductsByCategory(action.payload.categoryId)
    ).pipe(
      map((products: Array<Product>) =>
        dbRequestCategoryProductsSuccess(products)
      ),
    )
  ),
)

/**
 * Perform DB request for a products by vendor
 */
export const dbRequestVendorProductsEpic = (
  action$: ActionsObservable<DbRequestVendorProducts>,
  state$: null,
  { indexedDb }: { indexedDb: typeof db }
) => action$.pipe(
  ofType(ProductActionTypes.DB_REQUEST_VENDOR_PRODUCTS),
  switchMap((action: DbRequestVendorProducts) =>
    from(
      indexedDb.products.findProductsByVendor(action.payload.vendorId)
    ).pipe(
      map((products: Array<Product>) =>
        dbRequestVendorProductsSuccess(products)
      ),
    )
  ),
)

/**
 * Set current product
 */
export const setCurrentProductEpic = (
  action$: ActionsObservable<RequestBlocksSuccess>
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS_SUCCESS),
  switchMap((action) => {

    if (action.payload.entity && action.payload.entity instanceof Product) {
      return of(setCurrentProduct(action.payload.entity))
    }

    return EMPTY
  }),
)

/**
 * Map layout request with slug failure to db request for entity
 */
export const mapFailuredLayoutRequestToProductRequestEpic = (
  action$: ActionsObservable<RequestBlocksFailure>,
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS_FAILURE),
  filter((action) => action.payload.dispatch === LayoutTypes.PRODUCT && !!action.payload.slug),
  switchMap((action) =>
    of(requestProductFailure(action.payload.slug!, action.payload.error))
  ),
)

/**
 * set current product is loading true when map layout request for a product begins
 */
export const mapSetProductIsRequestingOnLayoutEpic = (
  action$: ActionsObservable<RequestBlocks>,
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS),
  filter((action) => action.payload.dispatch === LayoutTypes.PRODUCT && !!action.payload.slug),
  switchMap(() =>
    of(setProductIsRequesting(true))
  ),
)

export default [
  requestProductEpic,
  requestCategoryProductsEpic,
  requestVendorProductsEpic,
  requestSearchProductsEpic,
  dbRequestProductEpic,
  dbRequestCategoryProductsEpic,
  dbRequestVendorProductsEpic,

  mapApiRequestFailureToDbRequestEpic,
  mapCategoryProductsApiRequestFailureToDbRequestEpic,
  mapVendorProductsApiRequestFailureToDbRequestEpic,

  setCurrentProductEpic,
  mapFailuredLayoutRequestToProductRequestEpic,

  mapSetProductIsRequestingOnLayoutEpic,
]
