import CategoryActionTypes from './CategoryActionTypes'
import Category from '../../../entities/category/Category'
import ApiError from '../../../entities/error/ApiError'
import { persistCategory } from '../../../db/models/Category'

export interface RequestCategory {
  type: CategoryActionTypes.REQUEST_CATEGORY;
  payload: {
    categoryId: number;
  }
}

export function requestCategory(id: number): RequestCategory {
  return {
    type: CategoryActionTypes.REQUEST_CATEGORY,
    payload: {
      categoryId: id
    }
  }
}

export interface RequestCategorySuccess {
  type: CategoryActionTypes.REQUEST_CATEGORY_SUCCESS;
  payload: {
    category: Category;
  }
}

export function requestCategorySuccess(rawCategory: any): RequestCategorySuccess {
  const category = new Category(rawCategory);
  persistCategory(category)

  return {
    type: CategoryActionTypes.REQUEST_CATEGORY_SUCCESS,
    payload: {
      category
    }
  }
}

export interface RequestCategoryFailure {
  type: CategoryActionTypes.REQUEST_CATEGORY_FAILURE;
  payload: {
    id:     number|string;
    error:  ApiError;
  }
}

export function requestCategoryFailure(id: number|string, error: any): RequestCategoryFailure {

  const apiError = new ApiError(error)

  return {
    type: CategoryActionTypes.REQUEST_CATEGORY_FAILURE,
    payload: {
      id,
      error: apiError
    }
  }
}
