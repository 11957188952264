import Testimonial from './Testimonial'
import AbstractItemsSelection from '../selection/AbstractItemsSelection'
import Sorting from '../selection/Sorting'
import IReviewSelection from './IReviewSelection'

/**
 * Selection for testimonials
 */
class ReviewSelection extends AbstractItemsSelection implements IReviewSelection {
  testimonials:   Array<Testimonial>;
  filters: null;

  constructor(rawReviewSelection: any) {
    const testimonialSelection = {...rawReviewSelection};
    super(testimonialSelection)

    this.testimonials   = (testimonialSelection.testimonials || []).map((testimonial: any) => new Testimonial(testimonial));
  }

  prependItems(testimonials: Array<Testimonial>): ReviewSelection {
    return new ReviewSelection({
      ...this,
      testimonials: [
        ...testimonials,
        ...this.testimonials,
      ]
    })
  }

  fromUrl(url: string): ReviewSelection|undefined {
    const urlParams = new URLSearchParams(url);
    let isEmpty = true;

    if (urlParams.get('sortBy')) {
      this.sorting = new Sorting({
        sortBy: urlParams.get('sortBy'),
        order: urlParams.get('order'),
      });
      isEmpty = false;
    }

    if (isEmpty) {
      return undefined
    } else {
      return this
    }
  }
}

export default ReviewSelection
