import { defineMessages, Messages } from 'react-intl'

const messages: Messages = {
  title: {
    id: 'app.components.gdpr.agreement.title',
    defaultMessage: 'LOCAL STORAGE USE',
  },
  description: {
    id: 'app.components.gdpr.agreement.description',
    defaultMessage: `This website uses Local Storage for process your personal data to ensure you
      get the best experience on our site. This tool enable you to enjoy certain features (like saving your
      products to cart or wish list). Please note that we don't use cookies, however they can be
      used by third-party tools (like analytics widgets or google authorization). `,
  },
  conditions: {
    id: 'app.components.gdpr.agreement.conditions',
    defaultMessage: `You accept the use of Local Storage or other identifiers by closing or dismissing this notice
      by clicking a buton or by continuing to browse otherwise. `,
  },
  acceptButton: {
    id: 'app.components.gdpr.agreement.accept-button',
    defaultMessage: 'Accept',
  },
}

const definedMessages = defineMessages(messages)

export default definedMessages
