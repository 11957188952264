import values from 'lodash/values'

import config from '../../config/Config'

import ICategory from './ICategory'
import ImagePair from '../image-pair/ImagePair'

import hashCode from '../../utils/string/hashCode'
import intToRgb from '../../utils/color/intToRgb'
import acronym from '../../utils/string/acronym'
import SeoSnippet from './SeoSnippet'
import { createCategoryLinkUrl } from '../../url'
import Parent from '../parent/Parent'
import IParent from '../parent/IParent'

class Category implements ICategory {
  id:             number;
  slug:           string;

  productsCount:  number;
  position:       number;
  // type: 'C'
  name:           string;
  description:    string;

  subcategories:  Array<Category>;

  imagePair:      ImagePair;
  color:          string;
  acronym:        string;
  seoSnippet:     SeoSnippet;
  url:            string;
  parents:        Array<Parent>;

  constructor(rawCategory: ICategory | any) {
    const category = {...rawCategory};

    if (category.id) {
      this.id             = category.id;
      this.slug           = category.slug;
      this.productsCount  = category.productsCount;
      this.position       = category.position;
      this.name           = category.name;
      this.description    = category.description;
      this.seoSnippet     = new SeoSnippet(category.seoSnippet);
      this.imagePair      = new ImagePair(category.imagePair);
      this.subcategories  = category.subcategories.map((category: ICategory) => new Category(category));
      this.url            = category.url;
      this.parents        = category.parents.map((parent: IParent) => new Parent(parent));
    } else {
      this.id             = parseInt(category.category_id);
      this.slug           = category.seo_name;
      this.productsCount  = parseInt(category.product_count || '0');
      this.position       = parseInt(category.position || '0');
      this.name           = category.category;
      this.description    = category.description;
      this.seoSnippet     = new SeoSnippet({
        name:             this.name,
        title:            this.name,
        description:      category.description,
        metaDescription:  category.meta_description,
      });
      this.imagePair      = new ImagePair(category.main_pair || {});
      this.subcategories  = (values(category.subcategories) || []).map((category: any) => new Category(category));
      this.url            = createCategoryLinkUrl(this.slug);
      this.parents        = (category.categories || []).map((parent: any) => new Parent({
        id: parent.category_id,
        name: parent.category,
        url: createCategoryLinkUrl(parent.seo_key),
      }))
    }

    if (!this.name) {
      throw new Error('Category name is missing. Check category object')
    }

    this.color            = '#' + intToRgb(hashCode(this.name));
    this.acronym          = acronym(this.name).substring(0, 3);

    if (!this.id) {
      throw new Error('Id param is missing. Check category object')
    }
  }

  setSubcategories(categories: Array<Category>) {
    this.subcategories = categories;
  }

  removeSubcategories() {
    this.setSubcategories([]);
  }

  getSmallIcon() {
    return this.imagePair.getIconBySize(
      config.categorySmallIconWidth,
      config.categorySmallIconHeight
    )
  }
}

export default Category
