import ArticleActionTypes from './ArticleActionTypes'
import Article from '../../../entities/article/Article'
import ArticleSelection from '../../../entities/article/ArticleSelection'

export interface DbRequestArticles {
  type: ArticleActionTypes.DB_REQUEST_ARTICLES;
  payload: {
    parentId: number;
  }
}

export function dbRequestArticles(id: number): DbRequestArticles {
  return {
    type: ArticleActionTypes.DB_REQUEST_ARTICLES,
    payload: {
      parentId: id,
    }
  }
}

export interface DbRequestArticlesSuccess {
  type: ArticleActionTypes.DB_REQUEST_ARTICLES_SUCCESS;
  payload: {
    selection: ArticleSelection;
  }
}

export function dbRequestArticlesSuccess(articles: Array<Article>): DbRequestArticlesSuccess {

  const articleSelection = new ArticleSelection({
    pagination: {
      page: 1,
      total: articles.length,
      limit: articles.length,
    },
    filters: [],
    articles: articles,
  })

  return {
    type: ArticleActionTypes.DB_REQUEST_ARTICLES_SUCCESS,
    payload: {
      selection: articleSelection,
    }
  }
}

export interface DbRequestArticlesFailure {
  type: ArticleActionTypes.DB_REQUEST_ARTICLES_FAILURE;
  payload: {
    parentId:  number;
  }
}

export function dbRequestArticlesFailure(id: number): DbRequestArticlesFailure {

  return {
    type: ArticleActionTypes.DB_REQUEST_ARTICLES_FAILURE,
    payload: {
      parentId: id,
    }
  }
}
