import SearchActions from '../types/actions/Search'

export interface PerformSearch {
  type: SearchActions.PERFORM_SEARCH;
  payload: {
    query: string;
  }
}

export function performSearch(query: string): PerformSearch {
  return {
    type: SearchActions.PERFORM_SEARCH,
    payload: {
      query,
    }
  }
}

export interface OpenSearchAutocomplete {
  type: SearchActions.OPEN_SEARCH_AUTOCOMPLETE;
}

export function openSearchAutocomplete(): OpenSearchAutocomplete {
  return {
    type: SearchActions.OPEN_SEARCH_AUTOCOMPLETE,
  }
}

export interface CloseSearchAutocomplete {
  type: SearchActions.CLOSE_SEARCH_AUTOCOMPLETE;
}

export function closeSearchAutocomplete(): CloseSearchAutocomplete {
  return {
    type: SearchActions.CLOSE_SEARCH_AUTOCOMPLETE,
  }
}

export type SearchAction = PerformSearch
| OpenSearchAutocomplete | CloseSearchAutocomplete;
