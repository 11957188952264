import Vendor from './Vendor'
import AbstractItemsSelection from '../selection/AbstractItemsSelection'

class VendorSelection extends AbstractItemsSelection {
  vendors:   Array<Vendor>;
  filters:   Array<any>;

  constructor(rawVendorSelection: any) {
    const vendorSelection = {...rawVendorSelection};
    super(vendorSelection)

    this.vendors = vendorSelection.vendors.map((vendor: any) => new Vendor(vendor));
    this.filters = [];
  }

  prependItems(vendors: Array<Vendor>): VendorSelection {
    return new VendorSelection({
      ...this,
      vendors: [
        ...vendors,
        ...this.vendors,
      ]
    })
  }
}

export default VendorSelection
