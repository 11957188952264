enum AuthActionsTypes {
  REQUEST_LOGIN         = 'REQUEST_LOGIN',
  REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS',
  REQUEST_LOGIN_FAILURE = 'REQUEST_LOGIN_FAILURE',

  REQUEST_SOCIAL_LOGIN  = 'REQUEST_SOCIAL_LOGIN',

  LOGOUT          = 'LOGOUT',
  LOGOUT_SUCCESS  = 'LOGOUT_SUCCESS',

  REQUEST_PROFILE = 'REQUEST_PROFILE',
  REQUEST_PROFILE_SUCCESS = 'REQUEST_PROFILE_SUCCESS',
  REQUEST_PROFILE_FAILURE = 'REQUEST_PROFILE_FAILURE',

  REQUEST_SIGNUP_FORM = 'REQUEST_SIGNUP_FORM',
  REQUEST_SIGNUP_FORM_SUCCESS = 'REQUEST_SIGNUP_FORM_SUCCESS',
  REQUEST_SIGNUP_FORM_FAILURE = 'REQUEST_SIGNUP_FORM_FAILURE',

  REQUEST_SIGNUP = 'REQUEST_SIGNUP',
  REQUEST_SIGNUP_SUCCESS = 'REQUEST_SIGNUP_SUCCESS',
  REQUEST_SIGNUP_FAILURE = 'REQUEST_SIGNUP_FAILURE',

  SET_TOKEN = 'SET_TOKEN',
}

export default AuthActionsTypes
