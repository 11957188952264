enum WishlistActionsTypes {
  REQUEST_WISHLIST = 'REQUEST_WISHLIST',
  REQUEST_WISHLIST_SUCCESS = 'REQUEST_WISHLIST_SUCCESS',
  DB_REQUEST_WISHLIST_SUCCESS = 'DB_REQUEST_WISHLIST_SUCCESS',
  REQUEST_WISHLIST_FAILURE = 'REQUEST_WISHLIST_FAILURE',
  ADD_TO_WISHLIST_QUEUE = 'ADD_TO_WISHLIST_QUEUE',
  ADD_TO_WISHLIST_REMOVE_QUEUE = 'ADD_TO_WISHLIST_REMOVE_QUEUE',
  REMOVE_FROM_WISHLIST_QUEUE = 'REMOVE_FROM_WISHLIST_QUEUE',

  REQUEST_ADD_TO_WISHLIST = 'REQUEST_ADD_TO_WISHLIST',
  REQUEST_ADD_TO_WISHLIST_SUCCESS = 'REQUEST_ADD_TO_WISHLIST_SUCCESS',
  REQUEST_ADD_TO_WISHLIST_FAILURE = 'REQUEST_ADD_TO_WISHLIST_FAILURE',

  REQUEST_REMOVE_FROM_WISHLIST = 'REQUEST_REMOVE_FROM_WISHLIST',
  REQUEST_REMOVE_FROM_WISHLIST_SUCCESS = 'REQUEST_REMOVE_FROM_WISHLIST_SUCCESS',
  REQUEST_REMOVE_FROM_WISHLIST_FAILURE = 'REQUEST_REMOVE_FROM_WISHLIST_FAILURE',

  CLEAR_WISHLIST = 'CLEAR_WISHLIST',
  REQUEST_CLEAR_WISHLIST = 'REQUEST_CLEAR_WISHLIST',
  REQUEST_CLEAR_WISHLIST_SUCCESS = 'REQUEST_CLEAR_WISHLIST_SUCCESS',
  REQUEST_CLEAR_WISHLIST_FAILURE = 'REQUEST_CLEAR_WISHLIST_FAILURE',

  RESET_WISHLIST_STATE = 'RESET_WISHLIST_STATE',
}

export default WishlistActionsTypes
