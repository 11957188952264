enum ReviewActions {
  REQUEST_REVIEW_THREAD          = 'REQUEST_REVIEW_THREAD',
  REQUEST_REVIEW_THREAD_SUCCESS  = 'REQUEST_REVIEW_THREAD_SUCCESS',
  REQUEST_REVIEW_THREAD_FAILURE  = 'REQUEST_REVIEW_THREAD_FAILURE',

  CREATE_TESTIMONIAL_DRAFT  = 'CREATE_TESTIMONIAL_DRAFT',
  REMOVE_TESTIMONIAL_DRAFT  = 'REMOVE_TESTIMONIAL_DRAFT',

  REQUEST_REVIEW_ADD          = 'REQUEST_REVIEW_ADD',
  REQUEST_REVIEW_ADD_SUCCESS  = 'REQUEST_REVIEW_ADD_SUCCESS',
  REQUEST_REVIEW_ADD_FAILURE  = 'REQUEST_REVIEW_ADD_FAILURE',
}

export default ReviewActions
