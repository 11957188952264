import { map, switchMap, catchError, filter, auditTime } from 'rxjs/operators'
import { from, of, EMPTY } from 'rxjs'
import values from 'lodash/values'
import { ActionsObservable, StateObservable } from 'redux-observable'
import { ofType } from 'redux-observable'

import cscartApi from '../../api/cscart-api'
import { WishlistAction, AuthAction, AppAction } from '../actions'
import {
  // types
  AddToWishlistRemoveQueue,

  // actions
  resetWishlistState,
} from '../actions/Wishlist'
import { StoreState } from '../types'
import { persistProduct, findProducts } from '../../db/models/Product'

import Product from '../../entities/product/Product'

import typeCastWishlist from '../../type-casts/wishlist'
import {
  APP_INIT,
} from '../types/actions'
import AuthActionsTypes from '../types/actions/Auth'
import WishlistActionTypes from '../types/actions/Wishlist'

import { requestCatch } from './networkHelper'

export const requestWishlistEpic = (
  action$: ActionsObservable<WishlistAction>
) => action$.pipe(
  ofType(WishlistActionTypes.REQUEST_WISHLIST),
  switchMap((action: any) =>
    from(
      cscartApi
        .wishlist
        .get()
    ).pipe(
      map((response: any) => {
        const wishlist = typeCastWishlist(response.data);
        wishlist.products.map((product: any) => persistProduct(product))

        return {
          payload: {
            wishlist,
          },
          type: WishlistActionTypes.REQUEST_WISHLIST_SUCCESS
        }
      }),
      catchError((error: any) => requestCatch(action.payload, error, WishlistActionTypes.REQUEST_WISHLIST_FAILURE))
    )
  ),
)

export const requestAddToWishlistEpic = (
  action$: ActionsObservable<WishlistAction>,
  state$: StateObservable<StoreState>
) => action$.pipe(
  ofType(WishlistActionTypes.REQUEST_ADD_TO_WISHLIST),
  switchMap((action: any) => {

    const products = state$.value.Wishlist.queue.map((productId: number) => ({
      product_id: productId
    }));

    return from(
      cscartApi
        .wishlist
        .add(products)
    ).pipe(
      map(({ data }: any) => {
        // showSuccessMessage('Product added to cart')
        return {
          type: WishlistActionTypes.REQUEST_ADD_TO_WISHLIST_SUCCESS,
          payload: {
            productsIds: values(data.cart_ids)
          }
        }
      }),
      catchError((error: any) => {

        switch (error.response.status) {
          case 409:
            // TODO fix api logic
            // return of({
            //   type: REMOVE_FROM_WISHLIST_QUEUE,
            //   payload: {
            //     productsIds //removeFromWishlistQueue(productsIds)
            //   }
            // })
            // showWarningMessage('You should login to add products to cart')
            break;
        }
        return requestCatch(action.payload, error, WishlistActionTypes.REQUEST_ADD_TO_WISHLIST_FAILURE)
      }),
    )
  }),
)

export const addToWishlistUpdateEpic = (
  action$: ActionsObservable<WishlistAction | AuthAction>
) => action$.pipe(
  ofType<WishlistAction | AuthAction>(
    WishlistActionTypes.REQUEST_ADD_TO_WISHLIST_SUCCESS,
    AuthActionsTypes.REQUEST_LOGIN_SUCCESS,
  ),
  map(() => ({
    type: WishlistActionTypes.REQUEST_WISHLIST
  })
  ),
)

/**
 * Should start request on:
 *  - app init,
 *  - login,
 *  - internet restoring,
 *  - wishlist queue update
 * @param action$
 * @param state$
 */
export const appInitEpic = (
  action$: ActionsObservable<AppAction | WishlistAction | AuthAction>,
  state$: StateObservable<StoreState>
) =>
  action$.pipe(
    ofType<AppAction | WishlistAction | AuthAction>(
      APP_INIT,
      WishlistActionTypes.ADD_TO_WISHLIST_QUEUE,
      AuthActionsTypes.REQUEST_LOGIN_SUCCESS,
    ),
    filter(() => !!state$.value.Auth.token),
    filter(() => !!state$.value.Wishlist.queue.length),
    auditTime(5000),
    map(() => ({
        type: WishlistActionTypes.REQUEST_ADD_TO_WISHLIST,
      })
    ),
  )


export const removeFromWishlistQueueOnRequestSuccessEpic = (
  action$: ActionsObservable<WishlistAction>
) => action$.pipe(
  ofType(
    WishlistActionTypes.REQUEST_ADD_TO_WISHLIST_SUCCESS,
  ),
  map((action: any) => ({
    type: WishlistActionTypes.REMOVE_FROM_WISHLIST_QUEUE,
    payload: {
      productsIds: action.payload.productsIds
    }
  })
  ),
)

export const removeFromWishlistQueueEpic = (
  action$: ActionsObservable<AddToWishlistRemoveQueue>
) => action$.pipe(
  ofType(
    WishlistActionTypes.ADD_TO_WISHLIST_REMOVE_QUEUE,
  ),
  map((action: AddToWishlistRemoveQueue) => ({
    type: WishlistActionTypes.REMOVE_FROM_WISHLIST_QUEUE,
    payload: {
      productsIds: [action.payload.product.id]
    }
  })
  ),
)
// export const clearWishlistEpic = (
//   action$: ActionsObservable<OrderAction>
// ) => action$.pipe(
//   ofType(
//     REQUEST_ORDER_CREATE_SUCCESS,
//   ),
//   map(() => ({
//     type: CLEAR_WISHLIST
//   })
//   ),
// )

export const dbRequestWishlistProductsEpic = (
  action$: ActionsObservable<WishlistAction>,
  state$: StateObservable<StoreState>
) => action$.pipe(
  ofType(
    WishlistActionTypes.ADD_TO_WISHLIST_QUEUE,
    WishlistActionTypes.REMOVE_FROM_WISHLIST_QUEUE,
    WishlistActionTypes.REQUEST_WISHLIST,
  ),
  filter(() => !!state$.value.Wishlist.queue.length),
  switchMap(() =>
      from(
        findProducts(state$.value.Wishlist.queue)
      ).pipe(
        map((products: Array<Product>) => {

          if (!products.length) {
            throw new Error('Cannot find such products at indexedDB')
          }

          return {
            payload: {
              wishlist: {
                products
              },
            },
            type: WishlistActionTypes.DB_REQUEST_WISHLIST_SUCCESS
          }
        }),
        catchError((error: any) => EMPTY)
      )
  ),
)

export const resetWishlistOnLogoutEpic = (
  action$: ActionsObservable<AuthAction>
) => action$.pipe(
  ofType<AuthAction>(
    AuthActionsTypes.LOGOUT_SUCCESS,
  ),
  switchMap(() => of(
    resetWishlistState()
  )),
)

export default [
  // requestWishlistEpic,
  addToWishlistUpdateEpic,
  // requestAddToWishlistEpic,
  appInitEpic,
  removeFromWishlistQueueOnRequestSuccessEpic,
  removeFromWishlistQueueEpic,
  dbRequestWishlistProductsEpic,
  resetWishlistOnLogoutEpic,
]
