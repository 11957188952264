import { NavigationBarAction } from '../actions/NavigationBar'
import NavigationBarState from '../types/NavigationBarState'
import NavigationBarActionsTypes from '../types/actions/NavigationBar'

export const initialState: NavigationBarState = {
  title:                '',
  shouldShowBackButton: false,
  shouldShow:           false,
  offline:              false,
  isSearchVisible:      false,
  canGoBack:            false,
  shouldSetSearchFocusOnInit: true,
}

const NavigationBar = function navigationBar(
  state: NavigationBarState = initialState,
  action: NavigationBarAction
): NavigationBarState {
  switch (action.type) {
    case NavigationBarActionsTypes.RESET_NAVIGATION_BAR:
      return {
        ...initialState,
        canGoBack: state.canGoBack,
      }

    case NavigationBarActionsTypes.CONFIGURE_NAVIGATION_BAR:
      return {
        ...initialState,
        title: action.payload.config.title,
        shouldShowBackButton: action.payload.config.shouldShowBackButton,
        shouldShow: action.payload.config.shouldShow,
        offline: action.payload.config.offline,
        canGoBack: state.canGoBack,
        isSearchVisible: action.payload.config.isSearchVisible,
        shouldSetSearchFocusOnInit: action.payload.config.shouldSetSearchFocusOnInit,
      }

    case NavigationBarActionsTypes.TOGGLE_SEARCH:
      return {
        ...state,
        isSearchVisible: !state.isSearchVisible,
      }

    case NavigationBarActionsTypes.SET_CAN_GO_BACK:
      return {
        ...state,
        canGoBack: action.payload.canGoBack,
      }

    default:
      return state;
  }
}

export default NavigationBar
