import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

import cscartApi from './cscart-api'
import IAddToCart from '../entities/cart/IAddToCart'
import AddToCart from '../entities/cart/AddToCart'

export const getCart = (chosenShippings: Array<any>) => {
  const baseRequest = cscartApi.cart;

  if (chosenShippings.length) {

    return baseRequest.withShippings(chosenShippings).get()
  }

  return baseRequest.get()
}

export const add = (product: IAddToCart) => {

  const productOptions = (product.productOptions || []);

  return cscartApi.cart.add({
    product_id: product.productId,
    amount: product.amount,
    product_options: mapValues(keyBy(productOptions, 'optionId'), 'value')
  })
}

export const update = (cartId: string, addToCart: AddToCart) =>
  cscartApi.cart.one(cartId).update({
    product_id: addToCart.productId,
    amount: addToCart.amount,
    product_options: mapValues(keyBy((addToCart.productOptions || []), 'optionId'), 'value')
  })

export const remove = (cartId: string) =>
  cscartApi.cart.one(cartId).delete()

export const clear = () =>
  cscartApi.cart.delete()

export const saveUserData = (data: any) =>
  cscartApi.cart.saveUserData(data)
