import IParent from './IParent'

/**
 * Entity for parent representation
 * Product has categories as parents, categories has categories as parents and etc.
 */
class Parent implements IParent {
  id:   number;
  url:  string;
  name: string;

  constructor(parent: IParent) {
    this.id   = parent.id;
    this.url  = parent.url;
    this.name = parent.name;

    if (!this.url || !this.name || !this.id) {
      throw new Error('Can`t create a parent instance without data')
    }
  }
}

export default Parent
