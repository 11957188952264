import { useLocation } from 'react-router'
import { useRef } from 'react'

/**
 * Observes location change
 *
 * @returns true if location has changed
 */
function useLocationChange() {
  const location = useLocation();
  const _location = useRef(location);

  return location !== _location.current
}

export default useLocationChange
