
import OrderActionsTypes from '../types/actions/Order'
import Order from '../../entities/order/Order'
import ApiError from '../../entities/error/ApiError'
import CartProduct from '../../entities/cart/CartProduct'
import OrderSelection from '../../entities/order/OrderSelection'
import { persistOrder } from '../../db/models/Order'
import {
  SelectionAction,
  SuccessedRequestSelectionAction,
  IdentifierAction,
  DbIdentifierFailuredAction,
  DbIdentifierSuccessedAction,
  ApiIdentifierSuccessedAction,
  ApiIdentifierFailuredAction
} from '.'
import RedirectionDetails from '../../entities/payment/RedirectionDetails'
import RedirectionForm from '../../entities/payment/RedirectionForm'

export interface RequestOrderCreate {
  type: OrderActionsTypes.REQUEST_ORDER_CREATE;
  payload: {
    products: Array<CartProduct>;
    shippingIds: Array<number>;
    paymentId: number;
    couponCodes?: Array<string>;
    userData?: any;
    paymentInfo?: any;
  }
}

export function requestOrderCreate(
  products: Array<CartProduct>,
  shippingIds: Array<number>,
  paymentId: number,
  userData?: any,
  paymentInfo?: any
): RequestOrderCreate {
  delete paymentInfo.isValid

  return {
    type: OrderActionsTypes.REQUEST_ORDER_CREATE,
    payload: {
      products,
      shippingIds,
      paymentId,
      userData,
      paymentInfo
    }
  }
}

export interface RequestOrderCreateSuccess {
  type: OrderActionsTypes.REQUEST_ORDER_CREATE_SUCCESS;
  payload: {
    orderId: number;
  }
}

export function requestOrderCreateSuccess(orderId: number): RequestOrderCreateSuccess {

  return {
    type: OrderActionsTypes.REQUEST_ORDER_CREATE_SUCCESS,
    payload: {
      orderId
    }
  }
}

export interface RequestOrderCreateFailure {
  type: OrderActionsTypes.REQUEST_ORDER_CREATE_FAILURE;
  payload: {
    error: ApiError,
  }
}

export function requestOrderCreateFailure(error: any): RequestOrderCreateFailure {
  const apiError = new ApiError({
    status: error.status,
    shouldRetry: error.shouldRetry || false,
    message: error.message
  })

  return {
    type: OrderActionsTypes.REQUEST_ORDER_CREATE_FAILURE,
    payload: {
      error: apiError,
    }
  }
}

// --- RequestOrders
export interface RequestOrders {
  type: OrderActionsTypes.REQUEST_ORDERS;
  payload: {
    orderSelection?:  OrderSelection;
    shouldAppend?:    boolean;
  }
}

export function requestOrders(
  orderSelection?: OrderSelection,
  shouldAppend?: boolean
): RequestOrders {
  return {
    type: OrderActionsTypes.REQUEST_ORDERS,
    payload: {
      orderSelection,
      shouldAppend
    }
  }
}

export interface RequestOrdersSuccess {
  type: OrderActionsTypes.REQUEST_ORDERS_SUCCESS;
  payload: {
    orderSelection: OrderSelection;
    shouldAppend:   boolean;
  }
}

export function requestOrdersSuccess(
  rawOrders: Array<any>,
  rawParams: any = {},
  shouldAppend: boolean = false
): RequestOrdersSuccess {

  const orderSelection = new OrderSelection({
    orders: rawOrders,
    pagination: rawParams,
    filterHashes: rawParams.features_hash,
    sorting: rawParams,
    search: rawParams.q,
  });

  orderSelection.orders.forEach(order => persistOrder(order))

  return {
    type: OrderActionsTypes.REQUEST_ORDERS_SUCCESS,
    payload: {
      orderSelection,
      shouldAppend,
    }
  }
}

export interface RequestOrdersFailure {
  type: OrderActionsTypes.REQUEST_ORDERS_FAILURE;
  payload: {
    selection?:   OrderSelection;
    error:        ApiError;
  }
}

export function requestOrdersFailure(error: any, selection?: OrderSelection): RequestOrdersFailure {
  const apiError = new ApiError({
    status: error.status || 0,
    shouldRetry: error.shouldRetry || false,
    message: error.message || 'Unhandled error'
  })

  return {
    type: OrderActionsTypes.REQUEST_ORDERS_FAILURE,
    payload: {
      selection,
      error: apiError
    }
  }
}

// --- DbRequestOrders
export interface DbRequestOrders extends SelectionAction<OrderSelection> {
  type: OrderActionsTypes.DB_REQUEST_ORDERS;
  payload: {
    selection?:     OrderSelection;
    shouldAppend?:  boolean;
  }
}

export function dbRequestOrders(
  selection?: OrderSelection,
  shouldAppend?: boolean
): DbRequestOrders {
  return {
    type: OrderActionsTypes.DB_REQUEST_ORDERS,
    payload: {
      selection,
      shouldAppend
    }
  }
}

export interface DbRequestOrdersSuccess extends SuccessedRequestSelectionAction<OrderSelection> {
  type: OrderActionsTypes.DB_REQUEST_ORDERS_SUCCESS;
  payload: {
    selection:  OrderSelection;
  }
}

export function dbRequestOrdersSuccess(
  selection: OrderSelection,
): DbRequestOrdersSuccess {

  return {
    type: OrderActionsTypes.DB_REQUEST_ORDERS_SUCCESS,
    payload: {
      selection,
    }
  }
}

export interface DbRequestOrdersFailure {
  type: OrderActionsTypes.DB_REQUEST_ORDERS_FAILURE;
}

export function dbRequestOrdersFailure(): DbRequestOrdersFailure {
  return {
    type: OrderActionsTypes.DB_REQUEST_ORDERS_FAILURE,
  }
}

// --- RequestOrder
export interface RequestOrder extends IdentifierAction {
  type: OrderActionsTypes.REQUEST_ORDER;
  payload: {
    id: number;
  }
}

export function requestOrder(id: number): RequestOrder {
  return {
    type: OrderActionsTypes.REQUEST_ORDER,
    payload: {
      id,
    }
  }
}

export interface RequestOrderSuccess extends ApiIdentifierSuccessedAction {
  type: OrderActionsTypes.REQUEST_ORDER_SUCCESS;
  payload: {
    id:     number;
    order:  Order;
  }
}

export function requestOrderSuccess(id: number, rawOrder: any): RequestOrderSuccess {

  const order = new Order(rawOrder);
  persistOrder(order)

  return {
    type: OrderActionsTypes.REQUEST_ORDER_SUCCESS,
    payload: {
      id,
      order,
    }
  }
}

export interface RequestOrderFailure extends ApiIdentifierFailuredAction {
  type: OrderActionsTypes.REQUEST_ORDER_FAILURE;
  payload: {
    id:     number;
    error:  ApiError;
  }
}

export function requestOrderFailure(id: number, error: any): RequestOrderFailure {
  const apiError = new ApiError({
    status: error.status || 0,
    shouldRetry: error.shouldRetry || false,
    message: error.message || 'Unhandled error'
  })

  return {
    type: OrderActionsTypes.REQUEST_ORDER_FAILURE,
    payload: {
      id,
      error: apiError
    }
  }
}

/* Db Request Order */

export interface DbRequestOrder extends IdentifierAction {
  type: OrderActionsTypes.DB_REQUEST_ORDER;
  payload: {
    id: number;
  }
}

export function dbRequestOrder(id: number): DbRequestOrder {
  return {
    type: OrderActionsTypes.DB_REQUEST_ORDER,
    payload: {
      id,
    }
  }
}

export interface DbRequestOrderSuccess extends DbIdentifierSuccessedAction {
  type: OrderActionsTypes.DB_REQUEST_ORDER_SUCCESS;
  payload: {
    id:     number;
    order:  Order;
  }
}

export function dbRequestOrderSuccess(id: number, order: Order): DbRequestOrderSuccess {

  return {
    type: OrderActionsTypes.DB_REQUEST_ORDER_SUCCESS,
    payload: {
      id,
      order
    }
  }
}

export interface DbRequestOrderFailure extends DbIdentifierFailuredAction {
  type: OrderActionsTypes.DB_REQUEST_ORDER_FAILURE;
  payload: {
    id: number;
  }
}

export function dbRequestOrderFailure(id: number): DbRequestOrderFailure {

  return {
    type: OrderActionsTypes.DB_REQUEST_ORDER_FAILURE,
    payload: {
      id,
    }
  }
}

/* Settlements */
export interface RequestSettlementCreate {
  type: OrderActionsTypes.REQUEST_SETTLEMENT_CREATE;
  payload: {
    id: number;
  }
}

export function requestSettlementCreate(orderId: number): RequestSettlementCreate {

  return {
    type: OrderActionsTypes.REQUEST_SETTLEMENT_CREATE,
    payload: {
      id: orderId,
    }
  }
}

export interface RequestSettlementCreateSuccess {
  type: OrderActionsTypes.REQUEST_SETTLEMENT_CREATE_SUCCESS;
  payload: {
    id:                 number;
    redirectionDetails: RedirectionDetails;
  }
}

export function requestSettlementCreateSuccess(orderId: number, data: any): RequestSettlementCreateSuccess {

  const redirectionDetails = new RedirectionDetails(data);

  return {
    type: OrderActionsTypes.REQUEST_SETTLEMENT_CREATE_SUCCESS,
    payload: {
      id: orderId,
      redirectionDetails,
    }
  }
}

export interface RequestSettlementCreateFailure {
  type: OrderActionsTypes.REQUEST_SETTLEMENT_CREATE_FAILURE;
  payload: {
    id:     number;
    error:  ApiError;
  }
}

export function requestSettlementCreateFailure(orderId: number, error: any): RequestSettlementCreateFailure {
  const apiError = new ApiError({
    status: error.status,
    shouldRetry: error.shouldRetry || false,
    message: error.message
  })

  return {
    type: OrderActionsTypes.REQUEST_SETTLEMENT_CREATE_FAILURE,
    payload: {
      id: orderId,
      error: apiError
    }
  }
}
/* Online payments */
export interface RequestOnlinePayment {
  type: OrderActionsTypes.REQUEST_ONLINE_PAYMENT;
  payload: {
    id: number;
  }
}

export function requestOnlinePayment(orderId: number): RequestOnlinePayment {

  return {
    type: OrderActionsTypes.REQUEST_ONLINE_PAYMENT,
    payload: {
      id: orderId,
    }
  }
}

export interface RequestOnlinePaymentSuccess {
  type: OrderActionsTypes.REQUEST_ONLINE_PAYMENT_SUCCESS;
  payload: {
    id:                 number;
    redirectionForm:    RedirectionForm;
  }
}

export function requestOnlinePaymentSuccess(orderId: number, data: any): RequestOnlinePaymentSuccess {

  const redirectionForm = new RedirectionForm({
    html:     data,
    orderId:  orderId,
  });

  return {
    type: OrderActionsTypes.REQUEST_ONLINE_PAYMENT_SUCCESS,
    payload: {
      id: orderId,
      redirectionForm,
    }
  }
}

export interface RequestOnlinePaymentFailure {
  type: OrderActionsTypes.REQUEST_ONLINE_PAYMENT_FAILURE;
  payload: {
    id:     number;
    error:  ApiError;
  }
}

export function requestOnlinePaymentFailure(orderId: number, error: any): RequestOnlinePaymentFailure {
  const apiError = new ApiError({
    status: error.status,
    shouldRetry: error.shouldRetry || false,
    message: error.message
  })

  return {
    type: OrderActionsTypes.REQUEST_ONLINE_PAYMENT_FAILURE,
    payload: {
      id: orderId,
      error: apiError
    }
  }
}
/* Online payments result */
export interface RequestOnlinePaymentResult {
  type: OrderActionsTypes.REQUEST_ONLINE_PAYMENT_RESULT;
  payload: {
    url: string;
  }
}

export function requestOnlinePaymentResult(url: string): RequestOnlinePaymentResult {

  return {
    type: OrderActionsTypes.REQUEST_ONLINE_PAYMENT_RESULT,
    payload: {
      url,
    }
  }
}

export interface RequestOnlinePaymentResultSuccess {
  type: OrderActionsTypes.REQUEST_ONLINE_PAYMENT_RESULT_SUCCESS;
}

export function requestOnlinePaymentResultSuccess(): RequestOnlinePaymentResultSuccess {

  return {
    type: OrderActionsTypes.REQUEST_ONLINE_PAYMENT_RESULT_SUCCESS,
  }
}

export interface RequestOnlinePaymentResultFailure {
  type: OrderActionsTypes.REQUEST_ONLINE_PAYMENT_RESULT_FAILURE;
  payload: {
    error:  ApiError;
  }
}

export function requestOnlinePaymentResultFailure(error: any): RequestOnlinePaymentResultFailure {
  const apiError = new ApiError({
    status: error.status,
    shouldRetry: error.shouldRetry || false,
    message: error.message
  })

  return {
    type: OrderActionsTypes.REQUEST_ONLINE_PAYMENT_RESULT_FAILURE,
    payload: {
      error: apiError
    }
  }
}


export type OrderAction = RequestOrderCreate | RequestOrderCreateSuccess | RequestOrderCreateFailure
  | RequestOrders | RequestOrdersSuccess | RequestOrdersFailure
  | RequestOrder | RequestOrderSuccess | RequestOrderFailure
  | DbRequestOrders | DbRequestOrdersSuccess | DbRequestOrdersFailure
  | DbRequestOrder | DbRequestOrderSuccess | DbRequestOrderFailure
  | RequestSettlementCreate | RequestSettlementCreateSuccess | RequestSettlementCreateFailure
  | RequestOnlinePayment | RequestOnlinePaymentSuccess | RequestOnlinePaymentFailure
  | RequestOnlinePaymentResult | RequestOnlinePaymentResultSuccess | RequestOnlinePaymentResultFailure;
