import React from 'react'
import { NavLink } from 'react-router-dom'

import appendClassName from '../../../utils/string/appendClassName'
import { createProfileLinkUrl } from '../../../url'

import { IOwnProps } from './IProfileButton'
import './ProfileButton.css'

const ProfileButton:React.FC<IOwnProps> = (props) => {
  const {
    className,
    size,
    label,
  } = props;

  return (
    <NavLink
      to={{
        pathname: createProfileLinkUrl(),
        state: {
          modal: true,
        }
      }}
      className={appendClassName('b-profile-button', className)}
    >
      <ProfileIcon
        size={size}
      />
      {
        label
        &&
          <p className='b-main-menu__button-label'>
            {label}
          </p>
      }
    </NavLink>
  )
}

const ProfileIcon = require('react-feather/dist/icons/user').default;

export default ProfileButton
