enum CategoryActionTypes {
  REQUEST_CATEGORY          = 'REQUEST_CATEGORY',
  REQUEST_CATEGORY_SUCCESS  = 'REQUEST_CATEGORY_SUCCESS',
  REQUEST_CATEGORY_FAILURE  = 'REQUEST_CATEGORY_FAILURE',

  DB_REQUEST_CATEGORY         = 'DB_REQUEST_CATEGORY',
  DB_REQUEST_CATEGORY_SUCCESS = 'DB_REQUEST_CATEGORY_SUCCESS',
  DB_REQUEST_CATEGORY_FAILURE = 'DB_REQUEST_CATEGORY_FAILURE',

  SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY',

  SET_CATEGORY_IS_REQUESTING = 'SET_CATEGORY_IS_REQUESTING',
}

export default CategoryActionTypes
