import { useSelector } from 'react-redux'
import { StoreState } from '../../redux/types'

/**
 * Receive device type from redux state
 */
function useDevice() {
  return useSelector((state: StoreState) => state.Ui.deviceType)
}

export default useDevice
