import { CategoryAction } from '../actions/category/CategoryAction'
import CategoryState from '../types/CategoryState'
import CategoryActionTypes from '../actions/category/CategoryActionTypes'
import ProductActionTypes from '../actions/product/ProductActionTypes'
import { ProductAction } from '../actions'

export const initialState: CategoryState = {
  category:             null,
  isRequesting:         false,
  isProductsRequesting: false,
  error:                null,
}

const Category = function category(state: CategoryState = initialState, action: CategoryAction | ProductAction): CategoryState {
  switch (action.type) {
    case ProductActionTypes.REQUEST_CATEGORY_PRODUCTS:

      return {
        ...state,
        isProductsRequesting: true,
        productSelection: state.category ? action.payload.productSelection : undefined,
      }

    case CategoryActionTypes.REQUEST_CATEGORY:
      return {
        ...state,
        isRequesting: true,
        error:        null
      }

    case CategoryActionTypes.REQUEST_CATEGORY_SUCCESS:
      return {
        ...state,
        category:     action.payload.category,
        isRequesting: false,
      }

    case CategoryActionTypes.SET_CURRENT_CATEGORY:
      return {
        ...state,
        category:     action.payload.category,
        isRequesting: false,
      }

    case CategoryActionTypes.REQUEST_CATEGORY_FAILURE:
      return {
        ...state,
        error:        action.payload.error,
        isRequesting: false,
      }

    case CategoryActionTypes.DB_REQUEST_CATEGORY:
      return {
        ...state,
        category:     null,
        isRequesting: true,
      }

    case CategoryActionTypes.DB_REQUEST_CATEGORY_SUCCESS:
      return {
        ...state,
        category:     action.payload.category,
        isRequesting: false,
      }

    case CategoryActionTypes.DB_REQUEST_CATEGORY_FAILURE:
      return {
        ...state,
        category:     null,
        isRequesting: false,
      }

    case ProductActionTypes.REQUEST_CATEGORY_PRODUCTS_SUCCESS:

      return {
        ...state,
        productSelection: (
          (action.payload.shouldAppend && state.productSelection)
            ?
            action.payload.productSelection.prependItems(state.productSelection.products)
            :
              action.payload.productSelection),
        isProductsRequesting: false,
      }

    case ProductActionTypes.REQUEST_CATEGORY_PRODUCTS_FAILURE:
      return {
        ...state,
        productSelection: undefined,
        isProductsRequesting: false,
      }

    case ProductActionTypes.DB_REQUEST_CATEGORY_PRODUCTS_SUCCESS:

      return {
        ...state,
        productSelection: action.payload.productSelection,
        isProductsRequesting: false
      }

    case CategoryActionTypes.SET_CATEGORY_IS_REQUESTING:
      return {
        ...state,
        isRequesting: action.payload.isRequesting,
      }

    default:
      return state;
  }
}

export default Category
