enum ProductActionTypes {
  REQUEST_PRODUCT         = 'REQUEST_PRODUCT',
  REQUEST_PRODUCT_SUCCESS = 'REQUEST_PRODUCT_SUCCESS',
  REQUEST_PRODUCT_FAILURE = 'REQUEST_PRODUCT_FAILURE',

  DB_REQUEST_PRODUCT          = 'DB_REQUEST_PRODUCT',
  DB_REQUEST_PRODUCT_SUCCESS  = 'DB_REQUEST_PRODUCT_SUCCESS',
  DB_REQUEST_PRODUCT_FAILURE  = 'DB_REQUEST_PRODUCT_FAILURE',

  SET_CURRENT_PRODUCT  = 'SET_CURRENT_PRODUCT',

  REQUEST_CATEGORY_PRODUCTS         = 'REQUEST_CATEGORY_PRODUCTS',
  REQUEST_CATEGORY_PRODUCTS_SUCCESS = 'REQUEST_CATEGORY_PRODUCTS_SUCCESS',
  REQUEST_CATEGORY_PRODUCTS_FAILURE = 'REQUEST_CATEGORY_PRODUCTS_FAILURE',

  DB_REQUEST_CATEGORY_PRODUCTS         = 'DB_REQUEST_CATEGORY_PRODUCTS',
  DB_REQUEST_CATEGORY_PRODUCTS_SUCCESS = 'DB_REQUEST_CATEGORY_PRODUCTS_SUCCESS',
  DB_REQUEST_CATEGORY_PRODUCTS_FAILURE = 'DB_REQUEST_CATEGORY_PRODUCTS_FAILURE',

  REQUEST_VENDOR_PRODUCTS         = 'REQUEST_VENDOR_PRODUCTS',
  REQUEST_VENDOR_PRODUCTS_SUCCESS = 'REQUEST_VENDOR_PRODUCTS_SUCCESS',
  REQUEST_VENDOR_PRODUCTS_FAILURE = 'REQUEST_VENDOR_PRODUCTS_FAILURE',


  DB_REQUEST_VENDOR_PRODUCTS         = 'DB_REQUEST_VENDOR_PRODUCTS',
  DB_REQUEST_VENDOR_PRODUCTS_SUCCESS = 'DB_REQUEST_VENDOR_PRODUCTS_SUCCESS',
  DB_REQUEST_VENDOR_PRODUCTS_FAILURE = 'DB_REQUEST_VENDOR_PRODUCTS_FAILURE',

  REQUEST_SEARCH_PRODUCTS         = 'REQUEST_SEARCH_PRODUCTS',
  REQUEST_SEARCH_PRODUCTS_SUCCESS = 'REQUEST_SEARCH_PRODUCTS_SUCCESS',
  REQUEST_SEARCH_PRODUCTS_FAILURE = 'REQUEST_SEARCH_PRODUCTS_FAILURE',

  SET_PRODUCT_IS_REQUESTING = 'SET_PRODUCT_IS_REQUESTING',
}

export default ProductActionTypes
