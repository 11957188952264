import VendorActionTypes from './VendorActionTypes'
import Vendor from '../../../entities/vendor/Vendor'

export interface DbRequestVendor {
  type: VendorActionTypes.DB_REQUEST_VENDOR;
  payload: {
    id: number|string;
  }
}

export function dbRequestVendor(
  id: number|string
): DbRequestVendor {
  return {
    type: VendorActionTypes.DB_REQUEST_VENDOR,
    payload: {
      id,
    }
  }
}

export interface DbRequestVendorSuccess {
  type: VendorActionTypes.DB_REQUEST_VENDOR_SUCCESS;
  payload: {
    vendor: Vendor;
  }
}

export function dbRequestVendorSuccess(
  vendor: Vendor
): DbRequestVendorSuccess {

  return {
    type: VendorActionTypes.DB_REQUEST_VENDOR_SUCCESS,
    payload: {
      vendor,
    }
  }
}

export interface DbRequestVendorFailure {
  type: VendorActionTypes.DB_REQUEST_VENDOR_FAILURE;
  payload: {
    id: number|string;
  }
}

export function dbRequestVendorFailure(id: number|string): DbRequestVendorFailure {

  return {
    type: VendorActionTypes.DB_REQUEST_VENDOR_FAILURE,
    payload: {
      id,
    }
  }
}
