import * as products from './Product'
import * as categories from './Category'
import * as articles from './Article'
import * as layouts from './Layout'
import * as vendors from './Vendor'
import * as orders from './Order'

const db = {
  products,
  categories,
  articles,
  layouts,
  vendors,
  orders,
}

export default db
