import { ArticleAction } from '../actions/article/ArticleAction'
import ArticleState from '../types/ArticleState'
import ArticleActionTypes from '../actions/article/ArticleActionTypes'

export const initialState: ArticleState = {
  article:              null,
  isRequesting:         false,
  isArticlesRequesting: false,
  error:                null,
}

const Article = function article(state: ArticleState = initialState, action: ArticleAction): ArticleState {
  switch (action.type) {
    case ArticleActionTypes.REQUEST_ARTICLE:
      return {
        ...state,
        isRequesting: true,
        article: state.article && (state.article.id === action.payload.articleId ? state.article: null),
      }

    case ArticleActionTypes.REQUEST_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload.article,
        isRequesting: false,
      }

    case ArticleActionTypes.REQUEST_ARTICLE_FAILURE:
      return {
        ...state,
        error:        action.payload.error,
        isRequesting: false,
      }

    case ArticleActionTypes.REQUEST_ARTICLES:
      return {
        ...state,
        isArticlesRequesting: true,
        articleSelection: action.payload.shouldAppend ? state.articleSelection : undefined,
      }

    case ArticleActionTypes.REQUEST_ARTICLES_SUCCESS:
        return {
          ...state,
          articleSelection: (
            (action.payload.shouldAppend && state.articleSelection)
              ?
              action.payload.selection.prependItems(state.articleSelection.articles)
              :
                action.payload.selection),
          isArticlesRequesting: false,
        }

    case ArticleActionTypes.REQUEST_ARTICLES_FAILURE:
      return {
        ...state,
        articleSelection:     undefined,
        isArticlesRequesting: false,
      }

    case ArticleActionTypes.DB_REQUEST_ARTICLE:

      return {
        ...state,
        article:      state.article && (state.article.id === action.payload.id ? state.article: null),
        isRequesting: true,
      }

    case ArticleActionTypes.DB_REQUEST_ARTICLE_SUCCESS:
      return {
        ...state,
        article:      action.payload.article,
        isRequesting: false,
      }

    case ArticleActionTypes.DB_REQUEST_ARTICLE_FAILURE:
      return {
        ...state,
        article:      null,
        isRequesting: false,
      }

    case ArticleActionTypes.DB_REQUEST_ARTICLES_SUCCESS:

      return {
        ...state,
        articleSelection:     action.payload.selection,
        isArticlesRequesting: false,
      }

    case ArticleActionTypes.SET_CURRENT_ARTICLE:

      return {
        ...state,
        isRequesting: false,
        article:      action.payload.article,
      }

    case ArticleActionTypes.SET_ARTICLE_IS_REQUESTING:

      return {
        ...state,
        isRequesting: action.payload.isRequesting,
      }

    default:
      return state;
  }
}

export default Article
