import values from 'lodash/values'
import forOwn from 'lodash/forOwn'

import ApplyForVendorActionTypes from '../types/actions/ApplyForVendor'
import FormSection from '../../entities/form/FormSection'
import ApiError from '../../entities/error/ApiError'

// Request form for apply for vendor
export interface RequestApplyForVendorForm {
  type: ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_FORM;
}

export function requestApplyForVendorForm(): RequestApplyForVendorForm {
  return {
    type: ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_FORM,
  }
}

export interface RequestApplyForVendorFormSuccess {
  type: ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_FORM_SUCCESS;
  payload: {
    sections: Array<FormSection>;
  }
}

export function requestApplyForVendorFormSuccess(rawSections: any): RequestApplyForVendorFormSuccess {
  const sections = values(
    forOwn(rawSections, (section, id) => {
      section.id = id;
    })
  ).map((section: any) => new FormSection(section))

  return {
    type: ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_FORM_SUCCESS,
    payload: {
      sections,
    }
  }
}

export interface RequestApplyForVendorFormFailure {
  type: ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_FORM_FAILURE;
  payload: {
    error: ApiError;
  }
}

export function requestApplyForVendorFormFailure(error: any): RequestApplyForVendorFormFailure {
  const apiError = new ApiError(error);

  return {
    type: ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_FORM_FAILURE,
    payload: {
      error: apiError,
    }
  }
}

// Signup
export interface RequestApplyForVendorSignup {
  type: ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_SIGNUP;
  payload: {
    profile: any;
  }
}

export function requestApplyForVendorSignup(profile: any): RequestApplyForVendorSignup {
  return {
    type: ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_SIGNUP,
    payload: {
      profile
    }
  }
}

export interface RequestApplyForVendorSignupSuccess {
  type: ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_SIGNUP_SUCCESS;
}

export function requestApplyForVendorSignupSuccess(): RequestApplyForVendorSignupSuccess {
  return {
    type: ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_SIGNUP_SUCCESS,
  }
}

export interface RequestApplyForVendorSignupFailure {
  type: ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_SIGNUP_FAILURE;
  payload: {
    error: ApiError;
  }
}

export function requestApplyForVendorSignupFailure(error: any): RequestApplyForVendorSignupFailure {
  const apiError = new ApiError(error);

  return {
    type: ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_SIGNUP_FAILURE,
    payload: {
      error: apiError,
    }
  }
}

export type ApplyForVendorAction = RequestApplyForVendorForm | RequestApplyForVendorFormSuccess | RequestApplyForVendorFormFailure
| RequestApplyForVendorSignup | RequestApplyForVendorSignupSuccess | RequestApplyForVendorSignupFailure;
