enum BlockTypes {
  MAIN            = 'main',
  CATEGORIES      = 'categories',
  HTML            = 'html',
  BANNERS         = 'banners',
  PRODUCTS        = 'products',
  ARTICLES        = 'articles',
  MENU            = 'menu',
  PAGES           = 'pages',
  VENDORS         = 'vendors',
  LANGUAGES       = 'languages',
  CURRENCIES      = 'currencies',
  LOGO            = 'logo',
  TEMPLATE        = 'template',
  CART_BUTTON     = 'cart_button',
  WISHLIST_BUTTON = 'wishlist_button',
  PROFILE_BUTTON  = 'profile_button',
  MENU_BUTTON     = 'menu_button',
  SEARCH          = 'search',
}

export default BlockTypes
