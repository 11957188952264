import {
  IdentifierAction,
  DbIdentifierSuccessedAction,
  DbIdentifierFailuredAction,
} from '../../actions'
import { ActionsObservable, ofType } from 'redux-observable'
import { switchMap, map } from 'rxjs/operators'
import { from } from 'rxjs'

/**
 * Perform DB request for a item by id
 */
export function createRequestDbById<
  Action extends IdentifierAction,
  Success extends DbIdentifierSuccessedAction,
  Failure extends DbIdentifierFailuredAction,
  ItemType
> (
  action$: ActionsObservable<Action>,
  type: string,
  find: (id: Action['payload']['id']) => Promise<ItemType>,
  onSuccess: (id: Action['payload']['id'], item: ItemType) => Success,
  onFailure: (id: Action['payload']['id']) => Failure,
) {

  return action$.pipe(
    ofType(type),
    switchMap((action: Action) =>
      from(
        find(action.payload.id)
      ).pipe(
        map((item: ItemType) =>
          item
            ?
              onSuccess(action.payload.id, item)
            :
              onFailure(action.payload.id)
        ),
      )
    ),
  )
}
