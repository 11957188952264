import { defineMessages, Messages } from 'react-intl'

const messages: Messages = {
  placeholder: {
    id: 'app.components.search.products.placeholder',
    defaultMessage: 'Search products',
  },
}

const definedMessages = defineMessages(messages)
export default definedMessages
