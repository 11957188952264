import CartButton from './CartButton'
import { connect } from 'react-redux'

import { StoreState } from '../../../redux/types'
import { IStateProps, IDispatchProps, IOwnProps, } from './ICartButton'

export function mapStateToProps(state: StoreState): IStateProps {
  return {
    amount: state.Cart.cart.amount,
    isQueued: !!state.Cart.additionQueue.length,
  };
}

export function mapDispatchToProps(): IDispatchProps {
  return {
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(CartButton);
