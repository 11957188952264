import values from 'lodash/values'
import kebabCase from 'lodash/kebabCase'
import Product from '../product/Product'
import Category from '../category/Category'
import Article from '../article/Article'
import IProduct from '../product/IProduct'
import ICategory from '../category/ICategory'
import IArticle from '../article/IArticle'
import BlockTypes from '../../constants/BlockTypes'
import DeviceTypes from '../../constants/DeviceTypes'
import Banner from '../banner/Banner'
import IBanner from '../banner/IBanner'
import IVendor from '../vendor/IVendor'
import Vendor from '../vendor/Vendor'
import LayoutContainerTypes from '../../constants/LayoutContainerTypes'

import IBlock from './IBlock'

class Block implements IBlock {
  id:               number;
  deviceTypes:      Array<string>;
  title:            string;
  shouldShowTitle:  boolean;
  position:         number;
  type:             BlockTypes;
  content:          any;
  container:        LayoutContainerTypes;
  modifier?:        string;
  disposition:      'full_screen_width' | 'grid_width';

  constructor(rawBlock: any) {
    const block = {...rawBlock};

    if ('id' in block) {
      this.id               = block.id;
      this.deviceTypes      = block.deviceTypes;
      this.title            = block.title;
      this.shouldShowTitle  = block.shouldShowTitle;
      this.position         = block.position;
      this.type             = block.type;
      this.modifier         = block.modifier;
      this.disposition      = block.disposition;

      switch (this.type) {
        case BlockTypes.PRODUCTS:
          this.content = {
            products: block.content.products.map((rawProduct: IProduct) => new Product(rawProduct))
          };
          break

        case BlockTypes.ARTICLES:
          this.content = {
            articles: block.content.articles.map((rawArticle: IArticle) => new Article(rawArticle))
          };
          break

        case BlockTypes.CATEGORIES:
          this.content = {
            categories: block.content.categories.map((rawCategory: ICategory) => new Category(rawCategory))
          };
          break

        case BlockTypes.VENDORS:
          this.content = {
            vendors: block.content.vendors.map((rawVendor: IVendor) => new Vendor(rawVendor))
          };
          break

        case BlockTypes.HTML:
          this.content = block.content;
          break

        case BlockTypes.BANNERS:
          this.content = {
            banners: block.content.banners.map((rawBanner: IBanner) => new Banner(rawBanner))
          };
          break

        case BlockTypes.MENU:
          this.content = block.content;
          break

        default:
        this.content = block.content;
      }

    } else {
      this.id = parseInt(block.block_id);

      this.deviceTypes = [];
      if (block.availability.desktop === true) {
        this.deviceTypes.push(DeviceTypes.DESKTOP)
      }
      if (block.availability.tablet === true) {
        this.deviceTypes.push(DeviceTypes.TABLET)
      }
      if (block.availability.phone === true) {
        this.deviceTypes.push(DeviceTypes.MOBILE)
      }

      this.title            = block.name;
      /**
       * @todo rename at cscart location to disposition
       */
      this.disposition      = block.properties.disposition || block.properties.location;
      this.shouldShowTitle  = block.properties.hide_block_name === 'N';
      this.position         = parseInt(block.order);
      this.type     = block.type === BlockTypes.TEMPLATE ? convertTemplateToBlockType(block.properties.template) : convertType(block.type);
      this.modifier         = convertTemplateToModifier(block);

      switch (this.type) {
        case BlockTypes.PRODUCTS:
          this.content = {
            products: (values(block.content.items) || []).map(rawProduct => new Product(rawProduct))
          };
          break

        case BlockTypes.ARTICLES:
          this.content = {
            articles: (values(block.content.items) || []).map(rawArticle => new Article(rawArticle))
          };
          break

        case BlockTypes.CATEGORIES:
          this.content = {
            categories: (values(block.content.items) || []).map(rawCategory => new Category(rawCategory))
          };
          break

        case BlockTypes.VENDORS:
          this.content = {
            vendors: (values(block.content.items) || []).map(rawVendor => new Vendor(rawVendor))
          };
          break

        case BlockTypes.HTML:
          this.content = block.content.content;
          break

        case BlockTypes.LOGO:
          this.content = block.content.content;
          break

        case BlockTypes.BANNERS:
          this.content = {
            banners: (values(block.content.items) || []).map(rawBanner => new Banner(rawBanner))
          };
          break

        case BlockTypes.MENU:
          this.content = {
            items: (values(block.content.items) || [])
          };
          break

        case BlockTypes.MAIN:
          /**
           * It should be a placeholder
           * All data from content must be extracted
           */
          this.content = {};
          break

        default:
          this.content = block.content;
      }
    }

    try {
      this.container = convertLayoutContainerType(block.container);
    } catch(error) {
      this.container = LayoutContainerTypes.CONTENT;
    }

    if (!this.id) {
      throw new Error('Missing id of the block')
    }
  }
}

const convertTemplateToBlockType = (template: string): BlockTypes => {
  switch (template) {
    case 'blocks/static_templates/logo.tpl':
      return BlockTypes.LOGO

    case 'blocks/static_templates/search.tpl':
      return BlockTypes.SEARCH

    default:
      throw new Error(`Unhandled template ${template}`)
  }
}

const convertLayoutContainerType = (type: string): LayoutContainerTypes => {
  switch (type) {
    case LayoutContainerTypes.TOP_PANEL:
    case 'TOP_PANEL':
      return LayoutContainerTypes.TOP_PANEL

    case LayoutContainerTypes.HEADER:
    case 'HEADER':
      return LayoutContainerTypes.HEADER

    case LayoutContainerTypes.CONTENT:
    case 'CONTENT':
      return LayoutContainerTypes.CONTENT

    case LayoutContainerTypes.FOOTER:
    case 'FOOTER':
      return LayoutContainerTypes.FOOTER
  }

  throw new Error(`Unsupported container type ${type}`)
}

/**
 * Converts backend block type to SPA block type
 */
const convertType = (type: string | BlockTypes): BlockTypes => {
  switch (type) {
    case 'spa_cart_content_button':
      return BlockTypes.CART_BUTTON

    case 'spa_my_account_button':
      return BlockTypes.PROFILE_BUTTON

    case 'spa_html_block':
      return BlockTypes.HTML

    case 'spa_search':
      return BlockTypes.SEARCH

    case 'spa_main':
      return BlockTypes.MAIN

    case 'spa_categories':
      return BlockTypes.CATEGORIES

    case 'spa_banners':
      return BlockTypes.BANNERS

    case 'spa_products':
      return BlockTypes.PRODUCTS

    case 'spa_blog':
    case 'spa_pages':
      return BlockTypes.ARTICLES

    case 'spa_menu':
      return BlockTypes.MENU

    case 'spa_menu_button':
      return BlockTypes.MENU_BUTTON

    case 'spa_wishlist_button':
      return BlockTypes.WISHLIST_BUTTON

    case 'spa_vendors':
      return BlockTypes.VENDORS

    case 'spa_languages':
      return BlockTypes.LANGUAGES

    case 'spa_currencies':
      return BlockTypes.CURRENCIES

    case 'spa_logo':
      return BlockTypes.LOGO

    case 'template':
      return BlockTypes.TEMPLATE

    default:
      // return type
      throw new Error(`Unhandled block type ${type}`)
  }
}

const convertTemplateToModifier = (block: any): string|undefined => {
  if (!block.properties || !block.properties.template) {
    return undefined
  }

  return kebabCase(
    block.properties.template.split('/').pop()
  ).replace('-tpl', '')
}

export default Block
