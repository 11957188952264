import cscartApi from './cscart-api'
import VendorSelection from '../entities/vendor/VendorSelection'
import SortOrder from '../constants/SortOrder'

export const getForm = () =>
  cscartApi.vendors.getForm()

export const getVendor = (id: number) =>
  cscartApi.vendors.one(id).get()

/**
 * Create new vendor company based on fields recieved by request for a form
 *
 * @param data
 */
export const create = (data: any) =>
  cscartApi.vendors.create(data)

export const getVendors = (
  vendorSelection?: VendorSelection
) => {
  let baseRequest = cscartApi.vendors;

  if (vendorSelection) {
    if (vendorSelection.sorting) {
      baseRequest = baseRequest.orderBy(vendorSelection.sorting.sortBy);
      if (vendorSelection.sorting.order === SortOrder.DESC) {
        baseRequest = baseRequest.desc()
      }
    }
    if (vendorSelection.pagination) {
      baseRequest = baseRequest
        .limit(vendorSelection.pagination.limit)
        .page(vendorSelection.pagination.page)
    }
    if (vendorSelection.search) {
      baseRequest = baseRequest
        .search(vendorSelection.search)
    }
  }

  return baseRequest.get()
}
