import IProductExtras from './IProductExtras'
import ProductOptionSelected from './ProductOptionSelected'

class ProductExtras implements IProductExtras {
  productOptionsSelected: Array<ProductOptionSelected>;

  constructor(productExtrasItem: IProductExtras) {
    this.productOptionsSelected = productExtrasItem.productOptionsSelected;
  }
}

export default ProductExtras
