import { map, switchMap, filter } from 'rxjs/operators'
import { from, of, EMPTY } from 'rxjs'
import { ofType } from 'redux-observable'
import { ActionsObservable } from 'redux-observable'

import cscartApi from '../../api'
import db from '../../db/models/'
import {
  // types
  DbRequestArticle,
  DbRequestArticles,
  RequestArticle,
  RequestArticles,
  RequestArticleFailure,
  RequestArticlesFailure,

  // functions
  dbRequestArticle,
  dbRequestArticleSuccess,
  dbRequestArticleFailure,

  dbRequestArticles,
  dbRequestArticlesSuccess,
  // dbRequestArticlesFailure,

  requestArticleSuccess,
  requestArticleFailure,

  requestArticlesSuccess,
  requestArticlesFailure,
  setArticleIsRequesting,
  setCurrentArticle,
} from '../actions/article/ArticleAction'
// import { findArticle } from '../../db/models/Article'
import ArticleActionTypes from '../actions/article/ArticleActionTypes'
import Article from '../../entities/article/Article'
import { RequestBlocks, RequestBlocksFailure, RequestBlocksSuccess } from '../actions'
import LayoutsActionsTypes from '../types/actions/Layouts'
import LayoutTypes from '../../constants/LayoutTypes'

/**
 * Request API for a article
 */
export const requestArticleEpic = (
  action$: ActionsObservable<RequestArticle>,
  state$: null,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType(ArticleActionTypes.REQUEST_ARTICLE),
  switchMap((action: RequestArticle) =>
    from(
      api.articles.getArticle(action.payload.articleId)
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestArticleSuccess(result.data)
        :
          requestArticleFailure(
            action.payload.articleId,
            {
              status: result.status,
              message: result.message || ''
            }
          )
      )
    ),
  )
)

/**
 * Should request DB on network error
 */
export const mapApiRequestFailureToDbRequestEpic = (
  action$: ActionsObservable<RequestArticleFailure>
) => action$.pipe(
  ofType(ArticleActionTypes.REQUEST_ARTICLE_FAILURE),
  // get only network error
  filter((action: RequestArticleFailure) => action.payload.error.status === 0),
  switchMap((action: RequestArticleFailure) => of(dbRequestArticle(action.payload.articleId))),
)

/**
 * Perform DB request for a article by id
 */
export const dbRequestArticleEpic = (
  action$: ActionsObservable<DbRequestArticle>,
  state$: null,
  { indexedDb }: { indexedDb: typeof db }
) => action$.pipe(
  ofType(ArticleActionTypes.DB_REQUEST_ARTICLE),
  switchMap((action: DbRequestArticle) =>
    from(
      indexedDb.articles.findArticle(action.payload.id)
    ).pipe(
      map(article =>
        article
          ?
            dbRequestArticleSuccess(article)
          :
            dbRequestArticleFailure(action.payload.id)
      ),
    )
  ),
)

/**
 * Request articles for a parent article
 */
export const requestArticlesByParentEpic = (
  action$: ActionsObservable<RequestArticles>,
  state$: null,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(ArticleActionTypes.REQUEST_ARTICLES),
  switchMap((action: RequestArticles) =>
    from(
      api.articles.getArticlesByParent(
        action.payload.parentId,
        action.payload.selection
      )
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestArticlesSuccess(
            result.data.pages,
            result.data.params,
            action.payload.shouldAppend
          )
        :
          requestArticlesFailure(action.payload.parentId, {
            status: result.status,
            message: result.message || ''
          })
      ),
    )
  ),
)

/**
 * Should request DB for a articles on network error
 */
export const mapArticlesApiRequestFailureToDbRequestEpic = (
  action$: ActionsObservable<RequestArticlesFailure>
) => action$.pipe(
  ofType(ArticleActionTypes.REQUEST_ARTICLES_FAILURE),
  // get only network error
  filter((action: RequestArticlesFailure) => action.payload.error.status === 0),
  switchMap((action: RequestArticlesFailure) => of(dbRequestArticles(action.payload.categoryId))),
)

/**
 * Perform DB request for a articles by parent
 */
export const dbRequestArticlesEpic = (
  action$: ActionsObservable<DbRequestArticles>,
  state$: null,
  { indexedDb }: { indexedDb: typeof db }
) => action$.pipe(
  ofType(ArticleActionTypes.DB_REQUEST_ARTICLES),
  switchMap((action: DbRequestArticles) =>
    from(
      indexedDb.articles.findArticlesByParent(action.payload.parentId)
    ).pipe(
      map((articles: Array<Article>) =>
        dbRequestArticlesSuccess(articles)
      ),
    )
  ),
)

/**
 * Set current article
 */
export const setCurrentArticleEpic = (
  action$: ActionsObservable<RequestBlocksSuccess>
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS_SUCCESS),
  switchMap((action) => {

    if (action.payload.entity && action.payload.entity instanceof Article) {
      return of(setCurrentArticle(action.payload.entity))
    }

    return EMPTY
  }),
)

/**
 * Map layout request with slug failure to db request for entity
 */
export const mapFailuredLayoutRequestToArticleRequestEpic = (
  action$: ActionsObservable<RequestBlocksFailure>,
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS_FAILURE),
  filter((action) => action.payload.dispatch === LayoutTypes.ARTICLE && !!action.payload.slug),
  switchMap((action) =>
    of(dbRequestArticle(action.payload.slug!))
  ),
)

/**
 * set current article is loading true when map layout request for a article begins
 */
export const mapSetArticleIsRequestingOnLayoutEpic = (
  action$: ActionsObservable<RequestBlocks>,
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS),
  filter((action) => action.payload.dispatch === LayoutTypes.ARTICLE && !!action.payload.slug),
  switchMap(() =>
    of(setArticleIsRequesting(true))
  ),
)

export default [
  requestArticleEpic,
  requestArticlesByParentEpic,
  mapApiRequestFailureToDbRequestEpic,
  dbRequestArticleEpic,

  mapArticlesApiRequestFailureToDbRequestEpic,
  dbRequestArticlesEpic,

  setCurrentArticleEpic,
  mapFailuredLayoutRequestToArticleRequestEpic,
  mapSetArticleIsRequestingOnLayoutEpic,
]
