import includes from 'lodash/includes'

import Database from '../Database'
import Product from '../../entities/product/Product'
import notUndefined from '../../utils/notUndefined'

/**
 * Saves a product to indexedDB
 *
 * @param {Product} product
 *
 * @returns {void}
 */
export const persistProduct = (product: Product): void => {
  const productToSave = new Product({...product});

  Database.products.put(productToSave)
}

/**
 * Get a product from indexedDB by id
 *
 * @param {number|string} key
 *
 * @returns {Promise<Product|undefined>}
 */
export const findProduct = (key: number|string): Promise<Product|undefined> => {
  if (typeof key === 'string') {
    return Database.products.get({ slug: key })
  }

  return Database.products.get(key)
}

/**
 * Get products from indexedDB by it's ids
 *
 * @param {number[]} productsIds
 *
 * @returns {Promise<Array<Product>>}
 */
export const findProducts = (productsIds: Array<number>): Promise<Array<Product>> =>
  Database.products.filter(
    product => includes(productsIds, product.id)
  ).toArray()


/**
 * Works like findProducts but result will contain doubles
 * findNotDistinctProducts(1, 1, 1) will return promise with 3 products with id = 1
 *
 * @param {number[]} productsIds
 *
 * @returns {Promise<Array<Product>>}
 */
export const findNotDistinctProducts = (productsIds: Array<number>): Promise<Array<Product>> =>
  findProducts(productsIds).then((dbProducts) => {
    const fullList: Array<Product|undefined> = productsIds
      .map(
        productId => {
          const product = dbProducts.find(product => product.id === productId);
          return product ? new Product({...product}) : undefined
        }
      )

      return fullList.filter(notUndefined)
  })

/**
 * Get products associated with a category
 *
 * @param {number} categoryId
 *
 * @returns {Promise<Array<Product>>}
 */
export const findProductsByCategory = (categoryId: number): Promise<Array<Product>> =>
  Database.products.filter(
    product => includes(product.parents.map(category => category.id), categoryId)
  ).toArray()

/**
 * Get products associated with a company/vendor
 *
 * @param {number} companyId
 *
 * @returns {Promise<Array<Product>>}
 */
export const findProductsByVendor = (companyId: number): Promise<Array<Product>> =>
  Database.products.filter(
    product => product.vendors ? includes(product.vendors.map(vendor => vendor.id), companyId) : false
  ).toArray()
