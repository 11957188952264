import ArticleActionTypes from './ArticleActionTypes'
import Article from '../../../entities/article/Article'

export interface DbRequestArticle {
  type: ArticleActionTypes.DB_REQUEST_ARTICLE;
  payload: {
    id: number|string;
  }
}

export function dbRequestArticle(id: number|string): DbRequestArticle {
  return {
    type: ArticleActionTypes.DB_REQUEST_ARTICLE,
    payload: {
      id,
    }
  }
}

export interface DbRequestArticleSuccess {
  type: ArticleActionTypes.DB_REQUEST_ARTICLE_SUCCESS;
  payload: {
    article: Article;
  }
}

export function dbRequestArticleSuccess(article: Article): DbRequestArticleSuccess {

  return {
    type: ArticleActionTypes.DB_REQUEST_ARTICLE_SUCCESS,
    payload: {
      article
    }
  }
}

export interface DbRequestArticleFailure {
  type: ArticleActionTypes.DB_REQUEST_ARTICLE_FAILURE;
  payload: {
    id: number|string;
  }
}

export function dbRequestArticleFailure(id: number|string): DbRequestArticleFailure {

  return {
    type: ArticleActionTypes.DB_REQUEST_ARTICLE_FAILURE,
    payload: {
      id,
    }
  }
}
