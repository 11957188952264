import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import BackButton from './BackButton'
import * as actions from '../../../redux/actions'
import { StoreState } from '../../../redux/types'

import { IStateProps, IDispatchProps, IOwnProps } from './IBackButton'

export function mapStateToProps(state: StoreState, ownProps: IOwnProps): IStateProps {
  return {
    isSearchVisible:            state.NavigationBar.isSearchVisible,
    canGoBack:                  state.NavigationBar.canGoBack,
    isAutocompleteOpened:       state.Search.isAutocompleteOpened,
    shouldSetSearchFocusOnInit: state.NavigationBar.shouldSetSearchFocusOnInit,
  };
}

export function mapDispatchToProps(dispatch: Dispatch<actions.NavigationBarAction | actions.SearchAction>): IDispatchProps {
  return {
    toggleSearch: () => dispatch(actions.toggleSearch()),
    closeSearchAutocomplete: () => dispatch(actions.closeSearchAutocomplete()),
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(BackButton);
