import { map, switchMap, filter } from 'rxjs/operators'
import { from, of, EMPTY } from 'rxjs'
import { ofType } from 'redux-observable'
import { ActionsObservable } from 'redux-observable'

import cscartApi from '../../api'
import db from '../../db/models/'
import {
  // types
  DbRequestCategory,
  RequestCategory,
  RequestCategoryFailure,

  // functions
  dbRequestCategory,
  dbRequestCategorySuccess,
  dbRequestCategoryFailure,

  requestCategorySuccess,
  requestCategoryFailure,
  setCurrentCategory,
  setCategoryIsRequesting,
} from '../actions/category/CategoryAction'
import CategoryActionTypes from '../actions/category/CategoryActionTypes'
import Category from '../../entities/category/Category'
import { RequestBlocksFailure, RequestBlocksSuccess, RequestBlocks } from '../actions'
import LayoutsActionsTypes from '../types/actions/Layouts'
import LayoutTypes from '../../constants/LayoutTypes'

/**
 * Request API for a category
 */
export const requestCategoryEpic = (
  action$: ActionsObservable<RequestCategory>,
  state$: null,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType(CategoryActionTypes.REQUEST_CATEGORY),
  switchMap((action: RequestCategory) =>
    from(
      api.categories.getCategory(action.payload.categoryId)
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestCategorySuccess(result.data)
        :
          requestCategoryFailure(
            action.payload.categoryId,
            {
              status: result.response ? result.response.status : 0,
              message: result.message || ''
            }
          )
      )
    ),
  )
)

/**
 * Should request DB on network error
 */
export const mapApiRequestFailureToDbRequestEpic = (
  action$: ActionsObservable<RequestCategoryFailure>
) => action$.pipe(
  ofType(CategoryActionTypes.REQUEST_CATEGORY_FAILURE),
  // get only network error
  filter((action: RequestCategoryFailure) => action.payload.error.status === 0),
  switchMap((action: RequestCategoryFailure) => of(dbRequestCategory(action.payload.id))),
)

/**
 * Perform DB request for a category by id
 */
export const dbRequestCategoryEpic = (
  action$: ActionsObservable<DbRequestCategory>,
  state$: null,
  { indexedDb }: { indexedDb: typeof db }
) => action$.pipe(
  ofType(CategoryActionTypes.DB_REQUEST_CATEGORY),
  switchMap((action: DbRequestCategory) =>
    from(
      indexedDb.categories.findCategory(action.payload.id)
    ).pipe(
      map((category: Category|undefined) =>
        category
          ?
            dbRequestCategorySuccess(category)
          :
            dbRequestCategoryFailure(action.payload.id)
      ),
    )
  ),
)

/**
 * Set current category
 */
export const setCurrentCategooryEpic = (
  action$: ActionsObservable<RequestBlocksSuccess>
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS_SUCCESS),
  switchMap((action) => {

    if (action.payload.entity && action.payload.entity instanceof Category) {
      return of(setCurrentCategory(action.payload.entity))
    }

    return EMPTY
  }),
)

/**
 * Map layout request with slug failure to db request for entity
 */
export const mapFailuredLayoutRequestToCategoryRequestEpic = (
  action$: ActionsObservable<RequestBlocksFailure>,
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS_FAILURE),
  filter((action) => action.payload.dispatch === LayoutTypes.CATEGORY && !!action.payload.slug),
  switchMap((action) =>
    of(requestCategoryFailure(action.payload.slug!, action.payload.error))
  ),
)

/**
 * set current category is loading true when map layout request for a category begins
 */
export const mapSetCategoryIsRequestingOnLayoutEpic = (
  action$: ActionsObservable<RequestBlocks>,
) => action$.pipe(
  ofType(LayoutsActionsTypes.REQUEST_BLOCKS),
  filter((action) => action.payload.dispatch === LayoutTypes.CATEGORY && !!action.payload.slug),
  switchMap(() =>
    of(setCategoryIsRequesting(true))
  ),
)

export default [
  requestCategoryEpic,
  mapApiRequestFailureToDbRequestEpic,
  dbRequestCategoryEpic,

  setCurrentCategooryEpic,
  mapFailuredLayoutRequestToCategoryRequestEpic,
  mapSetCategoryIsRequestingOnLayoutEpic,
]
