import cscartApi from './cscart-api'
import config from '../config/Config'
import ArticleSelection from '../entities/article/ArticleSelection'
import SortOrder from '../constants/SortOrder'

export const getArticle = (articleId: number) =>
  cscartApi
    .pages
    .setIconSize([
      config.articleSmallIconWidth,
      config.articleSmallIconHeight
    ])
    .setIconSize([
      config.articleBigIconWidth,
      config.articleBigIconHeight
    ])
    .one(articleId)
    .get()

export const getArticlesByParent = (
  parentId: number,
  articleSelection?: ArticleSelection,
): Promise<any> => {
  let baseRequest = (
    cscartApi
      .pages
      .setIconSize([
        config.articleSmallIconWidth,
        config.articleSmallIconHeight
      ])
      .setIconSize([
        config.articleBigIconWidth,
        config.articleBigIconWidth
      ])
      .forParentPage(parentId)
  )

  if (articleSelection) {
    if (articleSelection.sorting) {
      baseRequest = baseRequest.orderBy(articleSelection.sorting.sortBy)

      if (articleSelection.sorting.order === SortOrder.DESC) {
        baseRequest = baseRequest.desc()
      }
    }
    if (articleSelection.pagination) {
      baseRequest = baseRequest
        .limit(articleSelection.pagination.limit)
        .page(articleSelection.pagination.page)
    }
    if (articleSelection.search) {
      baseRequest = baseRequest
        .search(articleSelection.search)
    }
  }

  return baseRequest.get()
}
