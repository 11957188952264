import SortOrder from '../../constants/SortOrder'

// import ISort from './ISort'

class Sorting {
  sortBy: string;
  order: string;

  constructor(rawSorting: any) {
    const sorting = {...rawSorting};

    if ('sortBy' in sorting) {
      this.sortBy = sorting.sortBy;
      this.order = sorting.order || SortOrder.ASC;
    } else {
      this.sortBy = sorting.sort_by;
      this.order = sorting.sort_order;
    }
  }
}

export default Sorting
