import Pagination from './Pagination'
import Sorting from './Sorting'
import IAbstractItemsSelection from './IAbstractItemsSelection'

/**
 * Abstract class for items selections
 * For pagination, filters, sortings, search
 * and other basic selection implementations
 */
abstract class AbstractItemsSelection implements IAbstractItemsSelection {
  pagination?:          Pagination;
  sorting?:             Sorting;
  search?:              string;
  abstract filters:     any;

  'constructor'(rawSelection: any) {
    const selection = {...rawSelection};

    if (selection.pagination) {
      this.pagination = new Pagination(selection.pagination);
    }
    if (selection.sorting) {
      this.sorting      = new Sorting(selection.sorting);
    }
    if (selection.search) {
      this.search = selection.search;
    }
  }

  nextPage(): this {
    if (!this.pagination) {
      console.warn('Pagination is not specified')
      return this
    }

    /**
     * At JS constructor is just a function
     */
    return new (this.constructor as any)({
      ...this,
      pagination: this.pagination.nextPage()
    })
  }

  sortBy(sortValue: string): this {
    const [sortBy, order] = sortValue.split('--');

    delete this.pagination

    return new (this.constructor as any)({
      ...this,
      sorting: {
        sortBy,
        order,
      }
    })
  }

  abstract prependItems(items: Array<any>): AbstractItemsSelection;
}

export default AbstractItemsSelection
