import notUndefined from "../notUndefined"

/**
 * Extends className string by additional class names
 *
 * @param {string} baseClass
 * @param {string|Array|false} extendClass
 *
 * @returns {string} result class name string with class names separated by space
 *
 * @example
 * // returns 'b-block b-product__element'
 * appendClassName('b-block', 'b-product__element')
 *
 * @example
 * // returns 'b-block b-product__element b-image'
 * appendClassName('b-block', ['b-product__element', 'b-image'])
 *
 * @example
 * // returns 'b-block'
 * appendClassName('b-block', [])
 * appendClassName('b-block', false)
 * appendClassName('b-block', '')
 */
const appendClassName = (baseClass: string, extendClass?: Array<string | false | undefined> | string | false): string => {
  if (!extendClass) {
    return baseClass
  }

  if (Array.isArray(extendClass)) {
    const classList = extendClass.filter(className => className && notUndefined(className));
    return [baseClass, ...classList].join(' ').trim()
  }

  return [baseClass, extendClass].join(' ').trim()
}

export default appendClassName
