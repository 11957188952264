import VendorActionTypes from './VendorActionTypes'
import ApiError from '../../../entities/error/ApiError'
import VendorSelection from '../../../entities/vendor/VendorSelection'
import { persistVendor } from '../../../db/models/Vendor'

export interface RequestVendors {
  type: VendorActionTypes.REQUEST_VENDORS;
  payload: {
    selection?: VendorSelection;
    shouldAppend?: boolean;
  }
}

export function requestVendors(
  selection?: VendorSelection,
  shouldAppend?: boolean
): RequestVendors {
  return {
    type: VendorActionTypes.REQUEST_VENDORS,
    payload: {
      selection,
      shouldAppend
    }
  }
}

export interface RequestVendorsSuccess {
  type: VendorActionTypes.REQUEST_VENDORS_SUCCESS;
  payload: {
    vendorSelection: VendorSelection;
    shouldAppend: boolean;
  }
}

export function requestVendorsSuccess(
  rawVendors: Array<any>,
  rawParams: any = {},
  shouldAppend: boolean = false
): RequestVendorsSuccess {

  const vendorSelection = new VendorSelection({
    vendors: rawVendors,
    pagination: rawParams,
    sorting: rawParams,
  })

  vendorSelection.vendors.forEach(vendor => persistVendor(vendor))

  return {
    type: VendorActionTypes.REQUEST_VENDORS_SUCCESS,
    payload: {
      vendorSelection,
      shouldAppend,
    }
  }
}

export interface RequestVendorsFailure {
  type: VendorActionTypes.REQUEST_VENDORS_FAILURE;
  payload: {
    error:  ApiError;
  }
}

export function requestVendorsFailure(error: ApiError): RequestVendorsFailure {

  return {
    type: VendorActionTypes.REQUEST_VENDORS_FAILURE,
    payload: {
      error,
    }
  }
}
