import ProductActionTypes from './ProductActionTypes'
import { persistProduct } from '../../../db/models/Product'
import ApiError from '../../../entities/error/ApiError'
import ProductSelection from '../../../entities/product/ProductSelection'

export interface RequestCategoryProducts {
  type: ProductActionTypes.REQUEST_CATEGORY_PRODUCTS;
  payload: {
    categoryId: number;
    productSelection?: ProductSelection;
    shouldAppend?: boolean;
  }
}

export function requestCategoryProducts(
  id: number,
  productSelection?: ProductSelection,
  shouldAppend?: boolean
): RequestCategoryProducts {
  return {
    type: ProductActionTypes.REQUEST_CATEGORY_PRODUCTS,
    payload: {
      categoryId: id,
      productSelection,
      shouldAppend
    }
  }
}

export interface RequestCategoryProductsSuccess {
  type: ProductActionTypes.REQUEST_CATEGORY_PRODUCTS_SUCCESS;
  payload: {
    productSelection: ProductSelection;
    shouldAppend: boolean;
  }
}

export function requestCategoryProductsSuccess(
  rawProducts: Array<any>,
  rawParams: any,
  rawFilters: Array<any>,
  shouldAppend: boolean = false
): RequestCategoryProductsSuccess {

  const productSelection = new ProductSelection({
    products: rawProducts,
    pagination: rawParams,
    filterHashes: rawParams.features_hash,
    filters: rawFilters,
    sorting: rawParams,
    search: rawParams.q,
  });

  productSelection.products.forEach(product => persistProduct(product))

  return {
    type: ProductActionTypes.REQUEST_CATEGORY_PRODUCTS_SUCCESS,
    payload: {
      productSelection,
      shouldAppend,
    }
  }
}

export interface RequestCategoryProductsFailure {
  type: ProductActionTypes.REQUEST_CATEGORY_PRODUCTS_FAILURE;
  payload: {
    categoryId:  number;
    error:        ApiError;
  }
}

export function requestCategoryProductsFailure(id: number, error: any): RequestCategoryProductsFailure {
  const apiError = new ApiError({
    status: error.status || 0,
    shouldRetry: error.shouldRetry || false,
    message: error.message || 'Unhandled error'
  })

  return {
    type: ProductActionTypes.REQUEST_CATEGORY_PRODUCTS_FAILURE,
    payload: {
      categoryId: id,
      error: apiError
    }
  }
}
