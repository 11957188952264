import { combineReducers } from 'redux'

import Auth from './Auth'
import Article from './Article'
import Cart from './Cart'
import Wishlist from './Wishlist'
import Ui from './Ui'
import NavigationBar from './NavigationBar'
import Layouts from './Layouts'
import Category from './Category'
import Product from './Product'
import Orders from './Orders'
import Order from './Order'
import Review from './Review'
import Vendors from './Vendors'
import Vendor from './Vendor'
import ApplyForVendor from './ApplyForVendor'
import Search from './Search'

export const rootReducer = combineReducers({
  Auth,
  Article,
  Cart,
  Wishlist,
  Ui,
  NavigationBar,
  Layouts,
  Category,
  Product,
  Orders,
  Order,
  Review,
  Vendors,
  Vendor,
  Search,
  ApplyForVendor,
})

