/**
 * Apply BEM modifier to classname
 *
 * @param {string} className
 * @param {string|false} modifier
 *
 * @returns {string} result class name
 *
 * @example
 * // returns 'b-block b-block--main'
 * modifyClassName('b-block', 'main')
 *
 * @example
 * // returns 'b-block'
 * modifyClassName('b-block', false)
 */
const modifyClassName = (className: string, modifiers?: Array<string> | false | string): string => {
  if (!modifiers) {
    return className
  }

  let newModifiers: Array<string>;

  if (Array.isArray(modifiers)) {
    newModifiers = [...modifiers];
  } else {
    newModifiers = [modifiers];
  }

  const arrayOfModifiers = newModifiers.map((modifier) => {
    return `${className}--${modifier}`
  });

  return `${className} ${arrayOfModifiers.join(' ')}`
}

export default modifyClassName
