export default {
  'app.common.removing': 'Удаление...',
  'app.common.sending': 'Отправляем...',
  'app.common.clear': 'Очистить',
  'app.common.saved-as-draft': 'Сохранено в черновики',
  'app.common.or': 'Или',

  'app.screens.wishlist.title': 'Избранное',
  'app.screens.wishlist.empty': 'Список пуст',
  'app.screens.wishlist.empty-description': 'Нажмите ❤ на любом товаре, чтобы добавить его в избранное',

  'app.screens.cart.title': 'Корзина',
  'app.screens.cart.empty': 'Список пуст',
  'app.screens.cart.empty-description': 'Купите любой товар, чтобы увидеть его в этом списке',
  'app.screens.cart.remove-confirm': 'Вы действительно хотите удалить этот товар?',

  'app.screens.orders.title': 'Заказы',
  'app.screens.order.title': 'Заказ № {orderNumber}',

  'app.screens.checkout.user-info-title': 'Информация о покупателе',
  'app.screens.checkout.payment-title': 'Способ оплаты',
  'app.screens.checkout.shipping-title': 'Спооб доставки',

  'app.screens.checkout-complete.title': 'Заказ №{orderId} успешно создан!',
  'app.screens.checkout-complete.text': 'Спасибо!',
  'app.screens.checkout-complete.go-to-order-button-title': 'Показать заказ',

  'app.screens.vendors.title': 'Продавцы',

    /**
   * Apply for vendor
   */
  'app.components.vendor-signup.title': 'Подать заявку на регистрацию продавца',
  'app.components.vendor-signup.success-result': 'Ваш запрос успешно отправлен. Ответ по заявке будет отправлен на указанную почту',
  'app.components.vendor-signup.submit-label': 'Отправить',

  /**
   * Search screen
   */
  'app.screens.search.title': 'Поиск',

  /**
   * Login screen/modal
   */
  'app.screens.login.title': 'Авторизация',
  'app.screens.login.submit-button': 'Войти',
  'app.screens.login.register-button': 'Регистрация',
  'app.screens.login.wrong-credentials': 'Пользователь с такими данными не найден',
  'app.screens.login.placeholders.email': 'Электронная почта',
  'app.screens.login.placeholders.password': 'Пароль',

  /**
   * Profile screen
   */
  'app.screens.profile.title': 'Мой профиль',

  'app.cart.messages.need-auth': 'Для покупки необходима авторизация',
  'app.cart.messages.success-added-to-cart': 'Товар добавлен в козину',

  'app.api.errors.no-internet': 'Ошибка сетевого соединения',
  'app.api.errors.not-found': 'Не найдено',
  'app.api.session-expired': 'Ваша сессия истекла. Пожалуйста авторизуйтесь заново',

  'app.system.ready-to-update': 'Доступна новая версия приложения, закройте все вкладки с этой страницей чтобы обновиться.',
  'app.system.updated': 'Приложение обновлено',

  'app.components.reviews.add-new-button': 'Оставьте отзыв',
  'app.components.reviews.form.textarea-title': 'Ваш отзыв',
  'app.components.reviews.form.submit-title': 'Отправить',
  'app.components.reviews.form.success-submit': 'Спасибо! Ваш отзыв отправлен на проверку.',
  'app.components.reviews.form.error-submit': 'Произошла ошибка во время отправления отзыва.',
  'app.components.reviews.no-content': 'Нет отзывов',
  'app.components.reviews.total-reviews': `{reviews, number} {reviews, plural,
    one {отзыв}
    few {отзыва}
    other {отзывов}
  }`,

  'app.components.order-single-item': 'Заказ № {orderNumber}',

  'app.components.userinfo.guest': 'Неавторизован',
  'app.components.userinfo.guest-acronym': 'Н',

  'app.form.sections.customer-information': 'Информация о пользователе',
  'app.form.sections.billing-information': 'Информация о плательщике',
  'app.form.sections.shipping-information': 'Информация о доставке',
  'app.form.sections.products-information': 'Информация о покупках',

  'app.form.labels.firstname': 'Имя',
  'app.form.labels.lastname': 'Фамилия',
  'app.form.labels.email': 'Электронная почта',
  'app.form.labels.Phone': 'Телефон',
  'app.form.labels.country': 'Страна',
  'app.form.labels.county': 'Округ',
  'app.form.labels.zipcode': 'Индекс',
  'app.form.labels.state': 'Регион',
  'app.form.labels.city': 'Город',
  'app.form.labels.address': 'Адрес',
  'app.form.labels.address2': 'Адрес 2',
  'app.form.labels.payment-method': 'Способ оплаты',

  'app.form.validation.too-short': 'Слишком коротко',
  'app.form.validation.too-long': 'Слишком длинно',
  'app.form.validation.requiered': 'Обязательно',
  'app.form.validation.invalid-email': 'Неверный адрес электронной почты',

  'app.components.product-sorting.name': 'По названию товара',
  'app.components.product-sorting.price': 'По цене товара',
  'app.components.product-sorting.new-products': 'Новинки',
  'app.components.product-sorting.bestsellers': 'Хиты продаж',
  'app.components.product-sorting.discounts': 'Товары со скидкой',

  'app.components.vendor-sorting.name': 'По имени продавца',
  'app.components.vendor-sorting.rating': 'По рейтингу продавца',

  'app.common.sorting.alpha.asc': 'А\u2011Я',
  'app.common.sorting.alpha.desc': 'Я\u2011А',

  'app.common.sorting.number.asc': 'Возрастание',
  'app.common.sorting.number.desc': 'Убывание',

  'app.common.sorting.date.asc': 'Старые',
  'app.common.sorting.date.desc': 'Новые',

  'app.components.quantity-selector.down': '-',
  'app.components.quantity-selector.up': '+',

  'app.common.address': 'Адрес',
  'app.common.city': 'Город',
  'app.common.state': 'Штат',
  'app.common.country': 'Страна',

  'app.components.vendor.registered': 'Зарегистрирован: ',
  'app.screens.vendor.tab-aria-label': 'Табы продавца',
  'app.screens.vendor.info': 'Информация',
  'app.screens.vendor.catalog': 'Каталог',
  'app.screens.vendor.rating': 'Рейтинг',

  'app.components.offline.title': 'оффлайн',
  'app.components.offline.message': 'Попробуйте перезагрузить страницу. Некоторые посещённые страницы всё ещё доступны для просмотра.',
  'app.components.offline.reload-button': 'Попробовать перезугрузить',

  'app.components.navigation-bar.offline': 'оффлайн',
  'app.components.navigation-bar.offline-title': 'Попробуйте перезагрузить страницу. Некоторые посещённые страницы всё ещё доступны для просмотра.',
  'app.components.navigation-bar.offline-description': 'Попробовать перезугрузить',

  'app.components.no-content.title': 'Пока пусто',

  'app.components.product-single-item.add-to-cart-button-title': 'Купить - {price}',

  'app.components.totals.subtotal': 'Подытог',
  'app.components.totals.discount': 'Скидка',
  'app.components.totals.shipping-cost': 'Доставка',
  'app.components.totals.total': 'Итого',
  'app.components.totals.tax-subtotal': 'Налоги',

  'app.components.checkout-button.button-name': 'Оформить',

  'app.components.reviews.title': 'Рейтинг и отзывы',

  'app.components.search.products.placeholder': 'Искать товары',

  'app.components.products-selection.no-results': 'Товары не найдены',
  'app.components.products-selection.filters-button': 'Фильтры',

  /**
   * Main menu
   */
  'app.components.main-menu.labels.hamburger': 'Каталог',
  'app.components.main-menu.labels.home-button': 'Домой',
  'app.components.main-menu.labels.wishlist-button': 'Избранное',
  'app.components.main-menu.labels.profile-button': 'Профиль',
  'app.components.main-menu.labels.cart-button': 'Корзина',

  /**
   * Online payment handler
   */
  'app.components.online-payment-handler.title': 'Платёж',

  /**
   * Load More
   */
  'app.components.load-more.button-label': 'Показать ещё',

  /**
   * Gdpr Agreement
   */
  'app.components.gdpr.agreement.title': 'использование local storage',
  'app.components.gdpr.agreement.description': `Этот сайт использует Local Storage (локальное хранилище данных) для обработки ваших персональных данных,
    чтобы обеспечить наилучшие условия пользования нашим сайтом. Этот инструмент позволяет вам использовать определенные функции,
    осуществляющие работу сайта (сохранение ваших продуктов в разделы корзина и избранное). Обратите внимание, что мы не используем
    куки, однако они могут быть использованы сторонними инструментами (виджетами аналитики или гугл-авторизацией). `,
  'app.components.gdpr.agreement.conditions': `Закрывая или отклоняя это уведомление, нажав на кнопку, или, продолжая просматривать
    содержимое нашего сайта, вы принимаете использование локального хранилища данных. `,
  'app.components.gdpr.agreement.accept-button': 'Принимаю',
}
