import VendorActionTypes from './VendorActionTypes'
import Vendor from '../../../entities/vendor/Vendor'
import VendorSelection from '../../../entities/vendor/VendorSelection'

export interface DbRequestVendors {
  type: VendorActionTypes.DB_REQUEST_VENDORS;
}

export function dbRequestVendors(): DbRequestVendors {
  return {
    type: VendorActionTypes.DB_REQUEST_VENDORS,
  }
}

export interface DbRequestVendorsSuccess {
  type: VendorActionTypes.DB_REQUEST_VENDORS_SUCCESS;
  payload: {
    vendorSelection: VendorSelection;
  }
}

export function dbRequestVendorsSuccess(
  vendors: Array<Vendor>
): DbRequestVendorsSuccess {

  const vendorSelection = new VendorSelection({
    vendors: vendors,
    pagination: {
      page: 1,
      total: vendors.length,
      limit: vendors.length,
    },
  })

  return {
    type: VendorActionTypes.DB_REQUEST_VENDORS_SUCCESS,
    payload: {
      vendorSelection,
    }
  }
}

export interface DbRequestVendorsFailure {
  type: VendorActionTypes.DB_REQUEST_VENDORS_FAILURE;
}

export function dbRequestVendorsFailure(): DbRequestVendorsFailure {

  return {
    type: VendorActionTypes.DB_REQUEST_VENDORS_FAILURE,
  }
}
