import values from 'lodash/values'

import IOrder from './IOrder'
import UserData from '../cart/UserData'
import IWithTotals from '../cart/IWithTotals'
import Payment from '../payment/Payment'
import Shipping from '../shipping/Shipping'
import OrderProduct from './OrderProduct'
import OrderStatus from './OrderStatus'

class Order implements IOrder, IWithTotals {

  id:               number;
  createdAt:        Date;
  orderStatus:      OrderStatus;
  companyId:        number;
  userData?:        UserData;
  totalPrice:       number;
  subtotalPrice:    number;
  discountPrice:    number;
  shippingPrice:    number;
  taxSubtotalPrice: number;
  payment:          Payment;
  shippings:        Array<Shipping>;
  orderProducts:    Array<OrderProduct>;

  constructor(rawOrder: any & IOrder) {
    const order = {...rawOrder};

    if ('id' in order) {
      this.id         = order.id;
      this.createdAt  = order.createdAt;
      this.orderStatus   = new OrderStatus(order.orderStatus);
      this.companyId  = order.companyId;
      this.userData   = order.userData ? new UserData(order.userData) : order.userData;

      this.totalPrice       = order.totalPrice;
      this.subtotalPrice    = order.subtotalPrice;
      this.discountPrice    = order.discountPrice;
      this.shippingPrice    = order.shippingPrice;
      this.taxSubtotalPrice = order.taxSubtotalPrice;
      this.payment          = new Payment(order.payment);
      this.shippings        = order.shippings.map((shipping: any) => new Shipping(shipping));
      this.orderProducts    = order.orderProducts.map((orderProduct: any) => new OrderProduct(orderProduct));
    } else {
      this.id         = parseInt(order.order_id);
      this.companyId  = parseInt(order.company_id);
      this.orderStatus  = new OrderStatus(order.status_info);
      this.createdAt  = new Date(0);
      this.createdAt.setSeconds(parseInt(order.timestamp || 0));
      this.userData   = new UserData(order);

      this.totalPrice       = order.total ? parseFloat(order.total) : 0;
      this.subtotalPrice    = order.subtotal ? parseFloat(order.subtotal) : 0;
      this.discountPrice    = order.discount ? parseFloat(order.discount) : 0;
      this.shippingPrice    = order.shipping_cost ? parseFloat(order.shipping_cost) : 0;
      this.taxSubtotalPrice = order.tax_subtotal ? parseFloat(order.tax_subtotal) : 0;

      this.payment          = new Payment(order.payment_method);
      this.shippings        = order.shipping.map((shipping: any) => new Shipping(shipping));
      this.orderProducts    = values(order.products).map((orderProduct: any) => new OrderProduct(orderProduct));
    }

    if (!this.id) {
      throw new Error('Id param is missing. Check incoming object')
    }
  }
}

export default Order
