import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import SelectionGlobalSearch from './SelectionGlobalSearch'
import * as actions from '../../../../redux/actions'
import { StoreState } from '../../../../redux/types'

import { IStateProps, IDispatchProps, IOwnProps } from './ISelectionGlobalSearch'
import ProductSelection from '../../../../entities/product/ProductSelection'

export function mapStateToProps(state: StoreState, ownProps: IOwnProps): IStateProps {
  return {
    isAutocompleteOpened: state.Search.isAutocompleteOpened,
    foundProducts:  state.Search.productSelection && state.Search.productSelection.products,
    queryHistories: state.Search.queryHistories,
  };
}

export function mapDispatchToProps(dispatch: Dispatch<actions.SearchAction | actions.ProductAction>): IDispatchProps {
  return {
    performSearch: (query) => dispatch(actions.performSearch(query)),
    openSearchAutocomplete: () => dispatch(actions.openSearchAutocomplete()),
    closeSearchAutocomplete: () => dispatch(actions.closeSearchAutocomplete()),
    searchProducts:  (query) => {
      const productSelection = new ProductSelection({
        search: query,
      });

      return dispatch(actions.requestSearchProducts(productSelection, false))
    },
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(SelectionGlobalSearch);
