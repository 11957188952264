import config from '../../config/Config'
import IProductOptionVariant from './IProductOptionVariant'
import PriceModifierTypes from '../../constants/PriceModifierTypes'
import ImagePair from '../image-pair/ImagePair'

class ProductOptionVariant implements IProductOptionVariant {
  id:           number;
  optionId:     number;
  name:         string;
  modifier:     number;
  modifierType: PriceModifierTypes;
  position:     number;
  mainPair:     ImagePair;

  constructor(rawVariant: any, optionId?: number) {
    const variant = {...rawVariant};

    if ('optionId' in variant) {
      this.id           = variant.id;
      this.optionId     = variant.optionId;
      this.name         = variant.name;
      this.modifier     = variant.modifier;
      this.modifierType = variant.modifierType;
      this.position     = variant.position;
      this.mainPair     = new ImagePair(variant.mainPair || {});
    } else {
      this.id           = parseInt(variant.variant_id);
      this.optionId     = optionId || parseInt(variant.option_id);
      this.name         = variant.variant_name;
      this.modifier     = parseFloat(variant.modifier);
      this.modifierType = convertModifierType(variant.modifier_type);
      this.position     = parseInt(variant.position);
      this.mainPair     = new ImagePair(variant.image_pair || {});
    }

    if (!this.id) {
      throw new Error('Missing id param. Check incoming object')
    }

    if (!this.optionId) {
      throw new Error('Missing optionId param. Check incoming object')
    }
  }

  public getSmallIcon() {

    return this.mainPair.getIconBySize(
      config.optionVariantSmallIconWidth,
      config.optionVariantSmallIconHeight
    )
  }

  public getBigIcon() {

    return this.mainPair.getIconBySize(
      config.optionVariantBigIconWidth,
      config.optionVariantBigIconHeight
    )
  }
}

const convertModifierType = (rawType: string) => {
  switch (rawType) {
    case 'A':
      return PriceModifierTypes.ABSOLUTE

    case 'P':
      return PriceModifierTypes.PERCENT

    default:
      return PriceModifierTypes.ABSOLUTE
  }
}

export default ProductOptionVariant
