import { VendorAction } from '../actions/vendor/VendorAction'
import VendorsState from '../types/VendorsState'
import VendorsActionsTypes from '../actions/vendor/VendorActionTypes'

export const initialState: VendorsState = {
  isRequesting: false,
  error:        null,
}

const Vendors = function orders(state: VendorsState = initialState, action: VendorAction): VendorsState {
  switch (action.type) {
    case VendorsActionsTypes.REQUEST_VENDORS:
      return {
        ...state,
        isRequesting: true,
      }

    case VendorsActionsTypes.REQUEST_VENDORS_SUCCESS:
      return {
        ...state,
        vendorSelection: (
          (action.payload.shouldAppend && state.vendorSelection)
            ?
            action.payload.vendorSelection.prependItems(state.vendorSelection.vendors)
            :
              action.payload.vendorSelection),
        isRequesting: false,
      }

    case VendorsActionsTypes.DB_REQUEST_VENDORS_SUCCESS:
      return {
        ...state,
        vendorSelection: action.payload.vendorSelection,
        isRequesting: false,
      }

    case VendorsActionsTypes.REQUEST_VENDORS_FAILURE:
      return {
        ...state,
        isRequesting: false,
        error: action.payload.error,
      }

    case VendorsActionsTypes.DB_REQUEST_VENDORS_FAILURE:
      return {
        ...state,
        isRequesting: false,
      }

    default:
      return state;
  }
}

export default Vendors
