import React from 'react'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'

// import FullScreenModal from '../../modal/full-screen/FullScreenModal'

import { IOwnProps } from './ISelectionSearch'
import './SelectionSearch.css'

/**
 * Low-level search component
 *
 * @param {IOwnProps} props
 */
const SelectionSearch:React.FC<IOwnProps> = (props) => {

  const {
    onChange,
    onSubmit,
    value,
    placeholder,
    focus,
    onFocus,
  } = props;

  if (!onChange && !onSubmit) {
    throw new Error('onChange or onSubmit must be specified');
  }

  const inputElement: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);
  const [ query, setQuery ] = React.useState(value || '');

  React.useEffect(() => {
    if (focus && inputElement && inputElement.current) {
      inputElement.current.focus()
    }

  }, [focus, inputElement])

  const handleChange = (value: string) => {
    setQuery(value)
    onChange && onChange(value)
  }

  return (
    <div className='b-selection-search b-selection-search__content'>
      <InputBase
        placeholder={placeholder}
        value={query}
        key='search'
        onFocus={() => onFocus && onFocus()}
        inputProps={{ 'aria-label': placeholder, ref: inputElement }}
        onChange={(event) => handleChange(event.target.value)}
        onKeyPress={event => {
          if (onSubmit && event.key === 'Enter') {
            onSubmit(query)
            event.preventDefault();
          }
        }}
        classes={{
          input: 'b-selection-search__input',
          root: 'b-selection-search__root',
        }}
      />
      <IconButton
        aria-label={placeholder}
        onClick={() => onSubmit && onSubmit(query)}
        classes={{
          root: 'b-selection-search__search-button'
        }}
      >
        <SearchIcon />
      </IconButton>
    </div>
  )
}

const SearchIcon = require('react-feather/dist/icons/search').default;

export default SelectionSearch
