enum OrderActionsTypes {
  REQUEST_ORDER_CREATE = 'REQUEST_ORDER_CREATE',
  REQUEST_ORDER_CREATE_SUCCESS = 'REQUEST_ORDER_CREATE_SUCCESS',
  REQUEST_ORDER_CREATE_FAILURE = 'REQUEST_ORDER_CREATE_FAILURE',

  REQUEST_ONLINE_PAYMENT = 'REQUEST_ONLINE_PAYMENT',
  REQUEST_ONLINE_PAYMENT_SUCCESS = 'REQUEST_ONLINE_PAYMENT_SUCCESS',
  REQUEST_ONLINE_PAYMENT_FAILURE = 'REQUEST_ONLINE_PAYMENT_FAILURE',

  REQUEST_ONLINE_PAYMENT_RESULT = 'REQUEST_ONLINE_PAYMENT_RESULT',
  REQUEST_ONLINE_PAYMENT_RESULT_SUCCESS = 'REQUEST_ONLINE_PAYMENT_RESULT_SUCCESS',
  REQUEST_ONLINE_PAYMENT_RESULT_FAILURE = 'REQUEST_ONLINE_PAYMENT_RESULT_FAILURE',

  REQUEST_SETTLEMENT_CREATE = 'REQUEST_SETTLEMENT_CREATE',
  REQUEST_SETTLEMENT_CREATE_SUCCESS = 'REQUEST_SETTLEMENT_CREATE_SUCCESS',
  REQUEST_SETTLEMENT_CREATE_FAILURE = 'REQUEST_SETTLEMENT_CREATE_FAILURE',

  REQUEST_ORDERS = 'REQUEST_ORDERS',
  REQUEST_ORDERS_SUCCESS = 'REQUEST_ORDERS_SUCCESS',
  REQUEST_ORDERS_FAILURE = 'REQUEST_ORDERS_FAILURE',

  REQUEST_ORDER = 'REQUEST_ORDER',
  REQUEST_ORDER_SUCCESS = 'REQUEST_ORDER_SUCCESS',
  REQUEST_ORDER_FAILURE = 'REQUEST_ORDER_FAILURE',

  DB_REQUEST_ORDERS = 'DB_REQUEST_ORDERS',
  DB_REQUEST_ORDERS_SUCCESS = 'DB_REQUEST_ORDERS_SUCCESS',
  DB_REQUEST_ORDERS_FAILURE = 'DB_REQUEST_ORDERS_FAILURE',

  DB_REQUEST_ORDER = 'DB_REQUEST_ORDER',
  DB_REQUEST_ORDER_SUCCESS = 'DB_REQUEST_ORDER_SUCCESS',
  DB_REQUEST_ORDER_FAILURE = 'DB_REQUEST_ORDER_FAILURE',
}

export default OrderActionsTypes
