import ICartProduct from './ICartProduct'
import Product from '../product/Product'

/**
 * @todo replace extending by composition
 */
class CartProduct implements ICartProduct {

  cartId:           string;
  subtotal:         number;
  amount:           number;
  amountTotal:      number;
  product:          Product;

  constructor(rawCartItem: ICartProduct | any) {
    const cartItem  = {...rawCartItem};

    if (cartItem.cartId) {
      this.cartId       = cartItem.cartId;
      this.subtotal     = cartItem.subtotal;
      this.amountTotal  = cartItem.amountTotal;
      this.product      = new Product(cartItem.product);
    } else {
      this.cartId       = cartItem.cart_id;
      this.subtotal     = cartItem.display_subtotal;
      this.amountTotal  = cartItem.amount_total;

      /**
       * Fix cscart product interface issues
       */
      const cartProduct = {
        ...cartItem,
        product_options:  cartItem.product_options_detailed,
        amount:           cartItem.in_stock,
      }
      // delete cartProduct.product_options
      this.product  =   new Product(cartProduct);
    }

    this.amount   =   cartItem.amount;

    if (!this.cartId) {
      throw new Error('cartId param is missing. Check object')
    }
  }
}

export default CartProduct
