import ITestimonial from './ITestimonial'

class Testimonial implements ITestimonial {
  id:         number;
  parentId:   number;
  author:     string;
  createdAt:  Date;
  text:       string;
  rating:     number;

  constructor(rawTestimonial: any) {
    const testimonial = {...rawTestimonial};

    if ('id' in testimonial) {
      this.id         = testimonial.id;
      this.parentId   = testimonial.parentId;
      this.author     = testimonial.author;
      this.createdAt  = testimonial.createdAt;
      this.text       = testimonial.text;
      this.rating     = testimonial.rating;
    } else {
      this.id         = parseInt(testimonial.post_id);
      this.parentId   = parseInt(testimonial.thread_id);
      this.author     = testimonial.name;
      this.createdAt  = new Date(0);
      this.createdAt.setSeconds(parseInt(testimonial.timestamp));
      this.text       = testimonial.message;
      this.rating     = parseFloat(testimonial.rating_value);
    }

    if (!this.id) {
      throw new Error('Id parameter is missing. Check incoming object')
    }
  }
}

export default Testimonial
