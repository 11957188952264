import ProductActionTypes from './ProductActionTypes'
import { persistProduct } from '../../../db/models/Product'
import ApiError from '../../../entities/error/ApiError'
import ProductSelection from '../../../entities/product/ProductSelection'

export interface RequestVendorProducts {
  type: ProductActionTypes.REQUEST_VENDOR_PRODUCTS;
  payload: {
    id: number;
    productSelection?: ProductSelection;
    shouldAppend?: boolean;
  }
}

export function requestVendorProducts(
  id: number,
  productSelection?: ProductSelection,
  shouldAppend?: boolean
): RequestVendorProducts {
  return {
    type: ProductActionTypes.REQUEST_VENDOR_PRODUCTS,
    payload: {
      id,
      productSelection,
      shouldAppend
    }
  }
}

export interface RequestVendorProductsSuccess {
  type: ProductActionTypes.REQUEST_VENDOR_PRODUCTS_SUCCESS;
  payload: {
    productSelection: ProductSelection;
    shouldAppend: boolean;
  }
}

export function requestVendorProductsSuccess(
  rawProducts: Array<any>,
  rawParams: any,
  rawFilters: Array<any>,
  shouldAppend: boolean = false
): RequestVendorProductsSuccess {

  const productSelection = new ProductSelection({
    products: rawProducts,
    pagination: rawParams,
    filterHashes: rawParams.features_hash,
    filters: rawFilters,
    sorting: rawParams,
    search: rawParams.q,
  });

  productSelection.products.forEach(product => persistProduct(product))

  return {
    type: ProductActionTypes.REQUEST_VENDOR_PRODUCTS_SUCCESS,
    payload: {
      productSelection,
      shouldAppend,
    }
  }
}

export interface RequestVendorProductsFailure {
  type: ProductActionTypes.REQUEST_VENDOR_PRODUCTS_FAILURE;
  payload: {
    id:     number;
    error:  ApiError;
  }
}

export function requestVendorProductsFailure(id: number, error: any): RequestVendorProductsFailure {
  const apiError = new ApiError({
    status: error.status,
    shouldRetry: error.shouldRetry || false,
    message: error.message || 'Unhandled error'
  })

  return {
    type: ProductActionTypes.REQUEST_VENDOR_PRODUCTS_FAILURE,
    payload: {
      id: id,
      error: apiError
    }
  }
}
