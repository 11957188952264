import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import MainMenu from './MainMenu'
import { StoreState } from '../../redux/types'

import { IStateProps, IDispatchProps, IOwnProps } from './IMainMenu'

export function mapStateToProps(state: StoreState, ownProps: IOwnProps): IStateProps {
  return {
    deviceType: state.Ui.deviceType,
  };
}

export function mapDispatchToProps(dispatch: Dispatch): IDispatchProps {
  return {}
}

export default connect<IStateProps, IDispatchProps, IOwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(MainMenu);
