import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import GdprAgreement from './GdprAgreement'
import * as actions from '../../../redux/actions'
import { StoreState } from '../../../redux/types'

import { IStateProps, IDispatchProps, IOwnProps } from './IGdprAgreement'

export function mapStateToProps(state: StoreState, ownProps: IOwnProps): IStateProps {
  return {
    isGdprAccepted: state.Ui.isGdprAccepted,
  };
}

export function mapDispatchToProps(dispatch: Dispatch<actions.UiAction>): IDispatchProps {
  return {
    acceptGdpr: () => dispatch(actions.acceptGdpr()),
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(GdprAgreement);
