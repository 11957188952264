import ICurrency from './ICurrency'

class Currency implements ICurrency {
  rate:           number;
  code:           string;
  decimals:       number;
  name:           string;
  symbol:         string;
  symbolPosition: 'before' | 'after';
  isBase:         boolean;
  position:       number;

  constructor(rawCurrency: any) {
    const currency = {...rawCurrency};

    if ('rate' in currency) {
      this.rate           = currency.rate;
      this.code           = currency.code;
      this.decimals       = currency.decimals;
      this.name           = currency.name;
      this.symbol         = currency.symbol;
      this.symbolPosition = currency.symbolPosition;
      this.isBase         = currency.isBase;
      this.position       = currency.position;
    } else {
      this.rate           = parseFloat(currency.coefficient);
      this.code           = currency.currency_code;
      this.decimals       = parseInt(currency.decimals);
      this.name           = currency.description;
      this.symbol         = currency.symbol;
      this.symbolPosition = currency.after === 'N' ? 'before' : 'after';
      this.isBase         = currency.is_primary === 'N' ? false : true;
      this.position       = parseInt(currency.position);
    }

    if (!this.code) {
      throw new Error('Currency code is missing. Check incoming object')
    }
  }
}

export default Currency
