import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import throttle from 'lodash/throttle'

import { rootReducer } from '../reducers'
import { APP_INIT } from '../types/actions'
import { initialState } from './initialState'
import * as localStorage from './localStorage'
import rehydrate from '../rehydrate'
import { epicMiddleware, rootEpic, } from '../epics'

const persistedState = rehydrate(localStorage.loadState(initialState));

export const store = createStore(rootReducer, persistedState, composeWithDevTools(
  applyMiddleware(epicMiddleware),
));

store.subscribe(throttle(() => {
  const state = store.getState();

  /**
   * @todo whitelist
   */
  localStorage.saveState(
    {
      Auth: {
        ...state.Auth,
        isLoginFailed: false,
        isLoginRequesting: false,
      },
      Cart: {
        ...state.Cart,
        // additionQueueErrors: [],
        additionQueueErrorsProducts: [],
        updateQueue: [],
        redirectionForm: undefined,
      },
      Ui: {
        currency: state.Ui.currency,
        language: state.Ui.language,
        currencies: state.Ui.currencies,
        languages: state.Ui.languages,
        properties: state.Ui.properties,
        isGdprAccepted: state.Ui.isGdprAccepted,
      },
      Wishlist: {
        ...state.Wishlist,
      },
      Search: {
        queryHistories: state.Search.queryHistories,
      }
    }
  );
}, 1000));

epicMiddleware.run(rootEpic as any)

store.dispatch({
  type: APP_INIT
})
