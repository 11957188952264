import CartState from '../types/CartState'
import IAddToCart from '../../entities/cart/IAddToCart'
import AddToCart from '../../entities/cart/AddToCart'
import Product from '../../entities/product/Product'
import Cart from '../../entities/cart/Cart'
import CartError from '../../entities/cart/CartError'
import ApiError from '../../entities/error/ApiError'
import CartUpdate from '../../entities/cart/CartUpdate'

const rehydrateCart = (state: CartState): CartState => {
  return {
    ...state,
    additionQueue: state.additionQueue.map((addToCart: IAddToCart) => new AddToCart(addToCart)),
    additionQueueProducts: state.additionQueueProducts.map((product) => new Product(product)),
    updateQueue: state.updateQueue.map(updateItem => new CartUpdate({
      ...updateItem,
      addToCart: new AddToCart(updateItem.addToCart),
    })),
    additionQueueErrors: state.additionQueueErrors.map(error => new CartError({
      error: new ApiError(error.error),
      addToCart: new AddToCart(error.addToCart),
    })),
    cart: new Cart(state.cart),
  }
}

export default rehydrateCart
