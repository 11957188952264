import React from 'react'
import { useHistory } from 'react-router'

import { IProps } from './IBackButton'
//import './BackButton.css'

/**
 * Back button
 *
 * @param props
 */
const BackButton:React.FC<IProps> = (props) => {

  const {
    isSearchVisible,
    isAutocompleteOpened,
    shouldSetSearchFocusOnInit,
    canGoBack,
    toggleSearch,
    closeSearchAutocomplete,
  } = props;

  const history = useHistory();

  const goBack = (event: React.MouseEvent) => {
    event.stopPropagation()
    history.goBack()
  }

  const isSearchAlwaysVisible = isSearchVisible && !shouldSetSearchFocusOnInit;
  const canCloseSearch        = !isSearchAlwaysVisible && isSearchVisible;
  const canCloseAutocomplete  = isAutocompleteOpened;

  if (
    !canCloseSearch &&
    !canGoBack &&
    !canCloseAutocomplete
  ) {
    return null
  }

  const handleBack = (event: React.MouseEvent) => {
    switch(true) {
      case canCloseSearch:
        toggleSearch()
        break;
      case canCloseAutocomplete:
        closeSearchAutocomplete()
        break;
      case canGoBack:
        goBack(event)
        break;
    }
  }

  return (
    <button
      onClick={event => handleBack(event)}
      className='b-back-button'
    >
      <BackIcon />
    </button>
  )
}

const BackIcon = require('react-feather/dist/icons/arrow-left').default;

export default BackButton
