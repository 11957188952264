import UiState from '../types/UiState'

import Language from '../../entities/language/Language'
import Currency from '../../entities/currency/Currency'

const rehydrateLayouts = (state: UiState): UiState => ({
  ...state,
  languages: state.languages.map(language => new Language(language)),
  currencies: state.currencies.map(currency => new Currency(currency)),
})

export default rehydrateLayouts
