import { defineMessages, Messages } from 'react-intl'

const messages: Messages = {
  offline: {
    id: 'app.components.navigation-bar.offline',
    defaultMessage: 'Offline',
  },
  offlineTitle: {
    id: 'app.components.navigation-bar.offline-title',
    defaultMessage: 'Slow or no internet connection',
  },
  offlineDescription: {
    id: 'app.components.navigation-bar.offline-description',
    defaultMessage: 'You can try reloading the page. Meanwhile, recently visited pages are still available for viewing.',
  },
}

const definedMessages = defineMessages(messages)

export default definedMessages
