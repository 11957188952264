
import React from 'react'

import Label from '../../subcomponents/label/Label'
import appendClassName from '../../../utils/string/appendClassName'

import { IProps } from './IWishlistButton'
import './WishlistButton.css'
import { NavLink } from 'react-router-dom'
import { createWishlistLinkUrl } from '../../../url'

/**
 * Wishlist button to toggle wishlist modal
 *
 * @param {IProps} props
 */
const WishlistButton: React.FC<IProps> = (props) => {

  const {
    amount,
    size,
    className,
    label,
  } = props;

  return (
    <NavLink
      to={{
        pathname: createWishlistLinkUrl(),
        state: {
          modal: true
        }
      }}
      className={appendClassName('b-wishlist-button', className)}
    >
      <div className='b-wishlist-button__icon-wrapper'>
        <HeartIcon size={size} />
        {
          !!amount
          &&
            <Label label={amount} />
        }
      </div>
      {
        label
        &&
          <p className='b-main-menu__button-label'>
            {label}
          </p>
      }
    </NavLink>
  );
}

export default WishlistButton

const HeartIcon = require('react-feather/dist/icons/heart').default;
