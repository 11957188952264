import { defineMessages, Messages } from 'react-intl'

const messages: Messages = {
  hamburger: {
    id: 'app.components.main-menu.labels.hamburger',
    defaultMessage: 'Catalog',
  },
  homeButton: {
    id: 'app.components.main-menu.labels.home-button',
    defaultMessage: 'Home',
  },
  wishlistButton: {
    id: 'app.components.main-menu.labels.wishlist-button',
    defaultMessage: 'Favorites',
  },
  profileButton: {
    id: 'app.components.main-menu.labels.profile-button',
    defaultMessage: 'Profile',
  },
  cartButton: {
    id: 'app.components.main-menu.labels.cart-button',
    defaultMessage: 'Cart',
  },
}

const definedMessages = defineMessages(messages)

export default definedMessages
