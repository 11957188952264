const replaceRelativeUrlsAtSrc = (text: string, path: string): string => {
  if (!path) {
    throw Error('Path argument is not specified')
  }

  if (!text) {
    return text
  }

  const baseUrl = new URL(path);

  return text.replace(/(<img.*?src=")(?!https?:\/\/)(.*?)"/gm, (matched, imageTag, imageSrc) => {
    const srcUrl = new URL(imageSrc, baseUrl);

    return `${imageTag}${srcUrl}"`
  })
}

export default replaceRelativeUrlsAtSrc
