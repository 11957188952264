const hashCode = (string: string): number => {

  if (!string.trim()) {
    // Set any string replace of nothing
    string = 'for5vmf';
  }

  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
}

export default hashCode
