import IArticleSeoSnippet from './ISeoSnippet'
import ISeoSnippet from '../seo/ISeoSnippet'

/**
 * Data for schema.org and html seo (title, meta description and etc.)
 */
class SeoSnippet implements IArticleSeoSnippet, ISeoSnippet {
  title:            string;
  metaDescription:  string;

  constructor(rawSeoSnippet: any) {
    const seoSnippet = {...rawSeoSnippet};

    if ('code' in seoSnippet) {
      // recreate from ISeoSnippet
      this.metaDescription  = seoSnippet.metaDescription;
    } else {
      // create from raw api data
      this.metaDescription = seoSnippet.meta_description || '';
    }

    this.title = seoSnippet.title;
  }
}

export default SeoSnippet
