import React from 'react'

import { ReactComponent as RingsSvg } from '../../../images/loader-icon.svg'

import { IOwnProps } from './ILoaderIcon'
import './LoaderIcon.css'
import '../../../../node_modules/animate.css/animate.min.css'

const LoaderIcon: React.FC<IOwnProps> = (props) => {
  let { isLoading } = props;

  if (isLoading === undefined) {
    isLoading = true;
  }

  return (
    isLoading
    ?
      <div className='b-loader d-flex justify-content-center'>
        <RingsSvg />
        <div className='loader-border' role='status'>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    :
      null
  );
}

export default LoaderIcon;
