import {
  RequestArticle,
  RequestArticleSuccess,
  RequestArticleFailure,
} from './RequestArticle'

import {
  DbRequestArticle,
  DbRequestArticleSuccess,
  DbRequestArticleFailure,
} from './DbRequestArticle'

import {
  RequestArticles,
  RequestArticlesSuccess,
  RequestArticlesFailure,
} from './RequestArticles'

import {
  DbRequestArticles,
  DbRequestArticlesSuccess,
  DbRequestArticlesFailure,
} from './DbRequestArticles'
import ArticleActionTypes from './ArticleActionTypes'
import Article from '../../../entities/article/Article'
import { persistArticle } from '../../../db/models/Article'

export * from './RequestArticle'
export * from './DbRequestArticle'
export * from './RequestArticles'
export * from './DbRequestArticles'

// Set current product
export interface SetCurrentArticle {
  type: ArticleActionTypes.SET_CURRENT_ARTICLE;
  payload: {
    article: Article;
  }
}

export function setCurrentArticle(rawArticle: any): SetCurrentArticle {

  const article = new Article(rawArticle);
  persistArticle(article)

  return {
    type: ArticleActionTypes.SET_CURRENT_ARTICLE,
    payload: {
      article,
    }
  }
}

export interface SetArticleIsRequesting {
  type: ArticleActionTypes.SET_ARTICLE_IS_REQUESTING;
  payload: {
    isRequesting: boolean;
  }
}

export function setArticleIsRequesting(isRequesting: boolean): SetArticleIsRequesting {

  return {
    type: ArticleActionTypes.SET_ARTICLE_IS_REQUESTING,
    payload: {
      isRequesting,
    }
  }
}


export type ArticleAction = SetCurrentArticle | SetArticleIsRequesting
| RequestArticle | RequestArticleSuccess | RequestArticleFailure
| RequestArticles | RequestArticlesSuccess | RequestArticlesFailure
| DbRequestArticle | DbRequestArticleSuccess | DbRequestArticleFailure
| DbRequestArticles | DbRequestArticlesSuccess | DbRequestArticlesFailure;
