import values from 'lodash/values'

import IProductFeature from './IProductFeature'
import IProductFeatureVariant from './IProductFeatureVariant'
import ProductFeatureVariant from './ProductFeatureVariant'

/**
 * Product feature entity
 */
class ProductFeature implements IProductFeature {
  id: number;
  name: string;
  description: string;
  position: number;
  prefix: string;
  value: any;
  variantId?: number; // only for types with variants
  variants?: Array<IProductFeatureVariant>; // only for types with variants

  type: string; // TODO convert to ENUM

  constructor(rawFeature: any) {
    const feature = {...rawFeature};

    if ('id' in feature) {
      this.id               = feature.id;
      this.name             = feature.name;
      this.description      = feature.description;
      this.position         = feature.position;
      this.prefix           = feature.prefix;
      this.value            = feature.value;
      this.variantId        = feature.variantId;
      if (feature.variants) {
        this.variants = feature.variants.map((variant: any) => new ProductFeatureVariant(variant));
      }
      this.type = feature.type;
    } else {
      this.id         = parseInt(feature.feature_id);
      this.variantId  = feature.variant_id ? parseInt(feature.variant_id) : undefined;
      this.position   = parseInt(feature.position || '0');

      this.name             = feature.description || '';
      this.description      = feature.full_description || '';
      this.prefix           = feature.prefix || '';
      this.value            = feature.value || '';
      this.type             = feature.feature_type;

      if (feature.variants) {
        this.variants = values(feature.variants).map((variant: any) => new ProductFeatureVariant(variant))
      }
    }

    if (!this.id) {
      throw new Error('Id parameter is missing. Check incoming object')
    }
  }
}

export default ProductFeature
