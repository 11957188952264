import IVendor from './IVendor'
import { createVendorLinkUrl } from '../../url'

class Vendor implements IVendor {
  id:             number;
  logo:           string;
  name:           string;
  description:    string;
  createdAt:      Date;
  averageRating:  number;
  address: {
    city:       string;
    address:    string;
    country:    string;
    state:      string;
    zipcode:    string;
  }
  slug: string;
  url:  string;

  constructor(rawVendor: any) {
    const vendor = {...rawVendor};

    if ('id' in vendor) {
      this.id             = vendor.id;
      this.slug           = vendor.slug;
      this.logo           = vendor.logo;
      this.name           = vendor.name;
      this.description    = vendor.description;
      this.createdAt      = vendor.createdAt;
      this.address        = vendor.address;
      this.averageRating  = vendor.averageRating;
      this.url            = vendor.url;
    } else {
      this.id             = parseInt(vendor.company_id);
      this.logo           = vendor.logo_url;
      this.slug           = vendor.seo_name;
      this.name           = vendor.company;
      this.description    = vendor.description;
      this.createdAt      = new Date(0);
      this.createdAt.setSeconds(parseInt(vendor.timestamp || '0'));
      this.averageRating  = parseFloat(vendor.average_rating || '0');
      this.address = {
        city: vendor.shipping_address.city,
        address: vendor.shipping_address.address,
        country: vendor.shipping_address.country,
        state: vendor.shipping_address.state,
        zipcode: vendor.shipping_address.zipcode,
      };
    }
    this.url = createVendorLinkUrl(this.slug);

    if (!this.id) {
      throw new Error('Id param is missing. Check incoming object')
    }
  }

}

export default Vendor
