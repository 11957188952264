import { map, switchMap, startWith, filter, auditTime, } from 'rxjs/operators'

import { ofType, ActionsObservable, StateObservable } from 'redux-observable'
import { interval, fromEvent, from, EMPTY, of } from 'rxjs'
import { AppAction, AppInit, setCanGoBack } from '../actions'
import { getLanguageFromUrl } from '../../url'
import { intl as i } from '../../intl/IntlGlobalProvider'

import config from '../../config/Config'
import cscartApi from '../../api/'
import {
  APP_INIT,
} from '../types/actions'
import {
  RequestStoreSettings,
  RequestStoreSettingsSuccess,
  SetLanguage,

  requestStoreSettings,
  requestStoreSettingsSuccess,
  requestStoreSettingsFailure,

  setCurrency,
  setInitialLanguage,
  readyToUpdate,
  updated,
  resize,
  setNotificationPermission,
  schedulerPerform,
} from '../actions/Ui'
import UiActionsTypes from '../types/actions/Ui'

import { showInformationMessage } from '../../utils/notifications/messages'
import { StoreState } from '../types'

/**
 * Request user permission for local notifications
 */
export const checkNotificationsPermissionEpic = (
  action$: ActionsObservable<AppInit>,
  state$: null,
  {
    asyncUtils: {
      requestNotificationPermission
    }
  }: {
    asyncUtils: {
      requestNotificationPermission: () => Promise<NotificationPermission>
    }
  }
) => action$.pipe(
  ofType(APP_INIT),
  filter(() => 'Notification' in global),
  switchMap(() =>
    from(requestNotificationPermission()).pipe(
      map(permission => setNotificationPermission(permission)),
    )
  ),
)

/**
 * Set language on init
 *
 * @param action$
 */
export const setLanguageOnInitEpic = (
  action$: ActionsObservable<AppInit>,
) =>
  action$.pipe(
    ofType(
      APP_INIT,
    ),
    map(() => setInitialLanguage(getLanguageFromUrl())),
  )

/**
 * Application scheduler
 *
 * @param action$
 */
export const setSchedulerOnInitEpic = (
    action$: ActionsObservable<AppInit>,
  ) =>
    action$.pipe(
      ofType(
        APP_INIT,
      ),
      switchMap(() =>
        interval(config.schedulerInterval).pipe(
          map(() => schedulerPerform())
        ),
      ),
    )

/**
 * Side effects when service workers ready to update
 *
 * @param action$
 * @param state$
 * @param param2
 */
export const readyToUpdateAppWithServiceWorkerEpic = (
  action$: null,
  state$: null,
  { intl }: { intl: () => typeof i }
) =>
  fromEvent(window, 'sw.ready-to-update')
  .pipe(
    map(() => {
      showInformationMessage(intl().formatMessage({ id: 'app.system.ready-to-update'}))

      return readyToUpdate()
    }),
  )

/**
 * Side effects whe service workers updated
 *
 * @param action$
 * @param state$
 * @param param2
 */
export const appIsUpdatedWithServiceWorkerEpic = (
  action$: null,
  state$: null,
  { intl }: { intl: () => typeof i }
) =>
  fromEvent(window, 'sw.updated')
  .pipe(
    map(() => {
      // showInformationMessage(intl().formatMessage({ id: 'app.system.updated'}))

      return updated()
    }),
  )

/**
 * Get on load and keep actual settings from a store
 *
 * @param action$
 */
export const requestStoreSettingsOnInitEpic = (
    action$: ActionsObservable<AppAction | SetLanguage>,
  ) =>
    action$.pipe(
      ofType<AppAction | SetLanguage>(
        APP_INIT,
        UiActionsTypes.SET_LANGUAGE,
      ),
      switchMap(() =>
        interval(config.storefrontSettingsLifetime).pipe(
          startWith(0),
          map(() => requestStoreSettings())
        ),
      ),
    )

/**
 * Requests store settings from API
 */
export const requestStoreSettingsEpic = (
  action$: ActionsObservable<RequestStoreSettings>,
  state$: null,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType(UiActionsTypes.REQUEST_STORE_SETTINGS),
  switchMap((action: RequestStoreSettings) =>
    from(
      api.settings.get()
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestStoreSettingsSuccess(result.data)
        :
          requestStoreSettingsFailure({
            status: result.status,
            message: result.message
          })
      ),
    )
  ),
)

/**
 * Set currency if not selected
 * @param action$
 */
export const setDefaultCurrencyInitEpic = (
  action$: ActionsObservable<RequestStoreSettingsSuccess>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(
      UiActionsTypes.REQUEST_STORE_SETTINGS_SUCCESS,
    ),
    filter(() => !state$.value.Ui.currency),
    switchMap(() => {
        const baseCurrency = state$.value.Ui.currencies.find(currency => currency.isBase);

        if (baseCurrency) {
          return of(setCurrency(baseCurrency.code))
        }

        return of({ type: EMPTY })
      }
    ),
  )

/**
 * Handle resize viewport
 */
export const resizeViewportEpic = () =>
  fromEvent(window, 'resize')
  .pipe(
    auditTime(config.windowResizeDebounceTime),
    map(() => resize(window.innerWidth)),
  )

/**
 * Handle history walking
 */
export const handleHistoryWalkEpic = () =>
  fromEvent<PopStateEvent>(window, 'popstate')
  .pipe(
    map(event => setCanGoBack(!!event.state)),
  )


export const requestNotificationPermission = (notification: typeof Notification = Notification): Promise<NotificationPermission> =>
  new Promise(resolve => {
    try {
      notification.requestPermission().then(result => resolve(result))
    } catch (error) {
      // Safari doesn't return a promise for requestPermissions and it
      // throws a TypeError. It takes a callback as the first argument
      // instead.
      if (error instanceof TypeError) {
          notification.requestPermission((result) => {
            resolve(result)
          });
      } else {
        throw error;
      }
    }
  })


export default [
  checkNotificationsPermissionEpic,

  setLanguageOnInitEpic,
  setSchedulerOnInitEpic,

  readyToUpdateAppWithServiceWorkerEpic,
  appIsUpdatedWithServiceWorkerEpic,

  requestStoreSettingsOnInitEpic,
  requestStoreSettingsEpic,
  setDefaultCurrencyInitEpic,
  resizeViewportEpic,
  handleHistoryWalkEpic,
]
