import React from 'react'

import { IProps } from './IHamburger'
import './Hamburger.css'

const Hamburger:React.FC<IProps> = (props) => {
  const {
    isOpened,
    open,
    close,
    size,
    className,
    onClick,
    label,
  } = props;

  const toggleMenu = () => {
    isOpened ? close() : open()
  }

  return (
    <button
      className={className}
      onClick={() => {
        onClick && onClick()
        toggleMenu()
      }}
    >
      {
        isOpened
        ?
          <XIcon size={size} />
        :
          <MenuIcon size={size} />
      }
      {
        label
        &&
          <p className='b-main-menu__button-label'>
            {label}
          </p>
      }
    </button>
  )
}

const XIcon = require('react-feather/dist/icons/x').default;
const MenuIcon = require('react-feather/dist/icons/menu').default;
export default Hamburger
