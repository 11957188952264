import values from 'lodash/values'

import config from '../../config/Config'
import IProduct from './IProduct'
import ProductFeature from './ProductFeature'
import SeoSnippet from './SeoSnippet'
import ImagePair from '../image-pair/ImagePair'
import Icon from '../image-pair/Icon'
import ProductOption from './ProductOption'
import ISelectedOption from './ISelectedOption'

import AddToCart from '../cart/AddToCart'
import replaceRelativeUrlsAtSrc from '../../utils/string/replaceRelativeUrlsAtSrc'
import { createProductLinkUrl, createCategoryLinkUrl } from '../../url'
import Parent from '../parent/Parent'
import IParent from '../parent/IParent'
import Vendor from '../vendor/Vendor'
import IVendor from '../vendor/IVendor'

class Product implements IProduct {
  id:               number;
  slug:             string;
  parents:          Array<Parent>;
  vendors?:         Array<Vendor>;
  name:             string;
  pageTitle:        string;
  shortDescription: string;
  description:      string;
  promoText:        string;
  code:             string;
  price:            number;
  basePrice:        number;
  listPrice:        number;
  averageRating:    number;
  position:         number;
  stockAmount:      number;
  amount:           number;
  amountStep:       number;
  minimumAmount:    number;
  popularity:       number;
  productFeatures:  Array<ProductFeature>;
  imagePairs:       Array<ImagePair>;
  mainPair:         ImagePair;
  seoSnippet:       SeoSnippet;
  options:          Array<ProductOption>;
  url:              string;

  constructor(rawProduct: IProduct | any) {
    const product = {...rawProduct};

    if ('id' in product) {
      this.id                 = product.id;
      this.slug               = product.slug;
      this.vendors            = product.vendors ? product.vendors.map((vendor: IVendor) => new Vendor(vendor)) : undefined;
      this.name               = product.name;
      this.shortDescription   = product.shortDescription;
      this.description        = product.description;
      this.promoText          = product.promoText;
      this.code               = product.product_code;
      this.price              = product.price;
      this.basePrice          = product.basePrice;
      this.listPrice          = product.listPrice;
      this.pageTitle          = product.pageTitle;
      this.averageRating      = product.averageRating;
      this.position           = product.position;
      this.amount             = product.amount;
      this.amountStep         = product.amountStep;
      this.stockAmount        = product.stockAmount;
      this.minimumAmount      = product.minimumAmount;
      this.popularity         = product.popularity;
      this.productFeatures    = product.productFeatures.map((feature: any) => new ProductFeature(feature));
      this.options            = (product.options || []).map((option: any) => new ProductOption(option));
      this.imagePairs         = product.imagePairs.map((image: any) => new ImagePair(image));
      this.parents            = (product.parents || []).map((parent: IParent) => new Parent(parent));
      this.mainPair           = new ImagePair(product.mainPair);
      this.seoSnippet         = new SeoSnippet(product.seoSnippet);
      this.url                = product.url;
    } else {
      this.id               = parseInt(product.product_id || '0');
      this.slug             = product.seo_name;
      this.vendors          = product.vendors ? product.vendors.map((vendor: any) => new Vendor(vendor)) : undefined;
      this.name             = product.product || '';
      this.shortDescription = product.short_description || '';
      this.description      = replaceRelativeUrlsAtSrc(product.full_description || '', config.backendSiteUrl);
      this.promoText        = product.promo_text || '';
      this.code             = product.product_code || '';
      this.price            = parseFloat(product.price);
      this.basePrice        = parseFloat(product.base_price);
      this.listPrice        = parseFloat(product.list_price);
      this.pageTitle        = product.page_title || '';
      this.averageRating    = parseFloat(product.average_rating || '0');
      this.position         = parseInt(product.position);
      this.minimumAmount    = parseInt(product.min_qty) || 1;
      this.amount           = parseInt(product.amount_total || '0') || this.minimumAmount;
      this.amountStep       = parseInt(product.qty_step || '1') || 1;
      this.stockAmount      = parseInt(product.amount);
      this.popularity       = parseInt(product.popularity || '0');
      this.productFeatures  = (values(product.product_features) || []).map((feature: any) => new ProductFeature(feature));
      this.options          = (values(product.product_options) || []).map((option: any) => new ProductOption(option));
      this.imagePairs       = (values(product.image_pairs) || []).map((image: any) => new ImagePair(image));
      this.parents          = (product.categories || []).map((parent: any) => new Parent({
        id:   parent.category_id,
        name: parent.category,
        url:  createCategoryLinkUrl(parent.seo_key)
      }));
      this.mainPair         = new ImagePair(product.main_pair || {});
      this.url              = createProductLinkUrl(this.slug);

      /**
       * Check for seo module enabled
       */
      this.seoSnippet       = new SeoSnippet({
        ...product.seo_snippet,
        title: product.page_title || this.name,
      });
    }

    if (!this.id) {
      throw new Error('Id param is missing. Check product object')
    }
  }

  get icons(): Array<Icon> {
    let icons: Array<Icon> = [];

    this.mainPair.icons.map((icon) => icons.push(icon))

    this.imagePairs.map((pair) =>
      pair.icons.map((icon) => icons.push(icon))
    )

    return icons
  }

  getIconsBySize(width: number, height: number): Array<Icon>|never {
    if (width <= 0 || height <= 0) {
      throw new RangeError(`Wrong dimension parameters are passed: ${width}x${height}`)
    }

    return this.icons.filter((icon: Icon) => icon.width === width && icon.height === height)
  }

  getSmallIcons(): Array<Icon> {

    return this.getIconsBySize(
      config.productSmallIconWidth,
      config.productSmallIconHeight
    )
  }

  getBigIcons(): Array<Icon> {

    return this.getIconsBySize(
      config.productBigIconWidth,
      config.productBigIconHeight
    )
  }

  getOption(optionId: number): ProductOption|undefined {
    return this.options.find(option => option.id === optionId)
  }

  /**
   * Updates option values for a product
   * Don't use at components!
   *
   * @param {number} optionId
   * @param {any} value
   */
  selectOption(optionId: number, value: any): boolean {
    const foundOption = this.getOption(optionId);

    if (foundOption) {
      foundOption.select(value)
      return true
    }

    return false
  }

  getSelectedOptions(): Array<ISelectedOption> {
    return this.options.map(option => ({
      optionId: option.id,
      value:    option.value
    }))
  }

  setAmount(amount: number): void {
    this.amount = amount;
  }

  getAddToCart(): AddToCart {
    return new AddToCart({
      productId:      this.id,
      productOptions: this.getSelectedOptions(),
      amount:         this.amount,
    })
  }
}

export default Product
