import IPayment from './IPayment'

class Payment implements IPayment {
  id:           number;
  name:         string;
  instructions: string;
  description:  string;
  templatePath: string;
  isOffline:    boolean;

  /**
   * set value = value from obj with IPayment interface || obj from cscart api || default value
   */
  constructor(rawPayment: any) {
    const payment = {...rawPayment};

    if ('id' in payment && 'templatePath' in payment) {
      this.id           = payment.id;
      this.name         = payment.name;
      this.description  = payment.description;
      this.instructions = payment.instructions;
      this.templatePath = payment.templatePath;
      this.isOffline    = payment.isOffline;
    } else {
      this.id           = parseInt(payment.id || payment.payment_id || '0');
      this.name         = payment.payment || '';
      this.description  = payment.description || '';
      this.instructions = payment.instructions || '';
      this.templatePath = payment.template;
      this.isOffline    = !payment.script;
    }

    if (!this.id) {
      throw new Error('Missing payment id')
    }
  }
}

export default Payment
