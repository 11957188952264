import values from 'lodash/values'
import _map from 'lodash/map'
import forOwn from 'lodash/forOwn'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { map, switchMap, filter, mergeMap } from 'rxjs/operators'
import { from, forkJoin, of } from 'rxjs'
import { ActionsObservable, StateObservable, ofType } from 'redux-observable'

import db from '../../db/models/'
import cscartApi from '../../api/'
import { AppAction, UiAction, CartAction, AuthAction, OrderAction } from '../actions'
import Product from '../../entities/product/Product'
import FormSection from '../../entities/form/FormSection'
import {
  removeFromDeleteQueue,
  removeFromAddQueue,
  removeFromUpdateQueue,
  removeFromCartErrors,
  addToCartErrors,

  requestCart,
  requestCartSuccess,
  requestCartFailure,

  resetCartState,
  requestCheckoutFormSuccess,
  requestCheckoutFormFailure,

  requestUpdateUserData,
  requestUpdateUserDataSuccess,
  requestUpdateUserDataFailure,
  SetUserData,
  RequestUpdateUserData,
  fillQueue,
  fillProductErrors,
  clearCart,
  RequestClearCart,
  requestClearCartSuccess,
  requestClearCartFailure,
  requestClearCart,
} from '../actions/Cart'
import { StoreState } from '../types'
import UiActionsTypes from '../types/actions/Ui'
import OrderActionsTypes from '../types/actions/Order'
import CartActionsTypes from '../types/actions/Cart'
import AuthActionsTypes from '../types/actions/Auth'
import { APP_INIT } from '../types/actions'
import AddToCart from '../../entities/cart/AddToCart'

/**
 * Request cart from api
 */
export const cartRequestEpic = (
  action$: ActionsObservable<CartAction>,
  state$: StateObservable<StoreState>,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType(
    CartActionsTypes.REQUEST_CART,
    CartActionsTypes.SET_SHIPPING_METHOD,
    CartActionsTypes.SET_PAYMENT_METHOD
  ),
  switchMap(() =>
    from(
      api.cart.getCart(state$.value.Cart.cart ? state$.value.Cart.cart.chosenShippings : [])
    ).pipe(
      map((result: any) =>

        result.data
        ?
          requestCartSuccess(result.data)
        :
          requestCartFailure({
            status: result.response ? result.response.status : 0,
            message: result.message || ''
          })
      ),
    )
  ),
)

/**
 * Execute requests to add products to cart
 */
export const executeAdditionQueueEpic = (
  action$: ActionsObservable<UiAction | CartAction | AuthAction>,
  state$: StateObservable<StoreState>,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType<UiAction | CartAction  | AuthAction>(
    UiActionsTypes.SCHEDULER_PERFORM,
    AuthActionsTypes.REQUEST_LOGIN_SUCCESS,
    AuthActionsTypes.REQUEST_SIGNUP_SUCCESS,
    CartActionsTypes.ADD_TO_ADD_QUEUE,
  ),
  // filter(() => state$.value.Auth.isLogged),
  filter(() => !!state$.value.Cart.additionQueue.length),
  filter(() => !state$.value.Cart.deletionQueue.length), // first delete from cart, then add
  // filter(() => !!state$.value.Cart.additionQueue.length),
  switchMap(() => {

    const requests = state$.value.Cart.additionQueue.map(addToCart => ({
      uuid: addToCart.uuid,
      request: api.cart.add(addToCart),
      addToCart,
    }))

    return forkJoin(
      mapValues(keyBy(requests, 'uuid'), 'request')
    )
    .pipe(
      mergeMap((results: any) => {
        const resultsArray = _map(results, (result, uuid) =>
          ({
            ...result,
            addToCart: requests.find(request => request.uuid === uuid)!.addToCart,
            uuid,
          })
        )

        // keep in queue only if request got offline status
        const resultsToBeRemovedFromQueue = resultsArray.filter(result => result.status !== 0);

        // remove from cart error if request is success
        const resultsToBeSuccess          = resultsArray.filter(result => result.status === 201);

        // notify about errors only if api responded with error status
        const resultsToBeErrors           = resultsArray.filter(result => result.status >= 300);

        return [
          requestCart(),
          ...resultsToBeRemovedFromQueue.map(result => removeFromAddQueue(result.addToCart)),
          ...resultsToBeErrors.map(result => addToCartErrors(result.addToCart, {
            status: result.status,
            message: result.response.data.message || ''
          })),
          ...resultsToBeSuccess.map(result => removeFromCartErrors(result.addToCart))
        ]
      }),
    )
  }
  ),
)

/**
 * Excecute requests to delete products from cart
 *
 * @param action$
 * @param state$
 */
export const executeDeletionQueueEpic = (
  action$: ActionsObservable<UiAction | CartAction>,
  state$: StateObservable<StoreState>,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType<UiAction | CartAction>(
    UiActionsTypes.SCHEDULER_PERFORM,
    CartActionsTypes.ADD_TO_DELETE_QUEUE,
  ),
  filter(() => !!state$.value.Cart.deletionQueue.length),
  switchMap(() => {

    const requests = state$.value.Cart.deletionQueue.map(cartId => ({
      cartId,
      request: api.cart.remove(cartId)
    }))

    return forkJoin(
      mapValues(keyBy(requests, 'cartId'), 'request')
    )
    .pipe(
      mergeMap((results: any) => {
        const resultsArray = _map(results, (result, cartId) =>
          ({
            ...result,
            cartId,
          })
        )

        // keep in queue only if request got offline status
        // if api respond with 404 error status, so the product already is missing in cart
        const resultsToBeRemovedFromQueue = resultsArray.filter(result => result.status !== 0);

        return [
          requestCart(),
          ...resultsToBeRemovedFromQueue.map(result => removeFromDeleteQueue(result.cartId))
        ]
      }),
    )
  }

  ),
)

/**
 * Excecute requests to update products at cart
 *
 * @param action$
 * @param state$
 */
export const executeUpdateQueueEpic = (
  action$: ActionsObservable<UiAction | CartAction>,
  state$: StateObservable<StoreState>,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType<UiAction | CartAction>(
    UiActionsTypes.SCHEDULER_PERFORM,
    CartActionsTypes.ADD_TO_UPDATE_QUEUE,
  ),
  switchMap(() => {

    const requests = state$.value.Cart.updateQueue.map(cartUpdate => ({
      cartId: cartUpdate.cartId,
      addToCart: cartUpdate.addToCart,
      request: api.cart.update(cartUpdate.cartId, cartUpdate.addToCart)
    }))

    return forkJoin(
      mapValues(keyBy(requests, 'cartId'), 'request')
    )
    .pipe(
      mergeMap((results: any) => {
        const resultsArray = _map(results, (result, cartId) =>
          ({
            ...result,
            addToCart: requests.find(request => request.cartId === cartId)!.addToCart,
            cartId,
          })
        )

        // keep in queue only if request got offline status
        const resultsToBeRemovedFromQueue = resultsArray.filter(result => result.status !== 0);

        const resultsToBeErrors           = resultsArray.filter(result => result.status >= 300);

        return [
          requestCart(),
          ...resultsToBeRemovedFromQueue.map(result => removeFromUpdateQueue(result.cartId)),
          ...resultsToBeErrors.map(result => addToCartErrors(result.addToCart, {
            status: result.status,
            message: result.response.data.message || ''
          }))
        ]
      }),
    )
  }

  ),
)

/**
 * Handle actions that should trigger cart update
 */
export const cartUpdateEpic = (
  action$: ActionsObservable<CartAction | AuthAction>
) => action$.pipe(
  ofType<CartAction | AuthAction>(
    CartActionsTypes.REQUEST_ADD_TO_CART_SUCCESS,
    AuthActionsTypes.REQUEST_LOGIN_SUCCESS,
    AuthActionsTypes.REQUEST_SIGNUP_SUCCESS,
  ),
  map(() => ({
    type: CartActionsTypes.REQUEST_CART
  })
  ),
)

/**
 * Handle actions that should trigger cart clear
 */
export const cartClearEpic = (
  action$: ActionsObservable<OrderAction>
) => action$.pipe(
  ofType(
    OrderActionsTypes.REQUEST_ORDER_CREATE_SUCCESS,
  ),
  map(() => requestClearCart()),
)

/**
 * Request api for cart clear
 */
export const requestClearCartEpic = (
  action$: ActionsObservable<RequestClearCart>,
  state$: null,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType(CartActionsTypes.REQUEST_CLEAR_CART),
  switchMap(() =>
    from(
      api.cart.clear()
    ).pipe(
      mergeMap((result: any) =>
        result.status === 204
        ?
          [
            requestClearCartSuccess(),
            clearCart()
          ]
        :
          [
            requestClearCartFailure({
              status: result.status,
              message: result.message,
            })
          ]
      ),
    )
  ),
)

/**
 * Fill addition queue by Array<Product> to get possibility show this products at UI
 */
export const fillAdditionQueueByProductsEpic = (
  action$: ActionsObservable<AppAction | CartAction>,
  state$: StateObservable<StoreState>,
  { indexedDb }: { indexedDb: typeof db }
) => action$.pipe(
  ofType<AppAction | CartAction>(
    CartActionsTypes.ADD_TO_ADD_QUEUE,
    APP_INIT,
    CartActionsTypes.REQUEST_ADD_TO_CART_SUCCESS,
  ),
  switchMap(() =>
    from(
      indexedDb
        .products
        .findNotDistinctProducts(
          state$.value.Cart.additionQueue.map(addToCart => addToCart.productId)
        )
    ).pipe(
      map(dbProducts => fillQueue(
        actualizeProductsByAddToCart(dbProducts, state$.value.Cart.additionQueue)
      )),
    )
  ),
)

/**
 * Fill error list by Array<Product> to get possibility show this products at UI
 */
export const fillCartErrorListByProductsEpic = (
  action$: ActionsObservable<AppAction | CartAction>,
  state$: StateObservable<StoreState>,
  { indexedDb }: { indexedDb: typeof db }
) => action$.pipe(
  ofType<AppAction | CartAction>(
    CartActionsTypes.ADD_TO_CART_ERRORS,
    CartActionsTypes.REMOVE_FROM_CART_ERRORS,
    APP_INIT,
  ),
  switchMap(() =>
    from(
      indexedDb
        .products
        .findNotDistinctProducts(
          state$.value.Cart.additionQueueErrors.map(cartError => cartError.addToCart.productId)
        )
    ).pipe(
      map(dbProducts => fillProductErrors(
        actualizeProductsByAddToCart(dbProducts, state$.value.Cart.additionQueueErrors.map(error => error.addToCart))
      )),
    )
  ),
)

/**
 * Handle actions that should trigger cart reset
 */
export const cartResetEpic = (
  action$: ActionsObservable<AuthAction>,
) => action$.pipe(
  ofType<AuthAction>(
    AuthActionsTypes.LOGOUT_SUCCESS,
  ),
  switchMap(() =>
    of(resetCartState())
  ),
)

/**
 * Request form scheme for order
 */
export const getCheckoutFormEpic = (
  action$: ActionsObservable<CartAction>,
  state$: StateObservable<StoreState>,
  {
    api
  }: {
    api: typeof cscartApi
  }
) =>
  action$.pipe(
    ofType(
      CartActionsTypes.REQUEST_CHECKOUT_FORM,
    ),
    switchMap(() =>
      from(
        api.profile.getOrderForm()
      ).pipe(
        map((result: any) =>
          result.data
          ?
            requestCheckoutFormSuccess(
              values(
                forOwn(result.data, (section, id) => {
                  section.id = id;
                })
              ).map(
                (section: any) => new FormSection(section)
              )
            )
          :
            requestCheckoutFormFailure()
        ),
      )
    ),
  )

/**
 * Request api to update user data at cart
 * if user data at redux state would update
 */
export const requestUpdateUserDataOnStateChangeEpic = (
  action$: ActionsObservable<SetUserData>
) =>
  action$.pipe(
    ofType<SetUserData>(
      CartActionsTypes.SET_USER_DATA,
    ),
    switchMap((action: SetUserData) =>
      of(requestUpdateUserData(action.payload.userData))
    ),
  )

/**
 * Send request to cart api to update user data
 */
export const requestUpdateUserDataEpic = (
  action$: ActionsObservable<RequestUpdateUserData>,
  state$: StateObservable<StoreState>,
  {
    api
  }: {
    api: typeof cscartApi
  }
) =>
  action$.pipe(
    ofType(
      CartActionsTypes.REQUEST_UPDATE_USER_DATA,
    ),
    switchMap((action: RequestUpdateUserData) =>
      {
        const requestPayload = action.payload.userData || state$.value.Cart.cart.userData;

        return from(
            api.cart.saveUserData(requestPayload!.toApiJson())
        ).pipe(
          map((result: any) =>
            result.data
            ?
              requestUpdateUserDataSuccess()
            :
              requestUpdateUserDataFailure({
                status: result.response ? result.response.status : 0,
                message: result.message || ''
              })
          ),
        )
      }
    ),
  )

export default [
  cartRequestEpic,
  cartUpdateEpic,
  cartClearEpic,
  cartResetEpic,
  requestClearCartEpic,

  executeAdditionQueueEpic,
  executeDeletionQueueEpic,
  executeUpdateQueueEpic,

  fillAdditionQueueByProductsEpic,
  fillCartErrorListByProductsEpic,

  getCheckoutFormEpic,

  requestUpdateUserDataOnStateChangeEpic,
  requestUpdateUserDataEpic,
]

/**
 * Pick expected options and amount at general product
 */
const actualizeProductsByAddToCart = (products: Array<Product>, addToCartArray: Array<AddToCart>) => {

  let actualProducts: Array<Product> = [];

  addToCartArray.forEach(addToCart => {
    const foundProduct = products.find(product => product.id === addToCart.productId);

    if (foundProduct) {
      (addToCart.productOptions || []).forEach(option => {
        foundProduct.selectOption(option.optionId, option.value)
      })
      foundProduct.setAmount(addToCart.amount)
      actualProducts.push(foundProduct)
    }

    products.shift()
  })

  return actualProducts
}
