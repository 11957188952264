enum ArticleActionTypes {
  REQUEST_ARTICLE          = 'REQUEST_ARTICLE',
  REQUEST_ARTICLE_SUCCESS  = 'REQUEST_ARTICLE_SUCCESS',
  REQUEST_ARTICLE_FAILURE  = 'REQUEST_ARTICLE_FAILURE',

  DB_REQUEST_ARTICLE          = 'DB_REQUEST_ARTICLE',
  DB_REQUEST_ARTICLE_SUCCESS  = 'DB_REQUEST_ARTICLE_SUCCESS',
  DB_REQUEST_ARTICLE_FAILURE  = 'DB_REQUEST_ARTICLE_FAILURE',

  REQUEST_ARTICLES          = 'REQUEST_ARTICLES',
  REQUEST_ARTICLES_SUCCESS  = 'REQUEST_ARTICLES_SUCCESS',
  REQUEST_ARTICLES_FAILURE  = 'REQUEST_ARTICLES_FAILURE',

  DB_REQUEST_ARTICLES          = 'DB_REQUEST_ARTICLES',
  DB_REQUEST_ARTICLES_SUCCESS  = 'DB_REQUEST_ARTICLES_SUCCESS',
  DB_REQUEST_ARTICLES_FAILURE  = 'DB_REQUEST_ARTICLES_FAILURE',

  SET_CURRENT_ARTICLE  = 'SET_CURRENT_ARTICLE',

  SET_ARTICLE_IS_REQUESTING  = 'SET_ARTICLE_IS_REQUESTING',
}

export default ArticleActionTypes
