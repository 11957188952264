import { createSelector } from 'reselect'
import { StoreState } from '../types'

const getCart = (state: StoreState) => 
  state.Cart

const getQueueProduct = (state: StoreState, props: any) =>
  state.Cart.additionQueue.find((queueItem) => queueItem.productId === props.product.id)

export const getProductError = (state: StoreState, props: any) =>
  state.Cart.additionQueueErrors.find(error => error.addToCart.isEqual(props.product.getAddToCart())) || null

export const getProductAmount = (state: StoreState, props: any) => {
  const foundProduct = state.Cart.cart.products.find(
    cartProduct => cartProduct.product.id === props.product.id
  )

  return foundProduct ? foundProduct.amount : 0;
}

export const getSelectedPayment = (state: StoreState) => {

  const foundPayment = state.Cart.cart.payments.find(
    payment => payment.id === state.Cart.cart.chosenPayment
  )

  if (!foundPayment) {
    throw new Error('Selected unavailable payment');
  }

  return foundPayment
}

export const countProductAtCartQueue = createSelector(
  [ getQueueProduct ],
  (queue) =>
    queue ? queue.amount : 0
)

export const getCartProducts = createSelector(
  [ getCart ],
  (cart) =>
    cart.cart.products
)

/**
 * Returns true if there are products in cart
 */
export const hasCartProducts = createSelector(
  [ getCartProducts ],
  (products) =>
    !!products.length
)
