import IPagination from "./IPagination";

class Pagination implements IPagination {
  total:  number;
  page:   number;
  limit:  number;

  constructor(rawPagination: any) {
    const pagination = {...rawPagination};

    if ('total' in pagination) {
      this.total  = pagination.total;
      this.page   = pagination.page || 1;
      this.limit  = pagination.limit;
    } else {
      this.total  = parseInt(pagination.total_items);
      this.page   = pagination.page ? parseInt(pagination.page) : 1;
      this.limit  = parseInt(pagination.items_per_page);
    }

    if (typeof this.limit === 'undefined') {
      throw new Error('Limit must be specified')
    }
  }

  nextPage() {
    return new Pagination({
      ...this,
      page: this.page + 1
    })
  }
}

export default Pagination
