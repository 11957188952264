import IAddress from './IAddress'

class Address implements IAddress {
  firstname: string;
  lastname: string;
  phone: string;
  address: string;
  address2: string;
  city: string;
  county: string;
  country: string;
  countryName: string;
  state: string;
  stateName: string;
  zipcode: string;

  constructor(rawAddress: IAddress | any) {
    const address = {...rawAddress};

    this.firstname  = address.firstname || '';
    this.lastname   = address.lastname || '';
    this.phone      = address.phone || '';
    this.address    = address.address || '';
    this.address2   = address.address2 || '';
    this.city       = address.city || '';
    this.county     = address.county || '';
    this.country    = address.country || '';
    this.countryName = address.countryName || '';
    this.state      = address.state || '';
    this.stateName  = address.stateName || '';
    this.zipcode    = address.zipcode || '';
  }
}

export default Address
