import IRedirectionDetails from './IRedirectionDetails'

/**
 * Information for opening in iframe payment gateway page
 */
class RedirectionDetails implements IRedirectionDetails {
  method:     'GET' | 'POST';
  url:        string;
  query:      string;
  successUrl: string;
  failureUrl: string;

  constructor(rawSettlementData: any) {
    const settlementData = {...rawSettlementData};

    if ('url' in settlementData) {
      this.url = settlementData.url;
      this.query = settlementData.query;
      this.successUrl = settlementData.successUrl;
      this.failureUrl = settlementData.failureUrl;
    } else {
      this.url = settlementData.payment_url;
      this.query = (settlementData.query_parameters || []).join('&');
      this.successUrl = settlementData.return_url;
      this.failureUrl = settlementData.cancel_url;
    }

    this.method = settlementData.method;

    if (!this.url) {
      throw Error('Url must be specified')
    }
  }
}

export default RedirectionDetails
