import { Component } from 'react'
import { InjectedIntl, InjectedIntlProps, injectIntl } from 'react-intl'

/**
 * Should only use this when not inside a React component (such as redux actions), see:
 * https://github.com/yahoo/react-intl/issues/416
 */
export let intl: InjectedIntl;
export const setIntl = (newIntl: InjectedIntl) => {
  intl = newIntl;
}

class IntlGlobalProvider extends Component<InjectedIntlProps> {
  constructor(props: any) {
    super(props);
    setIntl(this.props.intl);
  }

  public render() {
    return this.props.children;
  }
}

export default injectIntl(IntlGlobalProvider);
