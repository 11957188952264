import values from 'lodash/values'
import ILayout from './ILayout'
import LayoutTypes from '../../constants/LayoutTypes'
import notUndefined from '../../utils/notUndefined'
import Block from './Block'

class Layout implements ILayout {
  type:   LayoutTypes;
  blocks: Array<Block>;
  recievedAt: Date;

  constructor(rawLayout: any) {
    const layout = {...rawLayout};

    this.type = layout.type;

    this.blocks = (values(layout.blocks) || [])
      .map((block: any) => {
        try {
          return new Block(block)
        } catch (error) {
          console.warn(error)
          return undefined
        }
      })
      .filter(notUndefined);

    this.recievedAt = layout.recievedAt || new Date();

    if (!this.type) {
      throw new Error('Layout type is missing. Check incoming object')
    }
  }
}

export default Layout
