import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { IOwnProps } from './INotificationsProvider'
/**
 * Container for some notifications
 */
const NotificationsProvider: React.FC<IOwnProps> = () => {

  return <ToastContainer />
}

export default NotificationsProvider
