import uniqBy from 'lodash/uniqBy'

import { WishlistAction } from '../actions/Wishlist'
import WishlistState from '../types/WishlistState'
import WishlistActionTypes from '../types/actions/Wishlist'
import Product from '../../entities/product/Product'

export const initialState: WishlistState = {
  isWishlistRequesting: false,
  wishlist: {},
  queue: [],
  removeQueue: [],
}

let queue: Set<number>;
let removeQueue: Set<number>;

const Wishlist = function wishlist(state: WishlistState = initialState, action: WishlistAction): WishlistState {
  switch (action.type) {
    case WishlistActionTypes.ADD_TO_WISHLIST_QUEUE:
      queue = new Set(state.queue);
      queue.add(action.payload.product.id)

      return {
        ...state,
        queue: Array.from(queue)
      }

    case WishlistActionTypes.ADD_TO_WISHLIST_REMOVE_QUEUE:
      removeQueue = new Set(state.removeQueue);

      removeQueue.add(action.payload.product.id)
      return {
        ...state,
        wishlist: {
          ...state.wishlist,
          products: (state.wishlist.products || []).filter((product: Product) => product.id !== action.payload.product.id),
        },
        removeQueue: Array.from(removeQueue)
      }

    case WishlistActionTypes.REMOVE_FROM_WISHLIST_QUEUE:
      queue = new Set(state.queue);
      action.payload.productsIds.map((productId) => queue.delete(productId))

      return {
        ...state,
        queue: Array.from(queue),
      }
    case WishlistActionTypes.REQUEST_WISHLIST:
      return {
        ...state,
        isWishlistRequesting: true,
      }
    case WishlistActionTypes.REQUEST_CLEAR_WISHLIST:
      return {
        ...state,
        isWishlistRequesting: true,
      }
    case WishlistActionTypes.REQUEST_CLEAR_WISHLIST_SUCCESS:
      return {
        ...state,
        isWishlistRequesting: false,
      }
    case WishlistActionTypes.REQUEST_CLEAR_WISHLIST_FAILURE:
      return {
        ...state,
        isWishlistRequesting: false,
      }
    case WishlistActionTypes.REQUEST_WISHLIST_SUCCESS:
    case WishlistActionTypes.DB_REQUEST_WISHLIST_SUCCESS:
      return {
        ...state,
        isWishlistRequesting: false,
        wishlist: {
          ...action.payload.wishlist,
          products: uniqBy([
            ...(state.wishlist.products || []),
            ...(action.payload.wishlist.products || []),
          ], 'id').map((p: any) => {
            return p
          })
        }
      }
    case WishlistActionTypes.REQUEST_WISHLIST_FAILURE:
      return {
        ...state,
        isWishlistRequesting: false,
      }
    case WishlistActionTypes.CLEAR_WISHLIST:
      return {
        ...state,
        isWishlistRequesting: false,
        wishlist: {}
      }

    case WishlistActionTypes.RESET_WISHLIST_STATE:
      return initialState

    default:
      return state
  }
}

export default Wishlist
