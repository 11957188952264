import values from 'lodash/values'
import forOwn from 'lodash/forOwn'

import AuthActionsTypes from '../types/actions/Auth'
import Profile from '../../entities/auth/Profile'
import FormSection from '../../entities/form/FormSection'
import ApiError from '../../entities/error/ApiError'
import AuthProviders from '../../constants/AuthProviders'

export interface RequestLogin {
  type: AuthActionsTypes.REQUEST_LOGIN;
  payload: {
    email:    string;
    password: string;
    provider: AuthProviders.EMAIL_PASSWORD,
  }
}

export function requestLogin(email: string, password: string): RequestLogin {
  return {
    type: AuthActionsTypes.REQUEST_LOGIN,
    payload: {
      email:    email,
      password: password,
      provider: AuthProviders.EMAIL_PASSWORD,
    }
  }
}

export interface RequestLoginSuccess {
  type: AuthActionsTypes.REQUEST_LOGIN_SUCCESS;
  payload: {
    token:    string;
    provider: AuthProviders;
  }
}

export function requestLoginSuccess(token: string, provider: AuthProviders): RequestLoginSuccess {
  return {
    type: AuthActionsTypes.REQUEST_LOGIN_SUCCESS,
    payload: {
      token,
      provider,
    }
  }
}

export interface RequestLoginFailure {
  type: AuthActionsTypes.REQUEST_LOGIN_FAILURE;
  payload: {
    error: ApiError;
  }
}

export function requestLoginFailure(error: any): RequestLoginFailure {
  const apiError = new ApiError(error);

  return {
    type: AuthActionsTypes.REQUEST_LOGIN_FAILURE,
    payload: {
      error: apiError,
    }
  }
}

// request social login verification
export interface RequestSocialLogin {
  type: AuthActionsTypes.REQUEST_SOCIAL_LOGIN;
  payload: {
    provider: AuthProviders;
    idToken:  string;
    clientId: string;
  }
}

export function requestSocialLogin(provider: AuthProviders, idToken: string, clientId: string): RequestSocialLogin {
  return {
    type: AuthActionsTypes.REQUEST_SOCIAL_LOGIN,
    payload: {
      provider,
      idToken,
      clientId,
    }
  }
}

// ---

export interface SetToken {
  type: AuthActionsTypes.SET_TOKEN;
  payload: {
    token: string
  }
}

export function setToken(token?: string): SetToken {
  return {
    type: AuthActionsTypes.SET_TOKEN,
    payload: {
      token: token || '',
    }
  }
}

// --
export interface Logout {
  type: AuthActionsTypes.LOGOUT;

}

export function logout(): Logout {
  return {
    type: AuthActionsTypes.LOGOUT,
  }
}

export interface LogoutSuccess {
  type: AuthActionsTypes.LOGOUT_SUCCESS;

}

export function logoutSuccess(): LogoutSuccess {
  return {
    type: AuthActionsTypes.LOGOUT_SUCCESS,
  }
}

// --
export interface RequestProfile {
  type: AuthActionsTypes.REQUEST_PROFILE;
}

export function requestProfile(): RequestProfile {
  return {
    type: AuthActionsTypes.REQUEST_PROFILE,
  }
}

export interface RequestProfileSuccess {
  type: AuthActionsTypes.REQUEST_PROFILE_SUCCESS;
  payload: {
    profile: Profile
  }
}

export function requestProfileSuccess(profile: any): RequestProfileSuccess {
  return {
    type: AuthActionsTypes.REQUEST_PROFILE_SUCCESS,
    payload: {
      profile: new Profile(profile)
    }
  }
}

export interface RequestProfileFailure {
  type: AuthActionsTypes.REQUEST_PROFILE_FAILURE;
  payload: {
    error: ApiError;
  }
}

export function requestProfileFailure(error: any): RequestProfileFailure {
  const apiError = new ApiError(error);

  return {
    type: AuthActionsTypes.REQUEST_PROFILE_FAILURE,
    payload: {
      error: apiError,
    }
  }
}

// --
export interface RequestSignupForm {
  type: AuthActionsTypes.REQUEST_SIGNUP_FORM;
}

export function requestSignupForm(): RequestSignupForm {
  return {
    type: AuthActionsTypes.REQUEST_SIGNUP_FORM,
  }
}

export interface RequestSignupFormSuccess {
  type: AuthActionsTypes.REQUEST_SIGNUP_FORM_SUCCESS;
  payload: {
    sections: Array<FormSection>
  }
}

export function requestSignupFormSuccess(rawSections: any): RequestSignupFormSuccess {
  const sections = values(
    forOwn(rawSections, (section, id) => {
      section.id = id;
    })
  ).map((section: any) => new FormSection(section))

  return {
    type: AuthActionsTypes.REQUEST_SIGNUP_FORM_SUCCESS,
    payload: {
      sections,
    }
  }
}

export interface RequestSignupFormFailure {
  type: AuthActionsTypes.REQUEST_SIGNUP_FORM_FAILURE;
  payload: {
    error: ApiError;
  }
}

export function requestSignupFormFailure(error: any): RequestSignupFormFailure {
  const apiError = new ApiError(error);

  return {
    type: AuthActionsTypes.REQUEST_SIGNUP_FORM_FAILURE,
    payload: {
      error: apiError,
    }
  }
}

// --
export interface RequestSignup {
  type: AuthActionsTypes.REQUEST_SIGNUP;
  payload: {
    profile: any;
  }
}

export function requestSignup(profile: any): RequestSignup {
  return {
    type: AuthActionsTypes.REQUEST_SIGNUP,
    payload: {
      profile
    }
  }
}

export interface RequestSignupSuccess {
  type: AuthActionsTypes.REQUEST_SIGNUP_SUCCESS;
  payload: {
    token: string;
  }
}

export function requestSignupSuccess(token: string): RequestSignupSuccess {
  return {
    type: AuthActionsTypes.REQUEST_SIGNUP_SUCCESS,
    payload: {
      token
    }
  }
}

export interface RequestSignupFailure {
  type: AuthActionsTypes.REQUEST_SIGNUP_FAILURE;
  payload: {
    error: ApiError;
  }
}

export function requestSignupFailure(error: any): RequestSignupFailure {
  const apiError = new ApiError(error);

  return {
    type: AuthActionsTypes.REQUEST_SIGNUP_FAILURE,
    payload: {
      error: apiError,
    }
  }
}

export type AuthAction = RequestLogin | RequestSocialLogin  | RequestLoginSuccess | RequestLoginFailure
| RequestProfile | RequestProfileSuccess | RequestProfileFailure
| Logout | LogoutSuccess | SetToken
| RequestSignupForm | RequestSignupFormSuccess | RequestSignupFormFailure
| RequestSignup | RequestSignupSuccess | RequestSignupFailure;
