/**
 * name = dispatch keyword at block manager of cscart
 */
enum LayoutTypes {
  DEFAULT   = 'default',
  CATEGORY  = 'categories.view',
  PRODUCT   = 'products.view',
  ARTICLE   = 'pages.view',
  WISHLIST  = 'wishlist',
  MENU      = 'Menu',
  HOME      = 'index',
  DRAWER    = 'drawer',
  CART      = 'checkout.cart',
  CHECKOUT  = 'checkout',
  CHECKOUT_COMPLETE = 'checkout.complete',
  PROFILE   = 'profiles',
  SIGNUP    = 'signup',
  SIGNIN    = 'signin',
  ORDERS    = 'orders',
  ORDER     = 'orders.details',
  VENDORS   = 'companies.catalog',
  VENDOR    = 'companies.view',
  VENDOR_SIGNUP = 'companies.signup',
  SEARCH    = 'search',
}

export default LayoutTypes
