import Order from './Order'
import AbstractItemsSelection from '../selection/AbstractItemsSelection'
import Sorting from '../selection/Sorting'
import IOrderSelection from './IOrderSelection'

/**
 * Orders selection
 */
class OrderSelection extends AbstractItemsSelection implements IOrderSelection {
  orders:       Array<Order>;
  filters:        null;

  constructor(rawOrderSelection: any) {
    const orderSelection = {...rawOrderSelection};
    super(orderSelection)

    this.orders   = (orderSelection.orders || []).map((order: any) => new Order(order));
  }

  prependItems(orders: Array<Order>): OrderSelection {
    return new OrderSelection({
      ...this,
      orders: [
        ...orders,
        ...this.orders,
      ]
    })
  }

  fromUrl(url: string): OrderSelection|undefined {
    const urlParams = new URLSearchParams(url);
    let isEmpty = true;

    if (urlParams.get('sortBy')) {
      this.sorting = new Sorting({
        sortBy: urlParams.get('sortBy'),
        order: urlParams.get('order'),
      });
      isEmpty = false;
    }

    if (urlParams.get('q')) {
      this.search = urlParams.get('q') || '';
      isEmpty = false;
    }

    if (isEmpty) {
      return undefined
    } else {
      return this
    }
  }
}

export default OrderSelection
