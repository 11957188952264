import ArticleActionTypes from './ArticleActionTypes'
import ApiError from '../../../entities/error/ApiError'
import { persistArticle } from '../../../db/models/Article'
import ArticleSelection from '../../../entities/article/ArticleSelection'

export interface RequestArticles {
  type: ArticleActionTypes.REQUEST_ARTICLES;
  payload: {
    parentId:       number;
    selection?:     ArticleSelection;
    shouldAppend?:  boolean;
  }
}

export function requestArticles(
  id: number,
  selection?: ArticleSelection,
  shouldAppend?:  boolean
): RequestArticles {
  return {
    type: ArticleActionTypes.REQUEST_ARTICLES,
    payload: {
      parentId: id,
      selection,
      shouldAppend,
    }
  }
}

export interface RequestArticlesSuccess {
  type: ArticleActionTypes.REQUEST_ARTICLES_SUCCESS;
  payload: {
    selection:    ArticleSelection;
    shouldAppend: boolean;
  }
}

export function requestArticlesSuccess(rawArticles: Array<any>, rawParams: any = {}, shouldAppend: boolean = false): RequestArticlesSuccess {

  const selection = new ArticleSelection({
    articles: rawArticles,
    pagination: rawParams,
    sorting: rawParams,
    search: rawParams.q,
  });

  selection.articles.forEach(article => persistArticle(article))

  return {
    type: ArticleActionTypes.REQUEST_ARTICLES_SUCCESS,
    payload: {
      selection,
      shouldAppend,
    }
  }
}

export interface RequestArticlesFailure {
  type: ArticleActionTypes.REQUEST_ARTICLES_FAILURE;
  payload: {
    categoryId:  number;
    error:        ApiError;
  }
}

export function requestArticlesFailure(id: number, error: any): RequestArticlesFailure {
  const apiError = new ApiError({
    status: error.status || 0,
    shouldRetry: error.shouldRetry || false,
    message: error.message || 'Unhandled error'
  })

  return {
    type: ArticleActionTypes.REQUEST_ARTICLES_FAILURE,
    payload: {
      categoryId: id,
      error: apiError
    }
  }
}
