enum NavigationBarActionsTypes {
  CONFIGURE_NAVIGATION_BAR = 'CONFIGURE_NAVIGATION_BAR',
  RESET_NAVIGATION_BAR     = 'RESET_NAVIGATION_BAR',

  TOGGLE_SEARCH = 'TOGGLE_SEARCH',

  SET_CAN_GO_BACK = 'SET_CAN_GO_BACK',
}

export default NavigationBarActionsTypes
