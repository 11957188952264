import { generatePath } from 'react-router'
import { LinkProps} from 'react-router-dom'
import memoize from 'lodash/memoize'

import config from '../config/Config'

export enum UrlEntities {
  HOMEPAGE    = 'HOMEPAGE',
  DRAWER            = 'DRAWER',
  PROFILE           = 'PROFILE',
  WISHLIST          = 'WISHLIST',
  CART              = 'CART',
  CHECKOUT          = 'CHECKOUT',
  CHECKOUT_COMPLETE = 'CHECKOUT_COMPLETE',
  LOGIN             = 'LOGIN',
  REGISTRATION      = 'REGISTRATION',
  PRODUCTS          = 'PRODUCTS',
  PRODUCT           = 'PRODUCT',
  CATEGORIES        = 'CATEGORIES',
  CATEGORY          = 'CATEGORY',
  ARTICLES          = 'ARTICLES',
  ARTICLE           = 'ARTICLE',
  VENDORS           = 'VENDORS',
  VENDOR            = 'VENDOR',
  VENDOR_REGISTRATION = 'VENDOR_REGISTRATION',
  NOT_FOUND         = 'NOT_FOUND',
  ORDERS            = 'ORDERS',
  ORDER             = 'ORDER',
  SEARCH            = 'SEARCH',
  ONLINE_PAYMENT    = 'ONLINE_PAYMENT',
}

export const createLinkUrl = (
  itemType: UrlEntities,
  params: any = {},
) =>
  generatePath(
    createRouteUrl(itemType),
    {
      ...params,
      language: getLanguageFromUrlMemoized(getLocationPathname())
    }
  )

/**
 * Prepare route url with params like :language and :itemId
 *
 * @param itemType
 */
export const createRouteUrl = (
  itemType: UrlEntities,
) => {
  const language = ':language([a-z]{2})';

  switch (itemType) {
    case UrlEntities.HOMEPAGE:
      return `/${language}/`

    case UrlEntities.DRAWER:
      return `/${language}/m/drawer`

    case UrlEntities.CATEGORY:
      return `/${language}/catalog/:itemId`

    case UrlEntities.ARTICLE:
      return `/${language}/articles/:itemId`

    case UrlEntities.PRODUCT:
      return `/${language}/products/:itemId`

    case UrlEntities.NOT_FOUND:
      return `/${language}/404`

    case UrlEntities.PROFILE:
      return `/${language}/my/profile`

    case UrlEntities.WISHLIST:
      return `/${language}/my/wishlist`

    case UrlEntities.CART:
      return `/${language}/my/cart`

    case UrlEntities.CHECKOUT:
      return `/${language}/my/cart/checkout`

    case UrlEntities.CHECKOUT_COMPLETE:
      return `/${language}/my/cart/checkout/complete/:itemId`

    case UrlEntities.LOGIN:
      return `/${language}/my/login`

    case UrlEntities.REGISTRATION:
      return `/${language}/my/signup`

    case UrlEntities.ORDERS:
      return `/${language}/my/profile/orders`

    case UrlEntities.ORDER:
        return `/${language}/my/profile/orders/:itemId`

    case UrlEntities.VENDORS:
      return `/${language}/vendors/`

    case UrlEntities.VENDOR:
      return `/${language}/vendors/:itemId`

    case UrlEntities.VENDOR_REGISTRATION:
      return `/${language}/vendors/signup`

    case UrlEntities.SEARCH:
      return `/${language}/search/`
  }

  throw new Error(`There is no such 'itemType': ${itemType}`)
}

// Route address creation
export const createHomepageRouteUrl = () =>
  createRouteUrl(UrlEntities.HOMEPAGE)

export const createDrawerRouteUrl = () =>
  createRouteUrl(UrlEntities.DRAWER)

export const createCategoryRouteUrl = () =>
  createRouteUrl(UrlEntities.CATEGORY)

export const createArticleRouteUrl = () =>
  createRouteUrl(UrlEntities.ARTICLE)

export const createProductRouteUrl = () =>
  createRouteUrl(UrlEntities.PRODUCT)

export const createNotFoundRouteUrl = () =>
  createRouteUrl(UrlEntities.NOT_FOUND)

export const createProfileRouteUrl = () =>
  createRouteUrl(UrlEntities.PROFILE)

export const createWishlistRouteUrl = () =>
  createRouteUrl(UrlEntities.WISHLIST)

export const createCartRouteUrl = () =>
  createRouteUrl(UrlEntities.CART)

export const createCheckoutRouteUrl = () =>
  createRouteUrl(UrlEntities.CHECKOUT)

export const createCheckoutCompleteRouteUrl = () =>
  createRouteUrl(UrlEntities.CHECKOUT_COMPLETE)

export const createLoginRouteUrl = () =>
  createRouteUrl(UrlEntities.LOGIN)

export const createRegistrationRouteUrl = () =>
  createRouteUrl(UrlEntities.REGISTRATION)

export const createOrdersRouteUrl = () =>
  createRouteUrl(UrlEntities.ORDERS)

export const createOrderRouteUrl = () =>
  createRouteUrl(UrlEntities.ORDER)

export const createVendorsRouteUrl = () =>
  createRouteUrl(UrlEntities.VENDORS)

export const createVendorRouteUrl = () =>
  createRouteUrl(UrlEntities.VENDOR)

export const createVendorSignupRouteUrl = () =>
  createRouteUrl(UrlEntities.VENDOR_REGISTRATION)

export const createSearchRouteUrl = () =>
  createRouteUrl(UrlEntities.SEARCH)

// Link creation
export const createHomepageLinkUrl = () =>
  createLinkUrl(UrlEntities.HOMEPAGE)

export const createDrawerLinkUrl = () =>
  createLinkUrl(UrlEntities.DRAWER)

export const createCategoryLinkUrl = (slug: string) =>
  createLinkUrl(UrlEntities.CATEGORY, { itemId: slug })

export const createArticleLinkUrl = (slug: string) =>
  createLinkUrl(UrlEntities.ARTICLE, { itemId: slug })

export const createProductLinkUrl = (slug: string) =>
  createLinkUrl(UrlEntities.PRODUCT, { itemId: slug })

export const createNotFoundLinkUrl = () =>
  createLinkUrl(UrlEntities.NOT_FOUND)

export const createProfileLinkUrl = () =>
  createLinkUrl(UrlEntities.PROFILE)

export const createWishlistLinkUrl = () =>
  createLinkUrl(UrlEntities.WISHLIST)

export const createCartLinkUrl = () =>
  createLinkUrl(UrlEntities.CART)

export const createCheckoutLinkUrl = () =>
  createLinkUrl(UrlEntities.CHECKOUT)

export const createCheckoutCompleteLinkUrl = (id: number) =>
  createLinkUrl(UrlEntities.CHECKOUT_COMPLETE, { itemId: id })

export const createLoginLinkUrl = () =>
  createLinkUrl(UrlEntities.LOGIN)

export const createRegistrationLinkUrl = () =>
  createLinkUrl(UrlEntities.REGISTRATION)

export const createOrdersLinkUrl = () =>
  createLinkUrl(UrlEntities.ORDERS)

export const createOrderLinkUrl = (id: number) =>
  createLinkUrl(UrlEntities.ORDER, { itemId: id })

export const createVendorsLinkUrl = () =>
  createLinkUrl(UrlEntities.VENDORS)

export const createVendorLinkUrl = (slug: string) =>
  createLinkUrl(UrlEntities.VENDOR, { itemId: slug })

export const createVendorSignupLinkUrl = () =>
  createLinkUrl(UrlEntities.VENDOR_REGISTRATION)

export const createSearchLinkUrl = () =>
createLinkUrl(UrlEntities.SEARCH)

/**
 * Creates new to object with language param substituted
 *
 * @returns LinkProps['to']
 */
export const rewriteTo = (
  {
    to,
    language
  }: {
    to: LinkProps['to'],
    language: string
  },
) => {
  let newTo: LinkProps['to'] = '';

  if (typeof to === 'string') {
    newTo = generatePath(to, { language });
  } else if (typeof to === 'object' && to.pathname) {
    newTo = { ...to, pathname: generatePath(to.pathname, { language })};
  }

  if (!newTo) {
    throw new Error('Looks like param `to` is invalid')
  }

  return newTo
}

export const getLanguageFromUrl = (pathname: string = getLocationPathname()) => {
  const language = pathname.match(/^\/(\w{2})(\/|$)/);

  if (language && language.length) {
    return language[1]
  } else {
    return config.defaultLanguage
  }
  // if (navigator && navigator.language) {
  //   return navigator.language
  // }
}

const getLanguageFromUrlMemoized = memoize(getLanguageFromUrl);

export const getLocationPathname = () => {
  return window.location.pathname
}

export const getUrlWithNewLanguage = (url: string, language: string): string =>
  url.replace(/^(\/)(\w{2})(\/|$)/, (match, p1, p2, p3) => `${p1}${language}${p3}`)

/**
 * Check if url is external
 *
 * @param {string} url
 *
 * @returns true if link is external
 */
export const isUrlExternal = (url: string, host: string = window.location.host) =>
  !isUrlInternal(url, host)

/**
 * Check if url is internal
 *
 * @param {string} url
 *
 * @returns true if link is internal
 */
export const isUrlInternal = (url: string, host: string = window.location.host) =>
  url.startsWith('/') || (new RegExp(`^(http|https)://${host}`)).test(url)

/**
 * Convert absolute url to relative
 *
 * @example convertAbsoluteUrlToRelative('http://google.com/catalog/')
 *
 *
 * @param {string} url - url to convert
 *
 * @returns string - relative url
 */
export const convertAbsoluteUrlToRelative = (url: string) =>
  url.replace(/^(?:\/\/|[^/]+)*\//, '/')
