import React, { ImgHTMLAttributes } from 'react'

import noInternetImage from '../../../images/no-internet-2.png'
import { IOwnProps } from './IImage'

const preloadImage  = new Image();
preloadImage.src    = noInternetImage;



/**
 * Default html img element
 * but with no-internet placeholder
 */
class ImageP extends React.Component<IOwnProps & ImgHTMLAttributes<HTMLImageElement>> {
  imgRef: HTMLImageElement | null = null;

  componentDidMount() {
    if (this.imgRef && !this.props.noPlaceholder) {
      this.imgRef.addEventListener('error', replaceSrc)
    }
  }

  componentWillUnmount() {
    if (this.imgRef && !this.props.noPlaceholder) {
      this.imgRef.removeEventListener('error', replaceSrc)
    }
  }

  render() {
    return (
      <img
        {...this.props} // todo exclude duplicate for alt prop
        alt={this.props.alt}
        ref={ img => (this.imgRef = img)}
      />
    )
  }
}

const replaceSrc = (event: any) => {
  if (event.target && event.target.src !== noInternetImage) {
    event.target.src = noInternetImage;
    // event.target.removeEventListener('error', replaceSrc)
  }
}

export default ImageP
