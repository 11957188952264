import cscartApi from './cscart-api'

export const getOrderForm = () =>
  cscartApi.profile.form().forUpdateOrder().get()

export const getSignupForm = () =>
  cscartApi.profile.form().forAddProfile().get()

export const getProfile = () =>
  cscartApi.profile.get()

export const createProfile = (profile: any) =>
  cscartApi.profile.create(profile)
