import React from 'react'
import { FormattedMessage } from 'react-intl'

import useLocationChange from '../../../hooks/use-location-change/useLocationChange'

import { IProps } from './IGdprAgreement'
import './GdprAgreement.css'
import messages from './GdprAgreement.messages'

const GdprAgreement:React.FC<IProps> = props => {
  const {
    isGdprAccepted,
    acceptGdpr,
  } = props;

  const isLocationChanged = useLocationChange();

  React.useEffect(() => {
    if (isLocationChanged) {
      acceptGdpr()
    }
  }, [isLocationChanged, acceptGdpr])

  if (isGdprAccepted) {
    return null
  }

  return (
    <article className='b-gdpr-agreement'>
      <header className='b-gdpr-agreement__header'>
        <h1>
          <FormattedMessage {...messages.title} />
        </h1>
      </header>
      <p>
        <FormattedMessage {...messages.description} />
      </p>
      <p>
        <FormattedMessage {...messages.conditions} />
      </p>
      <footer className='b-gdpr-agreement__footer'>
        <button
          className='b-button b-button--secondary b-gdpr-agreement__accept-button'
          onClick={() => acceptGdpr()}
        >
          <FormattedMessage {...messages.acceptButton} />
        </button>
      </footer>
    </article>
  )
}

export default GdprAgreement
