import { UiAction } from '../actions/Ui'
import UiActionTypes from '../types/actions/Ui'
import UiState from '../types/UiState'
import { getLanguageFromUrl } from '../../url'
import getMatchedDeviceType from '../../utils/responsive/getMatchedDeviceType'

export const initialState: UiState = {
  loading: true,
  online: true,
  language: getLanguageFromUrl(),
  currency: '',
  languages: [],
  currencies: [],
  deviceType: getMatchedDeviceType(window.innerWidth),
  openedDrawers: [],
  notificationPermission: 'not-available',
  properties: {},
  isGdprAccepted: false,
}

const Ui = function ui(state: UiState = initialState, action: UiAction): UiState {
  switch (action.type) {
    case UiActionTypes.SET_LANGUAGE:
    case UiActionTypes.SET_INITIAL_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
      }

    case UiActionTypes.SET_CURRENCY:
      return {
        ...state,
        currency: action.payload.currency,
      }

    case UiActionTypes.SET_NOTIFICATION_PERMISSION:
      return {
        ...state,
        notificationPermission: action.payload.permission,
      }

    case UiActionTypes.REQUEST_STORE_SETTINGS_SUCCESS:
      return {
        ...state,
        currencies: action.payload.currencies,
        languages:  action.payload.languages,
        properties: action.payload.properties,
      }

    case UiActionTypes.RESIZE:
      return {
        ...state,
        deviceType: action.payload.matchDeviceMedia,
      }

    case UiActionTypes.OPEN_DRAWER:
    case UiActionTypes.CLOSE_DRAWER:

      const openedDrawers = new Set(state.openedDrawers);
      action.type === UiActionTypes.OPEN_DRAWER
      ? openedDrawers.add(action.payload.id)
      : openedDrawers.delete(action.payload.id)

      return {
        ...state,
        openedDrawers: Array.from(openedDrawers),
      }

    case UiActionTypes.CLOSE_ALL_DRAWERS:
      return {
        ...state,
        openedDrawers: [],
      }

    case UiActionTypes.ACCEPT_GDPR:
      return {
        ...state,
        isGdprAccepted: true,
      }

    default:
      return state;
  }
}

export default Ui
