enum FieldTypes {
  EMAIL           = 'email',
  PASSWORD        = 'password',
  SELECTBOX       = 'select',
  SLAVE_SELECTBOX = 'slave-select',
  CHECKBOX        = 'checkbox',
  TEXT            = 'text',
  MULTILINE_TEXT  = 'multiline-text',
  DATE      = 'D',
  // CHECKBOX  = 'C',
  RADIO     = 'R',
  // TEXT      = 'I',
  COUNTRY   = 'O',
  STATE     = 'A',
}

export default FieldTypes
