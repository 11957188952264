enum LayoutsActionsTypes {
  GET_LAYOUT = 'GET_LAYOUT',

  REQUEST_BLOCKS         = 'REQUEST_BLOCKS',
  REQUEST_BLOCKS_SUCCESS = 'REQUEST_BLOCKS_SUCCESS',
  REQUEST_BLOCKS_FAILURE    = 'REQUEST_BLOCKS_FAILURE',

  DB_REQUEST_BLOCKS         = 'DB_REQUEST_BLOCKS',
  DB_REQUEST_BLOCKS_SUCCESS = 'DB_REQUEST_BLOCKS_SUCCESS',
  DB_REQUEST_BLOCKS_FAILURE    = 'DB_REQUEST_BLOCKS_FAILURE',
}

export default LayoutsActionsTypes
