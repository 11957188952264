import React, { Suspense, lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { IntlProvider, addLocaleData } from 'react-intl'

import en from 'react-intl/locale-data/en'
import ru from 'react-intl/locale-data/ru'

import messages from '../../intl/messages'
import IntlGlobalProvider from '../../intl/IntlGlobalProvider'

import { getLanguageFromUrl,
  createHomepageRouteUrl,
  createWishlistRouteUrl,
  createLoginRouteUrl,
  createCartRouteUrl,
  createProfileRouteUrl,
  createVendorSignupRouteUrl,
  createCategoryRouteUrl,
  createProductRouteUrl,
  createArticleRouteUrl,
  createVendorsRouteUrl,
  createVendorRouteUrl,
  createSearchRouteUrl,
  createOrderRouteUrl,
  createOrdersRouteUrl,
  createRegistrationRouteUrl,
  createCheckoutRouteUrl,
  createCheckoutCompleteRouteUrl,
  createHomepageLinkUrl,
} from '../../url/index'

import LoaderIcon from '../subcomponents/loader-icon/LoaderIcon'

import MainMenu from '../main-menu/MainMenuContainer'
import NavigationBar from '../navigation-bar/NavigationBarContainer'
import AuthRoute from '../auth/AuthRouteContainer'

import LanguageContext from '../../contexts/language/LanguageContext'
import CurrencyContext from '../../contexts/currency/CurrencyContext'

import './ScreenHandler.css'
import StuffHandler from '../stuff/StuffHandler'
import GdprAgreement from '../gdpr/agreement/GdprAgreementContainer'

const CheckoutScreen    = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/checkout/CheckoutScreenContainer')
);
const CheckoutCompleteScreen    = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/checkout-complete/CheckoutCompleteScreenContainer')
);
const SignupScreen      = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/signup/SignupScreen')
);
const VendorSignupScreen      = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/vendor-signup/VendorSignupScreen')
);
const ProductScreen     = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/product/ProductScreenContainer')
);
const ArticleScreen     = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/article/ArticleScreenContainer')
);
const CategoryScreen  = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/category/CategoryScreenContainer')
);
const SearchScreen  = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/search/SearchScreenContainer')
);
const VendorsScreen  = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/vendors/VendorsScreenContainer')
);
const VendorScreen  = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/vendor/VendorScreenContainer')
);
const HomeScreen        = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/home/HomeScreenContainer')
);
const NotFoundScreen    = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/not-found/NotFoundScreen')
);

const Drawer            = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../side-menu/SideMenu')
);
const CartScreen    = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/cart/CartScreen')
);
const CartModal    = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../modals/cart/CartModal')
);
const WishlistScreen    = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/wishlist/WishlistScreen')
);
const WishlistModal    = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../modals/wishlist/WishlistModal')
);
const LoginScreen    = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/signin/Signin')
);
const LoginModal    = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../modals/login/LoginModal')
);
const ProfileScreen    = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/profile/ProfileScreenContainer')
);
const ProfileModal    = lazy(() =>
import(/* webpackChunkName: "Catalog" */ '../modals/profile/ProfileModal')
);
const OrdersScreen    = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/orders/OrdersScreenContainer')
);
const OrderScreen    = lazy(() =>
  import(/* webpackChunkName: "Catalog" */ '../screens/order/OrderScreenContainer')
);

addLocaleData([ ...en, ...ru ])

class ScreenHandler extends React.Component<any> {
  previousLocation = this.props.location;

  componentDidUpdate(prevProps: any) {

    if (this.props.location.pathname !== prevProps.location.pathname
      && !(this.props.location.state && this.props.location.state.modal)
    ) {
      window.scrollTo(0, 0)
      this.props.closeAllDrawers()
      this.props.resetNavigationBar()
    }
  }

  componentWillReceiveProps(nextProps: any) {
    let { setLanguage } = this.props;
    const nextLocationLanguage = getLanguageFromUrl(nextProps.location.pathname);

    // update redux language on route change
    if (this.props.location.pathname !== nextProps.location.pathname
      && nextLocationLanguage !== this.props.language) {
      const language = nextLocationLanguage;
      setLanguage(language)
    }
  }

  componentWillUpdate(nextProps: any) {
    let { location } = this.props;

    if (nextProps.history.action === 'PUSH') {
      this.props.setCanGoBack()
    }
    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== 'POP' &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location;
    }
  }

  render() {
    const {
      location,
      language,
      currency,
    } = this.props;

    const isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    ); // not initial render

    return (
      <IntlProvider locale={language} key={language} messages={(messages as any)[language]}>
        <IntlGlobalProvider>
          <LanguageContext.Provider value={language}>
            <CurrencyContext.Provider value={currency}>
              <Helmet>
                <html lang={language} />
              </Helmet>
              <div className='b-screen-handler__wrapper'>
                <Route path='/' component={StuffHandler}></Route>
                <Route path='/' component={MainMenu}></Route>
                <Route path='/' component={NavigationBar}></Route>
                <Route path='/' component={GdprAgreement}></Route>
                <Suspense fallback={null}>
                  <Route path='/' component={Drawer} key={`drawer-${language}`} />
                  {isModal ? <Route path={createWishlistRouteUrl()} exact={true} component={WishlistModal} key={`wishlist-${language}`} /> : null}
                  {isModal ? <Route path={createLoginRouteUrl()} exact={true} component={LoginModal} key={`login-${language}`} /> : null}
                  {isModal ? <Route path={createCartRouteUrl()} exact={true} component={CartModal} key={`cart-${language}`} /> : null}
                  {isModal ? <AuthRoute path={createProfileRouteUrl()} exact={true} component={ProfileModal} key={`profile-${language}`} /> : null}
                </Suspense>

                <div className='b-screen-handler__item'>
                  <Suspense fallback={<LoaderIcon />}>
                    <Switch location={isModal ? this.previousLocation : location}>
                      <Route exact path={createHomepageRouteUrl()} component={HomeScreen} key={`homepage-${language}`} />
                      <Route path={createVendorSignupRouteUrl()} component={VendorSignupScreen} key={`vendor-signup-${language}`} />
                      <Route
                        path={createCategoryRouteUrl()}
                        render={
                          (props) =>
                            <CategoryScreen
                              key={props.match.params.itemId}
                              itemId={props.match.params.itemId}
                              {...props}
                            />
                          }
                        key={`categories-${language}`}
                      />
                      <Route
                        path={createProductRouteUrl()}
                        render={
                          (props) =>
                            <ProductScreen
                              key={props.match.params.itemId}
                              {...props}
                            />
                        }
                        key={`product-${language}`}
                      />
                      <Route
                        path={createArticleRouteUrl()}
                        render={
                          (props) =>
                            <ArticleScreen
                              key={props.match.params.itemId}
                              itemId={props.match.params.itemId}
                              {...props}
                            />
                        }
                        key={`article-${language}`}
                      />

                      <Route
                        exact
                        path={createVendorsRouteUrl()}
                        render={
                          (props) =>
                            <VendorsScreen
                              {...props}
                            />
                        }
                        key={`vendors-${language}`}
                      />
                      <Route
                        path={createVendorRouteUrl()}
                        render={
                          (props) =>
                            <VendorScreen
                              itemId={props.match.params.itemId}
                              {...props}
                            />
                        }
                        key={`vendors-${language}`}
                      />
                      <Route
                        path={createSearchRouteUrl()}
                        render={
                          (props) =>
                            <SearchScreen
                              {...props}
                            />
                        }
                        key={`search-${language}`}
                      />
                      <Route path={createLoginRouteUrl()} component={LoginScreen} key={`login-${language}`} />
                      <Route path={createWishlistRouteUrl()} component={WishlistScreen} key={`wishlist-${language}`} />
                      <Route exact path={createCartRouteUrl()} component={CartScreen} key={`cart-${language}`} />
                      <Route exact path={createCheckoutCompleteRouteUrl()} component={CheckoutCompleteScreen} key={`checkout-complete-${language}`} />
                      <Route path={createCheckoutRouteUrl()} component={CheckoutScreen} key={`checkout-${language}`} />
                      <Route path={createRegistrationRouteUrl()} component={SignupScreen} key={`signup-${language}`} />
                      <AuthRoute exact path={createProfileRouteUrl()} component={ProfileScreen} key={`profile-${language}`} />
                      <AuthRoute exact path={createOrdersRouteUrl()} component={OrdersScreen} key={`orders-${language}`} />
                      <AuthRoute
                        path={createOrderRouteUrl()}
                        render={
                          (props: any) =>
                            <OrderScreen
                              key={props.match.params.itemId}
                              itemId={props.match.params.itemId}
                              {...props}
                            />
                        }
                        key={`order-${language}`}
                      />
                      <Redirect exact from='/' to={createHomepageLinkUrl()} />
                      <Route component={NotFoundScreen} status={404} />
                    </Switch>
                  </Suspense>
                </div>
              </div>
            </CurrencyContext.Provider>
          </LanguageContext.Provider>
        </IntlGlobalProvider>
      </IntlProvider>
    )
  }
}


export default ScreenHandler
