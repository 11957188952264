import ProductActionTypes from './ProductActionTypes'
import Product from '../../../entities/product/Product'

export interface DbRequestProduct {
  type: ProductActionTypes.DB_REQUEST_PRODUCT;
  payload: {
    id: number|string;
  }
}

export function dbRequestProduct(id: number|string): DbRequestProduct {
  return {
    type: ProductActionTypes.DB_REQUEST_PRODUCT,
    payload: {
      id,
    }
  }
}

export interface DbRequestProductSuccess {
  type: ProductActionTypes.DB_REQUEST_PRODUCT_SUCCESS;
  payload: {
    product: Product;
  }
}

export function dbRequestProductSuccess(product: Product): DbRequestProductSuccess {

  return {
    type: ProductActionTypes.DB_REQUEST_PRODUCT_SUCCESS,
    payload: {
      product
    }
  }
}

export interface DbRequestProductFailure {
  type: ProductActionTypes.DB_REQUEST_PRODUCT_FAILURE;
  payload: {
    id: number|string;
  }
}

export function dbRequestProductFailure(id: number|string): DbRequestProductFailure {
  return {
    type: ProductActionTypes.DB_REQUEST_PRODUCT_FAILURE,
    payload: {
      id,
    }
  }
}
