import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import Hamburger from './Hamburger'
import * as actions from '../../../redux/actions'
import { StoreState } from '../../../redux/types'
import { isDrawerOpened } from '../../../redux/selectors/Ui'

import { IStateProps, IDispatchProps, IOwnProps } from './IHamburger'

export const drawerId = 'side-menu';

export function mapStateToProps(state: StoreState, ownProps: IOwnProps) {
  return {
    isOpened: isDrawerOpened(state, { id: drawerId}),
  };
}

export function mapDispatchToProps(dispatch: Dispatch<actions.UiAction>) {
  return {
    open:   () => dispatch(actions.openDrawer(drawerId)),
    close:  () => dispatch(actions.closeDrawer(drawerId)),
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(Hamburger);
