import CategoryActionTypes from './CategoryActionTypes'

import {
  RequestCategory,
  RequestCategorySuccess,
  RequestCategoryFailure,
} from './RequestCategory'

import {
  DbRequestCategory,
  DbRequestCategorySuccess,
  DbRequestCategoryFailure,
} from './DbRequestCategory'
import Category from '../../../entities/category/Category'
import { persistCategory } from '../../../db/models/Category'

export * from './RequestCategory'
export * from './DbRequestCategory'

export interface SetCurrentCategory {
  type: CategoryActionTypes.SET_CURRENT_CATEGORY;
  payload: {
    category: Category;
  }
}

export function setCurrentCategory(category: Category): SetCurrentCategory {

  persistCategory(category)

  return {
    type: CategoryActionTypes.SET_CURRENT_CATEGORY,
    payload: {
      category,
    }
  }
}

export interface SetCategoryIsRequesting {
  type: CategoryActionTypes.SET_CATEGORY_IS_REQUESTING;
  payload: {
    isRequesting: boolean;
  }
}

export function setCategoryIsRequesting(isRequesting: boolean): SetCategoryIsRequesting {

  return {
    type: CategoryActionTypes.SET_CATEGORY_IS_REQUESTING,
    payload: {
      isRequesting,
    }
  }
}

export type CategoryAction = SetCurrentCategory | SetCategoryIsRequesting
| RequestCategory | RequestCategorySuccess | RequestCategoryFailure
| DbRequestCategory | DbRequestCategorySuccess | DbRequestCategoryFailure;
