import { map, switchMap, } from 'rxjs/operators'
import { from, } from 'rxjs'
import { ActionsObservable, ofType, StateObservable } from 'redux-observable'

import cscartApi from '../../api/'
import history from '../../url/history'
import {
  RequestApplyForVendorForm,
  requestApplyForVendorFormSuccess,
  requestApplyForVendorFormFailure,
  RequestApplyForVendorSignup,
  requestApplyForVendorSignupFailure,
  requestApplyForVendorSignupSuccess,
} from '../actions/ApplyForVendor'
import ApplyForVendorActionTypes from '../types/actions/ApplyForVendor'
import { StoreState } from '../types'
import { showSuccessMessage } from '../../utils/notifications/messages'
import { intl as i } from '../../intl/IntlGlobalProvider'
import { createHomepageLinkUrl } from '../../url'

/**
 * Request signup form for vendor from api
 */
export const getVendorSignupFormEpic = (
  action$: ActionsObservable<RequestApplyForVendorForm>,
  state$: StateObservable<StoreState>,
  {
    api
  }: {
    api: typeof cscartApi
  }
) =>
  action$.pipe(
    ofType(
      ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_FORM,
    ),
    switchMap(() =>
      from(
        api.vendors.getForm()
      ).pipe(
        map((result: any) =>
          result.data
          ?
            requestApplyForVendorFormSuccess(result.data)
          :
            requestApplyForVendorFormFailure({
              status: result.status
            })
        ),
      )
    ),
  )


/**
 * Request api for create profile
 */
export const requestVendorSignupEpic = (
  action$: ActionsObservable<RequestApplyForVendorSignup>,
  state$: StateObservable<StoreState>,
  {
    api,
    intl
  }: {
    api: typeof cscartApi,
    intl: () => typeof i;
  }
) =>
  action$.pipe(
    ofType(
      ApplyForVendorActionTypes.REQUEST_APPLY_FOR_VENDOR_SIGNUP,
    ),
    switchMap(action =>
      from(
        api.vendors.create(action.payload.profile)
      ).pipe(
        map((result: any) =>
        {

          if (result.data) {
            history.push(createHomepageLinkUrl())
            showSuccessMessage(intl().formatMessage({ id: 'app.components.vendor-signup.success-result'}))

            requestApplyForVendorSignupSuccess()
          }

          return requestApplyForVendorSignupFailure({
            status: result.status,
            message: result.message
          })
        }),
      )
    ),
  )

export default [
  getVendorSignupFormEpic,
  requestVendorSignupEpic,
]
