import { map, mergeMap, switchMap } from 'rxjs/operators'
import { from, forkJoin } from 'rxjs'
import _map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'

import { intl as i } from '../../intl/IntlGlobalProvider'
import cscartApi from '../../api/'
import { ActionsObservable, ofType, StateObservable } from 'redux-observable'
import {
  RequestReviewThread,
  CreateDraft,

  removeDraft,
  requestReviewThread,
  requestReviewThreadSuccess,
  requestReviewThreadFailure,
  requestReviewAddFailure,
  requestReviewAddSuccess,
} from '../actions/Review'
import ReviewActionsTypes from '../types/actions/Review'
import { createUuid } from '../../entities/testimonial/ReviewsThread'
import { StoreState } from '../types'
import { showSuccessMessage, showWarningMessage } from '../../utils/notifications/messages'

/**
 * Requests a review thread from API
 */
export const requestReviewThreadEpic = (
  action$: ActionsObservable<RequestReviewThread>,
  state$: null,
  {
    api
  }: {
    api: typeof cscartApi
  }
) => action$.pipe(
  ofType(ReviewActionsTypes.REQUEST_REVIEW_THREAD),
  mergeMap((action: RequestReviewThread) =>
    from(
      api.testimonials.getReviewThread(
        action.payload.id,
        action.payload.type,
        action.payload.selection
      )
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestReviewThreadSuccess({
            ...result.data,
            parentId: action.payload.id,
            parentType: action.payload.type,
          },
          action.payload.shouldAppend)
        :
          requestReviewThreadFailure(createUuid(action.payload.id, action.payload.type), {
            status: result.status,
            message: result.message
          })
      ),
    )
  ),
)

/**
 * Requests an adding new post to testimonials thread
 */
export const requestAddReviewEpic = (
  action$: ActionsObservable<CreateDraft>,
  state$: StateObservable<StoreState>,
  {
    api,
    intl,
  }: {
    api: typeof cscartApi;
    intl: () => typeof i;
  }
) => action$.pipe(
  ofType(ReviewActionsTypes.CREATE_TESTIMONIAL_DRAFT),
  switchMap(() => {
    const requests = state$.value.Review.drafts
      .filter(draft => draft.isFinal)
      .map(draft => ({
        uuid: draft.uuid,
        draft: draft,
        request: api.testimonials.create(
          draft.parentId,
          draft.parentType,
          state$.value.Auth.isLogged ? state$.value.Auth.profile.firstname : 'Guest',
          draft.rating,
          draft.text,
        ),
      }))

    return forkJoin(
      mapValues(keyBy(requests, 'uuid'), 'request')
    ).pipe(
      mergeMap((results: any) => {
        const resultsArray = _map(results, (result, uuid) =>
          ({
            ...result,
            draft: requests.find(request => request.uuid === uuid)!.draft,
            uuid,
          })
        )

        // keep in drafts if request got some failure status
        const resultsToBeRemovedFromDrafts = resultsArray.filter(result => result.status === 201);

        // remove from cart error if request is success
        const resultsToBeSuccess          = resultsArray.filter(result => result.status === 201);

        resultsToBeSuccess.forEach(() => {
          showSuccessMessage(intl().formatMessage({ id: 'app.components.reviews.form.success-submit'}))
        })

        // notify about errors only if api responded with error status
        const resultsToBeErrors           = resultsArray.filter(result => result.status >= 300);

        resultsToBeErrors.forEach(result => {
          showWarningMessage(result.response.data.message ||
            intl().formatMessage({ id: 'app.components.reviews.form.error-submit'})
          )
        })

        return [
          ...resultsToBeSuccess.map(result => requestReviewThread(result.draft.parentId, result.draft.parentType)),
          ...resultsToBeRemovedFromDrafts.map(result => removeDraft(result.uuid)),
          ...resultsToBeErrors.map(result => requestReviewAddFailure(result.uuid, {
            status: result.status,
            message: result.response.data.message || ''
          })),
          ...resultsToBeSuccess.map(result => requestReviewAddSuccess(result.uuid))
        ]
      }),
    )
  }),
)


export default [
  requestReviewThreadEpic,
  requestAddReviewEpic,
]
