import { switchMap, } from 'rxjs/operators'

import history from '../../url/history'
import { ofType, ActionsObservable, StateObservable } from 'redux-observable'

import { EMPTY, of } from 'rxjs'
import {
  PerformSearch,
} from '../actions/Search'
import SearchActionsTypes from '../types/actions/Search'

import { StoreState } from '../types'
import { createSearchLinkUrl } from '../../url'

/**
 * Set currency if not selected
 * @param action$
 */
export const pushQueryParameterOnGlobalSearchEpic = (
  action$: ActionsObservable<PerformSearch>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(
      SearchActionsTypes.PERFORM_SEARCH,
    ),
    switchMap(action => {
      history.push(createSearchLinkUrl() + '?' + action.payload.query)

      return of({ type: EMPTY })
      }
    ),
  )

export default [
  pushQueryParameterOnGlobalSearchEpic,
]
