import IProductSeoSnippet from './ISeoSnippet'
import ISeoSnippet from '../seo/ISeoSnippet'

/**
 * Data for schema.org and html seo (title, meta description and etc.)
 */
class SeoSnippet implements IProductSeoSnippet, ISeoSnippet {
  title:            string;
  metaDescription:  string;
  availability:     string;
  description:      string;
  name:             string;
  price:            number;
  currency:         string;
  code:             string;

  constructor(rawSeoSnippet: any) {
    const seoSnippet = {...rawSeoSnippet};

    if (seoSnippet.code) {
      // recreate from ISeoSnippet
      this.price            = seoSnippet.price;
      this.currency         = seoSnippet.currency;
      this.name             = seoSnippet.name;
      this.description      = seoSnippet.description;
      this.code             = seoSnippet.code;
      this.availability     = seoSnippet.availability;
      this.metaDescription  = seoSnippet.metaDescription;
    } else {
      // create from raw api data
      this.price        = parseFloat(seoSnippet.price || '0');
      this.currency     = seoSnippet.price_currency || '';
      this.name         = seoSnippet.name || '';
      this.description  = seoSnippet.description || '';
      this.code         = seoSnippet.sku || '';
      this.availability = seoSnippet.availability || '';
      this.metaDescription = seoSnippet.meta_description || this.description;
    }

    this.title = seoSnippet.title;
  }
}

export default SeoSnippet
