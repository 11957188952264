import ReviewableEntities from '../../constants/ReviewableEntities'

import IReviewsThread from './IReviewsThread'
import ReviewSelection from './ReviewSelection'

/**
 * Reviews thread with all information about reviews and ratings
 */
class ReviewsThread implements IReviewsThread {
  id:                 number;
  totalTestimonials:  number;
  isReadonly:         boolean;
  rating:             number;
  parentId:           number;
  parentType:         ReviewableEntities;
  withReviews:        boolean;
  withRaiting:        boolean;
  selection:          ReviewSelection;

  constructor(rawThread: any) {
    const thread = {...rawThread};

    if (!thread.parentId || !thread.parentType) {
      throw new Error('parentId or parentType parameter is missing. Check incoming object')
    }

    if ('id' in thread) {
      this.id                 = thread.id;
      this.isReadonly         = thread.isReadonly;
      this.rating             = thread.rating;
      this.totalTestimonials  = thread.totalTestimonials;
      this.withReviews        = thread.withReviews;
      this.withRaiting        = thread.withRaiting;
      this.selection          = new ReviewSelection(thread.selection);
    } else {
      this.id                 = parseInt(thread.thread_id);
      this.isReadonly         = thread.disable_adding;
      this.rating             = parseFloat(thread.average_rating);
      this.totalTestimonials  = parseInt(thread.search.total_items);
      this.withReviews        = thread.type === 'C' || thread.type === 'B';
      this.withRaiting        = thread.type === 'R' || thread.type === 'B';
      this.selection          = new ReviewSelection({
        testimonials: thread.posts || [],
        pagination: thread.search,
      });
    }

    this.parentId     = thread.parentId;
    this.parentType   = thread.parentType;

    if (!this.id) {
      throw new Error('Id parameter is missing. Check incoming object')
    }
  }

  get uuid(): string {
    return createUuid(this.parentId, this.parentType)
  }
}

/**
 * Create uuid by parent id and parent type
 * If thread for a product with id 105
 * uuid would be '105product'
 *
 * @param parentId
 * @param parentType
 */
export const createUuid = (
  parentId: IReviewsThread['parentId'],
  parentType: IReviewsThread['parentType']
) => `${parentId}${parentType}`

export default ReviewsThread
