import { map, switchMap, mergeMap } from 'rxjs/operators'
import { from, of, } from 'rxjs'
import { ActionsObservable, ofType, StateObservable } from 'redux-observable'

import history from '../../url/history'
import cscartApi from '../../api/'
import {
  RequestOrders,
  RequestOrderCreate,
  RequestOrder,

  requestOrderCreateSuccess,
  requestOrderCreateFailure,
  requestOrdersSuccess,
  requestOrdersFailure,

  requestOrderSuccess,
  requestOrderFailure,
  RequestOrderFailure,
  DbRequestOrder,
  dbRequestOrder,
  RequestOrdersFailure,
  DbRequestOrders,
  dbRequestOrders,
  DbRequestOrderSuccess,
  DbRequestOrderFailure,
  dbRequestOrderSuccess,
  dbRequestOrderFailure,
  DbRequestOrdersSuccess,
  dbRequestOrdersSuccess,
  requestSettlementCreateSuccess,
  RequestSettlementCreate,
  requestSettlementCreateFailure,
  RequestOnlinePayment,
  requestOnlinePaymentSuccess,
  requestOnlinePaymentFailure,
  requestOnlinePayment,
  RequestOnlinePaymentResult,
  requestOnlinePaymentResultFailure,
  requestOnlinePaymentResultSuccess,
  RequestOnlinePaymentResultSuccess,
} from '../actions/Order'
import OrderActionsTypes from '../types/actions/Order'
import { StoreState } from '../types'
import { getSelectedPayment } from '../selectors/Cart'
import { switchFailedRequestActionToAction, switchFailedRequestSelectionActionToAction } from './utils/mapFailActionToAction'
import OrderSelection from '../../entities/order/OrderSelection'
import { createRequestDbById } from './utils/requestDbById'
import Order from '../../entities/order/Order'
import db from '../../db/models'
import { requestDbBySelection } from './utils/requestDbBySelection'
import { createCheckoutCompleteLinkUrl } from '../../url'

export const createOrderEpic = (
  action$: ActionsObservable<RequestOrderCreate>,
  state$: StateObservable<StoreState>,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(OrderActionsTypes.REQUEST_ORDER_CREATE),
  switchMap(action =>
    from(
      api
        .orders
        .create(action.payload)
    ).pipe(
      mergeMap((result: any) =>
      {

        if (result.data) {
          const orderPayment = getSelectedPayment(state$.value);

          if (orderPayment.isOffline) {
            history.push(createCheckoutCompleteLinkUrl(result.data.order_id))
            return [
              requestOrderCreateSuccess(result.data.order_id)
            ]
          } else {
            return [
              requestOnlinePayment(result.data.order_id)
            ]
          }

        } else {
          return [
            requestOrderCreateFailure({
              status: result.status,
              message: result.message
            })
          ]
        }
      }),
    )
  ),
)

export const completeOrderWithOnlinePaymentEpic = (
  action$: ActionsObservable<RequestOnlinePaymentResultSuccess>,
  state$: StateObservable<StoreState>
) => action$.pipe(
  ofType(OrderActionsTypes.REQUEST_ONLINE_PAYMENT_RESULT_SUCCESS),
  switchMap(action =>
    of(requestOrderCreateSuccess(state$.value.Cart.redirectionForm!.orderId))
  ),
)

export const hookBackendPaymentResultPageEpic = (
  action$: ActionsObservable<RequestOnlinePaymentResult>,
  state$: null,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(OrderActionsTypes.REQUEST_ONLINE_PAYMENT_RESULT),
  switchMap(action =>
    from(
      api
        .settlements
        .hookUrl(action.payload.url)
    ).pipe(
      map((result: any) =>
      result.data
      ?
        requestOnlinePaymentResultSuccess()
      :
        requestOnlinePaymentResultFailure({
          status: result.status,
          message: result.message || ''
        })
      ),
    )
  ),
)

export const placeSettlementEpic = (
  action$: ActionsObservable<RequestSettlementCreate>,
  state$: null,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(OrderActionsTypes.REQUEST_SETTLEMENT_CREATE),
  switchMap(action =>
    from(
      api
        .settlements
        .create({
          orderId: action.payload.id
        })
    ).pipe(
      map((result: any) =>
      result.data
      ?
        requestSettlementCreateSuccess(action.payload.id, result.data)
      :
        requestSettlementCreateFailure(action.payload.id, {
          status: result.status,
          message: result.message || ''
        })
      ),
    )
  ),
)

export const placeOnlinePaymentEpic = (
  action$: ActionsObservable<RequestOnlinePayment>,
  state$: null,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(OrderActionsTypes.REQUEST_ONLINE_PAYMENT),
  switchMap(action =>
    from(
      api
        .settlements
        .getForm(action.payload.id)
    ).pipe(
      map((result: any) => {

        return result.data
        ?
          requestOnlinePaymentSuccess(action.payload.id, result.data)
        :
          requestOnlinePaymentFailure(action.payload.id, {
            status: result.status,
            message: result.message || ''
          })
      }
      ),
    )
  ),
)

/**
 * Request orders
 */
export const requestOrdersEpic = (
  action$: ActionsObservable<RequestOrders>,
  state$: null,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(OrderActionsTypes.REQUEST_ORDERS),
  switchMap((action: RequestOrders) =>
    from(
      api.orders.getOrders(
        action.payload.orderSelection
      )
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestOrdersSuccess(
            result.data.orders,
            result.data.params,
            action.payload.shouldAppend
          )
        :
          requestOrdersFailure(
            {
              status: result.status,
              message: result.message || ''
            },
            action.payload.orderSelection
          )
      ),
    )
  ),
)

/**
 * Should request DB for an orders on network error
 */
export const mapApiRequestSelectionFailureToDbRequestEpic = (action$: ActionsObservable<RequestOrdersFailure>) =>
  switchFailedRequestSelectionActionToAction<OrderSelection, RequestOrdersFailure, DbRequestOrders>(
    action$,
    OrderActionsTypes.REQUEST_ORDERS_FAILURE,
    (selection) => dbRequestOrders(selection)
  );



/**
 * Requests an order from API
 */
export const requestOrderEpic = (
  action$: ActionsObservable<RequestOrder>,
  state$: null,
  { api }: {api: typeof cscartApi}
) => action$.pipe(
  ofType(OrderActionsTypes.REQUEST_ORDER),
  switchMap((action: RequestOrder) =>
    from(
      api.orders.getOrder(action.payload.id)
    ).pipe(
      map((result: any) =>
        result.data
        ?
          requestOrderSuccess(action.payload.id, result.data)
        :
          requestOrderFailure(action.payload.id, {
            status: result.status,
            message: result.message || ''
          })
      ),
    )
  ),
)

/**
 * Should request DB for an order on network error
 */
export const mapApiRequestFailureToDbRequestEpic = (action$: ActionsObservable<RequestOrderFailure>) =>
  switchFailedRequestActionToAction<RequestOrderFailure, DbRequestOrder>(
    action$,
    OrderActionsTypes.REQUEST_ORDER_FAILURE,
    (id) => dbRequestOrder(id)
  );

/**
 * Perform DB request for the order
 * @param action$
 * @param state$
 * @param param2
 */
export const dbRequestOrderEpic = (
  action$: ActionsObservable<DbRequestOrder>,
  state$: null,
  { indexedDb }: { indexedDb: typeof db }
) =>
  createRequestDbById<DbRequestOrder, DbRequestOrderSuccess, DbRequestOrderFailure, Order>(
    action$,
    OrderActionsTypes.DB_REQUEST_ORDER,
    indexedDb.orders.findOrder,
    dbRequestOrderSuccess,
    dbRequestOrderFailure
  )

/**
 * Perform DB request for the order
 * @param action$
 * @param state$
 * @param param2
 */
export const dbRequestOrdersEpic = (
  action$: ActionsObservable<DbRequestOrders>,
  state$: null,
  { indexedDb }: { indexedDb: typeof db }
) =>
  requestDbBySelection<OrderSelection, DbRequestOrders, DbRequestOrdersSuccess>(
    action$,
    OrderActionsTypes.DB_REQUEST_ORDERS,
    indexedDb.orders.selectOrders,
    dbRequestOrdersSuccess
  )

export default [
  createOrderEpic,
  mapApiRequestSelectionFailureToDbRequestEpic,
  mapApiRequestFailureToDbRequestEpic,
  placeSettlementEpic,
  hookBackendPaymentResultPageEpic,
  placeOnlinePaymentEpic,
  dbRequestOrderEpic,
  dbRequestOrdersEpic,
  requestOrdersEpic,
  requestOrderEpic,
  completeOrderWithOnlinePaymentEpic,
]
