import { createSelector } from 'reselect'
import mapValues from 'lodash/mapValues'
import values from 'lodash/values'
import uniq from 'lodash/uniq'

import { StoreState } from '../types'
const getWishlistState = (state: StoreState) => state.Wishlist

export const getWishlistProducts = createSelector(
  [ getWishlistState ],
  (wishlistState) =>
    (wishlistState.wishlist.products || [])
)

export const getWishlistQueueProducts = createSelector(
  [ getWishlistState ],
  (wishlistState) =>
    (wishlistState.queue || [])
)

export const getWishlistProductsCount = createSelector(
  [ getWishlistProducts, getWishlistQueueProducts ],
  (products, queue) => {

    const productsIds = values(mapValues(products, 'id'));

    return uniq([
      ...productsIds,
      ...queue
    ]).length
  }
)
