import React from 'react'

import { IOwnProps, IOwnState } from './IErrorBoundary'

class ErrorBoundary extends React.Component<IOwnProps, IOwnState> {
  constructor(props: IOwnProps) {
    super(props);
    this.state = {
      hasError: false,
      error: '',
      stack: ''
    };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({
      hasError: true,
      error: error.message,
      stack: info.componentStack
    });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>Something went wrong.</h1>
          <div>{this.state.error}</div>
          <div>{this.state.stack}</div>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary
