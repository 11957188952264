import cscartApi from './cscart-api'

export const create = (settlementData: any) =>
  cscartApi
    .settlements
    .create({
      orderId: settlementData.orderId,
    })

export const getForm = (orderId: number) =>
  cscartApi
    .settlements
    .getForm(orderId)

/**
 * Request final page for online payment to hook cscart logic
 *
 * @param url
 */
export const hookUrl = (url: string) =>
  cscartApi
    .settlements
    .hookUrl(url)
