
import React from 'react'

import DeviceTypes from '../../constants/DeviceTypes'
import CartButton from '../cart/cart-button/CartButtonContainer'
import WishlistButton from '../wishlist/wishlist-button/WishlistButtonContainer'
import Hamburger from '../subcomponents/hamburger/HamburgerContainer'
import ProfileButton from '../profile/profile-button/ProfileButton'
import HomeButton from '../subcomponents/home-button/HomeButton'
import { intl } from '../../intl/IntlGlobalProvider'

import { IProps } from './IMainMenu'
import messages from './MainMenu.messages'
import './MainMenu.css'

const MainMenu: React.FC<IProps> = (props) => {

  const {
    deviceType,
  } = props;

  const isVisible = shouldShowMainMenu(deviceType);

  React.useEffect(() => {
    setVisibility(isVisible)

    return onMainMenuHide
  }, [isVisible])

  if (!isVisible) {
    return null
  }

  const buttonsSize = 23;

  return (
    <nav className='b-main-menu'>
      <div className='b-main-menu__button-group'>
        <HomeButton
          size={buttonsSize}
          className='b-main-menu__button'
          label={intl.formatMessage({...messages.homeButton})}
        />
        <Hamburger
          size={buttonsSize}
          className='b-main-menu__button'
          label={intl.formatMessage({...messages.hamburger})}
        />
        <WishlistButton
          size={buttonsSize}
          className='b-main-menu__button'
          label={intl.formatMessage({...messages.wishlistButton})}
        />
        <CartButton
          size={buttonsSize}
          className='b-main-menu__button'
          label={intl.formatMessage({...messages.cartButton})}
        />
        <ProfileButton
          size={buttonsSize}
          className='b-main-menu__button'
          label={intl.formatMessage({...messages.profileButton})}
        />
      </div>
    </nav>
  );
}

const setVisibility = (isVisible: boolean): void => {
  isVisible ? onMainMenuShow() : onMainMenuHide()
}

const shouldShowMainMenu = (deviceType: DeviceTypes): boolean => {
  return deviceType === DeviceTypes.MOBILE
}

const onMainMenuShow = (): void => {
  document.body.classList.add('with-main-menu')
}

const onMainMenuHide = (): void => {
  document.body.classList.remove('with-main-menu')
}


export default MainMenu
