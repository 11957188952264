import IApiError from './IApiError'

/**
 * Api error representation
 */
class ApiError implements IApiError {
  status:       number;
  message:      string;
  shouldRetry:  boolean;
  type?:        string;

  constructor(rawError: any) {
    const error = {...rawError};

    this.status       = error.status || 0;
    this.message      = error.message || 'Unhandled error';
    this.shouldRetry  = error.shouldRetry || false;
    this.type         = error.type;
  }
}

export default ApiError
