import cscartApiSdk from 'cscart-sdk'
import config from '../config/Config'

const cscartApi = new cscartApiSdk({
  username: process.env.REACT_APP_API_USERNAME || '',
  apiKey:   config.backendApiKey,
  apiUrl:   config.backendApiUrl,
  siteUrl:  config.backendSiteUrl
})

cscartApi.setClientId('sd_spa')

cscartApi.getClient().interceptors.response.use(
  response => response || Promise.reject(response),
  error => {
    if (error.response && error.response.status === 401) {
      window.dispatchEvent(new Event('api.need-auth'))
    }

    error.message = (error.response && error.response.data)
    && (error.response.data.message || '');

    error.status = (error.response && error.response.status)
    ? error.response.status
    : 0;

    return Promise.resolve(error)
  }
);

export default cscartApi
