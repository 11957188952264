import IShipping from './IShipping'

class Shipping implements IShipping {
  id:           number
  name:         string;
  deliveryTime: string;
  description:  string;
  isFree:       boolean;
  price:        number;

  /**
   * set value = value from obj with IShipping interface || obj from cscart api || default value
   */
  constructor(rawShipping: any) {
    const shipping = {...rawShipping};

    this.id   = parseInt(shipping.id  || shipping.shipping_id || '0')
    this.name = shipping.name         || shipping.shipping        || '';
    this.description = shipping.description || '';

    this.isFree = shipping.isFree || shipping.free_shipping || false;
    this.price = shipping.price || shipping.rate || 0;

    this.deliveryTime = shipping.deliveryTime || shipping.delivery_time || '';

    if (!this.id) {
      throw Error('Missing shipping id')
    }
  }
}

export default Shipping
