import { Action as ReduxAction } from 'redux'
import ApiError from '../../entities/error/ApiError'
import AbstractItemsSelection from '../../entities/selection/AbstractItemsSelection'

export * from  './App'
export * from  './article/ArticleAction'
export * from  './Auth'
export * from  './Cart'
export * from  './Wishlist'
export * from  './Order'
export * from  './Ui'
export * from  './NavigationBar'
export * from  './Layouts'
export * from  './category/CategoryAction'
export * from  './product/ProductAction'
export * from  './Review'
export * from  './vendor/VendorAction'
export * from  './Search'
export * from  './ApplyForVendor'

export type Id = string|number;

/* Low-level action types */

/**
 * Default action with payload
 */
export interface Action extends ReduxAction {
  payload?: any;
}

/* identifier action types */

/**
 * Action for entities single requests
 * Like sra_products/498 or sra_products/product-name
 */
export interface IdentifierAction<T = Id> extends Action {
  payload: {
    id:               T;
    [others: string]: any;
  };
}

/**
 * Action for entities successed single requests
 */
export interface IdentifierSuccessedAction<T = Id> extends IdentifierAction<T> {
  payload: {
    id:               T;
    [others: string]: any;
  };
}

/**
 * Action for entities failured single requests
 */
export interface IdentifierFailuredAction<T = Id> extends IdentifierAction<T> {
  payload: {
    id:               T;
    [others: string]: any;
  };
}

/* Api identifier action types */

/**
 * API Action for entities successed single requests
 */
export interface ApiIdentifierSuccessedAction<T = Id> extends IdentifierAction<T> {
  payload: {
    id:               T;
    [others: string]: any;
  };
}

/**
 * API Action for entities failured single requests
 */
export interface ApiIdentifierFailuredAction<T = Id> extends IdentifierAction<T> {
  payload: {
    id:               T;
    error:            ApiError;
    [others: string]: any;
  };
}

/* Db identifier action types */

/**
 * DB Action for entities successed single requests
 */
export interface DbIdentifierSuccessedAction<T = Id> extends IdentifierAction<T> {
  payload: {
    id:               T;
    [others: string]: any;
  };
}

/**
 * DB Action for entities failured single requests
 */
export interface DbIdentifierFailuredAction<T = Id> extends IdentifierAction<T> {
  payload: {
    id:               T;
    [others: string]: any;
  };
}

/* selection action types */

/**
 * Action for selection requests
 */
export interface SelectionAction<T extends AbstractItemsSelection> extends Action {
  payload: {
    selection?: T;
  }
}

/**
 * Action for successes for SelectionAction requests
 */
export interface SuccessedRequestSelectionAction<T extends AbstractItemsSelection> extends SelectionAction<T> {
  payload: {
    selection?:       T;
    [others: string]: any;
  };
}

/**
 * Action for failures for SelectionAction requests
 */
export interface FailedRequestSelectionAction<T extends AbstractItemsSelection> extends SelectionAction<T> {
  payload: {
    selection?:       T;
    error:            ApiError;
    [others: string]: any;
  };
}

