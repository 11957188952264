import NavigationBarActionsTypes from '../types/actions/NavigationBar'

export interface ConfigureNavigationBar {
  type: NavigationBarActionsTypes.CONFIGURE_NAVIGATION_BAR;
  payload: {
    config: any;
  }
}

export function configureNavigationBar(config: any): ConfigureNavigationBar {
  return {
    type: NavigationBarActionsTypes.CONFIGURE_NAVIGATION_BAR,
    payload: {
      config
    }
  }
}

export interface ResetNavigationBar {
  type: NavigationBarActionsTypes.RESET_NAVIGATION_BAR;
}

export function resetNavigationBar(): ResetNavigationBar {
  return {
    type: NavigationBarActionsTypes.RESET_NAVIGATION_BAR,
  }
}

export interface ToggleSearch {
  type: NavigationBarActionsTypes.TOGGLE_SEARCH;
}

export function toggleSearch(): ToggleSearch {
  return {
    type: NavigationBarActionsTypes.TOGGLE_SEARCH,
  }
}

export interface SetCanGoBack {
  type: NavigationBarActionsTypes.SET_CAN_GO_BACK;
  payload: {
    canGoBack: boolean;
  }
}

export function setCanGoBack(canGoBack: boolean): SetCanGoBack {
  return {
    type: NavigationBarActionsTypes.SET_CAN_GO_BACK,
    payload: {
      canGoBack,
    }
  }
}

export type NavigationBarAction = ConfigureNavigationBar | ResetNavigationBar | ToggleSearch
| SetCanGoBack;
