import { ProductAction } from '../actions/product/ProductAction'
import ProductState from '../types/ProductState'
import ProductActionTypes from '../actions/product/ProductActionTypes'

export const initialState: ProductState = {
  isRequesting: false,
  isOptionsRequesting: false,
  product: null,
  error: null,
}

const ProductReducer = function product(state: ProductState = initialState, action: ProductAction): ProductState {
  switch (action.type) {
    case ProductActionTypes.REQUEST_PRODUCT:
      return {
        ...state,
        isRequesting: action.payload.options ? false : true,
        isOptionsRequesting: action.payload.options ? true : false,
        product: action.payload.options ? state.product: null,
        error: null,
      }

    case ProductActionTypes.DB_REQUEST_PRODUCT:
      return {
        ...state,
        isRequesting: true,
        product: null,
      }

    case ProductActionTypes.REQUEST_PRODUCT_SUCCESS:
    case ProductActionTypes.DB_REQUEST_PRODUCT_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isOptionsRequesting: false,
        product: action.payload.product,
      }

    case ProductActionTypes.SET_CURRENT_PRODUCT:
      return {
        ...state,
        isOptionsRequesting:  false,
        isRequesting:         false,
        product:              action.payload.product,
      }

    case ProductActionTypes.REQUEST_PRODUCT_FAILURE:
      return {
        ...state,
        isRequesting: false,
        error: action.payload.error
      }

    case ProductActionTypes.SET_PRODUCT_IS_REQUESTING:
      return {
        ...state,
        isRequesting: true,
      }

    default:
      return state;
  }
}

export default ProductReducer
