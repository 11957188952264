import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import WishlistButton from './WishlistButton'
import { IStateProps, IOwnProps, IDispatchProps } from './IWishlistButton'
import { StoreState } from '../../../redux/types'
import { getWishlistProductsCount, } from '../../../redux/selectors/Wishlist'

export function mapStateToProps(state: StoreState, ownProps: IOwnProps): IStateProps {
  return {
    amount: getWishlistProductsCount(state),
  };
}

export function mapDispatchToProps(dispatch: Dispatch): IDispatchProps {
  return {
  };
}

export default connect<IStateProps, IDispatchProps, IOwnProps, StoreState>(
  mapStateToProps,
  mapDispatchToProps
)(WishlistButton);
