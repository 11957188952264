import IOrderStatus from './IOrderStatus'

class OrderStatus implements IOrderStatus {

  id:             number;
  code:           string;
  name:           string;
  color:          string;

  constructor(rawOrderStatus: IOrderStatus | any) {
    const orderStatus  = {...rawOrderStatus};

    if ('id' in orderStatus) {
      this.id           = orderStatus.id;
      this.code         = orderStatus.code;
      this.name         = orderStatus.name;
      this.color        = orderStatus.color;
    } else {
      this.id           = parseInt(orderStatus.status_id);
      this.code         = orderStatus.status;
      this.name         = orderStatus.description;
      this.color        = orderStatus.params.color;
    }
  }
}

export default OrderStatus
