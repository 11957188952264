import { combineEpics, createEpicMiddleware, } from 'redux-observable'

import db from '../../db/models/'
import api from '../../api/'
import { intl } from '../../intl/IntlGlobalProvider'
import { handleThirdpartyLogout } from '../epics/Auth'
import { requestNotificationPermission } from '../epics/Ui'
import authEpic from './Auth'
import cartEpic from './Cart'
import wishlistEpic from './Wishlist'
import orderEpic from './Order'
import layoutsEpic from './Layouts'
import categoryEpic from './Category'
import productEpic from './Product'
import articleEpic from './Article'
import reviewEpic from './Review'
import uiEpic from './Ui'
import vendorEpic from './Vendor'
import searchEpic from './Search'
import applyForVendorEpic from './ApplyForVendor'

export const rootEpic = combineEpics(
  ...Object.values(authEpic),
  ...Object.values(cartEpic),
  ...Object.values(wishlistEpic),
  ...Object.values(orderEpic),
  ...Object.values(layoutsEpic),
  ...Object.values(categoryEpic),
  ...Object.values(productEpic),
  ...Object.values(articleEpic),
  ...Object.values(reviewEpic),
  ...Object.values(uiEpic),
  ...Object.values(vendorEpic),
  ...Object.values(searchEpic),
  ...Object.values(applyForVendorEpic),
);

export const epicMiddleware = createEpicMiddleware({
  dependencies: {
    indexedDb: db,
    api: api,
    intl: () => intl,
    /**
     * All async function helpers with promises for example
     * You should mock it for marble testing
     */
    asyncUtils: {
      handleThirdpartyLogout: handleThirdpartyLogout,
      requestNotificationPermission: requestNotificationPermission,
    }
  }
});
