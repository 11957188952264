import ProductActionTypes from './ProductActionTypes'
import Product from '../../../entities/product/Product'
import ProductSelection from '../../../entities/product/ProductSelection'

export interface DbRequestCategoryProducts {
  type: ProductActionTypes.DB_REQUEST_CATEGORY_PRODUCTS;
  payload: {
    categoryId: number;
  }
}

export function dbRequestCategoryProducts(id: number): DbRequestCategoryProducts {
  return {
    type: ProductActionTypes.DB_REQUEST_CATEGORY_PRODUCTS,
    payload: {
      categoryId: id,
    }
  }
}

export interface DbRequestCategoryProductsSuccess {
  type: ProductActionTypes.DB_REQUEST_CATEGORY_PRODUCTS_SUCCESS;
  payload: {
    productSelection: ProductSelection;
  }
}

export function dbRequestCategoryProductsSuccess(products: Array<Product>): DbRequestCategoryProductsSuccess {
  const productSelection = new ProductSelection({
    pagination: {
      page: 1,
      total: products.length,
      limit: products.length,
    },
    filters: [],
    products: products,
  })

  return {
    type: ProductActionTypes.DB_REQUEST_CATEGORY_PRODUCTS_SUCCESS,
    payload: {
      productSelection,
    }
  }
}

export interface DbRequestCategoryProductsFailure {
  type: ProductActionTypes.DB_REQUEST_CATEGORY_PRODUCTS_FAILURE;
  payload: {
    categoryId:  number;
  }
}

export function dbRequestCategoryProductsFailure(id: number): DbRequestCategoryProductsFailure {

  return {
    type: ProductActionTypes.DB_REQUEST_CATEGORY_PRODUCTS_FAILURE,
    payload: {
      categoryId: id,
    }
  }
}
