enum FilterTypes {
  CHECKBOX  = 'checkbox',
  SLIDER    = 'slider',
  RANGE     = 'range',
  PRICE     = 'price',
  COLOR     = 'color',
  DATE      = 'date',
}

export default FilterTypes
