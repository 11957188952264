import includes from 'lodash/includes'

import Database from '../Database'
import Order from '../../entities/order/Order'
import OrderSelection from '../../entities/order/OrderSelection';

/**
 * Saves a order to indexedDB
 *
 * @param {Order} order
 *
 * @returns {void}
 */
export const persistOrder = (order: Order): void => {
  const orderToSave = new Order({...order});

  Database.orders.put(orderToSave)
}

/**
 * Get a order from indexedDB by id
 *
 * @param {number} orderId
 *
 * @returns {Promise<Order>}
 */
export const findOrder = (orderId: number): Promise<Order> =>
  Database.orders.get(orderId)

/**
 * Get orders from indexedDB by it's ids
 *
 * @param {number[]} ordersIds
 *
 * @returns {Promise<Array<Order>>}
 */
export const findOrders = (ordersIds: Array<number>): Promise<Array<Order>> =>
  Database.orders.filter(
    order => includes(ordersIds, order.id)
  ).toArray()

/**
 * Select orders from indexedDB
 *
 * @param {number[]} ordersIds
 *
 * @returns {Promise<OrderSelection>}
 */
export const selectOrders = (selection: OrderSelection|undefined): Promise<OrderSelection> =>
Database.orders.filter(
  /**
   * @todo add selection logic
   */
  () => true
).toArray().then(
  orders => new OrderSelection({
    orders,
    pagination: {
      page: 1,
      total: orders.length,
      limit: orders.length,
    },
  })
)

/**
 * Get all orders from indexedDB
 *
 * @returns {Promise<Array<Order>>}
 */
export const findAllOrders = (): Promise<Array<Order>> =>
  Database.orders.toArray()
