import cscartApi from './cscart-api'
import ReviewableEntities from '../constants/ReviewableEntities'
import ReviewSelection from '../entities/testimonial/ReviewSelection'

export const getReviewThread = (
  id: number,
  type: ReviewableEntities,
  selection?: ReviewSelection
) => {

  let baseRequest = cscartApi.testimonials

  if (selection && selection.pagination) {
    baseRequest = baseRequest
      .limit(selection.pagination.limit)
      .page(selection.pagination.page)
  }

  switch(type) {
    case ReviewableEntities.PRODUCT:
      return baseRequest.forProduct(id).get()
    case ReviewableEntities.VENDOR:
      return baseRequest.forVendor(id).get()
    default:
      throw new Error(`Unhandled entity type ${type}`)
  }
}

export const create = (
  id: number,
  type: ReviewableEntities,
  name: string,
  rating?: number,
  text?: string,
) => {
  let convertedType = '';

  switch(type) {
    case ReviewableEntities.PRODUCT:
      convertedType = 'P';
      break
    case ReviewableEntities.CATEGORY:
      convertedType = 'C';
      break
    case ReviewableEntities.ARTICLE:
      convertedType = 'A';
      break
    case ReviewableEntities.ORDER:
      convertedType = 'O';
      break
    case ReviewableEntities.STOREFRONT:
      convertedType = 'E';
      break
    case ReviewableEntities.VENDOR:
      convertedType = 'M';
      break

    default:
      throw new Error(`Unhandled entity type ${type}`)
  }

  return cscartApi.testimonials.create(
    id,
    convertedType,
    name,
    rating,
    text
  )
}
