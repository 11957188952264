import includes from 'lodash/includes'

import Database from '../Database'
import Article from '../../entities/article/Article'

export const persistArticle = (article: Article) => {
  const articleToSave = new Article({...article});

  Database.articles.put(articleToSave)
}

/**
 * Get an article from indexedDB by id
 *
 * @param {number|string} key
 *
 * @returns {Promise<Article|undefined>}
 */
export const findArticle = (articleId: number|string): Promise<Article|undefined> => {
  let query: any;
  if (typeof articleId === 'string') {
    query = { slug: articleId };
  } else {
    query = articleId;
  }

  return Database.articles.get(query)
}

export const findArticles = (articlesIds: Array<number>): Promise<Array<Article>> => {

  return Database.articles.filter(
    (article: any) => includes(articlesIds, article.id)
  ).toArray()
}

export const findArticlesByParent = (parentId: number): Promise<Array<Article>> => {

  return Database.articles.filter(
    (article: any) => article.parentId === parentId || includes(article.parentIds, parentId)
  ).toArray()
}
