import React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import appendClassName from '../../../utils/string/appendClassName'

import { IOwnProps } from './ILabel'
import './Label.css'

const Label: React.FC<IOwnProps> = (props) => {
  const {
    label,
    className,
    children
  } = props;

  return (
    <TransitionGroup
      component={null}
    >
      <CSSTransition
          timeout={500}
          key={label}
          classNames={{
            exit: 'animated heartBeat',
          }}
          component={null}
        >
          <span className={appendClassName('b-label', className)}>
            {children ? children : label}
          </span>
        </CSSTransition>
    </TransitionGroup>
  )
}

export default Label;
