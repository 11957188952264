import stripTags from './stripTags'

/**
 * Get short variant of a text or html content
 *
 * @param text string
 * @param length number of words to be returned
 *
 * @returns string
 */
const getShort = (text: string, length: number): string => {
  if (length <= 0) {
    throw new Error(`Wrong length parameter ${length}`)
  }
  const plainText   = stripTags(text);
  const words       = plainText.split(/[\s]+/);
  const wordsCount  = words.length;

  if (length >= wordsCount) {
    return plainText
  }

  const shortText                     = words.splice(0, length).join(' ');
  const shortTextWithoutTrailingMark  = shortText.replace(/\W$/, '');

  return `${shortTextWithoutTrailingMark}…`
}

export default getShort
