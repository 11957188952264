import ImagePair from '../image-pair/ImagePair'

import IBanner from './IBanner'

/**
 * Banner entity
 */
class Banner implements IBanner {
  id:               number;
  title:            string;
  description:      string;
  imagePair:        ImagePair;
  position:         number;
  url:              string;
  originalImageUrl: string;

  constructor(rawBanner: any) {
    const banner = {...rawBanner};

    if ('id' in banner) {
      this.id               = banner.id;
      this.title            = banner.title;
      this.description      = banner.description;
      this.imagePair        = new ImagePair(banner.imagePair);
      this.position         = banner.position;
      this.url              = banner.url;
      this.originalImageUrl = banner.originalImageUrl;
    } else {
      this.id           = parseInt(banner.banner_id);
      this.title        = banner.banner;
      this.description  = banner.description;
      this.imagePair    = new ImagePair(banner.main_pair);
      this.position     = parseInt(banner.position);
      this.url          = banner.url;

      try {
        this.originalImageUrl = banner.main_pair.icon.image_path;
      } catch (error) {
        this.originalImageUrl = '';
      }
    }

    if (!this.id) {
      throw new Error('Id is missing. Check incoming object')
    }
  }
}

export default Banner
