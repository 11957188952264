import isEqual from 'lodash/isEqual'

import IAddToCart from './IAddToCart'
import ISelectedOption from '../product/ISelectedOption'

/**
 * Minimal product entity for request
 * 1. Add a product to cart
 * 2. Update product at cart
 * 3. Request product from api with params (options for example)
 */
class AddToCart implements IAddToCart {
  productId:        number;
  amount:           number;
  productOptions?:  Array<ISelectedOption>;

  constructor(rawRequest: any) {
    const request = {...rawRequest};

    if (!request.productId) {
      throw new Error('Property productId is missing')
    }

    this.productId = parseInt(request.productId);
    this.amount = parseInt(request.amount || '1');
    this.productOptions = request.productOptions;
  }

  addAmount(amount: number) {

    return new AddToCart({
      ...this,
      amount: this.amount += amount,
    })
  }

  setAmount(amount: number) {
    return new AddToCart({
      ...this,
      amount: amount,
    })
  }

  selectOption(optionId: number, value: any) {
    if (!this.productOptions || !this.productOptions.length) {
      return this
    }

    const foundOption = this.productOptions.find(option => option.optionId === optionId);

    if (foundOption) {
      foundOption.value = value;
      return this
    }

    return this
  }

  public getSelectedOptions(): Array<ISelectedOption> {
    return (this.productOptions || [])
  }

  /**
   * Returns true if both add to cart items are the same products, exept amount property
   */
  isEqual(another: AddToCart): boolean {
    return isEqual(
      {
        ...this,
        amount: 0,
      },
      {
        ...another,
        amount: 0
      }
    )
  }

  /**
   * Returns true if both add to cart items are the same products
   */
  isSame(another: AddToCart): boolean {
    return isEqual(
      {
        ...this,
      },
      {
        ...another,
      }
    )
  }


  /**
   * String id, unique to the same request params
   */
  toString():string {
    let params = [this.productId.toString()];

    params.push(this.amount.toString())

    if (this.productOptions) {
      this.productOptions.forEach(option => {
        params.push(option.optionId.toString())
        params.push(`${option.value}`)
      })
    }

    return params.join('-')
  }

  get uuid() {
    return this.toString()
  }
}

export default AddToCart
