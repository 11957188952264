import ICartUpdate from './ICartUpdate'
import AddToCart from './AddToCart'

/**
 * Request entity to update product at cart
 */
class CartUpdate implements ICartUpdate {
  cartId: string;
  addToCart: AddToCart;

  constructor(rawUpdate: any) {
    const cartUpdate = {...rawUpdate};

    this.cartId     = cartUpdate.cartId;
    this.addToCart  = cartUpdate.addToCart;
  }

  updateAddToCart(addToCart: AddToCart) {
    this.addToCart = addToCart;
  }
}

export default CartUpdate
