import Product from './Product'
import Filter from '../filter/Filter'
import notNull from '../../utils/notNull'
import AbstractItemsSelection from '../selection/AbstractItemsSelection'
import Sorting from '../selection/Sorting'
import IProductSelection from './IProductSelection'

class ProductSelection extends AbstractItemsSelection implements IProductSelection {
  products:       Array<Product>;
  filters:        Array<Filter>;
  filterHashes?:  string;

  constructor(rawProductSelection: any) {
    const productSelection = {...rawProductSelection};
    super(productSelection)

    this.products   = (productSelection.products || []).map((product: any) => new Product(product));
    this.filters    = (productSelection.filters || []).map((filter: any) => {
      try {
        return new Filter(filter)
      } catch (error) {
        console.warn(error);

        return null
      }
    }).filter(notNull);
    this.filterHashes = productSelection.filterHashes;
  }

  prependItems(products: Array<Product>): ProductSelection {
    return new ProductSelection({
      ...this,
      products: [
        ...products,
        ...this.products,
      ]
    })
  }

  fromUrl(url: string): ProductSelection|undefined {
    const urlParams = new URLSearchParams(url);
    let isEmpty = true;

    if (urlParams.get('sortBy')) {
      this.sorting = new Sorting({
        sortBy: urlParams.get('sortBy'),
        order: urlParams.get('order'),
      });
      isEmpty = false;
    }

    if (urlParams.get('q')) {
      this.search = urlParams.get('q') || '';
      isEmpty = false;
    }

    if (urlParams.get('filters')) {
      this.filterHashes = urlParams.get('filters') || '';
      isEmpty = false;
    }

    if (isEmpty) {
      return undefined
    } else {
      return this
    }
  }

  filter(hash: string) : ProductSelection {

    return new ProductSelection({
      ...this,
      filterHashes: hash,
    })
  }
}

export default ProductSelection
