import React from 'react'
import { Route } from 'react-router-dom'
import { Router } from 'react-router-dom'
import { config } from 'dotenv'
import 'animate.css'

import ScreenHandler from './screen-handler/ScreenHandlerContainer'
import PageLoadIndicator from './subcomponents/page-load-indicator/PageLoadIndicatorContainer'
import history from '../url/history'

import './App.css'
import '../styles/vendors/bootstrap.min.css'
import '../styles/variables.css'
import '../styles/common.css'
import '../styles/placeholders.css'

const App: React.FC = () => {

  config()
  return (
    <Router history={history}>
      <PageLoadIndicator />
      <Route component={ScreenHandler} />
    </Router>
  );
}


export default App

