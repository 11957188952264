import values from 'lodash/values'
import forOwn from 'lodash/forOwn'
import sortBy from 'lodash/sortBy'

import ICart from './ICart'
import CartProduct from './CartProduct'
import ProductGroup from './ProductGroup'
import UserData from './UserData'
import Payment from '../payment/Payment'
import IWithTotals from './IWithTotals'

class Cart implements ICart, IWithTotals {
  products: Array<CartProduct>;
  amount: number;
  productGroups: Array<ProductGroup>;

  chosenShippings: Array<number>;
  chosenPayment: number;
  userData?: UserData;

  payments: Array<Payment>;
  paymentData: {
    isValid: boolean;
    [others: string]: any;
  }

  totalPrice:       number;
  subtotalPrice:    number;
  discountPrice:    number;
  shippingPrice:    number;
  taxSubtotalPrice: number;

  /**
   * set value = value from obj with ICart interface || obj from cscart api || default value
   */
  constructor(rawCart: ICart | any) {

    const cart = {...rawCart};

    if (cart.productGroups) {

      this.products   = cart.products.map((product: any) => new CartProduct(product));
      this.amount     = cart.amount;

      this.productGroups = cart.productGroups.map((group: any) =>
        new ProductGroup(group))

      this.chosenShippings = cart.chosenShippings;

      this.userData = cart.userData ? new UserData(cart.userData) : cart.userData;

      this.payments = cart.payments.map((payment: any) =>
        new Payment(payment));

      this.chosenPayment    = cart.chosenPayment;
      this.paymentData      = cart.paymentData;

      this.totalPrice       = cart.totalPrice;
      this.subtotalPrice    = cart.subtotalPrice;
      this.discountPrice    = cart.discountPrice;
      this.shippingPrice    = cart.shippingPrice;
      this.taxSubtotalPrice = cart.taxSubtotalPrice;
    } else {
      const cartProducts = values(
        forOwn(cart.products, (product, cartId) => {
          product.cart_id = cartId;
        })
      );

      const cartProductsTypeCasted        = cartProducts.map((cartProduct: any) => new CartProduct(cartProduct));
      const cartProductsTypeCastedSorted  = sortBy(cartProductsTypeCasted, cartProduct => cartProduct.product.id);
      this.products                       = cartProductsTypeCastedSorted;
      this.amount                         = cart.amount || 0;

      this.productGroups = (values(cart.product_groups) || []).map((group: any) =>
        new ProductGroup(group))

      const chosenShippings = (cart.chosen_shipping || []).map((id: string) => parseInt(id)) || [];

      if (!chosenShippings.length && this.productGroups.length) {
        this.chosenShippings = this.productGroups.map(group => group.shippings.length ? group.shippings[0].id : 0)
      } else {
        this.chosenShippings = chosenShippings;
      }

      this.userData = cart.user_data ? new UserData(cart.user_data) : cart.user_data;

      const paymentsArray = values(
        forOwn(cart.payments, (payment, paymentId) => {
          payment.id = paymentId;
        })
      )

      this.payments = paymentsArray.map((payment: any) =>
        new Payment(payment));

      this.chosenPayment = cart.chosen_payment || 0;

      if (this.chosenPayment === 0 && this.payments.length) {
        this.chosenPayment = this.payments[0].id;
      }

      this.paymentData = { isValid: false };

      this.totalPrice       = cart.total ? parseFloat(cart.total) : 0;
      this.subtotalPrice    = cart.subtotal ? parseFloat(cart.subtotal) : 0;
      this.discountPrice    = cart.discount ? parseFloat(cart.discount) : 0;
      this.shippingPrice    = cart.shipping_cost ? parseFloat(cart.shipping_cost) : 0;
      this.taxSubtotalPrice = cart.tax_subtotal ? parseFloat(cart.tax_subtotal) : 0;
    }
  }
}

export default Cart
