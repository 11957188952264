export default {
  /**
   * Common - can be used anywhere
   */
  'app.common.removing': 'Removing...',
  'app.common.sending': 'Sending...',
  'app.common.clear': 'Clear',
  'app.common.saved-as-draft': 'Saved as draft',
  'app.common.or': 'Or',

  /**
   * Wishlist screen
   * Can be used on wishlist screen only
   */
  'app.screens.wishlist.title': 'Wishlist',
  'app.screens.wishlist.empty': 'List is empty',
  'app.screens.wishlist.empty-description': 'Press ❤ at any product to add it to the list',

  /**
   * Cart screen
   * Can be used on cart screen only
   */
  'app.screens.cart.title': 'Cart',
  'app.screens.cart.empty': 'List is empty',
  'app.screens.cart.empty-description': 'Buy any product to see it at this list',
  'app.screens.cart.remove-confirm': 'Are you shure to remove this product?',

  'app.screens.orders.title': 'Orders',
  'app.screens.order.title': 'Order #{orderNumber}',

  'app.screens.checkout.user-info-title': 'Customer information',
  'app.screens.checkout.payment-title': 'Payment method',
  'app.screens.checkout.shipping-title': 'Shipping method',

  'app.screens.checkout-complete.title': 'Order #{orderId} has been placed!',
  'app.screens.checkout-complete.text': 'Thank you!',
  'app.screens.checkout-complete.go-to-order-button-title': 'Show me the order',

  'app.screens.vendors.title': 'Vendors',

  /**
   * Apply for vendor
   */
  'app.components.vendor-signup.title': 'Apply for a vendor account',
  'app.components.vendor-signup.success-result': 'Your request was successfully added. You will receive the email with the answer later',
  'app.components.vendor-signup.submit-label': 'Apply',

  /**
   * Search screen
   */
  'app.screens.search.title': 'Search',

  /**
   * Login screen/modal
   */
  'app.screens.login.title': 'Login',
  'app.screens.login.submit-button': 'Login',
  'app.screens.login.register-button': 'Register',
  'app.screens.login.wrong-credentials': 'User with such login and password not found',
  'app.screens.login.placeholders.email': 'Email',
  'app.screens.login.placeholders.password': 'Password',

  /**
   * Profile screen
   */
  'app.screens.profile.title': 'My profile',

  'app.cart.messages.need-auth': 'You should login to add products to cart',
  'app.cart.messages.success-added-to-cart': 'Product added to cart',

  'app.api.errors.no-internet': 'Network connection error',
  'app.api.errors.not-found': 'Not found',
  'app.api.session-expired': 'Your session has expired. Please log in',

  'app.system.ready-to-update': 'New version of the app is available and will be used when all tabs for this page are closed.',
  'app.system.updated': 'Application updated',

  'app.components.reviews.add-new-button': 'Leave your feedback',
  'app.components.reviews.form.textarea-title': 'Your feedback',
  'app.components.reviews.form.submit-title': 'Send',
  'app.components.reviews.form.success-submit': 'Thank you! Your review was sent for verification.',
  'app.components.reviews.form.error-submit': 'Some error happened during review submit.',
  'app.components.reviews.no-content': 'No reviews available',
  'app.components.reviews.total-reviews': `{reviews, number} {reviews, plural,
    one {review}
    other {reviews}
  }`,

  'app.components.order-single-item': 'Order #{orderNumber}',

  'app.components.userinfo.guest': 'Unauthorized',
  'app.components.userinfo.guest-acronym': 'G',

  'app.form.sections.customer-information': 'Customer information',
  'app.form.sections.billing-information': 'Billing information',
  'app.form.sections.shipping-information': 'Shipping information',
  'app.form.sections.products-information': 'Products information',

  'app.form.labels.firstname': 'Firstname',
  'app.form.labels.lastname': 'Lastname',
  'app.form.labels.email': 'Email',
  'app.form.labels.phone': 'Phone',
  'app.form.labels.country': 'Country',
  'app.form.labels.county': 'County',
  'app.form.labels.zipcode': 'Zipcode',
  'app.form.labels.state': 'State',
  'app.form.labels.city': 'City',
  'app.form.labels.address': 'Address',
  'app.form.labels.address2': 'Address 2',
  'app.form.labels.payment-method': 'Payment method',

  'app.form.validation.too-short': 'Too short',
  'app.form.validation.too-long': 'Too long',
  'app.form.validation.requiered': 'Required',
  'app.form.validation.invalid-email': 'Invalid email',

  'app.components.product-sorting.name': 'By product name',
  'app.components.product-sorting.price': 'By product price',
  'app.components.product-sorting.new-products': 'New products',
  'app.components.product-sorting.bestsellers': 'Bestsellers',
  'app.components.product-sorting.discounts': 'Discounts',

  'app.components.vendor-sorting.name': 'By vendor name',
  'app.components.vendor-sorting.rating': 'By vendor rating',

  'app.components.order-sorting.date': 'By order date',

  'app.common.sorting.alpha.asc': 'A\u2011Z',
  'app.common.sorting.alpha.desc': 'Z\u2011A',

  'app.common.sorting.number.asc': 'Increase',
  'app.common.sorting.number.desc': 'Decrease',

  'app.common.sorting.date.asc': 'Older',
  'app.common.sorting.date.desc': 'Newer',

  'app.components.quantity-selector.down': '-',
  'app.components.quantity-selector.up': '+',

  'app.common.address': 'Address',
  'app.common.city': 'City',
  'app.common.state': 'State',
  'app.common.country': 'Country',

  'app.components.vendor.registered': 'Registered: ',
  'app.screens.vendor.tab-aria-label': 'Vendor tabs',
  'app.screens.vendor.info': 'Information',
  'app.screens.vendor.catalog': 'Catalog',
  'app.screens.vendor.rating': 'Rating',

  'app.components.offline.title': 'offline',
  'app.components.offline.message': 'You can try reloading the page. Meanwhile, recently visited pages are still available for viewing.',
  'app.components.offline.reload-button': 'Try to reload',

  'app.components.navigation-bar.offline': 'offline',
  'app.components.navigation-bar.offline-title': 'You can try reloading the page. Meanwhile, recently visited pages are still available for viewing.',
  'app.components.navigation-bar.offline-description': 'Try to reload',

  'app.components.no-content.title': 'No content, yet',

  'app.components.product-single-item.add-to-cart-button-title': 'Buy - {price}',

  'app.components.totals.subtotal': 'Subtotal',
  'app.components.totals.discount': 'Discount',
  'app.components.totals.shipping-cost': 'Shipping cost',
  'app.components.totals.total': 'Total',
  'app.components.totals.tax-subtotal': 'Tax subtotal',

  'app.components.checkout-button.button-name': 'Checkout',

  'app.components.reviews.title': 'Ratings & reviews',

  'app.components.search.products.placeholder': 'Search products',

  'app.components.products-selection.no-results': 'No products found',
  'app.components.products-selection.filters-button': 'Filters',

  /**
   * Main menu
   */
  'app.components.main-menu.labels.hamburger': 'Catalog',
  'app.components.main-menu.labels.home-button': 'Home',
  'app.components.main-menu.labels.wishlist-button': 'Favorites',
  'app.components.main-menu.labels.profile-button': 'Profile',
  'app.components.main-menu.labels.cart-button': 'Cart',

  /**
   * Online payment handler
   */
  'app.components.online-payment-handler.title': 'Payment',

  /**
   * Load More
   */
  'app.components.load-more.button-label': 'Load more',

  /**
   * Gdpr Agreement
   */
  'app.components.gdpr.agreement.title': 'local storage use',
  'app.components.gdpr.agreement.description': `This website uses Local Storage for process your
    personal data to ensure you get the best experience on our site. This tool enable you to enjoy
    certain features (like saving your products to cart or wish list). Please note that we don't
    use cookies, however they can be used by third-party tools (like analytics widgets or google authorization).`,
  'app.components.gdpr.agreement.conditions': `You accept the use of Local Storage or other identifiers by closing or dismissing this notice
    by clicking a button or by continuing to browse otherwise.`,
  'app.components.gdpr.agreement.accept-button': 'Accept',
}
