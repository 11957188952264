import { VendorAction } from '../actions/vendor/VendorAction'
import { ProductAction } from '../actions/product/ProductAction'
import VendorState from '../types/VendorState'
import VendorsActionsTypes from '../actions/vendor/VendorActionTypes'
import ProductActionTypes from '../actions/product/ProductActionTypes'
import VendorActionTypes from '../actions/vendor/VendorActionTypes'

export const initialState: VendorState = {
  isRequesting: false,
  isProductsRequesting: false,
  error:        null,
}

const Vendors = function orders(state: VendorState = initialState, action: VendorAction | ProductAction): VendorState {
  switch (action.type) {
    case VendorsActionsTypes.REQUEST_VENDOR:
      return {
        ...state,
        isRequesting: true,
      }

    case VendorsActionsTypes.REQUEST_VENDOR_SUCCESS:
    case VendorsActionsTypes.DB_REQUEST_VENDOR_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        vendor: action.payload.vendor,
      }

    case VendorsActionsTypes.REQUEST_VENDOR_FAILURE:
      return {
        ...state,
        isRequesting: false,
        error: action.payload.error,
      }

    case VendorsActionsTypes.DB_REQUEST_VENDOR_FAILURE:
      return {
        ...state,
        isRequesting: false,
      }

    case ProductActionTypes.REQUEST_VENDOR_PRODUCTS:

      return {
        ...state,
        isProductsRequesting: true,
        productSelection: action.payload.productSelection || state.productSelection,
      }

    case ProductActionTypes.REQUEST_VENDOR_PRODUCTS_SUCCESS:

      return {
        ...state,
        productSelection: (
          (action.payload.shouldAppend && state.productSelection)
            ?
            action.payload.productSelection.prependItems(state.productSelection.products)
            :
              action.payload.productSelection),
        isProductsRequesting: false,
      }

    case ProductActionTypes.REQUEST_VENDOR_PRODUCTS_FAILURE:
      return {
        ...state,
        productSelection: undefined,
        isProductsRequesting: false,
      }

    case ProductActionTypes.DB_REQUEST_VENDOR_PRODUCTS_SUCCESS:

      return {
        ...state,
        productSelection: action.payload.productSelection,
        isProductsRequesting: false
      }

    case VendorActionTypes.SET_CURRENT_VENDOR:

      return {
        ...state,
        vendor: action.payload.vendor,
        isRequesting: false,
      }

    case VendorActionTypes.SET_VENDOR_IS_REQUESTING:

      return {
        ...state,
        isRequesting: true,
      }

    default:
      return state;
  }
}

export default Vendors
