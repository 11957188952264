import differenceWith from 'lodash/differenceWith'

import { ReviewAction } from '../actions/Review'
import ReviewState from '../types/ReviewState'
import ReviewsActionsTypes from '../types/actions/Review'
import ReviewsThread, { createUuid } from '../../entities/testimonial/ReviewsThread'
import { getReviewThreadBy } from '../selectors/Review';
import uniq from 'lodash/uniq'
import TestimonialDraft from '../../entities/testimonial/TestimonialDraft'

export const initialState: ReviewState = {
  reviews:        [],
  errors:         [],
  requestingIds:  [],
  drafts:         [],
  successes:      [],
}

const removeFromRequestingIds = (requestingIds: ReviewState['requestingIds'], uuid: string) => {
  const requestingIdsSet = new Set(requestingIds);

  requestingIdsSet.delete(uuid)
  return  Array.from(requestingIdsSet)
}

const Review = function review(state: ReviewState = initialState, action: ReviewAction): ReviewState {
  switch (action.type) {
    case ReviewsActionsTypes.REQUEST_REVIEW_THREAD:
      const requestingIds = new Set(state.requestingIds);
      requestingIds.add(createUuid(action.payload.id, action.payload.type))

      return {
        ...state,
        requestingIds: Array.from(requestingIds),
      }

    case ReviewsActionsTypes.REQUEST_REVIEW_THREAD_SUCCESS:

      // try to get previuos thread
      const oldThread = (
        action.payload.shouldAppend
          ?
            getReviewThreadBy(
              state,
              {
                id: action.payload.thread.parentId,
                type: action.payload.thread.parentType
              }
            )
          : null
      )

      // if exists - merge selection items
      const newThread = (
        oldThread
        ?
          new ReviewsThread({
            ...oldThread,
            selection: action.payload.thread.selection.prependItems(oldThread.selection.testimonials)
          })
        :
          action.payload.thread
      )

      return {
        ...state,
        requestingIds: removeFromRequestingIds(state.requestingIds, action.payload.thread.uuid),
        reviews: [
          ...differenceWith(
            state.reviews,
            [action.payload.thread],
            (stateThread, actionThread) => stateThread.uuid === actionThread.uuid
          ),
          newThread
        ],
      }

    case ReviewsActionsTypes.REQUEST_REVIEW_THREAD_FAILURE:
      return {
        ...state,
        requestingIds: removeFromRequestingIds(state.requestingIds, action.payload.uuid),
      }

    case ReviewsActionsTypes.REQUEST_REVIEW_ADD_FAILURE:

      let newDrafts: Array<TestimonialDraft> = [];
      const draft   = state.drafts.find(draft => draft.uuid === action.payload.uuid);

      if (draft) {
        newDrafts = [
          ...differenceWith(
            state.drafts,
            [action.payload.uuid],
            (stateDraft, uuid) => stateDraft.uuid === uuid
          ),
          new TestimonialDraft({
            ...draft,
            isFinal: false,
          })

        ];
      } else {
        newDrafts = [...state.drafts];
      }

      return {
        ...state,

        errors: [
          ...differenceWith(
            state.errors,
            [action.payload.uuid],
            (stateError, uuid) => stateError.uuid === uuid
          ),
          {
            uuid: action.payload.uuid,
            error: action.payload.error
          }
        ],
        drafts: newDrafts,
      }

    case ReviewsActionsTypes.REQUEST_REVIEW_ADD_SUCCESS:

      return {
        ...state,
        successes: uniq([
          ...state.successes,
          action.payload.uuid,
        ])
      }

    case ReviewsActionsTypes.CREATE_TESTIMONIAL_DRAFT:

      return {
        ...state,
        drafts: [
          ...differenceWith(
            state.drafts,
            [action.payload.draft],
            (stateDraft, actionDraft) => stateDraft.uuid === actionDraft.uuid
          ),
          action.payload.draft
        ],
      }

    case ReviewsActionsTypes.REMOVE_TESTIMONIAL_DRAFT:

      return {
        ...state,
        drafts: [
          ...differenceWith(
            state.drafts,
            [action.payload.uuid],
            (stateDraft, uuid) => stateDraft.uuid === uuid
          )
        ],
      }

    default:
      return state;
  }
}

export default Review
