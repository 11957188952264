import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import NotificationsProvider from './utils/notifications/NotificationsProvider'
import App from './components/App'
import ErrorBoundary from './components/system/error-boundary/ErrorBoundary'
import * as serviceWorker from './serviceWorker'

import './index.css'

import { store } from './redux/store'

const startApp = () => {
  const rootElement = document.getElementById('root');

  if (!rootElement) {
    throw new Error('Root element is missing')
  }

  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <App />
        <NotificationsProvider />
      </Provider>
    </ErrorBoundary>,
    rootElement
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();

declare let window: any;

const deviceReady = new Promise((resolve, reject) => {
  if (window.cordova) {
    document.addEventListener('deviceready', resolve, false)
  } else {
    resolve()
  }
});

Promise.all([deviceReady]).then(() => {
  startApp()
})

console.log(`
██████╗ ██╗    ██╗ █████╗      ██╗███████╗████████╗
██╔══██╗██║    ██║██╔══██╗     ██║██╔════╝╚══██╔══╝
██████╔╝██║ █╗ ██║███████║     ██║█████╗     ██║
██╔═══╝ ██║███╗██║██╔══██║██   ██║██╔══╝     ██║
██║     ╚███╔███╔╝██║  ██║╚█████╔╝███████╗   ██║
╚═╝      ╚══╝╚══╝ ╚═╝  ╚═╝ ╚════╝ ╚══════╝   ╚═╝
              https://simtechdev.com/
`)
