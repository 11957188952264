import { OrderAction } from '../actions/Order'
import OrdersState from '../types/OrdersState'
import OrdersActionsTypes from '../types/actions/Order'

export const initialState: OrdersState = {
  orderId:              0,
  isRequesting:         false,
  error:                null,
}

const Orders = function orders(state: OrdersState = initialState, action: OrderAction): OrdersState {
  switch (action.type) {
    case OrdersActionsTypes.REQUEST_ORDERS:
      return {
        ...state,
        isRequesting: true,
      }

    case OrdersActionsTypes.REQUEST_ORDERS_SUCCESS:

      return {
        ...state,
        orderSelection: (
          (action.payload.shouldAppend && state.orderSelection)
            ?
            action.payload.orderSelection.prependItems(state.orderSelection.orders)
            :
              action.payload.orderSelection),
        isRequesting: false,
        error: null,
      }

    case OrdersActionsTypes.DB_REQUEST_ORDERS_SUCCESS:
      return {
        ...state,
        orderSelection: action.payload.selection,
        isRequesting: false
      }

    case OrdersActionsTypes.REQUEST_ORDERS_FAILURE:
      return {
        ...state,
        orderSelection: undefined,
        isRequesting: false,
        error: action.payload.error,
      }

    default:
      return state;
  }
}

export default Orders
