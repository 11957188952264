import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import SelectionSearch from '../search/SelectionSearch'
import { intl } from '../../../../intl/IntlGlobalProvider'
import Autocomplete from '../autocomplete/Autocomplete'
import Image from '../../../subcomponents/image/Image'

import { IProps } from './ISelectionGlobalSearch'
import messages from './SelectionGlobalSearch.messages'
import './SelectionGlobalSearch.css'

const maximumProductResults = 10;

const SelectionGlobalSearch:React.FC<IProps> = (props) => {

  const {
    performSearch,
    searchProducts,
    focus = false,
    openSearchAutocomplete,
    closeSearchAutocomplete,
    isAutocompleteOpened,
    foundProducts,
    queryHistories,
  } = props;

  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const [debouncedOnChange] = useDebouncedCallback(query => searchProducts(query), 600);

  const [ oldUrl, setOldUrl ] = React.useState(history.location.pathname + history.location.search);
  const newUrl = history.location.pathname + history.location.search;

  React.useEffect(() => {
    if (oldUrl !== newUrl) {
      closeSearchAutocomplete()
      setOldUrl(newUrl)
    }
  }, [newUrl, oldUrl, closeSearchAutocomplete])

  const foundProductNodes = (
    foundProducts
    ?
      foundProducts.slice(0, maximumProductResults).map(product => {
        const images = product.getSmallIcons();

        if (images.length) {
          const image = images[0];

          return (
            <Link to={product.url} key={product.id}>
              <p className='b-autocomplete__item-name'>{product.name}</p>
              <Image
                src={image.url}
                className='b-autocomplete__item-thumbnail'
              />
            </Link>
          )
        }

        return null
      })
    :
      []
  );

  const historyNodes = (queryHistories.map(query =>
    <button
      className='b-history-button'
      onClick={() => performSearchWithUrl(query)} key={query}
    >
      <HistoryIcon size={23} className='b-history-button__icon' />
      {query}
    </button>
  ));

  const performSearchWithUrl = (query: string) => {
    const urlParams = new URLSearchParams(history.location.search);
    urlParams.set('q', query)
    performSearch(urlParams.toString())
  }

  return (
    <Autocomplete
      key='autocomplete'
      onClickAway={() =>closeSearchAutocomplete()}
      autocomplete={isAutocompleteOpened}
      autocompleteContent={[
        ...historyNodes,
        ...foundProductNodes,
      ]}
    >
      <SelectionSearch
        value={urlParams.get('q') || ''}
        focus={focus}
        key='search'
        placeholder={intl.formatMessage(messages.placeholder)}
        onSubmit={(query) => performSearchWithUrl(query)}
        onChange={(search) => debouncedOnChange(search)}
        onFocus={() => openSearchAutocomplete()}
      />
    </Autocomplete>
  )
}

export default SelectionGlobalSearch

const HistoryIcon = require('react-feather/dist/icons/clock').default;
