import { ActionsObservable, ofType, } from 'redux-observable'
import { switchMap, filter } from 'rxjs/operators'
import { of, } from 'rxjs'
import { IdentifierFailuredAction, FailedRequestSelectionAction } from '../../actions/'
import AbstractItemsSelection from '../../../entities/selection/AbstractItemsSelection'

/**
 * Map failed identifier action to another
 *
 * @param action$
 * @param {string} type
 * @param mapToAction
 */
export function switchFailedRequestActionToAction<T extends IdentifierFailuredAction, O> (
  action$: ActionsObservable<T>,
  type: string,
  mapToAction: (id: any) => O
) {
  return action$.pipe(
    ofType(type),
    // get only network error
    filter((action: T) => action.payload.error.status === 0),
    switchMap((action: T) => of(mapToAction(action.payload.id))),
  )
}

/**
 * Map failed selection action to another
 *
 * @param action$
 * @param {string} type
 * @param mapToAction
 */
export function switchFailedRequestSelectionActionToAction<S extends AbstractItemsSelection, T extends FailedRequestSelectionAction<S>, O> (
  action$: ActionsObservable<T>,
  type: string,
  mapToAction: (selection: S|undefined) => O
) {
  return action$.pipe(
    ofType(type),
    // get only network error
    filter((action: T) => action.payload.error.status === 0),
    switchMap((action: T) => of(mapToAction(action.payload.selection))),
  )
}

