enum CartActionsTypes {
  REQUEST_CART = 'REQUEST_CART',
  REQUEST_CART_SUCCESS = 'REQUEST_CART_SUCCESS',
  REQUEST_CART_FAILURE = 'REQUEST_CART_FAILURE',

  ADD_TO_ADD_QUEUE              = 'ADD_TO_ADD_QUEUE',
  REMOVE_FROM_ADD_QUEUE         = 'REMOVE_FROM_ADD_QUEUE',

  ADD_TO_UPDATE_QUEUE           = 'ADD_TO_UPDATE_QUEUE',
  REMOVE_FROM_UPDATE_QUEUE      = 'REMOVE_FROM_UPDATE_QUEUE',

  ADD_TO_DELETE_QUEUE             = 'ADD_TO_DELETE_QUEUE',
  REMOVE_FROM_DELETE_QUEUE        = 'REMOVE_FROM_DELETE_QUEUE',

  ADD_TO_CART_ERRORS              = 'ADD_TO_CART_ERRORS',
  REMOVE_FROM_CART_ERRORS         = 'REMOVE_FROM_CART_ERRORS',
  FILL_ERROR_PRODUCTS             = 'FILL_ERROR_PRODUCTS',
  CLEAR_CART_ERRORS               = 'CLEAR_CART_ERRORS',

  FILL_QUEUE = 'FILL_QUEUE',
  CLEAR_QUEUE = 'CLEAR_QUEUE',
  REQUEST_ADD_TO_CART = 'REQUEST_ADD_TO_CART',
  REQUEST_ADD_TO_CART_SUCCESS = 'REQUEST_ADD_TO_CART_SUCCESS',
  REQUEST_ADD_TO_CART_FAILURE = 'REQUEST_ADD_TO_CART_FAILURE',
  CLEAR_CART = 'CLEAR_CART',

  REQUEST_CLEAR_CART = 'REQUEST_CLEAR_CART',
  REQUEST_CLEAR_CART_SUCCESS = 'REQUEST_CLEAR_CART_SUCCESS',
  REQUEST_CLEAR_CART_FAILURE = 'REQUEST_CLEAR_CART_FAILURE',

  RESET_CART_STATE = 'RESET_CART_STATE',

  SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD',
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
  SET_PAYMENT_DATA = 'SET_PAYMENT_DATA',

  SET_USER_DATA = 'SET_USER_DATA',
  REQUEST_UPDATE_USER_DATA = 'REQUEST_UPDATE_USER_DATA',
  REQUEST_UPDATE_USER_DATA_SUCCESS = 'REQUEST_UPDATE_USER_DATA_SUCCESS',
  REQUEST_UPDATE_USER_DATA_FAILURE = 'REQUEST_UPDATE_USER_DATA_FAILURE',

  REQUEST_CHECKOUT_FORM = 'REQUEST_CHECKOUT_FORM',
  REQUEST_CHECKOUT_FORM_SUCCESS = 'REQUEST_CHECKOUT_FORM_SUCCESS',
  REQUEST_CHECKOUT_FORM_FAILURE = 'REQUEST_CHECKOUT_FORM_FAILURE',

  ORDER_SUCCESS = 'ORDER_SUCCESS',
}

export default CartActionsTypes
