import ArticleActionTypes from './ArticleActionTypes'
import Article from '../../../entities/article/Article'
import ApiError from '../../../entities/error/ApiError'
import { persistArticle } from '../../../db/models/Article'

export interface RequestArticle {
  type: ArticleActionTypes.REQUEST_ARTICLE;
  payload: {
    articleId: number;
  }
}

export function requestArticle(id: number): RequestArticle {
  return {
    type: ArticleActionTypes.REQUEST_ARTICLE,
    payload: {
      articleId: id
    }
  }
}

export interface RequestArticleSuccess {
  type: ArticleActionTypes.REQUEST_ARTICLE_SUCCESS;
  payload: {
    article: Article;
  }
}

export function requestArticleSuccess(rawArticle: any): RequestArticleSuccess {
  const article = new Article(rawArticle);
  persistArticle(article)

  return {
    type: ArticleActionTypes.REQUEST_ARTICLE_SUCCESS,
    payload: {
      article
    }
  }
}

export interface RequestArticleFailure {
  type: ArticleActionTypes.REQUEST_ARTICLE_FAILURE;
  payload: {
    articleId: number;
    error: ApiError;
  }
}

export function requestArticleFailure(id: number, error: any): RequestArticleFailure {

  const apiError = new ApiError({
    status: error.status || 0,
    shouldRetry: error.shouldRetry || false,
    message: error.message || 'Unhandled error'
  })

  return {
    type: ArticleActionTypes.REQUEST_ARTICLE_FAILURE,
    payload: {
      articleId: id,
      error: apiError
    }
  }
}
