
import React from 'react'
import { NavLink } from 'react-router-dom'

import Label from '../../subcomponents/label/Label'

import appendClassName from '../../../utils/string/appendClassName'
import { createCartLinkUrl } from '../../../url'

import { IProps } from './ICartButton'
import './CartButton.css'

const CartButton: React.FC<IProps> = (props) => {
  const {
    amount,
    size,
    className,
    isQueued,
    label,
  } = props;

  return (
    <NavLink
      exact={true}
      to={{
        pathname: createCartLinkUrl(),
        state: {
          modal: true,
        }
      }}
      className={appendClassName('b-cart-button', className)}
    >
      <div className='b-cart-button__icon-wrapper'>
        <ShoppingCartIcon size={size} />
        {
          !!amount && !isQueued
          &&
            <Label
              label={amount}
              className='b-cart-button__amount'
            />
        }
        {
          !!isQueued
          &&
            <Label
            className='b-cart-button__is-queued'
            >
              <LoaderIcon
                size={16}
                className='animated rotate infinite'
              />
            </Label>
        }
      </div>
      {
        label
        &&
          <p className='b-main-menu__button-label b-main-menu__button-signature--padding-left'>
            {label}
          </p>
      }
    </NavLink>
  )
}

export default CartButton

const LoaderIcon       = require('react-feather/dist/icons/loader').default;
const ShoppingCartIcon = require('react-feather/dist/icons/shopping-cart').default;
