import CategoryActionTypes from './CategoryActionTypes'
import Category from '../../../entities/category/Category'

export interface DbRequestCategory {
  type: CategoryActionTypes.DB_REQUEST_CATEGORY;
  payload: {
    id: number|string;
  }
}

export function dbRequestCategory(id: number|string): DbRequestCategory {
  return {
    type: CategoryActionTypes.DB_REQUEST_CATEGORY,
    payload: {
      id,
    }
  }
}

export interface DbRequestCategorySuccess {
  type: CategoryActionTypes.DB_REQUEST_CATEGORY_SUCCESS;
  payload: {
    category: Category;
  }
}

export function dbRequestCategorySuccess(category: Category): DbRequestCategorySuccess {

  return {
    type: CategoryActionTypes.DB_REQUEST_CATEGORY_SUCCESS,
    payload: {
      category
    }
  }
}

export interface DbRequestCategoryFailure {
  type: CategoryActionTypes.DB_REQUEST_CATEGORY_FAILURE;
  payload: {
    id: number|string;
  }
}

export function dbRequestCategoryFailure(id: number|string): DbRequestCategoryFailure {

  return {
    type: CategoryActionTypes.DB_REQUEST_CATEGORY_FAILURE,
    payload: {
      id,
    }
  }
}
