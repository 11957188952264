import { LayoutsAction } from '../actions/Layouts'
import LayoutsState from '../types/LayoutsState'
import LayoutsActionsTypes from '../types/actions/Layouts'
import LayoutTypes from '../../constants/LayoutTypes'

export const initialState: LayoutsState = {
  layouts:  [],
}

const removeLayout = (
  layouts: LayoutsState['layouts'],
  type: LayoutTypes
): LayoutsState['layouts'] =>
  layouts.filter(layout => layout.type !== type)

const Layouts = function layout(state: LayoutsState = initialState, action: LayoutsAction): LayoutsState {
  switch (action.type) {
    case LayoutsActionsTypes.REQUEST_BLOCKS_SUCCESS:
    case LayoutsActionsTypes.DB_REQUEST_BLOCKS_SUCCESS:

      return {
        ...state,
        layouts: [
          ...removeLayout(state.layouts, action.payload.layout.type),
          ...[action.payload.layout]
        ]
      }

    default:
      return state;
  }
}

export default Layouts
