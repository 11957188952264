import { initialState as AuthInitialState } from '../reducers/Auth'
import { initialState as ArticleInitialState } from '../reducers/Article'
import { initialState as CartInitialState } from '../reducers/Cart'
import { initialState as CategoryInitialState } from '../reducers/Category'
import { initialState as LayoutsInitialState } from '../reducers/Layouts'
import { initialState as NavigationBarInitialState } from '../reducers/NavigationBar'
import { initialState as ProductInitialState } from '../reducers/Product'
import { initialState as UiInitialState } from '../reducers/Ui'
import { initialState as WishlistInitialState } from '../reducers/Wishlist'
import { initialState as OrdersInitialState } from '../reducers/Orders'
import { initialState as OrderInitialState } from '../reducers/Order'
import { initialState as ReviewInitialState } from '../reducers/Review'
import { initialState as VendorsInitialState } from '../reducers/Vendors'
import { initialState as VendorInitialState } from '../reducers/Vendor'
import { initialState as SearchInitialState } from '../reducers/Search'
import { initialState as ApplyForVendorState } from '../reducers/ApplyForVendor'

export const initialState = {
  Auth:           AuthInitialState,
  Article:        ArticleInitialState,
  Cart:           CartInitialState,
  Wishlist:       WishlistInitialState,
  Ui:             UiInitialState,
  NavigationBar:  NavigationBarInitialState,
  Layouts:        LayoutsInitialState,
  Category:       CategoryInitialState,
  Product:        ProductInitialState,
  Orders:         OrdersInitialState,
  Order:          OrderInitialState,
  Review:         ReviewInitialState,
  Vendors:        VendorsInitialState,
  ApplyForVendor: ApplyForVendorState,
  Vendor:         VendorInitialState,
  Search:         SearchInitialState,
}
