import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

import cscartApi from './cscart-api'
import config from '../config/Config'
import CartProduct from '../entities/cart/CartProduct'
import OrderSelection from '../entities/order/OrderSelection'
import SortOrder from '../constants/SortOrder'

export const create = (orderData: {
  products: Array<CartProduct>;
  shippingIds: Array<number>;
  paymentId: number;
  couponCodes?: Array<string>;
  userData?: any;
  paymentInfo?: any;
}) =>
  cscartApi
    .orders
    .create({
      products:     orderData.products.map(cartProduct => ({
        amount: cartProduct.amount,
        product_id: cartProduct.product.id,
        cart_id: cartProduct.cartId,
        product_options: mapValues(keyBy(cartProduct.product.getSelectedOptions(), 'optionId'), 'value')
      })),
      shippingIds:  orderData.shippingIds,
      paymentId:    orderData.paymentId,
      userData:     orderData.userData,
      paymentInfo:  orderData.paymentInfo,
    })

export const getOrder = (orderId: number) =>
  cscartApi
    .orders
    .setIconSize([
      config.productSmallIconWidth,
      config.productSmallIconHeight
    ])
    .setIconSize([
      config.productBigIconWidth,
      config.productBigIconWidth
    ])
    .one(orderId)
    .get()

export const getOrders = (
  orderSelection?: OrderSelection
): Promise<any> => {
  let baseRequest = (
    cscartApi
      .orders
      .setIconSize([
        config.productSmallIconWidth,
        config.productSmallIconHeight
      ])
      .setIconSize([
        config.productBigIconWidth,
        config.productBigIconWidth
      ])
  )

  if (orderSelection) {
    if (orderSelection.sorting) {
      baseRequest = baseRequest.orderBy(orderSelection.sorting.sortBy)

      if (orderSelection.sorting.order === SortOrder.DESC) {
        baseRequest = baseRequest.desc()
      } else {
        baseRequest = baseRequest.asc()
      }
    }
    if (orderSelection.pagination) {
      baseRequest = baseRequest
        .limit(orderSelection.pagination.limit)
        .page(orderSelection.pagination.page)
    }
    if (orderSelection.search) {
      baseRequest = baseRequest
        .search(orderSelection.search)
    }
  }

  return baseRequest.get()
}
