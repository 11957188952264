import IArticle from './IArticle'
import SeoSnippet from './SeoSnippet'
import countWords from '../../utils/string/countWords'
import getShort from '../../utils/string/getShort'
import replaceRelativeUrlsAtSrc from '../../utils/string/replaceRelativeUrlsAtSrc'
import config from '../../config/Config'
import ImagePair from '../image-pair/ImagePair'
import { createArticleLinkUrl, } from '../../url'

class Article implements IArticle {
  id:           number;
  companyId:    number;
  parentId:     number;
  createdAt:    Date;
  name:         string;
  content:      string;
  shortContent: string;
  seoSnippet:   SeoSnippet;
  imagePair:    ImagePair;
  slug:         string;
  url:          string;
  /**
   * Reading speed - words per minute
   */
  protected readingSpeed = 200;

  constructor(rawArticle: any) {
    const article = {...rawArticle};

    if (rawArticle.id) {
      this.id           = article.id;
      this.slug         = article.slug;
      this.companyId    = article.companyId;
      this.parentId     = article.parentId;
      this.name         = article.name;
      this.content      = article.content;
      this.createdAt    = article.createdAt;
      this.shortContent = article.shortContent;
      /**
       * Check for seo module enabled
       */
      this.seoSnippet = new SeoSnippet(article.seoSnippet);
      this.imagePair  = new ImagePair(article.imagePair);
      this.url        = article.url;
    } else {
      this.id         = parseInt(article.page_id);
      this.slug       = article.seo_name;
      this.companyId  = parseInt(article.company_id);
      this.parentId   = parseInt(article.parent_id);
      this.name       = article.page;
      this.content    = replaceRelativeUrlsAtSrc(article.description, config.backendSiteUrl);
      this.shortContent = getShort(article.description, 25);
      this.createdAt  = new Date(0);
      this.createdAt.setSeconds(parseInt(article.timestamp || '0'));
      /**
       * Check for seo module enabled
       */
      this.seoSnippet = new SeoSnippet({
        title: article.page_title,
        metaDescription: article.meta_description || this.content
      });
      this.imagePair = new ImagePair(article.main_pair || {});
      this.url       = createArticleLinkUrl(this.slug);
    }

    if (!this.id) {
      throw new Error('Id param is missing. Check incoming object')
    }
  }

  getReadingTime(): number {
    return Math.ceil(countWords(this.content) / this.readingSpeed);
  }

  getSmallIcon() {
    return this.imagePair.getIconBySize(
      config.articleSmallIconWidth,
      config.articleSmallIconHeight
    )
  }
}


export default Article
