import values from 'lodash/values'

import IProductGroup from './IProductGroup'
import Shipping from '../shipping/Shipping'

class ProductGroup implements IProductGroup {
  companyId: number;
  companyName: string;
  shippings: Array<Shipping>;

  constructor(rawGroup: IProductGroup | any) {
    const group = {...rawGroup};

    this.companyId = group.companyId || group.company_id || 0;

    if (!this.companyId) {
      throw new Error('company id is missing')
    }

    this.companyName = group.companyName || group.name || '';

    const shippingsArray = (
      Array.isArray(group.shippings)
      ?
        group.shippings
      :
        values(group.shippings) || []
    );

    this.shippings = shippingsArray.map((shipping: any) => new Shipping(shipping));
  }
}

export default ProductGroup
