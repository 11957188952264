// import { createSelector } from 'reselect'
import { StoreState } from '../types'
import { createUuid } from '../../entities/testimonial/ReviewsThread'
import TestimonialDraft from '../../entities/testimonial/TestimonialDraft'
import ReviewableEntities from '../../constants/ReviewableEntities'
import Product from '../../entities/product/Product'

export const getReviewThreadBy = (state: StoreState|StoreState['Review'], props: any) =>
{
  const reviewState = ('Review' in state) ? state.Review : state;

  return reviewState.reviews.find(thread => thread.uuid === createUuid(props.id, props.type))
}

export const getReviewThreadByProduct = (state: StoreState, product?: Product) =>
product ? getReviewThreadBy(state, {id: product.id, type: ReviewableEntities.PRODUCT}) : undefined

export const getReviewDraftBy = (state: StoreState, props: any): TestimonialDraft | undefined =>
  state.Review.drafts.find(draft => draft.uuid === createUuid(props.id, props.type))

export const isRequesting = (state: StoreState, props: any): boolean =>
  !!state.Review.requestingIds.find(uuid => uuid === createUuid(props.id, props.type))

export const getReviewErrorBy = (state: StoreState, props: any) => {

  const error = state.Review.errors.find(error => error.uuid === createUuid(props.id, props.type))

  return error && error.error
}

export const getIsSuccessBy = (state: StoreState, props: any): boolean => {

  const isSuccess = state.Review.successes.find(success => success === createUuid(props.id, props.type))

  return !!isSuccess
}

// export const getCurrentOrder = createSelector(
//   [ getOrderState ],
//   (orderState) => {
//     return orderState.order
//   }
// )
