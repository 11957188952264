import VendorActionTypes from './VendorActionTypes'
import Vendor from '../../../entities/vendor/Vendor'
import { persistVendor } from '../../../db/models/Vendor'

import {
  RequestVendor,
  RequestVendorSuccess,
  RequestVendorFailure,
} from './RequestVendor'

import {
  DbRequestVendor,
  DbRequestVendorSuccess,
  DbRequestVendorFailure,
} from './DbRequestVendor'

import {
  RequestVendors,
  RequestVendorsSuccess,
  RequestVendorsFailure,
} from './RequestVendors'

import {
  DbRequestVendors,
  DbRequestVendorsSuccess,
  DbRequestVendorsFailure,
} from './DbRequestVendors'

export * from './RequestVendor'
export * from './DbRequestVendor'
export * from './RequestVendors'
export * from './DbRequestVendors'

export interface SetVendorIsRequesting {
  type: VendorActionTypes.SET_VENDOR_IS_REQUESTING;
  payload: {
    isRequesting: boolean;
  }
}

export function setVendorIsRequesting(isRequesting: boolean): SetVendorIsRequesting {

  return {
    type: VendorActionTypes.SET_VENDOR_IS_REQUESTING,
    payload: {
      isRequesting,
    }
  }
}

export interface SetCurrentVendor {
  type: VendorActionTypes.SET_CURRENT_VENDOR;
  payload: {
    vendor: Vendor;
  }
}

export function setCurrentVendor(rawVendor: any): SetCurrentVendor {

  const vendor = new Vendor(rawVendor);
  persistVendor(vendor)

  return {
    type: VendorActionTypes.SET_CURRENT_VENDOR,
    payload: {
      vendor
    }
  }
}

export type VendorAction = SetVendorIsRequesting | SetCurrentVendor
| RequestVendors | RequestVendorsSuccess | RequestVendorsFailure
| DbRequestVendors | DbRequestVendorsSuccess | DbRequestVendorsFailure
| RequestVendor | RequestVendorSuccess | RequestVendorFailure
| DbRequestVendor | DbRequestVendorSuccess | DbRequestVendorFailure;
