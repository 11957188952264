import React from 'react'
import IconButton from '@material-ui/core/IconButton'

import appendClassName from '../../../utils/string/appendClassName'

import { IOwnProps } from './IToggleSearchButton'
import './ToggleSearchButton.css'

const ToggleSearchButton:React.FC<IOwnProps> = (props) => {
  const {
    onClick,
    className,
  } = props;

  return (
    <IconButton
      // aria-label="search"
      classes={{
        root: appendClassName('b-toggle-search-button', className)
      }}
      onClick={() => onClick()}
    >
      <SearchIcon />
    </IconButton>
  )
}

const SearchIcon = require('react-feather/dist/icons/search').default;

export default ToggleSearchButton
