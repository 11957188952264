import React from 'react'

import AuthScriptsHandler from './auth/AuthScriptsHandlerContainer'

/**
 * Additional scrips required dinamically
 * Like Google auth library platform.js
 */
const StuffHandler: React.FC = () => {
  return (
    <>
      <AuthScriptsHandler />
    </>
  )
}

export default StuffHandler
